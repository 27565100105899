import React, { forwardRef, useContext, useState } from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import { DD_MM_YYYY } from "../../constants/const";
import {
  amountFormatter,
  getStatusPOBgClass,
  setActiveMenu
} from "../../constants/utils";
import { FormattedMessage } from "react-intl";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { useHistory } from "react-router-dom";

export const PDFPOView = forwardRef(
  ({ purchaseOrderProductList, PODetails }, ref) => {
    const history = useHistory();
    const uiContext = useContext(UIContext);
    const [mainmenu, setMainMenu] = useState(MENUITEMS);

    const {
      purchaseorder_number,
      purchaseorderstatus,
      contact,
      date,
      totalamount
    } = PODetails || {};
    const {
      id: contactId,
      contact_name = "",
      billingaddress = "",
      shippingaddress = ""
    } = contact || {};
    const { name: statusName } = purchaseorderstatus || {};
    const {
      building_no: billingBuildingNo = "",
      zipcode: billingZipcode = "",
      city: billingCity
    } = billingaddress || {};
    const { name: nameCityBilling = "" } = billingCity || {};
    const {
      building_no: shippingBuildingNo = "",
      zipcode: shippingZipcode = "",
      city: shippingCity
    } = shippingaddress || {};
    const { name: nameCityShipping = "" } = shippingCity || {};

    const onClickChangeRoute = (routePath) => {
      setMainMenu(setActiveMenu(mainmenu, routePath));
      uiContext.setCurrentPath(routePath);
      history.push(routePath);
    };

    return (
      <>
        <div className='view-body' id='pdfviewpo'>
          <div
            className='pdf-view'
            style={{ width: "210mm", margin: "0 auto" }}
            ref={ref}
          >
            <div className={`status-ribbon ${getStatusPOBgClass(statusName)}`}>
              {statusName}
            </div>
            <div style={{ padding: "0 0.4in 0 0.55in", height: "0.7in" }}></div>
            <Table style={{ width: "100%", tableLayout: "Fixed", border: "0" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      width: "50%",
                      verticalAlign: "top",
                      border: "0",
                      padding: "0"
                    }}
                  >
                    <span
                      style={{
                        color: "#000",
                        marginBottom: "5px",
                        display: "block",
                        fontSize: "10pt"
                      }}
                    >
                      <b> {contact_name ? contact_name : ""}</b>
                    </span>
                    <span
                      style={{
                        color: "#333",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        fontSize: "10pt"
                      }}
                    >
                      {`${billingBuildingNo ? billingBuildingNo : ""} ${
                        billingZipcode ? billingZipcode : ""
                      }`}{" "}
                      <br />
                      {`${nameCityBilling}`}
                    </span>
                  </td>
                  <td
                    style={{
                      width: "50%",
                      verticalAlign: "top",
                      border: "0",
                      padding: "0",
                      textAlign: "right"
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "24pt",
                        fontWeight: "900",
                        color: "#000",
                        marginBottom: "5px"
                      }}
                    >
                      <FormattedMessage id='TITLE.PURCHASE.ORDER' />
                    </h1>
                    <span
                      style={{
                        color: "#333",
                        fontWeight: "600",
                        fontSize: "10pt"
                      }}
                    >
                      {purchaseorder_number}
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table
              style={{
                width: "100%",
                marginTop: "30px",
                tableLayout: "Fixed",
                border: "0"
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      width: "50%",
                      verticalAlign: "bottom",
                      border: "0",
                      padding: "0",
                      wordWrap: "break-word"
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: "10pt",
                          color: "#000",
                          marginBottom: "5px",
                          lineHeight: "20px",
                          display: "block"
                        }}
                      >
                        <FormattedMessage id='TITLE.VENDOR.ADDRESS' />
                      </span>
                      <span
                        style={{
                          fontSize: "11pt",
                          color: "#2C4592",
                          marginBottom: "8px",
                          fontWeight: "600",
                          lineHeight: "20px",
                          display: "inline-block",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          const path = `/vendors/view-vendor-details/${contactId}`;
                          onClickChangeRoute(path);
                        }}
                      >
                        {contact_name}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "10pt",
                          color: "#000",
                          marginBottom: "5px",
                          lineHeight: "20px",
                          display: "inline-block"
                        }}
                      >
                        {`${billingBuildingNo ? billingBuildingNo : ""} ${
                          billingZipcode ? billingZipcode : ""
                        }`}{" "}
                        <br />
                        {`${nameCityBilling}`}
                      </span>
                    </div>
                    <div
                      style={{ marginTop: "20px", clear: "both", width: "50%" }}
                    >
                      <span
                        style={{
                          fontSize: "10pt",
                          color: "#000",
                          marginBottom: "10px",
                          lineHeight: "20px",
                          display: "block"
                        }}
                      >
                        <FormattedMessage id='TITLE.CHECKOUT.TO' />:
                      </span>
                      <span
                        style={{
                          fontSize: "10pt",
                          color: "#333",
                          marginBottom: "5px",
                          lineHeight: "20px",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        {`${shippingBuildingNo ? shippingBuildingNo : ""} ${
                          shippingZipcode ? shippingZipcode : ""
                        }`}{" "}
                        <br />
                        {`${nameCityShipping}`}
                      </span>
                    </div>
                  </td>
                  <td
                    style={{
                      verticalAlign: "bottom",
                      border: "0",
                      padding: "0",
                      textAlign: "right"
                    }}
                  >
                    <Table
                      style={{
                        width: "100%",
                        wordWrap: "break-word",
                        cellSpacing: "0",
                        cellPadding: "0",
                        tableLayout: "Fixed",
                        border: "0"
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              textAlign: "right",
                              entityDetails: "padding",
                              padding: "5px 10px 5px 0",
                              width: "60%",
                              border: "0"
                            }}
                          >
                            <span
                              style={{
                                fontSize: "10pt",
                                color: "#333",
                                marginBottom: "5px",
                                lineHeight: "20px",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              <FormattedMessage id='TITLE.DATE' />:
                            </span>
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              entityDetails: "padding",
                              padding: "5px 10px 5px 0",
                              width: "40%",
                              border: "0"
                            }}
                          >
                            <span
                              style={{
                                fontSize: "10pt",
                                color: "#333",
                                marginBottom: "5px",
                                lineHeight: "20px",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              <Moment date={date} format={DD_MM_YYYY} />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table
              style={{
                width: "100%",
                tableLayout: "fixed",
                marginTop: "20px",
                border: "0",
                cellSpacing: "0",
                cellPadding: "0"
              }}
            >
              <thead>
                <tr style={{ height: "40px" }}>
                  <td
                    style={{
                      padding: "5px 0px 5px 5px",
                      verticalAlign: "middle",
                      width: "5%",
                      textAlign: "center",
                      fontSize: "10pt",
                      color: "#fff",
                      background: "#333"
                    }}
                  >
                    #
                  </td>
                  <td
                    style={{
                      padding: "5px 10px 5px 20px",
                      verticalAlign: "middle",
                      textAlign: "left",
                      wordWrap: "break-word",
                      fontSize: "10pt",
                      color: "#fff",
                      background: "#333"
                    }}
                  >
                    <FormattedMessage id='TITLE.PRODUCT' />
                  </td>
                  <td
                    style={{
                      padding: "5px 10px 5px 5px",
                      verticalAlign: "middle",
                      width: "12%",
                      textAlign: "right",
                      wordWrap: "break-word",
                      fontSize: "10pt",
                      color: "#fff",
                      background: "#333"
                    }}
                  >
                    <FormattedMessage id='TITLE.QTY' />
                  </td>
                  <td
                    style={{
                      padding: "5px 10px 5px 5px",
                      verticalAlign: "middle",
                      width: "15%",
                      textAlign: "right",
                      wordWrap: "break-word",
                      fontSize: "10pt",
                      color: "#fff",
                      background: "#333"
                    }}
                  >
                    <FormattedMessage id='TITLE.STATUS' />
                  </td>
                  <td
                    style={{
                      padding: "5px 10px 5px 5px",
                      verticalAlign: "middle",
                      width: "15%",
                      textAlign: "right",
                      wordWrap: "break-word",
                      fontSize: "10pt",
                      color: "#fff",
                      background: "#333"
                    }}
                  >
                    <FormattedMessage id='TITLE.RATE' />
                  </td>
                  <td
                    style={{
                      padding: "5px 10px 5px 5px",
                      verticalAlign: "middle",
                      width: "18%",
                      textAlign: "right",
                      wordWrap: "break-word",
                      fontSize: "10pt",
                      color: "#fff",
                      background: "#333"
                    }}
                  >
                    <FormattedMessage id='TITLE.AMOUNT' />
                  </td>
                </tr>
              </thead>
              <tbody>
                {purchaseOrderProductList &&
                  purchaseOrderProductList.map((product, index) => (
                    <tr key={index} style={{ height: "40px" }}>
                      <td
                        style={{
                          padding: "5px 0px 5px 5px",
                          verticalAlign: "middle",
                          width: "5%",
                          textAlign: "center",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          padding: "5px 10px 5px 20px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          wordWrap: "break-word",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                        className='text-capitalize'
                      >
                        {product?.product?.name}
                      </td>
                      <td
                        style={{
                          padding: "5px 10px 5px 5px",
                          verticalAlign: "middle",
                          width: "12%",
                          textAlign: "right",
                          wordWrap: "break-word",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                      >
                        {product?.quantity}{" "}
                        {product?.product?.productunit?.name}
                        <br />
                        <div className='small-description small-text text-dark'>
                          <FormattedMessage id='TITLE.RECEIVED.QUANTITY' /> :{" "}
                          {product?.received_quantity}
                        </div>
                        {/* //TODO Received quantity will be only shown when status id received or partially received */}
                        {/* 250 Received */}
                      </td>
                      <td
                        style={{
                          padding: "5px 10px 5px 5px",
                          verticalAlign: "middle",
                          width: "15%",
                          textAlign: "right",
                          wordWrap: "break-word",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                      >
                        {product?.product_status}
                      </td>
                      <td
                        style={{
                          padding: "5px 10px 5px 5px",
                          verticalAlign: "middle",
                          width: "15%",
                          textAlign: "right",
                          wordWrap: "break-word",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                      >
                        {amountFormatter(product?.price)}
                      </td>
                      <td
                        style={{
                          padding: "5px 10px 5px 5px",
                          verticalAlign: "middle",
                          width: "18%",
                          textAlign: "right",
                          wordWrap: "break-word",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                      >
                        {amountFormatter(product?.totalamount)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Table
              style={{
                width: "100%",
                tableLayout: "fixed",
                marginTop: "20px",
                border: "0",
                borderTop: "1px solid #ddd",
                cellSpacing: "0",
                cellPadding: "0"
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      padding: "0",
                      verticalAlign: "top",
                      width: "50%",
                      textAlign: "right",
                      wordWrap: "break-word",
                      fontSize: "10pt",
                      color: "#333",
                      border: "0"
                    }}
                  ></td>
                  <td
                    style={{
                      padding: "0",
                      verticalAlign: "top",
                      width: "50%",
                      textAlign: "right",
                      wordWrap: "break-word",
                      fontSize: "10pt",
                      color: "#333",
                      border: "0"
                    }}
                  >
                    <Table
                      style={{
                        width: "100%",
                        tableLayout: "fixed",
                        marginTop: "0px",
                        border: "0",
                        cellSpacing: "0",
                        cellPadding: "0"
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "10px 10px 10px 0px",
                              verticalAlign: "middle",
                              width: "50%",
                              textAlign: "right",
                              wordWrap: "break-word",
                              fontSize: "10pt",
                              fontWeight: "bold",
                              color: "#333",
                              border: "0"
                            }}
                          >
                            <FormattedMessage id='TITLE.TOTAL' />
                          </td>
                          <td
                            style={{
                              padding: "10px 10px 10px 0px",
                              verticalAlign: "middle",
                              width: "50%",
                              textAlign: "right",
                              wordWrap: "break-word",
                              fontSize: "10pt",
                              fontWeight: "bold",
                              color: "#333",
                              border: "0"
                            }}
                          >
                            {amountFormatter(totalamount)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div style={{ pageBreakInside: "avoid" }}>
              <div style={{ marginTop: "30px" }}>
                <label
                  style={{
                    display: "table-cell",
                    fontSize: "10pt",
                    paddingRight: "5px",
                    color: "#333"
                  }}
                >
                  <FormattedMessage id='TITLE.AUTHORIZED.SIGNATURE' />
                </label>
                <div style={{ display: "table-cell" }}>
                  <div
                    style={{
                      width: "200px",
                      borderBottom: "1px solid #333",
                      display: "inline-block"
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div style={{ padding: "0 0.4in 0 0.55in", height: "0.7in" }}></div>
          </div>
        </div>
      </>
    );
  }
);

export default PDFPOView;
