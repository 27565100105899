import React, { useMemo, useState } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button, FormGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { useOutwardUIContext } from "../OutwardUIContext";
import { isEqual } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { dd_MM_yyyy, YYYY_MM_DD } from "../../../constants/const";
import { ErrorModal } from "../../common/modals/ErrorModal";

export function OutwardFilter({
  isLoadingFilter,
  DCStatusList,
  handleCLickOfFilterButtonFilter
}) {
  const intl = useIntl();
  const [filterStatus, setFilterStatus] = useState(" ");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialFilterValues = {
    dcnumber: "",
    status: "",
    startdate: startDate,
    enddate: endDate,
    customer: "",
    totalamountone: "",
    totalamounttwo: "",
    expected_delivery: ""
  };

  const outwardUIContext = useOutwardUIContext();
  const outwardUIUIProps = useMemo(() => {
    return {
      queryParams: outwardUIContext.queryParams,
      setQueryParams: outwardUIContext.setQueryParams
    };
  }, [outwardUIContext]);

  const prepareFilter = (queryParams, values) => {
    const {
      dcnumber,
      startdate,
      enddate,
      customer,
      status,
      totalamountone,
      totalamounttwo
    } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.purchaseorder_number = dcnumber !== "" ? dcnumber : undefined;
    if (startdate && enddate) {
      const date = {
        startdate: startdate ? moment(startdate).format(YYYY_MM_DD) : undefined,
        enddate: enddate ? moment(enddate).format(YYYY_MM_DD) : undefined
      };
      filter.date = date !== "" ? date : undefined;
    }

    filter.contact_name = customer !== "" ? customer : undefined;
    filter.status = status !== "" ? status : undefined;
    filter.totalamountone = totalamountone !== "" ? +totalamountone : undefined;
    filter.totalamounttwo = totalamounttwo !== "" ? +totalamounttwo : undefined;

    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(outwardUIUIProps.queryParams, values);
    if (!isEqual(newQueryParams, outwardUIUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      outwardUIUIProps.setQueryParams(newQueryParams);
    }
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          const { startdate, enddate, totalamountone, totalamounttwo } =
            values || {};
          if (startdate && !enddate) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.SELECT.END.DATE"
              })
            );
            handleErrorModalShow();
            return;
          }
          if (!startdate && enddate) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.SELECT.START.DATE"
              })
            );
            handleErrorModalShow();
            return;
          }
          if (totalamountone && !totalamounttwo) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.ENTER.TO.AMOUNT"
              })
            );
            handleErrorModalShow();
            return;
          }
          if (!totalamountone && totalamounttwo) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.ENTER.FROM.AMOUNT"
              })
            );
            handleErrorModalShow();
            return;
          }
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
          handleReset
        }) => (
          <Form
            className='form form-label-right filter-card'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialFilterValues);
              setStartDate(null);
              setEndDate(null);
              setFilterStatus("");
              handleReset();
            }}
          >
            <Form.Group className='form-group mb-md-0 filter-form-group'>
              <div className='filter-item'>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.CR.NUMBER' />
                    </b>
                  </small>

                  <Form.Control
                    type='text'
                    className='form-control'
                    name='dcnumber'
                    placeholder={intl.formatMessage({
                      id: "TITLE.CR.NUMBER"
                    })}
                    onBlur={handleBlur}
                    value={values.dcnumber}
                    onChange={(e) => {
                      setFieldValue("dcnumber", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.DATE' />
                    </b>
                  </small>

                  <FormGroup>
                    <DatePicker
                      className='mb-1'
                      dateFormat={dd_MM_yyyy}
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setFieldValue("startdate", date);
                      }}
                      placeholderText={intl.formatMessage({
                        id: "TITLE.START.DATE"
                      })}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                    />
                    <DatePicker
                      selected={endDate}
                      dateFormat={dd_MM_yyyy}
                      onChange={(date) => {
                        setEndDate(date);
                        setFieldValue("enddate", date);
                      }}
                      placeholderText={intl.formatMessage({
                        id: "TITLE.END.DATE"
                      })}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                    />
                  </FormGroup>
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.EMPLOYEE' />
                    </b>
                  </small>

                  <Form.Control
                    type='text'
                    className='form-control'
                    name='customer'
                    placeholder={intl.formatMessage({
                      id: "TITLE.EMPLOYEE"
                    })}
                    onBlur={handleBlur}
                    value={values.customer}
                    onChange={(e) => {
                      setFieldValue("customer", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.STATUS' />
                    </b>
                  </small>

                  <Form.Control
                    name='status'
                    as='select'
                    className='select-control'
                    onChange={(event) => {
                      setFieldValue("status", event.target.value);
                      setFilterStatus(event.target.value);
                    }}
                    value={filterStatus}
                  >
                    <option value=''>
                      {intl.formatMessage({
                        id: "TITLE.ALL"
                      })}
                    </option>
                    {DCStatusList &&
                      DCStatusList.map((status, index) => (
                        <option key={index} value={`${status.id}`}>
                          {status.name}
                        </option>
                      ))}
                  </Form.Control>
                </div>
                {/* <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.AMOUNT' />
                    </b>
                  </small>
                  <div className='d-flex'>
                    <Form.Control
                      type='number'
                      className='form-control mr-1'
                      style={{ minWidth: "90px" }}
                      name='totalamountone'
                      placeholder={intl.formatMessage({
                        id: "TITLE.FROM"
                      })}
                      value={values.totalamountone}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onPaste={(evt) => {
                        const values = evt.clipboardData.getData("text");
                        const nonAlpha = values.replace(/[^\d.-]/g, "");
                        evt.preventDefault();
                        setFieldValue("totalamountone", nonAlpha);
                      }}
                      onChange={(e) => {
                        setFieldValue("totalamountone", e.target.value);
                      }}
                      autoComplete='off'
                    />
                    <Form.Control
                      type='number'
                      className='form-control mr-2'
                      style={{ minWidth: "90px" }}
                      name='totalamounttwo'
                      placeholder={intl.formatMessage({
                        id: "TITLE.TO"
                      })}
                      value={values.totalamounttwo}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onPaste={(evt) => {
                        const values = evt.clipboardData.getData("text");
                        const nonAlpha = values.replace(/[^\d.-]/g, "");
                        evt.preventDefault();
                        setFieldValue("totalamounttwo", nonAlpha);
                      }}
                      onChange={(e) => {
                        setFieldValue("totalamounttwo", e.target.value);
                      }}
                      autoComplete='off'
                    />
                  </div>
                </div> */}
              </div>
              <div className='filter-action d-flex'>
                <Button
                  variant='secondary d-flex align-items-center btn-inventory mr-2'
                  type='submit'
                >
                  <Search
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader className={"ml-1 mr-1"} />}
                </Button>
                <Button
                  variant='tertiary d-flex align-items-center btn-inventory'
                  type='reset'
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
