import {
  Home,
  ShoppingBag,
  Users,
  User,
  PieChart,
  Menu,
  Table,
  Trello,
  Box
} from "react-feather";
import Inward from "../assets/fonts/feather/inward.svg";
import Outward from "../assets/fonts/feather/outward.svg";
import { FormattedMessage } from "react-intl";

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: <FormattedMessage id='TITLE.DASHBOARD' />,
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Inward Manager", "Outward Manager", "Asset Manager"]
  },
  {
    path: "/inventoryproducts/products",
    title: <FormattedMessage id='TITLE.PRODUCTS' />,
    icon: Box,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Inward Manager", "Outward Manager", "Asset Manager"]
  },
  {
    path: "/assets/list",
    title: <FormattedMessage id='TITLE.ASSETS' />,
    icon: Trello,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Inward Manager", "Outward Manager", "Asset Manager"]
  },
  {
    path: "/inwards/inward-listing",
    title: <FormattedMessage id='TITLE.INWARDS' />,
    icon: Inward,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Asset Manager", "Inward Manager"]
  },
  {
    path: "/outwards/outward-listing",
    title: <FormattedMessage id='TITLE.CHECKINOUT' />,
    icon: Outward,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Asset Manager", "Outward Manager", "Employee"]
  },
  {
    name: "reports",
    isOpen: false,
    title: <FormattedMessage id='TITLE.REPORTS' />,
    icon: PieChart,
    type: "sub",
    badgeType: "primary",
    active: false,
    role: ["Asset Manager"],
    children: [
      {
        path: "/reports/location-wise",
        title: "location wise",
        type: "link",
        active: false
      },
      {
        path: "/reports/outward-by-customer",
        title: <FormattedMessage id='TITLE.CHECKINOUT.BY.EMPLOYEE' />,
        type: "link",
        active: false
      },

      {
        path: "/reports/outwards-by-product",
        title: <FormattedMessage id='TITLE.CHECKINOUT.BY.PRODUCT' />,
        type: "link",
        active: false
      },
      {
        path: "/reports/inventory-aging-summary",
        title: <FormattedMessage id='TITLE.ASSETS.AGING' />,
        type: "link",
        active: false
      },
      {
        path: "/reports/inventory-valuation-summary",
        title: <FormattedMessage id='TITLE.ASSETS.VALUATION' />,
        type: "link",
        active: false
      },
      {
        path: "/reports/active-po",
        title: <FormattedMessage id='TITLE.ACTIVE.PO' />,
        type: "link",
        active: false
      },
      {
        path: "/reports/low-stock-items",
        title: <FormattedMessage id='TITLE.LOW.STOCK.ITEMS' />,
        type: "link",
        active: false
      },
      {
        path: "/reports/delayed-po-dc",
        title: <FormattedMessage id='TITLE.DELAYED.PO.CR' />,
        type: "link",
        active: false
      },
      {
        path: "/reports/inwards-valuation",
        title: <FormattedMessage id='TITLE.INWARDS.VALUATION' />,
        type: "link",
        active: false
      },
      {
        path: "/reports/outwards-valuation",
        title: <FormattedMessage id='TITLE.CHECKINOUT.VALUATION' />,
        type: "link",
        active: false
      },
      {
        path: "/reports/deprecated-assets",
        title: <FormattedMessage id='TITLE.DEPRECATED.ASSETS' />,
        type: "link",
        active: false
      }
    ]
  },
  {
    name: "master",
    isOpen: false,
    title: <FormattedMessage id='TITLE.MASTER' />,
    icon: Menu,
    type: "sub",
    badgeType: "primary",
    active: false,
    role: ["Admin", "Asset Manager"],
    children: [
      {
        path: "/master/brands/brand-listing",
        title: <FormattedMessage id='TITLE.BRANDS' />,
        type: "link",
        active: false
      },
      {
        path: "/master/categories/category-listing",
        title: <FormattedMessage id='TITLE.CATEGORIES' />,
        type: "link",
        active: false
      },
      {
        path: "/master/productunits/product-unit-listing",
        title: <FormattedMessage id='TITLE.PRODUCT.UNITS' />,
        type: "link",
        active: false
      },
      {
        path: "/master/products",
        title: <FormattedMessage id='TITLE.PRODUCTS' />,
        type: "link",
        active: false
      },
      {
        path: "/master/locations/locations",
        title: <FormattedMessage id='TITLE.LOCATIONS' />,
        type: "link",
        active: false
      },
      {
        path: "/master/customfields",
        title: <FormattedMessage id='TITLE.CUSTOMFIELDS' />,
        type: "link",
        active: false
      },
      {
        path: "/master/audit",
        title: <FormattedMessage id='TITLE.AUDITS' />,
        type: "link",
        active: false
      }
    ]
  },
  {
    path: "/vendors/vendor-listing",
    title: <FormattedMessage id='TITLE.VENDORS' />,
    icon: ShoppingBag,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Admin", "Asset Manager", "Inward Manager"]
  },
  {
    path: "/customers/customer-listing",
    title: <FormattedMessage id='TITLE.EMPLOYEES' />,
    icon: Users,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Admin", "Asset Manager", "Outward Manager"]
  },
  {
    path: "/users/user-listing",
    title: <FormattedMessage id='TITLE.USERS' />,
    icon: User,
    type: "link",
    badgeType: "primary",
    active: false,
    role: ["Admin"]
  }
];
