import React, { useMemo } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
import { useOutwardProductUIContext } from "../OutwardProductUIContext";
import { isEqual } from "lodash";
import { ButtonLoader } from "../../../../helper/ButtonLoader";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

const initialFilterValues = {
  name: "",
  stock_keeping_unit: ""
};

export function OutwardProductFilter({
  isLoadingFilter,
  handleCLickOfFilterButtonFilter,
  handleCLickOfResetButtonFilter
}) {
  const intl = useIntl();
  const outwardProductUIContext = useOutwardProductUIContext();
  const outwardProductUIProps = useMemo(() => {
    return {
      queryParams: outwardProductUIContext.queryParams,
      setQueryParams: outwardProductUIContext.setQueryParams
    };
  }, [outwardProductUIContext]);
  const prepareFilter = (queryParams, values) => {
    const { name, stock_keeping_unit } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.name = name !== "" ? name : undefined;
    filter.stock_keeping_unit =
      stock_keeping_unit !== "" ? stock_keeping_unit : undefined;

    newQueryParams.filter = { ...queryParams.filter, ...filter };
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      outwardProductUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, outwardProductUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      outwardProductUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
          handleCLickOfResetButtonFilter();
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue
        }) => (
          <Form
            className='form form-label-right filter-card'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm({ values: initialFilterValues });
              handleReset();
            }}
          >
            <Form.Group className='form-group mb-md-0 filter-form-group'>
              <div className='filter-item'>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.PRODUCT.NAME' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.PRODUCT.NAME"
                    })}
                    maxLength='70'
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.SKU' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='stock_keeping_unit'
                    placeholder={intl.formatMessage({
                      id: "TITLE.SKU"
                    })}
                    onChange={(e) => {
                      setFieldValue("stock_keeping_unit", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='filter-action d-flex align-items-center'>
                <Button
                  type='submit'
                  variant='secondary d-flex align-items-center btn-inventory mr-2'
                >
                  <Search
                    className='m-0'
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader />}
                </Button>
                <Button
                  type='reset'
                  variant='tertiary d-flex align-items-center btn-inventory'
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                    className='m-0'
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
}
