import NumberFormat from "react-number-format";
import { SERVER_URL } from "../ServerConfig";
import Moment from "react-moment";
import {
  DD_MM_YYYY,
  DD_MM_YYYY_HH_MM_A,
  I18N_CONFIG_KEY,
  STRING_CONSTANTS
} from "./const";
import moment from "moment";
import { AUTH } from "../axios/api";
import { useIntl } from "react-intl";
// import { FormattedMessage, useIntl } from "react-intl";

export function getStatusClass(name) {
  let statusClass = "";
  if (name === "Waiting For Approval") {
    statusClass = "badge badge-pill badge-warning";
  } else if (name === "Rejected") {
    statusClass = "badge badge-pill badge-danger";
  } else if (name === "Approved") {
    statusClass = "badge badge-pill badge-info";
  } else if (name === "Received") {
    statusClass = "badge badge-pill badge-success";
  } else if (name === "Partially Fulfilled") {
    statusClass = "badge badge-pill badge-success";
  } else if (name === "Delivered") {
    statusClass = "badge badge-pill badge-success";
  } else if (name === "Active") {
    statusClass = "badge badge-pill badge-success";
  } else if (name === "Inactive") {
    statusClass = "badge badge-pill badge-warning";
  } else if (name === 1) {
    statusClass = "badge badge-pill badge-success";
  } else if (name === 2) {
    statusClass = "badge badge-pill badge-warning";
  }
  return statusClass;
}

export function getStatusForActiveDeactiveClass(name) {
  let statusClass = "";
  const statusName = parseInt(name);
  if (statusName === 1) {
    statusClass = "badge badge-pill badge-success";
  } else if (statusName === 0) {
    statusClass = "badge badge-pill badge-warning";
  }
  return statusClass;
}

export function getStatusForAuditClass(name) {
  let statusClass = "";
  const statusName = parseInt(name);
  if (statusName === 1) {
    statusClass = "badge badge-pill badge-info";
  } else if (statusName === 2) {
    statusClass = "badge badge-pill badge-success";
  }
  else if (statusName === 3) {
    statusClass = "badge badge-pill badge-danger";
  }
  return statusClass;
}

export function getStatusPOClass(name) {
  let statusClass = "";
  if (name === "Waiting For Approval") {
    statusClass = "badge badge-pill badge-warning";
  } else if (name === "Rejected") {
    statusClass = "badge badge-pill badge-danger";
  } else if (name === "Approved") {
    statusClass = "badge badge-pill badge-info";
  } else if (name === "Received") {
    statusClass = "badge badge-pill badge-success";
  } else if (name === "Partially Fulfilled") {
    statusClass = "badge badge-pill badge-success";
  } else if (name === "Delivered") {
    statusClass = "badge badge-pill badge-success";
  }
  return statusClass;
}

export function getStatusPOBgClass(name) {
  let statusClass = "";
  if (name === "Waiting For Approval") {
    statusClass = "bg-warning";
  } else if (name === "Rejected") {
    statusClass = "bg-danger";
  } else if (name === "Approved") {
    statusClass = "bg-info";
  } else if (name === "Received") {
    statusClass = "bg-success";
  } else if (name === "Partially Fulfilled") {
    statusClass = "bg-success";
  } else if (name === "Delivered") {
    statusClass = "bg-success";
  }
  return statusClass;
}

export function getStatusPOClassForRibbon(name) {
  let statusClass = "";
  if (name === "Waiting For Approval") {
    statusClass = "status-ribbon badge-warning";
  } else if (name === "Rejected") {
    statusClass = "status-ribbon badge-danger";
  } else if (name === "Approved") {
    statusClass = "status-ribbon badge-info";
  } else if (name === "Received") {
    statusClass = "status-ribbon badge-success";
  } else if (name === "Partially Fulfilled") {
    statusClass = "status-ribbon badge-success";
  } else if (name === "Delivered") {
    statusClass = "status-ribbon badge-success";
  }
  return statusClass;
}

export function getProductStatusTextClass(name) {
  let statusClass = "";
  if (name === "Pending") {
    statusClass = "text-info";
  } else if (name === "Received") {
    statusClass = "text-success";
  } else if (name === "Partially Fulfilled") {
    statusClass = "text-success";
  }
  return statusClass;
}

export const amountFormatter = (amount) => {
  return (
    <NumberFormat
      value={amount}
      displayType='text'
      thousandSeparator={true}
      prefix='AED '
      decimalSeparator='.'
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "-";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getCheckInOutStatusName = (obj, isTitle) => {
  if (obj && obj.name) {
    const statusMap = {
      [STRING_CONSTANTS.CHECK_IN]: isTitle ? "TITLE.CHECKEDIN" : obj.name,
      [STRING_CONSTANTS.CHECK_OUT]: isTitle ? "TITLE.CHECKEDOUT" : obj.name
    };

    return statusMap[obj.name] || "Unknown Status";
  } else {
    return "Unknown Status";
  }
};

export const totalAmountFromProductList = (noOfRows) => {
  let totalAmount = 0;
  if (!noOfRows) {
    return totalAmount;
  }
  noOfRows.map((product) => {
    const { price, quantity } = product || {};
    totalAmount = totalAmount + parseFloat(price) * parseInt(quantity);
    return product;
  });
  return totalAmount;
};

export const totalQuantityFromProductList = (noOfRows) => {
  let totalQty = 0;
  if (!noOfRows) {
    return totalQty;
  }
  noOfRows.map((product) => {
    const { quantity } = product || {};
    totalQty = totalQty + parseInt(quantity);
    return product;
  });
  return totalQty;
};

export const stringToDateWithFormat = (dateString) => {
  return new Date(dateString);
};

export const getProductMediaUrl = (filename) => {
  let url = `${SERVER_URL}/api-v1/containers/download?containername=product&filename=${filename}&token=${AUTH}`;
  return url;
};

export const getMediaUrl = (filename, container) => {
  let url = `${SERVER_URL}/api-v1/containers/download?containername=${container}&filename=${filename}&token=${AUTH}`;
  return url;
};

export const dateFormatter = (date) => {
  return <Moment date={date} format={DD_MM_YYYY} />;
};

export const getTotalQuantityFromOutwardByProduct = (
  reportOutwardByProduct
) => {
  return 0;
};

export const getTotalTotalFromOutwardByProduct = (reportOutwardByProduct) => {
  return 0;
};

export const getEncodedURL = (url) => {
  return encodeURI(url);
};

export const renderStatus = (status) => {
  return (
    <span
      className={`${getStatusForActiveDeactiveClass(
        status
      )} status-color text-capitalize`}
    >
      {status && status === 1 ? "Active" : "Inactive"}
    </span>
  );
};

export const customRange = {
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month")
  ]
};

export const customRangeReport = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month")
  ],
  "Last Year": [
    moment().subtract(1, "year").startOf("year"),
    moment().subtract(1, "year").endOf("year")
  ]
};

export const getErrorMessageBasedOnLanguage = (Error) => {
  try{
    let errorMessage = "";
    const { message_en } = Error || {};
    const languageJson = JSON.parse(localStorage.getItem(I18N_CONFIG_KEY));
    if (languageJson) {
      const { selectedLang } = languageJson || "en";
      const key = `message_` + selectedLang;
      errorMessage = Error[key];
    } else {
      errorMessage = message_en;
    }
    return errorMessage ?? "SomeThing Went Wrong";
  }
  catch(err){
    return "SomeThing Went Wrong";
  }
};

export const getActiveDeactiveColorClass = (data) => {
  const { status } = data || {};
  return status === 0 ? "btn-danger" : "btn-success";
};

export const InitialCharacter = (string) => {
  let initials = "";
  if (!string) {
    return initials;
  }
  if (string.length > 1) {
    initials = string.charAt(0).toUpperCase();
  }
  return initials;
};

export const unauthorization = (Error) => {
  const { statusCode } = Error || {};
  if (statusCode === 401) {
    localStorage.clear();
    window.location.reload();
  }
};

export const reloadPage = () => {
  window.location.reload();
};

export const getLanguageCode = (data) => {
  var selected_language_code;
  if (data) {
    selected_language_code = data.selectedLang;
  } else {
    selected_language_code = "ar";
  }
  return selected_language_code;
};

export const InitLanguage = (data) => {
  const intl = useIntl();
  return intl.formatMessage({ id: data });
};

export const setActiveMenu = (mainmenu, item) => {
  const menus = mainmenu.map((menu) => {
    if (item === menu.path) {
      menu.active = true;
    } else {
      menu.active = false;
    }
    return menu;
  });
  return menus;
};

export const getUTCTime = (date) => {
  const dateString = moment(date)
    .utc(true)
    .format(DD_MM_YYYY_HH_MM_A)
    .toString();
  return dateString;
};

export const getHistoryActions = (editHistory) => {
  let actionString = "";
  const {
    approved_by,
    edited_by,
    inward_by,
    outward_by,
    received_by,
    rejected_by
  } = editHistory || {};
  if (approved_by) {
    actionString = "TITLE.PURCHASE.ORDER.APPROVED";
  } else if (edited_by) {
    actionString = "TITLE.PURCHASE.ORDER.EDITED";
  } else if (inward_by) {
    actionString = "TITLE.PURCHASE.ORDER.INWARDED";
  } else if (outward_by) {
    actionString = "TITLE.PURCHASE.ORDER.OUTWARDED";
  } else if (received_by) {
    actionString = "TITLE.PURCHASE.ORDER.RECEIVED";
  } else if (rejected_by) {
    actionString = "TITLE.PURCHASE.ORDER.REJECTED";
  }
  return actionString;
};

export const getHistoryActionsDC = (editHistory) => {
  let actionString = "";
  const {
    approved_by,
    edited_by,
    inward_by,
    outward_by,
    received_by,
    rejected_by
  } = editHistory || {};
  if (approved_by) {
    actionString = "TITLE.CHECKOUT.REQUEST.APPROVED";
  } else if (edited_by) {
    actionString = "TITLE.CHECKOUT.REQUEST.EDITED";
  } else if (inward_by) {
    actionString = "TITLE.CHECKOUT.REQUEST.INWARDED";
  } else if (outward_by) {
    actionString = "TITLE.CHECKOUT.REQUEST.OUTWARDED";
  } else if (received_by) {
    actionString = "TITLE.CHECKOUT.REQUEST.DELIVERED";
  } else if (rejected_by) {
    actionString = "TITLE.CHECKOUT.REQUEST.REJECTED";
  }
  return actionString;
};

export const getHistoryActionsUsername = (editHistory) => {
  let userName = "";
  const {
    approvedby,
    editedby,
    inwardby,
    outwardby,
    receivedby,
    rejectedby,
    approved_by,
    edited_by,
    inward_by,
    outward_by,
    received_by,
    rejected_by
  } = editHistory || {};
  if (approved_by) {
    const { full_name } = approvedby || {};
    userName = full_name;
  } else if (edited_by) {
    const { full_name } = editedby || {};
    userName = full_name;
  } else if (inward_by) {
    const { full_name } = inwardby || {};
    userName = full_name;
  } else if (outward_by) {
    const { full_name } = outwardby || {};
    userName = full_name;
  } else if (received_by) {
    const { full_name } = receivedby || {};
    userName = full_name;
  } else if (rejected_by) {
    const { full_name } = rejectedby || {};
    userName = full_name;
  }

  return userName;
};

export const customFieldTypeList = [
  {
    label:"Select",
    value:"select"
  },
  {
    label:"Text",
    value:"text"
  },
  {
    label:"Text Area",
    value:"textarea"
  },
  {
    label:"Date", 
    value:"date"
  },
  {
    label:"Date and Time", 
    value:"datetime"
  },
  {
    label:"File",
    value:"file"
  }
]

export const AuditStatusList = [
  {
    label:"Open",
    value:1
  },
  {
    label:"Schedule",
    value:2
  },
  {
    label:"Closed",
    value:3
  }
]

export const AuditMethodTypeList = [
  {
    label:"Barcode Scan",
    value:1
  },
  {
    label:"Manual Enter Id",
    value:2
  },
  {
    label:"Prompt Each Time",
    value:3
  }
]

export const RepeatsNumberTypeList = [
  {
    label:"Months",
    value:1
  },
  {
    label:"Years",
    value:2
  }
]

export const groupTypeList = [
  {
    label:"Assets",
    value:1
  },
  {
    label:"Categories",
    value:2
  },
  {
    label:"Schools",
    value:3
  },
  {
    label:"Employees",
    value:4
  },
  {
    label:"Kits",
    value:5
  },
  {
    label:"Students",
    value:6
  }
]
