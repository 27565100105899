import React, { useEffect, useRef, useState } from "react";
import {
  cityListAllApi,
  editCustomerApi,
  getSingleCustomerDetailsApi
} from "../../../axios/services/services";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { XCircle, Plus, X, Check } from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  InitLanguage,
  unauthorization
} from "../../../constants/utils";
import { FieldArray, Formik } from "formik";
import { EditCustomerSchema } from "../../../helper/FormikSchema";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorMessage from "../../../helper/ErrorMessage";

export function CustomerEditModal({
  data,
  isEditModal,
  onCloseModal,
  id,
  handleCloseOfEditCustomerModal
}) {
  const [isLoading, setLoading] = useState(false);
  const [selectedBillingCity, setSelectedBillingCity] = useState("");
  const [selectedShippingCity, setSelectedShippingCity] = useState("");
  const [cityList, setCityList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoadingSingleCustomer, setLoadingSingleCustomer] = useState(false);
  const [isCustomerEdited, setCustomerEdited] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [isAddContactPersonTable, setAddContactPersonTable] = useState(true);
  const [initialValues, setInitialValues] = useState({
    contact_name: "",
    email: "",
    phone_number: "",
    mobile_number: "",
    currency: "",
    billingAddress: "",
    billingCity: "",
    billingZipcode: "",
    shippingAddress: "",
    shippingCity: "",
    shippingZipcode: "",
    contact_persons: [
      {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        mobile: ""
      }
    ],
    remarks: "",
    emp_id: ""
  });
  const statusName = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" }
  ];
  const handleEditContactPersonRef = useRef([]);

  async function getCityList() {
    const cityListResponse = await cityListAllApi({});
    const { Error, data } = cityListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const cities = data.map((city) => {
      const { id, name } = city || {};
      city.value = id;
      city.label = capitalizeFirstLetter(name);
      return city;
    });
    setCityList(cities ? cities : []);
  }

  async function getSingleCustomerDetails() {
    enableLoadingSingleCustomer();
    const singleCustomerDetails = await getSingleCustomerDetailsApi({ id });
    disableLoadingSingleCustomer(false);
    const { Error, data } = singleCustomerDetails || {};
    const { get_contact } = data || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const {
      contact_name,
      email,
      status,
      phone_number,
      mobile_number,
      currency,
      billingaddress,
      shippingaddress,
      remarks,
      contact_person,
      user
    } = get_contact || {};
    const { building_no, city, zipcode } = billingaddress || {};
    const {
      building_no: shippingBuildingNo,
      city: shippingCity,
      zipcode: shippingZipcode
    } = shippingaddress || {};

    if (status === 1) {
      setSelectedOption({ value: "1", label: "Active" });
    } else {
      setSelectedOption({ value: "0", label: "Inactive" });
    }

    setSelectedBillingCity({
      value: city?.id,
      label: capitalizeFirstLetter(city?.name)
    });
    setSelectedShippingCity({
      value: shippingCity?.id,
      label: capitalizeFirstLetter(shippingCity?.name)
    });

    const contactPersonsPrevs = [];
    contact_person.map((contactPerson) => {
      const { first_name, last_name, email, phone, mobile, id } =
        contactPerson || {};
      let obj = {
        id,
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        email: email ? email : "",
        phone: phone ? phone : "",
        mobile: mobile ? mobile : ""
      };
      contactPersonsPrevs.push(obj);
      return contactPerson;
    });
    if (contactPersonsPrevs && contactPersonsPrevs.length === 0) {
      setAddContactPersonTable(false);
    }

    let customerDetail = {
      contact_name: contact_name ? contact_name : "",
      email: email ? email : "",
      phone_number: phone_number ? phone_number : "",
      mobile_number: mobile_number ? mobile_number : "",
      currency: currency ? currency : "",
      billingAddress: building_no ? building_no : "",
      billingCity: city?.id,
      billingZipcode: zipcode ? zipcode : "",
      shippingAddress: shippingBuildingNo ? shippingBuildingNo : "",
      shippingCity: shippingCity?.id,
      shippingZipcode: shippingZipcode ? shippingZipcode : "",
      remarks: remarks ? remarks : "",
      contact_persons: contactPersonsPrevs,
      emp_id: user.emp_id
    };
    setInitialValues(customerDetail);
  }

  useEffect(() => {
    getCityList();
  }, []);

  useEffect(() => {
    getSingleCustomerDetails();
  }, []);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingSingleCustomer = () => {
    setLoadingSingleCustomer(true);
  };

  const disableLoadingSingleCustomer = () => {
    setLoadingSingleCustomer(false);
  };

  const renderFirstNameValue = (values, index) => {
    const value = values.contact_persons[index].first_name;
    return value;
  };

  const renderLastNameValue = (values, index) => {
    const value = values.contact_persons[index].last_name;
    return value;
  };

  const renderContactEmailValue = (values, index) => {
    const value = values.contact_persons[index].email;
    return value;
  };

  const renderMobileValue = (values, index) => {
    const value = values.contact_persons[index].mobile;
    return value;
  };

  const renderPhoneValue = (values, index) => {
    const value = values.contact_persons[index].phone;
    return value;
  };

  const handleOnClickOfCopyBillingAddress = (values, setFieldValue) => {
    const { billingAddress, billingCity, billingZipcode } = values || {};
    setFieldValue("shippingAddress", billingAddress);
    setFieldValue("shippingCity", billingCity);
    setFieldValue("shippingZipcode", billingZipcode);
    setSelectedShippingCity(selectedBillingCity);
  };

  const editCustomer = async (values) => {
    enableLoading();
    const {
      contact_name,
      email,
      phone_number,
      mobile_number,
      currency,
      billingAddress,
      billingCity,
      billingZipcode,
      shippingAddress,
      shippingCity,
      shippingZipcode,
      remarks,
      contact_persons,
      emp_id
    } = values || {};

    const { value: status } = selectedOption;

    let obj = {
      id,
      contact_name,
      email,
      phone_number,
      mobile_number,
      currency,
      remarks,
      address: {
        billingaddressId: billingAddress,
        billing_zipcode: billingZipcode,
        billing_cityId: billingCity,
        shippingaddressId: shippingAddress,
        shipping_cityId: shippingCity,
        shipping_zipcode: shippingZipcode
      },
      contact_person: contact_persons,
      status: parseInt(status),
      emp_id
    };
    const editCategoryResponse = await editCustomerApi(obj);

    const { data, Error } = editCategoryResponse || {};
    if (Error) {
      disableLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setCustomerEdited(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableLoading();
      handleCloseOfEditCustomerModal(data);
    }, 2500);
  };
  const intl = useIntl();

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={EditCustomerSchema}
        onSubmit={(values) => {
          editCustomer(values);
        }}
        onReset={(values) => {}}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue,
          errors,
          touched
        }) => (
          <form
            className='form form-label-right filter-card'
            autocomplete='off'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialValues);
              handleReset();
            }}
          >
            <Modal
              size='lg'
              show={isEditModal}
              onHide={onCloseModal}
              aria-labelledby='example-modal-sizes-title-lg'
              dialogClassName='addvendor-modal modal'
              scrollable={true}
            >
              <Modal.Header className='d-flex justify-content-between align-items-center'>
                <h3 className='modal-title' id='exampleModalLabel2'>
                  <FormattedMessage id='TITLE.EDIT.EMPLOYEE' />
                  {isLoadingSingleCustomer && (
                    <Spinner animation='border' size='sm' className='ml-1' />
                  )}
                </h3>
                <Button
                  variant='link p-0 btn-icon'
                  onClick={() => onCloseModal()}
                >
                  <X size={20} className='text-dark mr-0 pr-0' />
                </Button>
              </Modal.Header>
              <Modal.Body className='pb-0'>
                {isCustomerEdited && (
                  <div className='notification icon inline success fs-16'>
                    <Check size={8} className='text-white'></Check>
                    <FormattedMessage id='TITLE.EMPLOYEE.EDITED.SUCCESSFULLY' />
                  </div>
                )}

                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='vendorname'>
                        <FormattedMessage id='TITLE.EMPLOYEE.DISPLAY.NAME' />
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        id='contact_name'
                        name='contact_name'
                        placeholder={intl.formatMessage({
                          id: "TITLE.EMPLOYEE.DISPLAY.NAME"
                        })}
                        className='form-control'
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value) {
                            value = value.replace(/[0-9]/g, "");
                            setFieldValue(`contact_name`, value);
                          } else {
                            setFieldValue(`contact_name`, "");
                          }
                        }}
                        value={values?.contact_name}
                        autoComplete='off'
                      />
                      {touched.contact_name && errors.contact_name ? (
                        <div className='text-danger'>
                          {InitLanguage(errors.contact_name)}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='vendorname'>
                        <FormattedMessage id='TITLE.EMPLOYEE.EMAIL.ADDRESS' />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        id='email'
                        name='email'
                        placeholder={intl.formatMessage({
                          id: "TITLE.EMPLOYEE.EMAIL.ADDRESS"
                        })}
                        className='form-control'
                        onChange={(e) => {
                          const val = e.target.value;
                          if (val) {
                            setFieldValue(`email`, val);
                          } else {
                            setFieldValue(`email`, "");
                          }
                        }}
                        value={values?.email}
                        autoComplete='off'
                      />
                      {touched.email && errors.email ? (
                        <div className='text-danger'>{errors.email}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='vendorname'>
                        <FormattedMessage id='TITLE.EMPLOYEE.MOBILE.NUMBER' />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        id='mobile_number'
                        type='number'
                        name='mobile_number'
                        placeholder={intl.formatMessage({
                          id: "TITLE.EMPLOYEE.MOBILE.NUMBER"
                        })}
                        className='form-control'
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onPaste={(evt) => {
                          const values = evt.clipboardData.getData("text");
                          const nonAlpha = values.replace(/[^\d.-]/g, "");
                          evt.preventDefault();
                          setFieldValue("mobile_number", nonAlpha);
                        }}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (val) {
                            setFieldValue(`mobile_number`, val);
                          } else {
                            setFieldValue(`mobile_number`, "");
                          }
                        }}
                        value={values?.mobile_number}
                        autoComplete='off'
                      />
                      {errors.mobile_number ? (
                        <div className='text-danger'>
                          {InitLanguage(errors.mobile_number)}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='username'>
                        <FormattedMessage id='TITLE.EMPLOYEE.ID' />{" "}
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        id='emp_id'
                        name='emp_id'
                        placeholder={intl.formatMessage({
                          id: "TITLE.EMPLOYEE.ID"
                        })}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (val) {
                            setFieldValue(`emp_id`, val);
                          } else {
                            setFieldValue(`emp_id`, "");
                          }
                        }}
                        value={values?.emp_id}
                      />
                      {errors.emp_id ? (
                        <div className='text-danger'>
                          {InitLanguage(errors.emp_id)}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='status'>
                        <FormattedMessage id='TITLE.STATUS' />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Select
                        value={selectedOption}
                        onChange={(value) => {
                          handleOptionChange(value);
                        }}
                        isSearchable={true}
                        options={statusName}
                        placeholder={intl.formatMessage({
                          id: "TITLE.SELECT.STATUS"
                        })}
                        id='status'
                        className='react-select-container'
                        classNamePrefix='react-select'
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type='submit'
                  variant='success'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    handleSubmit();
                  }}
                  disabled={isLoading}
                >
                  <FormattedMessage id='TITLE.SAVE' />
                  {isLoading && <ButtonLoader className='mr-1 ml-1' />}
                </Button>
                <Button
                  type='button'
                  variant='tertiary text-dark'
                  onClick={() => onCloseModal()}
                >
                  <FormattedMessage id='TITLE.CANCEL' />
                </Button>
              </Modal.Footer>
            </Modal>
          </form>
        )}
      </Formik>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
