import React, { useState, useMemo, useEffect } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useAssetUIContext } from "../AssetUIContext";
import { isEqual } from "lodash";
import { Formik } from "formik";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { useIntl, FormattedMessage } from "react-intl";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { initialFilter } from "../../customfield-module/CustomFieldUIHelpers";
import { customFieldListMastersApi, customFieldListWithFilterMastersApi } from "../../../axios/services/services";
import { getErrorMessageBasedOnLanguage, unauthorization } from "../../../constants/utils";
import Select from "react-select";
import { useLang } from "../../../i18n";

const initialFilterValues = {
  barcode: "",
  product_name: "",
  category_name: "",
  location_name: "",
  assignedto: "",
  status: ""
};
export function AssetFilter({
  isLoadingFilter,
  handleCLickOfFilterButtonFilter
}) {
  const selectedLang = useLang();
  const intl = useIntl();
  const [filterStatus, setFilterStatus] = useState(" ");
  const [startDate, setStartDate] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [customFieldReqData, setCustomFieldReqData] = useState({})
  const assetUIContext = useAssetUIContext();
  const assetUIProps = useMemo(() => {
    return {
      queryParams: assetUIContext.queryParams,
      setQueryParams: assetUIContext.setQueryParams
    };
  }, [assetUIContext]);

  const [customFieldData,setCustomFieldData] = useState([])

  const prepareFilter = (queryParams, values) => {
    const {
      barcode,
      product_name,
      category_name,
      location_name,
      assignedto,
      status
    } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.barcode = barcode !== "" ? barcode : undefined;
    filter.product_name = product_name !== "" ? product_name : undefined;
    filter.category_name = category_name !== "" ? category_name : undefined;
    filter.location_name = location_name !== "" ? location_name : undefined;
    filter.assignedto = assignedto !== "" ? assignedto : undefined;
    filter.status = status !== "" ? status : undefined;
    // filter.customField = []

    customFieldData.forEach((x)=>{
      if(x.inputValue !== ""){
        filter[`customField_${x.id}`]= x.inputValue
      }
    })

    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(assetUIProps.queryParams, values);
    if (!isEqual(newQueryParams, assetUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      assetUIProps.setQueryParams(newQueryParams);
    }
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  let queryParams = {...initialFilter, pageSize:1000, sortOrder:"asc", sortField:"created_at"}

  async function getCustomFieldList() {
    const customFieldListResponse = await customFieldListWithFilterMastersApi();
    
    const { Error, data } = customFieldListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setCustomFieldData(data.map((x)=>{
      x.inputValue = ""
      return x
    }) ?? []);
  }

  useEffect(()=>{
    getCustomFieldList()
  },[])

  const jsonDecode = (jsonData) => {
    try {
      let fieldValue = JSON.parse(jsonData) ?? []
      return fieldValue.map(x => ({ label: x, value: x }))
    }
    catch (error) {
      return []
    }
  }

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          const { priceone, pricetwo } = values || {};
          if (priceone && !pricetwo) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.ENTER.TO.AMOUNT"
              })
            );
            handleErrorModalShow();
            return;
          }
          if (!priceone && pricetwo) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.ENTER.FROM.AMOUNT"
              })
            );
            handleErrorModalShow();
            return;
          }
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          let resetDataCustomFieldData = customFieldData.filter(x=>x.field_type !== "file").map((x)=>{
            x.inputValue = ""
            return x
          }) ?? []
          setCustomFieldData(resetDataCustomFieldData);
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue
        }) => (
          <Form
            className='form form-label-right filter-card'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialFilterValues);
              setStartDate(null);
              setFilterStatus("");
              handleReset();
            }}
            autoComplete='off'
          >
            <Form.Group className='form-group mb-md-0 filter-form-group'>
              <div className='filter-item'>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.BARCODE.NO' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.BARCODE.NO"
                    })}
                    maxLength='70'
                    onChange={(e) => {
                      setFieldValue("barcode", e.target.value);
                    }}
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.PRODUCT.NAME' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='product_name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.PRODUCT.NAME"
                    })}
                    maxLength='70'
                    onChange={(e) => {
                      setFieldValue("product_name", e.target.value);
                    }}
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.CATEGORY' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='category_name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.CATEGORY"
                    })}
                    onChange={(e) => {
                      setFieldValue("category_name", e.target.value);
                    }}
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.LOCATION' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='location_name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.LOCATION"
                    })}
                    onChange={(e) => {
                      setFieldValue("location_name", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.ASSIGNED.TO' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='assignedto'
                    placeholder={intl.formatMessage({
                      id: "TITLE.ASSIGNED.TO"
                    })}
                    onChange={(e) => {
                      setFieldValue("assignedto", e.target.value);
                    }}
                  />
                </div>

                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.CHECKINOUT' />
                    </b>
                  </small>
                  <Form.Control
                    name='status'
                    as='select'
                    className='select-control'
                    onChange={(event) => {
                      setFieldValue("status", event.target.value);
                      setFilterStatus(event.target.value);
                    }}
                    value={filterStatus}
                  >
                    <option value=''>
                      {intl.formatMessage({
                        id: "TITLE.ALL"
                      })}
                    </option>
                    <option value='1'>
                      {intl.formatMessage({
                        id: "TITLE.CHECKEDIN"
                      })}
                    </option>
                    <option value='0'>
                      {intl.formatMessage({
                        id: "TITLE.CHECKEDOUT"
                      })}
                    </option>
                  </Form.Control>
                </div>
            
              </div>
              <div className='filter-action d-flex'>
                <Button
                  variant='secondary d-flex align-items-center btn-inventory mr-2'
                  type='submit'
                >
                  <Search
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader />}
                </Button>
                <Button
                  variant='tertiary d-flex align-items-center btn-inventory'
                  type='reset'
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                  />
                </Button>
              </div>
              <br></br>
            </Form.Group>
            <Row>
              {customFieldData.map((item,index)=>{
                return item.field_type == "select" ? <Col md={2}>
                  <div className=''>
                    <small className='form-text text-muted mb-1'>
                      <b>
                        {(selectedLang === "en" ? item?.field_name : item?.field_name_in_arabic)}
                      </b>
                    </small>
                    <Form.Control
                      name='status'
                      as='select'
                      className='select-control'
                      onChange={(event) => {
                        customFieldData[index].inputValue = event.target.value;
                        setCustomFieldData([...customFieldData])
                      }}
                      value={item.inputValue}
                    > 
                    <option value="">All</option>
                    {jsonDecode(item.field_value).map((x)=>{
                      return <option value={x.value}>{x.label}</option>
                    })}
                    </Form.Control>
                  </div>
                </Col> : item.field_type=="date" ? <Col md={2}>
                  <div className=''>
                    <small className='form-text text-muted mb-1'>
                      <b>
                        {(selectedLang === "en" ? item?.field_name : item?.field_name_in_arabic)}
                      </b>
                    </small>
                    <Form.Control
                      type='date'
                      id='date'
                      onChange={(event) => {
                        customFieldData[index].inputValue = event.target.value;
                        setCustomFieldData([...customFieldData])
                      }}
                      value={item.inputValue}
                    />
                  </div>
                </Col> : item.field_type=="datetime" ? <Col md={2}>
                  <div className=''>
                    <small className='form-text text-muted mb-1'>
                      <b>
                        {(selectedLang === "en" ? item?.field_name : item?.field_name_in_arabic)}
                      </b>
                    </small>
                    <Form.Control
                      type='datetime-local'
                      id='datetime'
                      onChange={(event) => {
                        customFieldData[index].inputValue = event.target.value;
                        setCustomFieldData([...customFieldData])
                      }}
                      value={item.inputValue}
                    />
                  </div>
                </Col> : 
                <Col md={2}>
                  <div className=''>
                    <small className='form-text text-muted mb-1'>
                      <b>
                        {(selectedLang === "en" ? item?.field_name : item?.field_name_in_arabic)}
                      </b>
                    </small>
                    <Form.Control
                      type='text'
                      id={`text_${index}`}
                      onChange={(event) => {
                        customFieldData[index].inputValue = event.target.value;
                        setCustomFieldData([...customFieldData])
                      }}
                      value={item.inputValue}
                    />
                  </div>
                </Col>
              })}
            </Row>
          </Form>
        )}
      </Formik>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
