import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import POStatusWidgets from "./dashboard-widget/POStatusWidgets";
import PODCSummaryWidgets from "./dashboard-widget/PODCSummaryWidgets";
import DCStatusWidgets from "./dashboard-widget/DCStatusWidgets";
import POSummaryWidgets from "./dashboard-widget/POSummaryWidgets";
import DCSummaryWidgets from "./dashboard-widget/DCSummaryWidgets";
import ExpiryProductWidgets from "./dashboard-widget/ExpiryProductWidgets";
import LowStockWidgets from "./dashboard-widget/LowStockWidgets";
import RecentPOWidgets from "./dashboard-widget/RecentPOWidgets";
import RecentDCWidgets from "./dashboard-widget/RecentDCWidgets";
import TopVenderByAmountWidgets from "./dashboard-widget/TopVenderByAmountWidgets";
import TopCustomerByAmountWidgets from "./dashboard-widget/TopCustomerByAmountWidgets";
import DashboardStatistics from "./dashboard-widget/DashboardStatistics";
import InventorySummary from "./dashboard-widget/InventorySummary";
import {
  getDashboardcounterApi,
  getTop5ProductsApi,
  getTop5POApi,
  getPoandDcStatusApi,
  getPoandDcSummaryApi,
  getPoandDcQuantitySummaryApi,
  getRecentFiveApi,
  getTop5NearExpiryProductApi,
  getRecentFiveCheckinApi
} from "../axios/services/services";
import { ErrorModal } from "./common/modals/ErrorModal";
import {
  getErrorMessageBasedOnLanguage,
  unauthorization
} from "../constants/utils";
import { USER_DETAILS } from "../constants/const";

const Dashboard = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [noOfAssets, setNoOfAssets] = useState(0);

  const [noOfProducts, setNoOfProducts] = useState(0);
  const [noOfCategories, setNoOfCategories] = useState(0);
  const [noOfCustomers, setNoOfCustomers] = useState(0);
  const [noOfVendors, setNoOfVendors] = useState(0);
  const [qtyInHand, setQtyInHand] = useState(0);
  const [qtyToReceive, setQtyToReceive] = useState(0);
  const [top5lowstockProduct, setTop5lowstockProduct] = useState([]);
  const [top5NearExpiryProduct, setTop5NearExpiryProduct] = useState([]);
  const [isLoadingTop5LowStock, setLoadingTop5LowStock] = useState(false);
  const [isLoadingTop5ExpiryProduct, setLoadingTop5ExpiryProduct] =
    useState(false);
  const [top5PO, setTop5PO] = useState([]);
  const [isLoadingTop5PO, setLoadingTop5PO] = useState(false);
  const [isLoadingTop5DC, setLoadingTop5DC] = useState(false);
  const [top5DC, setTop5DC] = useState([]);
  const [isLoadingTop5Vendor, setLoadingTop5Vendor] = useState(false);
  const [top5Vendor, setTop5Vendor] = useState([]);
  const [isLoadingTop5Customer, setLoadingTop5Customer] = useState(false);
  const [top5Customer, setTop5Customer] = useState([]);
  const [isLoadingPoStatus, setLoadingPoStatus] = useState(false);
  const [PoStatus, setPoStatus] = useState([]);
  const [isLoadingDCStatus, setLoadingDCStatus] = useState(false);
  const [DCStatus, setDCStatus] = useState([]);
  const [isLoadingPOSummary, setLoadingPOSummary] = useState(false);
  const [POSummary, setPOSummary] = useState([]);
  const [isLoadingDCSummary, setLoadingDCSummary] = useState(false);
  const [isLoadingPOQuantitySummary, setLoadingPOQuantitySummary] =
    useState(false);
  const [POQuantitySummary, setPOQuantitySummary] = useState([]);
  const [isLoadingDCQuantitySummary, setLoadingDCQuantitySummary] =
    useState(false);
  const [DCQuantitySummary, setDCQuantitySummary] = useState([]);

  useEffect(() => {
    getDashboardCounter();
    getTop5LowStockProduct();
    getTop5NearExpiryProduct();
    getTop5PO();
    getTop5DC();
    getTop5Vendor();
    getTop5Customer();
    getPoStatus();
    getDCStatus();
    getPOSummary();
    getPOQuantitySummary();
    getDCQuantitySummary();
  }, []);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  async function getDashboardCounter() {
    const dashboardCounterResponse = await getDashboardcounterApi();
    const { Error, data } = dashboardCounterResponse || {};
    const {
      no_of_assets,
      no_of_products,
      no_of_categories,
      no_of_customer,
      no_of_vendor,
      qty_in_hand,
      qty_tobe_received
    } = data || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setNoOfAssets(no_of_assets);
    setNoOfProducts(no_of_products);
    setNoOfCategories(no_of_categories);
    setNoOfCustomers(no_of_customer);
    setNoOfVendors(no_of_vendor);
    setQtyInHand(qty_in_hand);
    setQtyToReceive(qty_tobe_received);
  }

  async function getTop5LowStockProduct() {
    // const sortColumnName = "opening_stock";
    enableLoadingTop5LowStock();
    const top5lowstockProductResponse = await getTop5ProductsApi();
    disableLoadingTop5LowStock();
    const { Error, data } = top5lowstockProductResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setTop5lowstockProduct(data);
  }

  async function getTop5NearExpiryProduct() {
    enableLoadingTop5ExpiryProduct();
    const top5lowstockProductResponse = await getTop5NearExpiryProductApi();
    disableLoadingTop5ExpiryProduct();
    const { Error, data } = top5lowstockProductResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setTop5NearExpiryProduct(data);
  }

  async function getTop5PO() {
    if (userRole !== "Outward Manager") {
      enableLoadingTop5PO();
      const top5POResponse = await getRecentFiveCheckinApi();
      disableLoadingTop5PO();
      const { Error, data } = top5POResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setTop5PO(data);
    }
  }

  async function getTop5DC() {
    if (userRole !== "Inward Manager") {
      const contactName = "customer";
      enableLoadingTop5DC();
      const top5DCResponse = await getRecentFiveApi({
        contactName
      });
      disableLoadingTop5DC();
      const { Error, data } = top5DCResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setTop5DC(data);
    }
  }

  async function getTop5Vendor() {
    if (userRole !== "Outward Manager") {
      const contactName = "vendor";
      const columnName = "totalamount";
      enableLoadingTop5Vendor();
      const top5VendorResponse = await getTop5POApi({
        contactName,
        columnName
      });
      disableLoadingTop5Vendor();
      const { Error, data } = top5VendorResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setTop5Vendor(data);
    }
  }

  async function getTop5Customer() {
    if (userRole !== "Inward Manager") {
      const contactName = "customer";
      const columnName = "totalquantity";
      enableLoadingTop5Customer();
      const top5CustomerResponse = await getTop5POApi({
        contactName,
        columnName
      });
      disableLoadingTop5Customer();
      const { Error, data } = top5CustomerResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setTop5Customer(data);
    }
  }

  async function getPoStatus() {
    if (userRole !== "Outward Manager") {
      const contactTypeName = "vendor";
      enableLoadingPoStatus();
      const PoStatusResponse = await getPoandDcStatusApi({
        contactTypeName
      });
      disableLoadingPoStatus();
      const { Error, data } = PoStatusResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setPoStatus(data);
    }
  }

  async function getDCStatus() {
    if (userRole !== "Inward Manager") {
      const contactTypeName = "customer";
      enableLoadingDCStatus();
      const DCStatusResponse = await getPoandDcStatusApi({
        contactTypeName
      });

      disableLoadingDCStatus();
      const { Error, data } = DCStatusResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setDCStatus(data);
    }
  }

  async function getPOSummary(chosenLabel = "thismonth", fromdate, todate) {
    if (chosenLabel || fromdate || todate) enableLoadingPOSummary();
    const POSummaryResponse = await getPoandDcSummaryApi({
      chosenLabel,
      fromdate,
      todate
    });
    if (chosenLabel || fromdate || todate) disableLoadingPOSummary();
    const { Error, data } = POSummaryResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setPOSummary(data);
  }

  async function getPOQuantitySummary(
    chosenLabel = "thismonth",
    fromdate,
    todate
  ) {
    if (userRole !== "Outward Manager") {
      const contactTypeName = "vendor";
      if (chosenLabel || fromdate || todate) {
        enableLoadingPOQuantitySummary();
      }
      let obj = {
        contactTypeName,
        chosenLabel,
        ...(chosenLabel === "custom" && { fromdate }),
        ...(chosenLabel === "custom" && { todate })
      };
      const POQuantitySummaryResponse = await getPoandDcQuantitySummaryApi(obj);
      if (chosenLabel || fromdate || todate) {
        disableLoadingPOQuantitySummary();
      }
      const { Error, data } = POQuantitySummaryResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setPOQuantitySummary(data);
    }
  }

  async function getDCQuantitySummary(
    chosenLabel = "thismonth",
    fromdate,
    todate
  ) {
    if (userRole !== "Inward Manager") {
      const contactTypeName = "customer";
      if (chosenLabel || fromdate || todate) enableLoadingDCQuantitySummary();
      const DCQuantitySummaryResponse = await getPoandDcQuantitySummaryApi({
        contactTypeName,
        chosenLabel,
        ...(chosenLabel === "custom" && { fromdate }),
        ...(chosenLabel === "custom" && { todate })
      });

      if (chosenLabel || fromdate || todate) disableLoadingDCQuantitySummary();
      const { Error, data } = DCQuantitySummaryResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setDCQuantitySummary(data);
    }
  }

  const handlePODCSummaryFilter = (chosenLabel, fromdate, todate) => {
    if (chosenLabel === "Today") {
      chosenLabel = "today";
    }
    if (chosenLabel === "Yesterday") {
      chosenLabel = "yesterday";
    }

    if (chosenLabel === "Last 7 Days") {
      chosenLabel = "lastweek";
    }
    if (chosenLabel === "Last 30 Days") {
      chosenLabel = "last30days";
    }
    if (chosenLabel === "This Month") {
      chosenLabel = "thismonth";
    }
    if (chosenLabel === "Last Month") {
      chosenLabel = "lastmonth";
    }
    if (chosenLabel === "Last Year") {
      chosenLabel = "lastyear";
    }
    if (chosenLabel === "Custom Range") {
      chosenLabel = "custom";
    }
    getPOSummary(chosenLabel, fromdate, todate);
  };

  const handleDCSummaryFilter = (chosenLabel, fromdate, todate) => {
    if (chosenLabel === "Today") {
      chosenLabel = "today";
    }
    if (chosenLabel === "Yesterday") {
      chosenLabel = "yesterday";
    }
    if (chosenLabel === "Last 7 Days") {
      chosenLabel = "lastweek";
    }
    if (chosenLabel === "Last 30 Days") {
      chosenLabel = "last30days";
    }
    if (chosenLabel === "This Month") {
      chosenLabel = "thismonth";
    }
    if (chosenLabel === "Last Month") {
      chosenLabel = "lastmonth";
    }
    if (chosenLabel === "Last Year") {
      chosenLabel = "lastyear";
    }
    if (chosenLabel === "Custom Range") {
      chosenLabel = "custom";
    }
    getDCQuantitySummary(chosenLabel, fromdate, todate);
  };

  const handlePOSummaryFilter = (chosenLabel, fromdate, todate) => {
    if (chosenLabel === "Today") {
      chosenLabel = "today";
    }
    if (chosenLabel === "Yesterday") {
      chosenLabel = "yesterday";
    }
    if (chosenLabel === "Last 7 Days") {
      chosenLabel = "lastweek";
    }
    if (chosenLabel === "Last 30 Days") {
      chosenLabel = "last30days";
    }
    if (chosenLabel === "This Month") {
      chosenLabel = "thismonth";
    }
    if (chosenLabel === "Last Month") {
      chosenLabel = "lastmonth";
    }
    if (chosenLabel === "Last Year") {
      chosenLabel = "lastyear";
    }
    if (chosenLabel === "Custom Range") {
      chosenLabel = "custom";
    }

    getPOQuantitySummary(chosenLabel, fromdate, todate);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoadingTop5LowStock = () => {
    setLoadingTop5LowStock(true);
  };

  const disableLoadingTop5LowStock = () => {
    setLoadingTop5LowStock(false);
  };

  const enableLoadingTop5ExpiryProduct = () => {
    setLoadingTop5ExpiryProduct(true);
  };

  const disableLoadingTop5ExpiryProduct = () => {
    setLoadingTop5ExpiryProduct(false);
  };

  const enableLoadingTop5PO = () => {
    setLoadingTop5PO(true);
  };

  const disableLoadingTop5PO = () => {
    setLoadingTop5PO(false);
  };

  const enableLoadingTop5DC = () => {
    setLoadingTop5DC(true);
  };

  const disableLoadingTop5DC = () => {
    setLoadingTop5DC(false);
  };

  const enableLoadingTop5Vendor = () => {
    setLoadingTop5Vendor(true);
  };

  const disableLoadingTop5Vendor = () => {
    setLoadingTop5Vendor(false);
  };

  const enableLoadingTop5Customer = () => {
    setLoadingTop5Customer(true);
  };

  const disableLoadingTop5Customer = () => {
    setLoadingTop5Customer(false);
  };

  const enableLoadingPoStatus = () => {
    setLoadingPoStatus(true);
  };

  const disableLoadingPoStatus = () => {
    setLoadingPoStatus(false);
  };

  const enableLoadingDCStatus = () => {
    setLoadingDCStatus(true);
  };

  const disableLoadingDCStatus = () => {
    setLoadingDCStatus(false);
  };

  const enableLoadingPOSummary = () => {
    setLoadingPOSummary(true);
  };

  const disableLoadingPOSummary = () => {
    setLoadingPOSummary(false);
  };

  const enableLoadingDCSummary = () => {
    setLoadingDCSummary(true);
  };

  const disableLoadingDCSummary = () => {
    setLoadingDCSummary(false);
  };

  const enableLoadingPOQuantitySummary = () => {
    setLoadingPOQuantitySummary(true);
  };

  const disableLoadingPOQuantitySummary = () => {
    setLoadingPOQuantitySummary(false);
  };

  const enableLoadingDCQuantitySummary = () => {
    setLoadingDCQuantitySummary(true);
  };

  const disableLoadingDCQuantitySummary = () => {
    setLoadingDCQuantitySummary(false);
  };

  return (
    <>
      <Fragment>
        {/* <Breadcrumb title="Dashboard" parent="Dashboard" /> */}
        <Container fluid className='dashboard'>
          <div className='dashboard-section'>
            <Row>
              <Col lg={8}>
                <DashboardStatistics
                  noOfAssets={noOfAssets}
                  noOfProducts={noOfProducts}
                  noOfCustomers={noOfCustomers}
                  noOfCategories={noOfCategories}
                  noOfVendors={noOfVendors}
                />
              </Col>
              <Col lg={4}>
                <InventorySummary
                  qtyInHand={qtyInHand}
                  qtyToReceive={qtyToReceive}
                />
              </Col>
            </Row>
          </div>
          <div className='dashboard-section-bottom'>
            <Row>
              {/* Start Low Stock */}
              {/* <Col lg={4}>
                <LowStockWidgets
                  top5lowstockProduct={top5lowstockProduct}
                  isLoading={isLoadingTop5LowStock}
                />
              </Col> */}
              {/* Start PO Summary */}
              {/* {userRole !== "Outward Manager" && (
                <Col lg={4}>
                  <POSummaryWidgets
                    POQuantitySummary={POQuantitySummary}
                    isLoading={isLoadingPOQuantitySummary}
                    handlePOSummaryFilter={handlePOSummaryFilter}
                  />
                </Col>
              )} */}

              {/* Start DC Summary */}
              {/* {userRole !== "Inward Manager" && (
                <Col lg={4}>
                  <DCSummaryWidgets
                    DCQuantitySummary={DCQuantitySummary}
                    isLoading={isLoadingDCQuantitySummary}
                    handleDCSummaryFilter={handleDCSummaryFilter}
                  />
                </Col>
              )} */}
              {userRole !== "Inward Manager" && (
                <Col lg={12}>
                  <PODCSummaryWidgets
                    POSummary={POSummary}
                    isLoading={isLoadingPOSummary}
                    handlePODCSummaryFilter={handlePODCSummaryFilter}
                  />
                </Col>
              )}
              {/* Start PO/DC Summary */}

              {/* Start PO Status */}
              {userRole !== "Outward Manager" && (
                <Col lg={6}>
                  <POStatusWidgets
                    POStatus={PoStatus}
                    isLoading={isLoadingPoStatus}
                  />
                </Col>
              )}

              {/* Start DC Status */}
              {userRole !== "Inward Manager" && (
                <Col lg={6}>
                  <DCStatusWidgets
                    DCStatus={DCStatus}
                    isLoading={isLoadingDCStatus}
                  />
                </Col>
              )}

              {/* Start Expiry Products */}
              <Col lg={12}>
                <ExpiryProductWidgets
                  top5NearExpiryProduct={top5NearExpiryProduct}
                  isLoading={isLoadingTop5ExpiryProduct}
                />
              </Col>

              {/* Start Recenet PO */}
              {userRole !== "Outward Manager" && (
                <Col lg={6}>
                  <RecentPOWidgets
                    top5PO={top5PO}
                    isLoading={isLoadingTop5PO}
                  />
                </Col>
              )}

              {/* Start Recenet DC */}
              {userRole !== "Inward Manager" && (
                <Col lg={6}>
                  <RecentDCWidgets
                    top5DC={top5DC}
                    isLoading={isLoadingTop5DC}
                  />
                </Col>
              )}

              {/* Start Top Vendor */}
              {userRole !== "Outward Manager" && (
                <Col lg={6}>
                  <TopVenderByAmountWidgets
                    top5Vendor={top5Vendor}
                    isLoading={isLoadingTop5Vendor}
                  />
                </Col>
              )}

              {/* Start Top Customer */}
              {userRole !== "Inward Manager" && (
                <Col lg={6}>
                  <TopCustomerByAmountWidgets
                    top5Customer={top5Customer}
                    isLoading={isLoadingTop5Customer}
                  />
                </Col>
              )}
              {/*  */}
            </Row>
          </div>
        </Container>
      </Fragment>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
};

// javascript:void(0)

export default Dashboard;
