import React from "react";
import { Mail, Smartphone } from "react-feather";
import {
  capitalizeFirstLetter,
  getCheckInOutStatusName
} from "../../constants/utils";

import { FormattedMessage, useIntl } from "react-intl";
import {
  DDD_DD_MMM_YYYY,
  STRING_CONSTANTS,
  USER_DETAILS
} from "../../constants/const";

import Moment from "react-moment";
import { Image, Table } from "react-bootstrap";
import nodatafoundImage from "../../assets/images/dashboard/nodata-found.svg";
import InfiniteScroll from "react-infinite-scroll-component";

export function DefaultCustomerView({
  customerDetails,
  historyList,
  handlePaginationOfHistoryListView,
  isLoadingHistoryView,
  isLoadMoreLocHistoryView
}) {
  const intl = useIntl();

  return (
    <>
      <div>
        <div className='highlighted-row'>
          <div className='d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row'>
            <div className='left'>
              <h3 className='modal-title mb-2'>
                {capitalizeFirstLetter(customerDetails?.contact_name)}
              </h3>
              <div className='text-dark mb-2'>
                <Mail size={12} className='text-dark mr-2' />
                {customerDetails?.email ? customerDetails?.email : "-"}
              </div>

              <div className='text-dark mb-2'>
                <Smartphone size={12} className='text-dark mr-2' />
                <span className='ltr-direction'>
                  {customerDetails?.mobile_number
                    ? customerDetails?.mobile_number
                    : "-"}
                </span>
              </div>
            </div>
            <div className='right'>
              <h5 className='fs-14 text-right text-dark'>
                <FormattedMessage id='TITLE.ITEMS.TO.BE.DELIVERED' />
                <br />
                <span className='f-w-900 fs-16 text-primary'>
                  {customerDetails?.outstanding_products}
                </span>
              </h5>
            </div>
          </div>
        </div>
        <InfiniteScroll
          scrollableTarget='scrollabledivOverview'
          dataLength={historyList ? historyList.length : 0}
          next={() => {
            handlePaginationOfHistoryListView();
          }}
          hasMore={isLoadMoreLocHistoryView}
          loader={
            <h4 style={{ textAlign: "center" }} className='p-1'>
              <FormattedMessage id='TITLE.LOADING' />
              ...
            </h4>
          }
        >
          <div className='view-body'>
            <Table responsive className='viewpo-table table'>
              <thead>
                <tr>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.PRODUCT.NAME' />
                  </th>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.BARCODE.NUMBER' />
                  </th>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.ASSIGNED.BY' />
                  </th>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.ASSIGNED.TO' />
                  </th>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.CHECKOUT.DATE' />
                  </th>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.RETURNBY.DATE' />
                  </th>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.CHECKINOUT' />
                  </th>
                </tr>
              </thead>
              <tbody>
                {historyList &&
                  historyList.map((location, index) => {
                    return (
                      <tr key={index}>
                        <td className='digits'>{location?.product?.name}</td>
                        <td className='text-dark'>{location?.barcode_no}</td>
                        <td className='text-dark'>
                          {location?.user?.full_name}
                        </td>
                        <td className='text-dark'>
                          {location?.purchaseorder?.contact?.contact_name}
                        </td>

                        <td className='text-dark'>
                          {location?.actiondate ? (
                            <Moment
                              date={location?.actiondate}
                              format={DDD_DD_MMM_YYYY}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className='text-dark'>
                          {location?.purchaseorder?.returnByDate ? (
                            <Moment
                              date={location?.purchaseorder?.returnByDate}
                              format={DDD_DD_MMM_YYYY}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          className={`${
                            getCheckInOutStatusName(
                              location.check_in_out_status
                            ) === STRING_CONSTANTS.CHECK_IN
                              ? "text-primary"
                              : "text-secondary"
                          }`}
                        >
                          {intl.formatMessage({
                            id: getCheckInOutStatusName(
                              location.check_in_out_status,
                              true
                            )
                          })}
                        </td>
                      </tr>
                    );
                  })}

                {historyList &&
                  historyList.length === 0 &&
                  !isLoadingHistoryView && (
                    <tr>
                      <td className='no-record text-center' colSpan={6}>
                        <div className='d-flex align-items-center justify-content-center flex-column'>
                          <Image
                            src={nodatafoundImage}
                            alt='No Data Found'
                            className='img-fluid'
                            style={{ maxHeight: "200px" }}
                          />
                          <span className='d-block text-center'>
                            <FormattedMessage id='TITLE.NO.RECORDS.FOUND' />
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </Table>
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default DefaultCustomerView;
