import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { LocationWiseUIProvider } from "./LocationWiseUIContext";
import { initialFilter } from "./LocationWiseUIHelpers";
// import activepoJSON from "../../../mock/activepo";
import { toAbsoluteUrl } from "../../../helper/helper";
import { DeleteModal } from "../../common/modals/DeleteModal";
import { LcoationWiseLoadingDialog } from "./locationwise-loding-dialog/LocationWiseLoadingDialog";

import { locaionWiseApi } from "../../../axios/services/services";
import LocationWiseCard from "./LocationWiseCard";

const activepoJSON = {
  count: 10,
  data: []
};

export function LocationWisePage({ history }) {
  const [data, setData] = useState({});
  const [transactionList, setTransactionList] = useState(activepoJSON.data);
  const [counterList, setCounterList] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionListTotalCount, setTransactionListTotalCount] = useState(
    activepoJSON.count
  );
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [transactionListExport, setTransactionListExport] = useState(
    activepoJSON.data
  );
  const exportCSVButton = useRef();

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleFilterValues = (values) => {
    setFilterValues(values);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingExportButton = () => {
    setLoadingExportButton(true);
  };

  const disableLoadingExportButton = () => {
    setLoadingExportButton(false);
  };

  const handleSetCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const activepoUIEvents = {
    openViewDialog: (row) => {
      history.push("/view-po-details");
    },
    openEditDialog: (row) => {
      setData(row);
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    }
  };

  async function getReportActivePO() {
    // enableLoading();
    // if (isFilter) {
    //   enableLoadingFilter();
    // }
    const reportActivePOResponse = await locaionWiseApi({
      queryParams
    });
    // disableLoading();
    // if (isFilter) {
    //   disableLoadingFilter();
    // }
    // setFilter(false);
    const { Error, data, count, total } = reportActivePOResponse || {};
    // if (Error) {
    //   unauthorization(Error);
    //   setErrorMessage(getErrorMessageBasedOnLanguage(Error));
    //   handleErrorModalShow();
    //   return;
    // }
    setTransactionList(data ? data : []);
    setTransactionListTotalCount(count ? count : 0);
    // setStartDate(startdate);
    // setEndDate(enddate);
    // setReportActivePOTotalCount(count);
    // setReportActivePOTotalAmount(total);
  }

  useEffect(() => {
    getReportActivePO();
  }, [queryParams]);
  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    history.push("/add-activepo");
  };

  return (
    <div>
      <>
        {/* <Breadcrumb title="Product List" parent="Products" /> */}
        <LocationWiseUIProvider
          activepoUIEvents={activepoUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <LcoationWiseLoadingDialog />
          {/* <StatisticsCounter /> */}
          <LocationWiseCard
            isLoading={isLoading}
            counterList={counterList}
            transactionList={transactionList}
            handleFilterValues={handleFilterValues}
            filterValues={filterValues}
            loadingExportButton={loadingExportButton}
            transactionListExport={transactionListExport}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            transactionListTotalCount={transactionListTotalCount}
            currentPage={currentPage}
            setCurrentPage={handleSetCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
          />
        </LocationWiseUIProvider>

        {isDeleteModalOpen && (
          <DeleteModal
            title={"Delete LocationWise?"}
            description={
              "LocationWise will be deleted forever and cannot be retrieved later. Are you sure about deleting it?"
            }
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
          />
        )}

        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className='danger-icon-wrapper text-center'>
              <span className='svg-icon menu-icon svg-icon-white svg-icon-4x mr-0'>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />
      </>
    </div>
  );
}
