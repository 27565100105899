// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../CategoryUIHelpers";
import { useCategoryUIContext } from "../CategoryUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import StatusTypeFormatter from "../../../columnformatter/StatusTypeFormatter";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import CategorynameFormatter from "../../../columnformatter/CategoryNameFormatter";
import { useIntl } from "react-intl";
import DeprecationValueFormatter from "../../../columnformatter/DeprecationValueFormatter";

export function CategoryTable({
  isLoading,
  categoryList,
  categoryListTotalCount,
  currentPage,
  setCurrentPage
}) {
  const intl = useIntl();

  // Category UI Context
  const categoryUIContext = useCategoryUIContext();
  const categoryUIProps = useMemo(() => {
    return {
      ids: categoryUIContext.ids,
      setIds: categoryUIContext.setIds,
      queryParams: categoryUIContext.queryParams,
      setQueryParams: categoryUIContext.setQueryParams,
      openViewDialog: categoryUIContext.openViewDialog,
      openEditDialog: categoryUIContext.openEditDialog,
      openDeleteDialog: categoryUIContext.openDeleteDialog
    };
  }, [categoryUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "TITLE.CATEGORY.NAME"
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: CategorynameFormatter
    },
    // {
    //   dataField: "totalproducts",
    //   text: "Total products",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style: {
    //     minWidth: "100px",
    //   },
    // },

    {
      dataField: "depreciation_value",
      text: intl.formatMessage({
        id: "TITLE.DEPRECATION.VALUE"
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: DeprecationValueFormatter
    },
    {
      dataField: "status",
      text: intl.formatMessage({
        id: "TITLE.STATUS"
      }),
      sort: true,
      sortCaret: sortCaret,
      // style: {
      //   minWidth: "100px",
      // },
      formatter: StatusTypeFormatter
    },

    {
      dataField: "action",
      text: intl.formatMessage({
        id: "TITLE.ACTIONS"
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: categoryUIProps.openViewDialog,
        openEditDialog: categoryUIProps.openEditDialog,
        openDeleteDialog: categoryUIProps.openDeleteDialog,
        isVisibleViewButton: true
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px"
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: categoryListTotalCount ? categoryListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: categoryUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-head-custom table-head-bg table-borderless table-vertical-center category-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={categoryList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  categoryUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={categoryList}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
