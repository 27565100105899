import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const DeprecatedAssetsUIContext = createContext();

export function useDeprecatedAssetsUIContext() {
  return useContext(DeprecatedAssetsUIContext);
}

export const DeprecatedAssetsUIConsumer = DeprecatedAssetsUIContext.Consumer;

export function DeprecatedAssetsUIProvider({
  deprecatedassetsUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: deprecatedassetsUIEvents.openViewDialog,
    openEditDialog: deprecatedassetsUIEvents.openEditDialog,
    openDeleteDialog: deprecatedassetsUIEvents.openDeleteDialog
  };

  return (
    <DeprecatedAssetsUIContext.Provider value={value}>
      {children}
    </DeprecatedAssetsUIContext.Provider>
  );
}
