import React, { useRef, useState, useEffect, useContext } from "react";
import { AuditLoadingDialog } from "./audit-loading-dialog/AuditLoadingDialog";
import { AuditCard } from "./AuditCard";
import { AuditUIProvider } from "./AuditUIContext";
import SVG from "react-inlinesvg";
import { initialFilter } from "./AuditUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { toAbsoluteUrl } from "../../helper/helper";
import { DeleteModal } from "../common/modals/DeleteModal";
import { EditAuditModal } from "./edit-audit/EditAuditModal";
import {
  auditListMastersApi,
  deleteAuditApi
} from "../../axios/services/services";
import { SuccessModal } from "../common/modals/SuccessModal";
import {
  capitalizeFirstLetter,
  customFieldTypeList,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization
} from "../../constants/utils";
import { useIntl } from "react-intl";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { AddNewAuditModal } from "../../global-modal/AddNewAuditModal";

export function AuditPage({ history }) {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const [data, setData] = useState({});
  const [counterList, setCounterList] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [isOpenAddNewAuditModal, setOpenAddNewAuditModal] = useState(false);
  const [isOpenEditAuditModal, setOpenEditAuditModal] = useState(false);

  const exportCSVButton = useRef();
  const [auditList, setAuditList] = useState([]);
  const [auditListTotalCount, setAuditListTotalCount] = useState();
  const [isUpdateProductDetails, setUpdateProductDetails] = useState(0);
  const [isFilter, setFilter] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isLoadingDeleteProduct, setLoadingDeleteProduct] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  async function getProductList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const productListResponse = await auditListMastersApi({
      queryParams
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count } = productListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setAuditList(data ? data.map((x)=>{
      return {
        ...x,
        field_type: customFieldTypeList.find((y)=>y.value === x.field_type)?.label ?? "",
        categoryList: (()=>{
          try{
            let categoryName = x.categories.map(y=>y.name)
            return categoryName.join(", ")
          }
          catch(err){
            return ""
          }
        })()
      }
    }) : []);
    setAuditListTotalCount(count ? count : 0);
  }

  const deleteAudit = async () => {
    const { id } = data;
    enableLoadingDeleteProduct();
    const deleteProductResponse = await deleteAuditApi({ id });
    const { Error } = deleteProductResponse || {};
    if (Error) {
      disableLoadingDeleteProduct();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.AUDIT.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    window.scrollTo(0, 0);
    setAuditList([]);
    setUpdateProductDetails(Math.random());
    disableLoadingDeleteProduct();
  };

  useEffect(() => {
    getProductList();
  }, [queryParams, isUpdateProductDetails]);

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const enableLoadingDeleteProduct = () => {
    setLoadingDeleteProduct(true);
  };

  const disableLoadingDeleteProduct = () => {
    setLoadingDeleteProduct(false);
  };

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const addNewProductModalShow = () => {
    setOpenAddNewAuditModal(true);
  };

  const addNewAuditModalHide = () => {
    setOpenAddNewAuditModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleFilterValues = (values) => {
    setFilterValues(values);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingExportButton = () => {
    setLoadingExportButton(true);
  };

  const disableLoadingExportButton = () => {
    setLoadingExportButton(false);
  };

  const handleSetCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const editProductModalShow = () => {
    setOpenEditAuditModal(true);
  };

  const editAuditModalHide = () => {
    setOpenEditAuditModal(false);
  };

  const productUIEvents = {
    openViewDialog: (row) => {
      const path = `/master/audit/view/${row.id}`;
      onClickChangeRoute(path);
    },
    openEditDialog: (row) => {
      editProductModalShow();
      setData(row);
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    },
    redirectToViewPage: (row) => {
      const path = `/master/products/view-product-details/${row.id}`;
      onClickChangeRoute(path);
    },
    redirectToCategoryViewPage: (row) => {
      const path = `/master/categories/view-category-details/${row?.category?.id}`;
      onClickChangeRoute(path);
    }
  };

  const onClickOfDeleteButton = () => {
    deleteAudit();
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    addNewProductModalShow();
  };

  const handleCLickOfFilterButtonFilter = (values) => {
    setFilter(true);
  };

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const handleCloseOfEditNewAuditModal = (editNewVendorDetails) => {
    setAuditList([]);
    setUpdateProductDetails(Math.random());
    editAuditModalHide();
  };

  const handleCloseOfAddNewAuditModal = () => {
    setAuditList([]);
    setUpdateProductDetails(Math.random());
    addNewAuditModalHide();
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <div>
      <>
        {/* <Breadcrumb title="Product List" parent="Products" /> */}
        <AuditUIProvider
          productUIEvents={productUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <AuditLoadingDialog />
          <AuditCard
            isLoading={isLoading}
            counterList={counterList}
            auditList={auditList}
            handleFilterValues={handleFilterValues}
            filterValues={filterValues}
            loadingExportButton={loadingExportButton}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            auditListTotalCount={auditListTotalCount}
            currentPage={currentPage}
            setCurrentPage={handleSetCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            isLoadingFilter={isLoadingFilter}
            isFilterOpen={isFilterOpen}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
          />
        </AuditUIProvider>

        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className='danger-icon-wrapper text-center'>
              <span className='svg-icon menu-icon svg-icon-white svg-icon-4x mr-0'>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />

        {/* {isOpenAddNewAuditModal && ( */}
          <AddNewAuditModal
            open={isOpenAddNewAuditModal}
            onCloseModal={addNewAuditModalHide}
            handleCloseOfAddNewAuditModal={handleCloseOfAddNewAuditModal}
          />
        {/* )} */}

        {isOpenEditAuditModal && (
          <EditAuditModal
            open={isOpenEditAuditModal}
            data={data}
            onCloseModal={editAuditModalHide}
            id={data.id}
            handleCloseOfEditNewAuditModal={handleCloseOfEditNewAuditModal}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteProduct}
            title={intl.formatMessage({
              id: "TITLE.DELETE.AUDIT"
            })}
            description={intl.formatMessage({
              id: "TITLE.AUDIT.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
            })}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={capitalizeFirstLetter(data?.name)}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}
        {openSuccessModal && (
          <SuccessModal
            message={successMessage}
            open={openSuccessModal}
            onCloseModal={onCloseSuccessModal}
          />
        )}
      </>
    </div>
  );
}
