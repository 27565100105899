import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Form,
  FormGroup,
  OverlayTrigger,
  Row,
  Spinner,
  Table
} from "react-bootstrap";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import { Check, Info, Plus, XCircle } from "react-feather";
import { Link } from "react-router-dom";
import Switch from "react-switch";

const ProductName = [
  { value: "1", label: "Dell Laptop" },
  { value: "2", label: "Watch" }
];

export function ReceiveInwardForm({ onClickofcancel }) {
  const [isPageLoaderShow, setPageLoaderShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaderShow(false);
    }, 500);
  }, []);
  const [ReceivedDate, setReceivedDate] = useState(null);
  const [ShelfLifeDate, setShelfLifeDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [checked, setChecked] = useState(true);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const [condition2, setcondition2] = useState(true);
  const handlecondition2 = (nextChecked) => {
    setcondition2(nextChecked);
  };
  const [isaddNewReceiveItem, setaddNewReceiveItem] = useState(false);
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [isReceiveviewOpen, setReceiveviewOpen] = useState(false);
  return (
    <>
      <div className='column details-column'>
        {isPageLoaderShow && (
          <div className='section-loader'>
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
          </div>
        )}
        <div
          className='w-100 column-header d-flex align-items-center justify-content-between'
          style={{ minHeight: "71px" }}
        >
          <h2 className='modal-title'>New Purchase Receive</h2>
        </div>
        <div className='column-body with-footer custom-scrollbar'>
          <div className='view-body'>
            <Form autoComplete='off'>
              {isFormSuccess && (
                <div className='notification icon inline success fs-16'>
                  <Check size={8} className='text-white'></Check> Purchase
                  Received created successfully.
                </div>
              )}
              <Row className='mb-3'>
                <Col md={4} lg={3}>
                  <span className='text-dark'>PO Number</span>
                </Col>
                <Col md={4} lg={3}>
                  <span className='text-primary'>PO-00000013</span>
                </Col>
              </Row>
              <Form.Group as={Row} className='mb-3'>
                <Form.Label column md='4' lg='3'>
                  Received Date
                </Form.Label>
                <Col md={4} lg={3}>
                  <ReactDatePicker
                    selected={ReceivedDate}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date) => setReceivedDate(date)}
                    minDate={ReceivedDate}
                    placeholderText='DD/MM/YYYY'
                    className='form-control w-100'
                    closeOnScroll={(e) => e.target === document}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className='mb-5'>
                <Form.Label column md='4' lg='3'>
                  Shelf Life
                </Form.Label>
                <Col md={4} lg={3}>
                  <ReactDatePicker
                    selected={ShelfLifeDate}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date) => setShelfLifeDate(date)}
                    minDate={ShelfLifeDate}
                    placeholderText='DD/MM/YYYY'
                    className='form-control w-100'
                    closeOnScroll={(e) => e.target === document}
                  />
                </Col>
              </Form.Group>
              {/* <div className="notification light-warning icon">
                <Info size={16}></Info>  You can scan the items to be included from the purchase order. <Link className="text-warning ml-1">Scan items</Link>
              </div> */}
              <Row>
                <Col md={12}>
                  <Table
                    className='receive-item-table viewpo-table'
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ minWidth: "200px" }}>Product</th>
                        <th>Location</th>
                        <th className='text-center' style={{ width: "15%" }}>
                          Condition
                        </th>
                        <th className='text-center' style={{ width: "15%" }}>
                          Quantity
                        </th>
                        <th>Barcode No</th>
                        <th style={{ width: "5px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            isSearchable={true}
                            options={ProductName}
                            placeholder='Select'
                            id='state'
                            className='react-select-container receive-product-select'
                            classNamePrefix='react-select'
                          />
                        </td>
                        <td>
                          <Form.Control
                            as='select'
                            className='select-control form-control-sm'
                          >
                            <option value=' '>Location</option>
                            <option value='Location1'>Location1</option>
                            <option value='Location2'>Location2</option>
                            <option value='Location3'>Location3</option>
                            <option value='Location4'>Location4</option>
                          </Form.Control>
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center"
                          }}
                        >
                          <Switch
                            onChange={handleChange}
                            checked={checked}
                            onColor='#009970'
                            offHandleColor='#fff'
                            onHandleColor='#fff'
                            className='react-switch'
                            height={20}
                            width={40}
                          />
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center"
                          }}
                        >
                          1
                        </td>
                        <td>
                          <Form.Control
                            size='sm'
                            id='barcodenumber'
                            name='barcodenumber'
                            type='text'
                            className='form-control small-text'
                            placeholder='Barcode Number'
                            defaultValue='00-AAA-0000000'
                          />
                        </td>
                        <td>
                          {isaddNewReceiveItem && (
                            <Button
                              variant='link p-0 m-0'
                              className='delete-table-row'
                              onClick={() => {
                                setaddNewReceiveItem(!isaddNewReceiveItem);
                              }}
                            >
                              <XCircle
                                size={16}
                                style={{ color: "#990029", marginRight: "0" }}
                              />
                            </Button>
                          )}
                        </td>
                      </tr>
                      {isaddNewReceiveItem && (
                        <tr>
                          <td>
                            <Select
                              defaultValue={selectedOption}
                              onChange={setSelectedOption}
                              isSearchable={true}
                              options={ProductName}
                              placeholder='Select'
                              id='state'
                              className='react-select-container receive-product-select'
                              classNamePrefix='react-select'
                            />
                          </td>
                          <td>
                            <Form.Control
                              as='select'
                              className='select-control'
                              style={{ minWidth: "130px" }}
                            >
                              <option value=' '>Location</option>
                              <option value='Location1'>Location1</option>
                              <option value='Location2'>Location2</option>
                              <option value='Location3'>Location3</option>
                              <option value='Location4'>Location4</option>
                            </Form.Control>
                          </td>
                          <td
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center"
                            }}
                          >
                            <Switch
                              onChange={handlecondition2}
                              checked={checked}
                              onColor='#009970'
                              offHandleColor='#fff'
                              onHandleColor='#fff'
                              className='react-switch'
                              height={20}
                              width={40}
                            />
                          </td>
                          <td
                            style={{
                              verticalAlign: "middle",
                              textAlign: "center"
                            }}
                          >
                            1
                          </td>
                          <td>
                            <Form.Control
                              size='sm'
                              id='barcodenumber'
                              name='barcodenumber'
                              type='text'
                              className='form-control small-text'
                              placeholder='Barcode Number'
                              defaultValue='00-AAA-0000000'
                            />
                          </td>
                          <td>
                            {isaddNewReceiveItem && (
                              <Button
                                variant='link p-0 m-0'
                                className='delete-table-row'
                                onClick={() => {
                                  setaddNewReceiveItem(!isaddNewReceiveItem);
                                }}
                              >
                                <XCircle
                                  size={16}
                                  style={{ color: "#990029", marginRight: "0" }}
                                />
                              </Button>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Button
                    variant='link btn-icon text-primary px-0 fs-14'
                    onClick={() => {
                      setaddNewReceiveItem(!isaddNewReceiveItem);
                    }}
                  >
                    <Plus size={14} className='text-primary' /> Add New Receive
                    Item
                  </Button>
                </Col>
              </Row>
              <div className='mt-3'>
                <Form.Group>
                  <Form.Label for='notes'>Notes (For Internal Use)</Form.Label>
                  <Form.Control
                    id='notes'
                    name='notes'
                    as='textarea'
                    className='form-control'
                    resize='none'
                  />
                </Form.Group>
              </div>
            </Form>
          </div>
        </div>
        <div className='column-footer'>
          <Button
            variant='success mr-3 btn-inventory'
            onClick={() => {
              window.scrollTo(0, 0);
              setFormSuccess(!isFormSuccess);
              setTimeout(() => {
                onClickofcancel();
              }, 2500);
            }}
          >
            Save
          </Button>
          <Button
            variant='tertiary text-dark btn-inventory'
            onClick={() => {
              onClickofcancel();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
}

export default ReceiveInwardForm;
