import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner
} from "react-bootstrap";
import { Edit2, Plus, X } from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { AddNewVendorModal } from "../../global-modal/AddNewVendorModal";
import { DeleteModal } from "../common/modals/DeleteModal";
import { DefaultVendorView } from "./DefaultVendorView";
import ReceiveInwardForm from "./ReceiveVendorForm";
import {
  vendorListApi,
  getSingleVendorDetailsApi,
  deleteVendorApi,
  uploadDocumentApi,
  editVendorApi,
  getRecentFivePOApi,
  POStatusListApi,
  getRecentFiveCompletedPOApi
} from "../../axios/services/services";
import { ErrorModal } from "../common/modals/ErrorModal";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  renderStatus,
  getActiveDeactiveColorClass,
  unauthorization,
  setActiveMenu
} from "../../constants/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { SectionLoader } from "../../helper/SectionLoader";
import { SuccessModal } from "../common/modals/SuccessModal";
import { EditVendorModal } from "../vendor-module/edit-vendor/EditVendorModal";
import { useFormik } from "formik";
import { EditVendorRemarksSchema } from "../../helper/FormikSchema";
import { FormattedMessage, useIntl } from "react-intl";
import { initialFilter } from "./ViewVendorUIHelpers";
import { ContentLoader } from "../../helper/ContentLoader";
import { USER_DETAILS } from "../../constants/const";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { EditStatusVendorCustomerModal } from "../../global-modal/EditStatusVendorCustomerModal";

let EditVendorInitialValues = {
  remarks: ""
};

const ViewPODetails = ({ history, handlePDFViewValues, ...props }) => {
  const uiContext = useContext(UIContext);
  const [isLoading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpenAddNewVendorModal, setOpenAddNewVendorModal] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [queryParams, setQueryParams] = useState(initialFilter);
  const [isLoadingVendorListView, setLoadingVendorListView] = useState(false);
  const [POStatusList, setPOStatusList] = useState(null);
  const [isLoadMoreVendorListView, setLoadMoreVendorListView] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const { id } = props.match.params;
  const [vendorDetails, setVendorDetails] = useState({});
  const [isUpdateSingleVendorDetails, setUpdateSingleVendorDetails] =
    useState(0);
  const [isLoadingDeleteVendor, setLoadingDeleteVendor] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isUpdateVendorList, setUpdateVendorList] = useState(0);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [isOpenEditVendorModal, setOpenEditVendorModal] = useState(false);
  const [isEditVendorCustomerStatusModal, setEditVendorCustomerStatusModal] =
    useState(false);
  const [selectedVendorCustomerStatus, setSelectedVendorCustomerStatus] =
    useState(null);
  const [isLoadingEditVendor, setLoadingEditVendor] = useState(false);
  const [isLoadingRecentFivePO, setLoadingRecentFivePO] = useState(false);
  const [recentFivePO, setRecentFiveVendor] = useState([]);
  const [isReceiveviewOpen, setReceiveviewOpen] = useState(false);
  const [recentFiveFulfilledPO, setRecentFiveFulfilledPO] = useState([]);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const hiddenFileInput = useRef(null);
  const intl = useIntl();

  async function getSingleVendorDetails() {
    enableLoading();
    const singleVendorDetails = await getSingleVendorDetailsApi({
      id
    });
    disableLoading();
    const { Error, data } = singleVendorDetails || {};
    const { get_contact } = data || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setVendorDetails(get_contact ? get_contact : []);
    getRecentFiveOpenPO(id, get_contact);
    getRecentFiveFulfilledPO(get_contact);
    formikEditVendor.setFieldValue("remarks", get_contact?.remarks);
  }

  async function getVendorList() {
    enableLoadingVendorListView();
    const vendorListResponse = await vendorListApi({
      queryParams
    });
    disableLoadingVendorListView();
    const { Error, data } = vendorListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setVendorList((vendorList) => [...vendorList, ...data]);
    if (data.length >= 20) {
      setLoadMoreVendorListView(true);
    } else {
      setLoadMoreVendorListView(false);
    }
  }

  useEffect(() => {
    getPOStatusList();
  }, [isUpdateSingleVendorDetails, id]);

  useEffect(() => {
    getVendorList();
  }, [queryParams, isUpdateVendorList]);

  useEffect(() => {
    if (POStatusList) {
      getSingleVendorDetails();
    }
  }, [id, isUpdateSingleVendorDetails, POStatusList]);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const deleteVendor = async () => {
    enableLoadingDeleteVendor();
    const deleteVendorResponse = await deleteVendorApi({ id });
    const { Error } = deleteVendorResponse || {};
    if (Error) {
      disableLoadingDeleteVendor();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.VENDOR.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    setTimeout(() => {
      disableLoadingDeleteVendor();
      const path = "/vendors/vendor-listing";
      onClickChangeRoute(path);
    }, 2500);
    window.scrollTo(0, 0);
    setVendorList([]);
    setUpdateVendorList(Math.random());
  };

  async function getPOStatusList() {
    const POStatusListResponse = await POStatusListApi({});
    const { Error, get_po_status } = POStatusListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const poStatusList = get_po_status.map((status) => {
      const { id, name } = status || {};
      status.label = name;
      status.value = id;
      return status;
    });

    setPOStatusList(poStatusList ? poStatusList : []);
  }

  async function getRecentFiveOpenPO(id, vendorDetails) {
    const { contact_name } = vendorDetails || {};
    if (userRole !== "Admin") {
      const sortColumnName = "date";
      const [waitingStatus] = POStatusList.filter((status) => {
        return status.name === "Waiting For Approval";
      });
      const [issueStatus] = POStatusList.filter((status) => {
        return status.name === "Approved";
      });
      const [partialStatus] = POStatusList.filter((status) => {
        return status.name === "Partially Fulfilled";
      });
      enableLoadingRecentFivePO();
      const top5VendorResponse = await getRecentFivePOApi({
        id,
        sortColumnName,
        waitingStatus: waitingStatus.id,
        issueStatus: issueStatus.id,
        partialStatus: partialStatus.id,
        contact_name: vendorDetails.contact_name
      });
      disableLoadingRecentFivePO();
      const { Error, data } = top5VendorResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setRecentFiveVendor(data);
    }
  }

  async function getRecentFiveFulfilledPO(vendorDetails) {
    const { contact_name } = vendorDetails || {};
    if (userRole !== "Admin") {
      const sortColumnName = "date";
      const [waitingStatus] = POStatusList.filter((status) => {
        return status.name === "Received";
      });
      enableLoadingRecentFivePO();
      const top5VendorResponse = await getRecentFiveCompletedPOApi({
        sortColumnName,
        status: waitingStatus.id,
        contact_name: contact_name
      });
      disableLoadingRecentFivePO();
      const { Error, data } = top5VendorResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setRecentFiveFulfilledPO(data);
    }
  }

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingVendorListView = () => {
    setLoadingVendorListView(true);
  };

  const disableLoadingVendorListView = () => {
    setLoadingVendorListView(false);
  };

  const enableLoadingRecentFivePO = () => {
    setLoadingRecentFivePO(true);
  };

  const disableLoadingRecentFivePO = () => {
    setLoadingRecentFivePO(false);
  };

  const enableLoadingEditVendor = () => {
    setLoadingEditVendor(true);
  };

  const disableLoadingEditVendor = () => {
    setLoadingEditVendor(false);
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const onClickOfDeleteButton = () => {
    deleteVendor();
  };

  const enableLoadingDeleteVendor = () => {
    setLoadingDeleteVendor(true);
  };

  const disableLoadingDeleteVendor = () => {
    setLoadingDeleteVendor(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const addNewVendorModalShow = () => {
    setOpenAddNewVendorModal(true);
  };

  const addNewVendorModalHide = () => {
    setOpenAddNewVendorModal(false);
  };

  const handleEditVendorStatusModalShow = () => {
    setEditVendorCustomerStatusModal(true);
  };

  const handleEditVendorStatusModalHide = () => {
    setEditVendorCustomerStatusModal(false);
  };

  const handleOnClickOfcancle = () => {
    setReceiveviewOpen(false);
  };

  const handlePaginationOfVendorListView = () => {
    const { pageNumber, pageSize } = queryParams || {};
    setQueryParams({ pageNumber: pageNumber + 1, pageSize });
  };

  const editVendorModalShow = () => {
    setOpenEditVendorModal(true);
  };

  const editVendorModalHide = () => {
    setOpenEditVendorModal(false);
  };

  const attachDocument = async (event) => {
    let files = {
      file: event,
      containername: "product"
    };
    const uploadDocumentResponse = await uploadDocumentApi(files);
    const { Error } = uploadDocumentResponse;
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.FILE.ATTACH.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    setUploadDocument(uploadDocumentResponse);
    const [uploadedDoc] = uploadDocumentResponse;
    const { filename, mimetype } = uploadedDoc || {};
    let editVendorObj = {
      id: id,
      purchaseorder_document: {
        filename: filename,
        file_type: mimetype
      }
    };
    const editVendorResponse = await editVendorApi(editVendorObj);
    const { Error: editVendorError } = editVendorResponse || {};
    if (editVendorError) {
      unauthorization(editVendorError);
      setErrorMessage(getErrorMessageBasedOnLanguage(editVendorError));
      handleErrorModalShow();
      return;
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    attachDocument(fileUploaded);
  };

  const handleCloseOfEditNewVendorModal = () => {
    setVendorList([]);
    setQueryParams(initialFilter);
    setUpdateVendorList(Math.random());
    setUpdateSingleVendorDetails(Math.random());
    editVendorModalHide();
  };

  const handleCloseOfAddNewVendorModal = () => {
    setVendorList([]);
    setQueryParams(initialFilter);
    setUpdateVendorList(Math.random());
    addNewVendorModalHide();
  };

  const handleSelectedVendorCustomerStatus = (data) => {
    setSelectedVendorCustomerStatus(data);
  };

  const handleClickOfEditStatusVendorCustomerUpdateButton = () => {
    handleSaveOfStatus();
  };

  const onClickOfEditStatusVendorCustomerCancelButton = () => {
    handleEditVendorStatusModalHide();
  };

  const handleUpdateSingleVendorDetails = () => {
    setUpdateSingleVendorDetails(Math.random());
  };

  const handleSaveOfStatus = async () => {
    enableLoadingEditVendor();
    const { value } = selectedVendorCustomerStatus;
    const editStatus = await editVendorApi({
      id,
      status: parseInt(value)
    });
    const { Error } = editStatus || {};
    if (Error) {
      disableLoadingEditVendor();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    if (value === "1") {
      setSuccessMessage(
        intl.formatMessage({ id: "TITLE.VENDOR.MARKED.AS.ACTIVE" })
      );
    } else {
      setSuccessMessage(
        intl.formatMessage({ id: "TITLE.VENDOR.MARKED.AS.INACTIVE" })
      );
    }
    onOpenSuccessModal();
    setVendorList([]);
    handleEditVendorStatusModalHide();
    setQueryParams(initialFilter);
    setUpdateVendorList(Math.random());
    setUpdateSingleVendorDetails(Math.random());
    disableLoadingEditVendor();
  };

  const CreateNewPO = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.CREATE.NEW.VENDOR' />
    </Tooltip>
  );

  const editTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.EDIT.VENDOR' />
    </Tooltip>
  );

  const attachefileTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Attach File
    </Tooltip>
  );

  const updateVendorDataOnClick = (id, name) => {
    const path = `/vendors/view-vendor-details/${id}`;
    onClickChangeRoute(path);
  };

  const formikEditVendor = useFormik({
    initialValues: EditVendorInitialValues,
    validationSchema: EditVendorRemarksSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableSaveLoading();
      const { remarks } = values || {};
      let obj = {
        id,
        remarks
      };
      const editCategoryResponse = await editVendorApi(obj);
      const { Error } = editCategoryResponse || {};
      if (Error) {
        disableSaveLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }

      if (vendorDetails?.remarks) {
        setSuccessMessage(
          intl.formatMessage({ id: "TITLE.REMARKS.EDITED.SUCCESSFULLY" })
        );
      } else {
        setSuccessMessage(
          intl.formatMessage({ id: "TITLE.REMARKS.ADDED.SUCCESSFULLY" })
        );
      }

      onOpenSuccessModal();
      window.scrollTo(0, 0);
      handleUpdateSingleVendorDetails();
      disableSaveLoading();
    }
  });

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Card className='p-0 mb-0'>
        <div className='details-view d-flex'>
          {isLoading && <ContentLoader />}
          <div className='column list-column list-column-small border-right d-none d-lg-block'>
            <div className='column-header d-flex align-items-center justify-content-between'>
              <h2 className='modal-title'>
                <FormattedMessage id='TITLE.ALL.VENDORS' />
              </h2>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={CreateNewPO}
              >
                <Button
                  variant='primary btn-inventory btn-icon'
                  size='sm'
                  onClick={() => addNewVendorModalShow()}
                >
                  <Plus
                    size={14}
                    className='text-secondary'
                    onClick={() => addNewVendorModalShow()}
                  ></Plus>{" "}
                  <FormattedMessage id='TITLE.NEW' />
                </Button>
              </OverlayTrigger>
            </div>
            {isLoadingVendorListView && <SectionLoader />}
            <div id='scrollableDiv' className='column-body custom-scrollbar'>
              {!isLoadingVendorListView && (
                <InfiniteScroll
                  scrollableTarget='scrollableDiv'
                  dataLength={vendorList.length}
                  next={() => {
                    handlePaginationOfVendorListView();
                  }}
                  hasMore={isLoadMoreVendorListView}
                  loader={
                    <h4 style={{ textAlign: "center" }} className='p-1'>
                      <FormattedMessage id='TITLE.LOADING' />
                      ...
                    </h4>
                  }
                >
                  {vendorList.map((vendor, index) => (
                    <div
                      key={index}
                      className='column-box cursor-pointer'
                      onClick={() => {
                        updateVendorDataOnClick(
                          vendor.id,
                          vendor?.contact_name
                        );
                      }}
                    >
                      <div className='d-flex flex-column'>
                        <h5
                          className={`fs-14 text-primary font-weight-bold mb-0 cursor-pointer ${
                            vendor?.id === id ? "text-secondary" : ""
                          }`}
                        >
                          {capitalizeFirstLetter(vendor?.contact_name)}{" "}
                        </h5>
                        <div className='d-flex align-items-center'>
                          <span className='small-text pr-2 text-dark'>
                            <FormattedMessage id='TITLE.OUTSTANDING.PRODUCTS' />
                            {vendor?.outstanding_products}
                          </span>
                          <span className='text-capitalize py-1 px-2 ml-auto'>
                            {renderStatus(vendor?.status)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </div>
          {!isReceiveviewOpen && (
            <div className='column details-column'>
              <div className='column-header d-flex align-items-center justify-content-between'>
                <h2 className='modal-title'>
                  {capitalizeFirstLetter(vendorDetails?.contact_name)}
                </h2>
                <div className='d-flex'>
                  <span
                    className={`btn btn-icon btn-sm mr-2 text-capitalize ${getActiveDeactiveColorClass(
                      vendorDetails
                    )}`}
                    onClick={() => {
                      const { status } = vendorDetails || {};
                      if (status === 1) {
                        setSelectedVendorCustomerStatus({
                          value: "1",
                          label: "Active"
                        });
                      } else {
                        setSelectedVendorCustomerStatus({
                          value: "0",
                          label: "Inactive"
                        });
                      }
                      handleEditVendorStatusModalShow();
                    }}
                  >
                    {vendorDetails?.status === 0 ? (
                      <FormattedMessage id='TITLE.INACTIVE' />
                    ) : (
                      <FormattedMessage id='TITLE.ACTIVE' />
                    )}
                    {/* {isLoadingEditVendor && (
                      <ButtonLoader className="mr-1 ml-1" />
                    )} */}
                  </span>
                  <ButtonGroup className='mr-2' aria-label='First group'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={editTooltip}
                    >
                      <Button
                        variant='outline-tertiary btn-sm btn-icon'
                        onClick={() => editVendorModalShow()}
                      >
                        <Edit2 size={16} className='p-0 m-0'></Edit2>
                      </Button>
                    </OverlayTrigger>

                    {/* <div>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={attachefileTooltip}
                      >
                        <Button
                          onClick={handleClick}
                          variant="outline-tertiary btn-sm btn-icon"
                        >
                          <Upload size={16} className="p-0 m-0"></Upload>
                        </Button>
                      </OverlayTrigger>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                    </div> */}
                  </ButtonGroup>
                  {userRole !== "Admin" && (
                    <Button
                      variant='secondary btn-icon btn-sm mr-2'
                      onClick={() => {
                        window.scrollTo(0, 0);
                        const path = "/inwards/add-inward";
                        onClickChangeRoute(path);
                      }}
                    >
                      <FormattedMessage id='TITLE.NEW.PURCHASE.ORDER' />
                    </Button>
                  )}

                  <Dropdown>
                    <Dropdown.Toggle variant='dropdown-sm' id='dropdown-basic'>
                      <FormattedMessage id='TITLE.MORE' />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          handleDeleteModalShow();
                        }}
                      >
                        <FormattedMessage id='TITLE.DELETE.VENDOR' />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    variant='close btn-icon btn-sm'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      const path = "/vendors/vendor-listing";
                      onClickChangeRoute(path);
                    }}
                  >
                    <X size={20} className='p-0 m-0 text-dark'></X>
                  </Button>
                </div>
              </div>
              <div className='column-body custom-scrollbar'>
                <Tabs>
                  <TabList className='nav nav-tabs tab-coupon mb-0'>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.OVERVIEW' />
                    </Tab>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.REMARKS' />
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <DefaultVendorView
                      vendorDetails={vendorDetails}
                      id={id}
                      recentFivePO={recentFivePO}
                      isLoading={isLoadingRecentFivePO}
                      recentFiveFulfilledPO={recentFiveFulfilledPO}
                      handleUpdateSingleVendorDetails={
                        handleUpdateSingleVendorDetails
                      }
                    />
                  </TabPanel>
                  <TabPanel>
                    <div className='view-body'>
                      <Form.Group>
                        <Form.Label htmlFor='Status'>
                          <FormattedMessage id='TITLE.REMARKS' />
                          <span className='mr-1 ml-1 text-muted'>
                            (
                            <FormattedMessage id='TITLE.MAX.512.CHARACTERS' />)
                          </span>
                        </Form.Label>
                        <Form.Control
                          id='remarks'
                          name='remarks'
                          as='textarea'
                          placeholder={intl.formatMessage({
                            id: "TITLE.REMARKS"
                          })}
                          className='form-control'
                          resize='none'
                          maxLength={512}
                          {...formikEditVendor.getFieldProps("remarks")}
                        />
                        {formikEditVendor.touched.remarks &&
                        formikEditVendor.errors.remarks ? (
                          <div className='text-danger'>
                            {formikEditVendor.errors.remarks}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Button
                        variant='success mr-3 btn-inventory'
                        onClick={() => {
                          formikEditVendor.handleSubmit();
                        }}
                        disabled={isSaveLoading}
                      >
                        {vendorDetails?.remarks ? (
                          <FormattedMessage id='TITLE.EDIT.REMARKS' />
                        ) : (
                          <FormattedMessage id='TITLE.ADD.REMARKS' />
                        )}

                        {isSaveLoading && (
                          <Spinner
                            animation='border'
                            size='sm'
                            className='ml-1'
                          />
                        )}
                      </Button>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='view-body'>
                      <Form.Group>123</Form.Group>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          )}

          {isReceiveviewOpen && (
            <ReceiveInwardForm onClickofcancel={handleOnClickOfcancle} />
          )}
        </div>
      </Card>

      {isDeleteModalOpen && (
        <DeleteModal
          isLoading={isLoadingDeleteVendor}
          title={intl.formatMessage({ id: "TITLE.DELETE.VENDOR" })}
          description={
            <>
              <FormattedMessage id='TITLE.VENDOR.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER' />{" "}
              <FormattedMessage id='TITLE.ARE.YOU.SURE.ABOUT.DELETING.IT' />
            </>
          }
          isOpen={isDeleteModalOpen}
          onCloseModal={handleDeleteModalHide}
          id={capitalizeFirstLetter(vendorDetails?.contact_name)}
          onClickOfDeleteButton={onClickOfDeleteButton}
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}

      {isOpenAddNewVendorModal && (
        <AddNewVendorModal
          open={isOpenAddNewVendorModal}
          onCloseModal={addNewVendorModalHide}
          handleCloseOfAddNewVendorModal={handleCloseOfAddNewVendorModal}
        />
      )}

      {isOpenEditVendorModal && (
        <EditVendorModal
          open={isOpenEditVendorModal}
          data={vendorDetails}
          onCloseModal={editVendorModalHide}
          id={vendorDetails.id}
          handleCloseOfEditNewVendorModal={handleCloseOfEditNewVendorModal}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          message={successMessage}
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
        />
      )}
      {isEditVendorCustomerStatusModal && (
        <EditStatusVendorCustomerModal
          isLoadingEditVendorCustomerStatus={isLoadingEditVendor}
          isEditVendorCustomerStatusModal={isEditVendorCustomerStatusModal}
          data={vendorDetails}
          selectedVendorCustomerStatus={selectedVendorCustomerStatus}
          setSelectedVendorCustomerStatus={handleSelectedVendorCustomerStatus}
          handleClickOfEditStatusVendorCustomerUpdateButton={
            handleClickOfEditStatusVendorCustomerUpdateButton
          }
          onClickOfEditStatusVendorCustomerCancelButton={
            onClickOfEditStatusVendorCustomerCancelButton
          }
        />
      )}
    </>
  );
};

export default ViewPODetails;
