import React, { useEffect, useMemo, useState } from "react";
import { Card, Button, Spinner, ButtonGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Upload, Filter, Plus } from "react-feather";
import { StateFilter } from "./state-filter/StateFilter";
import { StateTable } from "./state-table/StateTable";
import { useStateUIContext } from "./StateUIContext";

export function StateCard({
  isLoading,
  stateList,
  handleFilterValues,
  filterValues,
  loadingExportButton,
  exportCSVButton,
  stateListExport,
  handleExportButton,
  stateListTotalCount,
  currentPage,
  setCurrentPage,
  onClickOfCreateNew
}) {
  const stateUIContext = useStateUIContext();
  const stateUIProps = useMemo(() => {
    return {
      ids: stateUIContext.ids,
      queryParams: stateUIContext.queryParams,
      setQueryParams: stateUIContext.setQueryParams,
      openViewDialog: stateUIContext.openViewDialog,
      openEditDialog: stateUIContext.openEditDialog,
      openDeleteDialog: stateUIContext.openDeleteDialog
    };
  }, [stateUIContext]);

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isPageLoaderShow, setPageLoaderShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaderShow(false);
    }, 500);
  }, []);

  return (
    <>
      <Card className='card-custom card-stretch make-payment-wrapper'>
        {isPageLoaderShow && (
          <div className='section-loader'>
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
          </div>
        )}
        <Card.Header className='align-items-center d-flex justify-content-between'>
          <h3 className='card-title page-title text-black mb-0'>States</h3>
          <div className='d-flex'>
            <ButtonGroup className='mr-3'>
              <Button
                variant='tertiary d-flex align-items-center btn-inventory'
                onClick={() => {
                  setFilterOpen(!isFilterOpen);
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className='d-none d-md-flex'>
                  {isFilterOpen ? "Remove" : "Filter"}
                </span>
              </Button>
              <CSVLink
                ref={exportCSVButton}
                className='btn btn-tertiary d-flex align-items-center btn-inventory'
                headers={[
                  { label: "State ID", key: "id" },
                  { label: "State Name", key: "name" },
                  { label: "Country", key: "country" },
                  { label: "Status", key: "status" }
                ]}
                data={stateListExport}
                filename={`states-export-${new Date().getTime()}.csv`}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className='d-none d-md-flex'>Export</span>
              </CSVLink>
            </ButtonGroup>
            <Button
              variant='primary d-flex align-items-center btn-inventory'
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className='d-none d-md-flex'>Create New</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && <StateFilter filterValues={handleFilterValues} />}
          <StateTable
            isLoading={isLoading}
            stateList={stateList}
            filterValues={filterValues}
            stateListTotalCount={stateListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
