import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  OverlayTrigger,
  Table,
  Tooltip
} from "react-bootstrap";
import { Edit2, FileText, X, AlertCircle, Printer, Check } from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import ExpectedDeliveryDateModal from "../../global-modal/ExpectedDeliveryDateModal";
import { DeleteModal } from "../common/modals/DeleteModal";
import DefaultPOView from "./DefaultPOView";
import PDFPOView from "./PDFPOView";
import ReceiveInwardForm from "./ReceiveInwardForm";
import {
  agreeStatusPOApi,
  deletePOApi,
  downloadAttachmentApi,
  downloadPdfPOApi,
  editExpectedDeliveryDateApi,
  editHistoryListApi,
  editPOApi,
  POListApi,
  POStatusListApi,
  printPdfPOApi,
  singlePODetailsApi,
  uploadDocumentApi
} from "../../axios/services/services";
import { ErrorModal } from "../common/modals/ErrorModal";
import ViewPODetailsListView from "./ViewPODetailsListView";
import { SectionLoader } from "../../helper/SectionLoader";
import { SuccessModal } from "../common/modals/SuccessModal";
import {
  AUTH_TOKEN,
  DD_MM_YYYY,
  SET_TIMEOUT,
  STR_PDF,
  YYYY_MM_DD
} from "../../constants/const";
import Moment from "react-moment";
import { useReactToPrint } from "react-to-print";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  getHistoryActions,
  getHistoryActionsUsername,
  getUTCTime,
  setActiveMenu,
  unauthorization
} from "../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { USER_DETAILS } from "../../constants/const";
import moment from "moment";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { ConfirmationModal } from "../common/modals/ConfirmationModal";
import InfiniteScroll from "react-infinite-scroll-component";

const ViewPODetails = ({ history, handlePDFViewValues, ...props }) => {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isExpectedDeliveryDateModalOpen, setExpectedDeliveryDateModalOpen] =
    useState(false);
  const { id } = props.match.params;
  const [POList, setPOList] = useState([]);
  const [PODetails, setPODetails] = useState({});
  const [POScanNotes, setPOScanNotes] = useState([]);
  const [purchaseOrderProductList, setPurchaseOrderProductList] = useState([]);
  const [purchaseOrderReceiveList, setPurchaseOrderReceiveList] = useState([]);
  const [editHistoryList, setEditHistoryList] = useState([]);
  const [isPDFViewOpen, setPDFViewOpen] = useState(false);
  const [isReceiveviewOpen, setReceiveviewOpen] = useState(false);
  const [POStatusList, setPOStatusList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDeletePO, setLoadingDeletePO] = useState(false);
  const [isLoadingEditHistory, setLoadingEditHistory] = useState(false);
  const [isLoadingConfirmationAgreeStatus, setLoadingConfirmationAgreeStatus] =
    useState(false);
  const [
    isLoadingConfirmationDisagreeStatus,
    setLoadingConfirmationDisagreeStatus
  ] = useState(false);
  const [isLoadingExpectedDeliveryDate, setLoadingExpectedDeliveryDate] =
    useState(false);
  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    pageSize: 20
  });
  const [queryParamsEditHistory, setQueryParamsEditHistory] = useState({
    pageNumber: 1,
    pageSize: 20
  });
  const [isLoadMorePurchaseOrderListView, setLoadMorePurchaseOrderListView] =
    useState(false);
  const [isLoadingPurchaseOrderListView, setLoadingPurchaseOrderListView] =
    useState(false);
  const [isLoadMoreEditHistory, setLoadMoreEditHistory] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(new Date());
  const [noteForExpectedDeliveryDate, setNoteForExpectedDeliveryDate] =
    useState("");
  const [isUpdateSinglePODetails, setUpdateSinglePODetails] = useState(0);
  const [isUpdatePOList, setUpdatePOList] = useState(0);
  const [isUpdateEditHistoryList, setUpdateEditHistoryList] = useState(0);
  const [
    isConfirmationAgreeStatusModalOpen,
    setConfirmationAgreeStatusModalOpen
  ] = useState(false);
  const [
    isConfirmationDisagreeStatusModalOpen,
    setConfirmationDisagreeStatusModalOpen
  ] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [isPDFView, setPDFView] = useState(
    intl.formatMessage({
      id: "TITLE.PRINT.VIEW"
    })
  );

  const pdfViewRef = useRef();

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};
  const { purchase_media } = PODetails || {};
  const [firstFile] = purchase_media || [];
  const { filename } = firstFile || {};

  async function getPOList() {
    if (userRole !== "Admin") {
      const { pageNumber } = queryParams || {};
      if (pageNumber === 1) {
        setLoadingPurchaseOrderListView(true);
      }
      const POListResponse = await POListApi({
        queryParams
      });
      if (pageNumber === 1) {
        setLoadingPurchaseOrderListView(false);
      }
      const { Error, data } = POListResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setPOList((poList) => [...poList, ...data]);
      if (data.length >= 20) {
        setLoadMorePurchaseOrderListView(true);
      } else {
        setLoadMorePurchaseOrderListView(false);
      }
    }
  }

  async function getSinglePODetails() {
    setLoading(true);
    const singlePODetails = await singlePODetailsApi({ POId: id });
    setLoading(false);
    const {
      Error,
      data,
      get_poitem,
      get_purchaseorder_receive,
      po_scan_notes
    } = singlePODetails || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setPODetails(data ? data : []);
    setPOScanNotes(po_scan_notes);
    setPurchaseOrderProductList(get_poitem);
    setPurchaseOrderReceiveList(get_purchaseorder_receive);
  }

  async function getPOStatusList() {
    const POStatusListResponse = await POStatusListApi({});
    const { Error, get_po_status } = POStatusListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const poStatusList = get_po_status.map((status) => {
      const { id, name } = status || {};
      status.label = name;
      status.value = id;
      return status;
    });
    setPOStatusList(poStatusList ? poStatusList : []);
  }

  async function getEditHistoryList() {
    if (userRole !== "Admin") {
      const { pageNumber } = queryParamsEditHistory || {};
      if (pageNumber === 1) {
        enableLoadingEditHistory();
      }
      const editHistoryListResponse = await editHistoryListApi({
        queryParamsEditHistory,
        id
      });
      if (pageNumber === 1) {
        disableLoadingEditHistory();
      }
      const { Error, data } = editHistoryListResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setEditHistoryList((historyList) => [...historyList, ...data]);
      if (data.length >= 20) {
        setLoadMoreEditHistory(true);
      } else {
        setLoadMoreEditHistory(false);
      }
    }
  }

  useEffect(() => {
    getPOList();
  }, [queryParams, isUpdatePOList]);

  useEffect(() => {
    setEditHistoryList([]);
  }, [id]);

  useEffect(() => {
    getEditHistoryList();
  }, [id, isUpdateEditHistoryList]);

  useEffect(() => {
    getSinglePODetails();
  }, [id, isUpdateSinglePODetails]);

  useEffect(() => {
    getPOStatusList();
  }, []);

  useEffect(() => {
    handleOnClickOfcancle();
  }, [id]);

  const enableLoadingEditHistory = () => {
    setLoadingEditHistory(true);
  };

  const disableLoadingEditHistory = () => {
    setLoadingEditHistory(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleExpectedDeliveryDateModalShow = () => {
    setExpectedDeliveryDateModalOpen(true);
  };

  const handleExpectedDeliveryDateModalHide = () => {
    setExpectedDeliveryDateModalOpen(false);
  };

  const handleOnClickOfcancle = () => {
    setReceiveviewOpen(false);
  };

  const showSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const hideSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const enableLoadingExpectedDeliveryDate = () => {
    setLoadingExpectedDeliveryDate(true);
  };

  const disableLoadingExpectedDeliveryDate = () => {
    setLoadingExpectedDeliveryDate(false);
  };

  const enableLoadingDeletePO = () => {
    setLoadingDeletePO(true);
  };

  const disableLoadingDeletePO = () => {
    setLoadingDeletePO(false);
  };

  const onClickOfDeleteButton = () => {
    deletePO();
  };

  const enableLoadingConfirmationAgree = () => {
    setLoadingConfirmationAgreeStatus(true);
  };

  const disableLoadingConfirmationAgree = () => {
    setLoadingConfirmationAgreeStatus(false);
  };

  const enableLoadingConfirmationDisagree = () => {
    setLoadingConfirmationDisagreeStatus(true);
  };

  const disableLoadingConfirmationDisagree = () => {
    setLoadingConfirmationDisagreeStatus(false);
  };

  const handleSaveOfExpectedDeliveryDate = async (expected_delivery, note) => {
    enableLoadingExpectedDeliveryDate();
    const editExpectedDeliveryDate = await editExpectedDeliveryDateApi({
      id,
      expected_delivery: expected_delivery
        ? moment(expected_delivery).format(YYYY_MM_DD)
        : null,
      notes: note
    });
    const { Error } = editExpectedDeliveryDate || {};
    if (Error) {
      disableLoadingExpectedDeliveryDate();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    handleExpectedDeliveryDateModalHide();
    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.EXPECTED.DELIVERY.DATE.UPDATED.SUCCESSFULLY"
      })
    );
    showSuccessModal();
    setUpdateSinglePODetails(Math.random());
    disableLoadingExpectedDeliveryDate();
  };

  const editTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.EDIT' />
    </Tooltip>
  );
  const downloadTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.DOWNLOAD.PDF' />
    </Tooltip>
  );
  const print = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.PRINT' />
    </Tooltip>
  );
  const attachefileTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.ATTACH.FILE' />
    </Tooltip>
  );

  const recivealertmessage = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      You cannot record receives for service items.
    </Tooltip>
  );

  const handlePaginationOfPurchaseOrderListView = () => {
    const { pageNumber, pageSize } = queryParams || {};
    setQueryParams({ pageNumber: pageNumber + 1, pageSize });
  };

  const handleClickOfPOList = () => {
    setQueryParamsEditHistory({ pageNumber: 1, pageSize: 20 });
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    attachDocument(fileUploaded);
  };

  const downloadPdf = async () => {
    await downloadPdfPOApi({
      id,
      filename:
        purchaseorder_number + " " + moment(new Date()).format("YYYY-MM-DD"),
      extention: STR_PDF
    });
  };

  const downloadAttachedFile = async () => {
    await downloadAttachmentApi({
      id,
      containername: "product",
      filename: filename,
      token: AUTH_TOKEN
    });
  };

  const printPdf = async () => {
    const { id } = props.match.params;
    const printPdfResponse = await printPdfPOApi({
      id
    });
    const { Error } = printPdfResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    var fils = new Blob([printPdfResponse.res.data], {
      type: "application/pdf"
    });
    var fileURLs = URL.createObjectURL(fils);
    setPdfUrl(fileURLs);
    setTimeout(() => {
      const iframe = document.getElementById("myframe");
      const iframeWindow = iframe.contentWindow || iframe;
      iframe.focus();
      iframeWindow.print();
    }, 50);
  };

  const attachDocument = async (event) => {
    let files = {
      file: event,
      containername: "product"
    };
    const uploadDocumentResponse = await uploadDocumentApi(files);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.FILE.ATTACH.SUCCESSFULLY" })
    );
    showSuccessModal();
    setUploadDocument(uploadDocumentResponse);
    const { filename, mimetype } = uploadDocumentResponse[0];
    let editPOObj = {
      id: id,
      purchaseorder_document: {
        filename: filename,
        file_type: mimetype
      }
    };
    const editPOResponse = await editPOApi(editPOObj);
    const { Error } = editPOResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
  };

  const deletePO = async () => {
    enableLoadingDeletePO();
    const deletePOResponse = await deletePOApi({ id });
    const { Error } = deletePOResponse || {};
    if (Error) {
      disableLoadingDeletePO();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.PURCHASE.ORDER.DELETED.SUCCESSFULLY"
      })
    );
    showSuccessModal();
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableLoadingDeletePO();
      const path = "/inwards/inward-listing";
      onClickChangeRoute(path);
    }, SET_TIMEOUT);
  };

  const handlePrint = useReactToPrint({
    content: () => pdfViewRef.current
  });

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const handleConfirmationAgreeStatusModalShow = () => {
    setConfirmationAgreeStatusModalOpen(true);
  };

  const handleConfirmationAgreeStatusModalHide = () => {
    setConfirmationAgreeStatusModalOpen(false);
  };

  const handleConfirmationDisagreeStatusModalShow = () => {
    setConfirmationDisagreeStatusModalOpen(true);
  };

  const handleConfirmationDisagreeStatusModalHide = () => {
    setConfirmationDisagreeStatusModalOpen(false);
  };

  const onClickOfConfirmationAgreeStatusButton = () => {
    AgreePurchaseOrderStatus();
  };

  const onClickOfConfirmationDisagreeStatusButton = () => {
    DisagreePurchaseOrderStatus();
  };

  const handlePaginationOfEditHistory = () => {
    const { pageNumber, pageSize } = queryParamsEditHistory || {};
    setQueryParamsEditHistory({ pageNumber: pageNumber + 1, pageSize });
    setUpdateEditHistoryList(Math.random());
  };

  const AgreePurchaseOrderStatus = async () => {
    enableLoadingConfirmationAgree();
    const [POStatus] = POStatusList.filter((status) => {
      return status.name === "Approved";
    });
    const agreeStatusPOResponse = await agreeStatusPOApi({
      id,
      purchaseorderstatusId: POStatus.id
    });
    const { Error } = agreeStatusPOResponse || {};
    if (Error) {
      disableLoadingConfirmationAgree();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    disableLoadingConfirmationAgree();
    handleConfirmationAgreeStatusModalHide();
    setUpdateSinglePODetails(Math.random());
    setPOList([]);
    const { pageNumber } = queryParams || {};
    if (pageNumber !== 1) {
      setQueryParams({ pageNumber: 1, pageSize: 20 });
    } else {
      setUpdatePOList(Math.random());
    }
  };

  const DisagreePurchaseOrderStatus = async () => {
    enableLoadingConfirmationDisagree();

    const [POStatus] = POStatusList.filter((status) => {
      return status.name === "Rejected";
    });
    const disagreeStatusPOResponse = await agreeStatusPOApi({
      id,
      purchaseorderstatusId: POStatus.id
    });
    const { Error } = disagreeStatusPOResponse || {};
    if (Error) {
      disableLoadingConfirmationDisagree();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    disableLoadingConfirmationDisagree();
    handleConfirmationDisagreeStatusModalHide();
    setUpdateSinglePODetails(Math.random());
    setPOList([]);
    const { pageNumber } = queryParams || {};
    if (pageNumber !== 1) {
      setQueryParams({ pageNumber: 1, pageSize: 20 });
    } else {
      setUpdatePOList(Math.random());
    }
  };

  const {
    purchaseorder_number,
    contact,
    date,
    expected_delivery,
    notes,
    purchaseorderstatus,
    delivery_date
  } = PODetails || {};
  const { name: purchaseOrderStatus } = purchaseorderstatus || {};

  return (
    <>
      <Card className='p-0 mb-0'>
        <div className='details-view d-block d-md-flex'>
          <ViewPODetailsListView
            id={id}
            POList={POList}
            isLoadingPurchaseOrderListView={isLoadingPurchaseOrderListView}
            isLoadingEditHistory={isLoadingEditHistory}
            isLoadMorePurchaseOrderListView={isLoadMorePurchaseOrderListView}
            handlePaginationOfPurchaseOrderListView={
              handlePaginationOfPurchaseOrderListView
            }
            handleClickOfPOList={handleClickOfPOList}
          />
          {/* Details */}
          {!isReceiveviewOpen && (
            <div className='column details-column'>
              <div className='column-header d-flex align-items-center justify-content-between'>
                <h2 className='modal-title'> {purchaseorder_number}</h2>
                <div className='d-flex'>
                  {userRole === "Asset Manager" &&
                    purchaseOrderStatus === "Waiting For Approval" && (
                      <ButtonGroup className='mr-2' aria-label='First group'>
                        <Badge
                          onClick={() => {
                            handleConfirmationAgreeStatusModalShow();
                          }}
                          className='d-flex align-items-center justify-content-center bg-success mr-1 cursor-pointer'
                          style={{ width: "28px", height: "28px" }}
                          bg='success'
                        >
                          <Check size={14} className='text-white' />
                        </Badge>

                        <Badge
                          onClick={() => {
                            handleConfirmationDisagreeStatusModalShow();
                          }}
                          className='d-flex align-items-center justify-content-center bg-danger ml-1 cursor-pointer'
                          style={{ width: "28px", height: "28px" }}
                          bg='danger'
                        >
                          <X size={14} className='text-white' />
                        </Badge>
                      </ButtonGroup>
                    )}

                  <ButtonGroup className='mr-2' aria-label='First group'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={editTooltip}
                    >
                      <Button
                        variant='outline-tertiary btn-sm btn-icon'
                        onClick={() => {
                          const path = `/inwards/edit-inward/${id}`;
                          onClickChangeRoute(path);
                        }}
                      >
                        <Edit2 size={16} className='p-0 m-0'></Edit2>
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={downloadTooltip}
                    >
                      <Button
                        variant='outline-tertiary btn-sm btn-icon'
                        onClick={() => {
                          downloadPdf();
                        }}
                      >
                        <FileText size={16} className='p-0 m-0'></FileText>
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={print}
                    >
                      <Button
                        variant='outline-tertiary btn-sm btn-icon'
                        onClick={() => printPdf()}
                      >
                        <Printer size={16} className='p-0 m-0'></Printer>
                      </Button>
                    </OverlayTrigger>

                    {/* <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={attachefileTooltip}
                      className="d-none"
                    >
                      <Button
                        variant="outline-tertiary btn-sm btn-icon"
                        onClick={handleClick}
                      >
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={handleChange}
                          style={{ display: "none" }}
                        />
                        <Upload size={16} className="p-0 m-0"></Upload>
                      </Button>
                    </OverlayTrigger> */}
                  </ButtonGroup>
                  <Dropdown>
                    <Dropdown.Toggle variant='dropdown-sm' id='dropdown-basic'>
                      <FormattedMessage id='TITLE.MORE' />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          if (
                            purchaseOrderStatus === "Waiting For Approval" ||
                            (userRole === "Asset Manager" &&
                              purchaseOrderStatus === "Approved")
                          ) {
                            handleDeleteModalShow();
                          } else {
                            setErrorMessage(
                              intl.formatMessage({
                                id: "TITLE.YOU.CAN.NOT.DELETE.PO"
                              })
                            );
                            handleErrorModalShow();
                          }
                        }}
                      >
                        <FormattedMessage id='TITLE.DELETE.PO' />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          // if (purchaseOrderStatus === "Waiting For Approval") {
                          //   handleExpectedDeliveryDateModalShow();
                          //   setNoteForExpectedDeliveryDate(notes);
                          //   setExpectedDeliveryDate(expected_delivery);
                          // } else {
                          //   setErrorMessage(
                          //     intl.formatMessage({
                          //       id: "TITLE.YOU.CAN.NOT.SET.EXPECTED.DELIVERY.DATE",
                          //     })
                          //   );
                          //   handleErrorModalShow();
                          // }
                          handleExpectedDeliveryDateModalShow();
                          setNoteForExpectedDeliveryDate(notes);
                          setExpectedDeliveryDate(expected_delivery);
                        }}
                      >
                        <FormattedMessage id='TITLE.EXPECTED.DELIVERY.DATE' />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    variant='close btn-icon btn-sm'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      const path = "/inwards/inward-listing";
                      onClickChangeRoute(path);
                    }}
                  >
                    <X size={20} className='p-0 m-0 text-dark'></X>
                  </Button>
                </div>
              </div>
              <div className='column-body custom-scrollbar'>
                {isLoading && <SectionLoader />}
                <Tabs>
                  <TabList className='nav nav-tabs tab-coupon mb-0'>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.DETAILS' />
                    </Tab>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.RECEIVES' />
                    </Tab>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.HISTORY' />
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <div className='highlighted-row'>
                      <div className='d-flex align-items-center justify-content-between'>
                        {(purchaseOrderStatus === "Approved" ||
                          purchaseOrderStatus === "Partially Fulfilled") && (
                          <div className='left'>
                            <Button
                              variant='tertiary text-dark'
                              onClick={() => {
                                setReceiveviewOpen(true);
                              }}
                            >
                              <FormattedMessage id='TITLE.RECEIVE' />
                            </Button>
                          </div>
                        )}
                        <div className='right justify-content-end'>
                          <Button
                            variant='link text-black'
                            onClick={() => {
                              setPDFViewOpen(!isPDFViewOpen);
                              isPDFViewOpen
                                ? setPDFView(
                                    intl.formatMessage({
                                      id: "TITLE.PRINT.VIEW"
                                    })
                                  )
                                : setPDFView(
                                    intl.formatMessage({
                                      id: "TITLE.HIDE.PRINT.VIEW"
                                    })
                                  );
                            }}
                          >
                            {isPDFView}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='highlighted-row'>
                      <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between'>
                        {(purchaseOrderStatus === "Waiting For Approval" ||
                          purchaseOrderStatus === "Approved") && (
                          <div className='left d-flex align-items-center'>
                            <span>
                              <FormattedMessage id='TITLE.NO.ITEMS.HAVE.BEEN.RECEIVED.YET' />
                            </span>
                            <OverlayTrigger
                              placement='top'
                              delay={{ show: 250, hide: 400 }}
                              overlay={recivealertmessage}
                            >
                              <AlertCircle
                                size={14}
                                className='ml-1'
                              ></AlertCircle>
                            </OverlayTrigger>
                            <Button
                              variant='link text-primary pl-2'
                              onClick={() => {
                                setReceiveviewOpen(true);
                              }}
                            >
                              <FormattedMessage id='TITLE.NEW.PURCHASE.RECEIVE' />
                            </Button>
                          </div>
                        )}
                        {(purchaseOrderStatus === "Received" ||
                          purchaseOrderStatus === "Partially Fulfilled") && (
                          <div className='col-md-8 pl-0 left d-flex align-items-center'>
                            <Table className='mb-0'>
                              <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id='TITLE.PURCHASE.RECEIVE' />
                                    #
                                  </th>
                                  <th>
                                    <FormattedMessage id='TITLE.RECEIVED.DATE' />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{purchaseorder_number}</td>
                                  <td>
                                    <Moment
                                      date={delivery_date}
                                      format={DD_MM_YYYY}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        )}
                        <div className='right'>
                          <Button
                            variant='link text-black'
                            onClick={() => {
                              setPDFViewOpen(!isPDFViewOpen);
                              isPDFViewOpen
                                ? setPDFView(
                                    intl.formatMessage({
                                      id: "TITLE.PRINT.VIEW"
                                    })
                                  )
                                : setPDFView(
                                    intl.formatMessage({
                                      id: "TITLE.HIDE.PRINT.VIEW"
                                    })
                                  );
                            }}
                          >
                            {isPDFView}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    {isLoadingEditHistory && <SectionLoader />}
                    <div
                      className='view-body border-bottom column-body custom-scrollbar'
                      id='viewpodetailsscrolldiv'
                      style={{ maxHeight: "400px", height: "auto" }}
                    >
                      <ul className='history-list'>
                        <InfiniteScroll
                          scrollableTarget='viewpodetailsscrolldiv'
                          dataLength={
                            editHistoryList ? editHistoryList.length : 0
                          }
                          next={() => {
                            handlePaginationOfEditHistory();
                          }}
                          hasMore={isLoadMoreEditHistory}
                          loader={
                            <h4 style={{ textAlign: "center" }} className='p-1'>
                              <FormattedMessage id='TITLE.LOADING' />
                              ...
                            </h4>
                          }
                        >
                          {editHistoryList &&
                            editHistoryList.map((editHistory, index) => {
                              return (
                                <li className='history__row' key={index}>
                                  <div className='date__column'>
                                    <div className='history__details'>
                                      {getUTCTime(editHistory.created)}
                                    </div>
                                  </div>
                                  <div className='comment__column'>
                                    <p className='comment__media'>
                                      <div className='comment-media__body'>
                                        <div className='history__details'>
                                          <FormattedMessage
                                            id={getHistoryActions(editHistory)}
                                          />
                                          <span className='ml-1 mr-1'>
                                            <FormattedMessage id='TITLE.BY.SMALL' />
                                            <strong className='ml-1 mr-1'>
                                              {getHistoryActionsUsername(
                                                editHistory
                                              )}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                    </p>
                                  </div>
                                </li>
                              );
                            })}
                        </InfiniteScroll>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>

                {!isPDFViewOpen && (
                  <DefaultPOView
                    PODetails={PODetails}
                    purchaseOrderProductList={purchaseOrderProductList}
                    purchaseOrderReceiveList={purchaseOrderReceiveList}
                    POScanNotes={POScanNotes}
                    id={id}
                  />
                )}

                {isPDFViewOpen && (
                  <PDFPOView
                    ref={pdfViewRef}
                    PODetails={PODetails}
                    purchaseOrderProductList={purchaseOrderProductList}
                  />
                )}
              </div>
            </div>
          )}
          {/* Receive Column */}
          {isReceiveviewOpen && (
            <ReceiveInwardForm
              PODetails={PODetails}
              onClickofcancel={handleOnClickOfcancle}
              // renderPONumber={renderPONumber}
              id={id}
              purchaseOrderProductList={purchaseOrderProductList}
            />
          )}
          {/*  */}
        </div>
      </Card>

      {isDeleteModalOpen && (
        <DeleteModal
          isLoading={isLoadingDeletePO}
          title={intl.formatMessage({
            id: "TITLE.DELETE.INWARD"
          })}
          description={intl.formatMessage({
            id: "TITLE.INWARD.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
          })}
          isOpen={isDeleteModalOpen}
          id={capitalizeFirstLetter(purchaseorder_number)}
          onCloseModal={handleDeleteModalHide}
          onClickOfDeleteButton={onClickOfDeleteButton}
        />
      )}

      {isExpectedDeliveryDateModalOpen && (
        <ExpectedDeliveryDateModal
          isLoadingExpectedDeliveryDate={isLoadingExpectedDeliveryDate}
          isOpen={isExpectedDeliveryDateModalOpen}
          noteForExpectedDeliveryDate={noteForExpectedDeliveryDate}
          expectedDeliveryDate={expectedDeliveryDate}
          date={date}
          onCloseModal={handleExpectedDeliveryDateModalHide}
          handleSaveOfExpectedDeliveryDate={handleSaveOfExpectedDeliveryDate}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          open={openSuccessModal}
          onCloseModal={hideSuccessModal}
          message={successMessage}
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}

      {isConfirmationAgreeStatusModalOpen && (
        <ConfirmationModal
          isLoading={isLoadingConfirmationAgreeStatus}
          title={intl.formatMessage({ id: "TITLE.APPROVE.PO" })}
          isSuccess={"text-success"}
          variant={"success"}
          icon={
            <Check
              size={60}
              className='text-success mb-2'
              style={{ strokeWidth: "1.5px" }}
            />
          }
          description={intl.formatMessage({
            id: "TITLE.ARE.YOU.SURE.YOU.WANT.TO.APPROVE.PURCHASE.ORDER"
          })}
          cancelText={intl.formatMessage({ id: "TITLE.CANCEL" })}
          logoutText={intl.formatMessage({ id: "TITLE.YES" })}
          isOpen={isConfirmationAgreeStatusModalOpen}
          onCloseModal={handleConfirmationAgreeStatusModalHide}
          id={purchaseorder_number}
          onClickOfConfirmationButton={onClickOfConfirmationAgreeStatusButton}
        />
      )}

      {isConfirmationDisagreeStatusModalOpen && (
        <ConfirmationModal
          isLoading={isLoadingConfirmationDisagreeStatus}
          title={intl.formatMessage({ id: "TITLE.REJECT.PO" })}
          icon={
            <X
              size={60}
              className='text-danger mb-2'
              style={{ strokeWidth: "1.5px" }}
            />
          }
          variant={"danger"}
          isSuccess={"text-danger"}
          description={intl.formatMessage({
            id: "TITLE.ARE.YOU.SURE.YOU.WANT.TO.REJECT.PURCHASE.ORDER"
          })}
          cancelText={intl.formatMessage({ id: "TITLE.CANCEL" })}
          logoutText={intl.formatMessage({ id: "TITLE.YES" })}
          isOpen={isConfirmationDisagreeStatusModalOpen}
          onCloseModal={handleConfirmationDisagreeStatusModalHide}
          id={purchaseorder_number}
          onClickOfConfirmationButton={
            onClickOfConfirmationDisagreeStatusButton
          }
        />
      )}

      <iframe
        className='d-none'
        src={pdfUrl}
        name='myframe'
        id='myframe'
        title='myframe'
      ></iframe>
    </>
  );
};

export default ViewPODetails;
