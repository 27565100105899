/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
const CustomerNameColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { redirectToViewPage }
) => {
  const { contact_name } = row || {};

  return (
    <>
      <div
        className='text-primary btn-link p-0 cursor-pointer'
        onClick={() => {
          redirectToViewPage(row);
        }}
      >
        {contact_name}
      </div>
      <> </>
    </>
  );
};

export default CustomerNameColumnFormatter;
