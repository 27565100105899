import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";
import { Check } from "react-feather";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import { ButtonLoader } from "../helper/ButtonLoader";

export function EditStatusPOModal({
  isLoadingEditPOStatus,
  isEditPOStatusSuccess,
  isEditStatusModal,
  data,
  POStatusList,
  selectedPOStatus,
  setSelectedPOStatus,
  handleClickOfEditStatusPOUpdateButton,
  onClickOfEditStatusPOCancelButton
}) {
  const { purchaseorder_number } = data || {};
  const intl = useIntl();
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    const statusList = POStatusList.filter((postatus) => {
      return postatus.name === "Approved" || postatus.name === "Rejected";
    });
    setStatusList(statusList);
  }, [POStatusList]);

  return (
    <>
      <Modal
        show={isEditStatusModal}
        onHide={onClickOfEditStatusPOCancelButton}
        dialogClassName='modal-90w status-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>
            <FormattedMessage id='TITLE.UPDATE.STATUS' />{" "}
            {` - ${purchaseorder_number}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <form autoComplete='off'>
            {isEditPOStatusSuccess && (
              <div className='notification icon inline success fs-16'>
                <Check size={8} className='text-white'></Check>{" "}
                <FormattedMessage id='TITLE.STATUS.UPDATED.SUCCESSFULLY' />
              </div>
            )}
            <div className='row'>
              <div className='col-md-6 mb-6'>
                <h5 className='fs-14 font-weight-normal text-left'>
                  <FormattedMessage id='TITLE.SELECT.STATUS' />
                </h5>

                <Select
                  value={selectedPOStatus}
                  onChange={setSelectedPOStatus}
                  isSearchable={true}
                  options={statusList}
                  placeholder={intl.formatMessage({
                    id: "TITLE.SELECT.STATUS"
                  })}
                  id='Currency'
                  className='react-select-container'
                  classNamePrefix='react-select'
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className='mt-3'>
          <ButtonToolbar className='d-flex justify-content-end button-wrap mb-8'>
            <Button
              type='button'
              variant='btn btn-primary mr-3'
              onClick={() => {
                handleClickOfEditStatusPOUpdateButton();
              }}
              disabled={isLoadingEditPOStatus}
            >
              <FormattedMessage id='TITLE.UPDATE' />
              {isLoadingEditPOStatus && <ButtonLoader className='mr-1 ml-1' />}
            </Button>
            <Button
              type='reset'
              variant='btn btn-tertiary'
              onClick={onClickOfEditStatusPOCancelButton}
            >
              <FormattedMessage id='TITLE.CANCEL' />
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </>
  );
}
