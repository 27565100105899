import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { Edit2, Plus, X } from "react-feather";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import {
  customerListApi,
  deleteCustomerApi,
  editCustomerApi,
  getHistoryListBasedOnEmployeeApi,
  getHistoryListBasedOnProductApi,
  getRecentFiveDCApi,
  getRecentFiveDCCompletedApi,
  getSingleCustomerDetailsApi,
  POStatusListApi,
  uploadDocumentApi
} from "../../axios/services/services";
import {
  capitalizeFirstLetter,
  getActiveDeactiveColorClass,
  getErrorMessageBasedOnLanguage,
  renderStatus,
  setActiveMenu,
  unauthorization
} from "../../constants/utils";
import { AddNewCustomerModal } from "../../global-modal/AddNewCustomerModal";
import { ButtonLoader } from "../../helper/ButtonLoader";
import { EditCustomerRemarksSchema } from "../../helper/FormikSchema";
import { SectionLoader } from "../../helper/SectionLoader";
import { DeleteModal } from "../common/modals/DeleteModal";
import { ErrorModal } from "../common/modals/ErrorModal";
import { SuccessModal } from "../common/modals/SuccessModal";
import { CustomerEditModal } from "../customer-module/customer-edit-modal/CustomerEditModal";
import DefaultCustomerView from "./DefaultCustomerView";
import { FormattedMessage, useIntl } from "react-intl";
import { USER_DETAILS } from "../../constants/const";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { EditStatusVendorCustomerModal } from "../../global-modal/EditStatusVendorCustomerModal";

let EditCustomerInitialValues = {
  remarks: ""
};

let initialFilter = {
  filter: {},
  sortOrder: "",
  sortField: "",
  pageNumber: 1,
  pageSize: 20
};

const initialQueryParamsLoc = { pageNumber: 1, pageSize: 20 };

const ViewCustomerDetails = ({ history, handlePDFViewValues, ...props }) => {
  const uiContext = useContext(UIContext);
  const [isLoading, setLoading] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isLoadingCustomerListView, setLoadingCustomerListView] =
    useState(true);
  const [isLoadingRecentFivePO, setLoadingRecentFivePO] = useState(false);
  const [isLoadingDeleteCustomer, setLoadingDeleteCustomer] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  const [DCStatusList, setDCStatusList] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditVendorCustomerStatusModal, setEditVendorCustomerStatusModal] =
    useState(false);
  const [selectedVendorCustomerStatus, setSelectedVendorCustomerStatus] =
    useState(null);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [queryParams, setQueryParams] = useState(initialFilter);
  // const [uploadDocument, setUploadDocument] = useState(null);
  const [isLoadingEditCustomer, setLoadingEditCustomer] = useState(false);
  const [isOpenEditCustomerModal, setOpenEditCustomerModal] = useState(false);
  const [recentFivePO, setRecentFiveCustomer] = useState([]);
  const [recentFiveFulfilledPO, setRecentFiveFulfilledPO] = useState([]);
  const [isLoadMoreCustomerListView, setLoadMoreCustomerListView] =
    useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [isOpenAddNewCustomerModal, setOpenAddNewCustomerModal] =
    useState(false);
  const [isUpdateSingleCustomerDetails, setUpdateSingleCustomerDetails] =
    useState(0);
  const [isUpdateCustomerList, setUpdateCustomerList] = useState(0);
  const [searchedCustomerName, setSearchedCustomerName] = useState("");
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const { id } = props.match.params;
  const hiddenFileInput = useRef(null);
  const intl = useIntl();
  const [historyList, setHistoryList] = useState([]);
  const [queryParamsLoc, setQueryParamsLoc] = useState(initialQueryParamsLoc);
  const [isLoadMoreLocHistoryView, setLoadMoreLocHistoryView] = useState(false);
  const [isLoadingHistoryView, setLoadingHistoryView] = useState(false);
  const [
    isUpdatePaginationHistoryListDetails,
    setUpdatePaginationHistoryListDetails
  ] = useState(0);

  async function getSingleCustomerDetails() {
    enableLoading();
    const singleCustomerDetails = await getSingleCustomerDetailsApi({
      id
    });
    disableLoading();
    const { Error, data } = singleCustomerDetails || {};
    const { get_contact } = data || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    await setCustomerDetails(get_contact ? get_contact : []);
    getRecentFiveOpenPO(get_contact);
    getRecentFiveFulfilledPO(get_contact);
    formikEditCustomer.setFieldValue("remarks", get_contact?.remarks);
  }
  async function getHistoryDetails() {
    const employeeId = id;
    console.log("employeeId", employeeId);

    if (historyList && historyList.length === 0) {
      setLoading(true);
    }
    const historyResponse = await getHistoryListBasedOnEmployeeApi({
      queryParams: queryParamsLoc,
      employeeId
    });
    const { Error, data } = historyResponse || {};
    if (Error) {
      if (historyList && historyList.length === 0) {
        setLoading(false);
      }
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setHistoryList((historyList) => [...historyList, ...data]);
    if (data.length >= 20) {
      setLoadMoreLocHistoryView(true);
    } else {
      setLoadMoreLocHistoryView(false);
    }
    if (historyList && historyList.length === 0) {
      setLoadingHistoryView(false);
    }
  }

  useEffect(() => {
    if (DCStatusList) {
      getSingleCustomerDetails();
      getHistoryDetails();
    }
  }, [id, isUpdateSingleCustomerDetails, DCStatusList]);

  async function getCustomerList() {
    setLoadingCustomerListView(true);
    const customerListResponse = await customerListApi({
      queryParams
    });
    setLoadingCustomerListView(false);
    const { Error, data } = customerListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setCustomerList((customerList) => [...customerList, ...data]);
    if (data.length >= 20) {
      setLoadMoreCustomerListView(true);
    } else {
      setLoadMoreCustomerListView(false);
    }
  }

  useEffect(() => {
    getCustomerList();
  }, [queryParams, isUpdateCustomerList, isUpdateSingleCustomerDetails]);

  async function getDCStatusList() {
    const DCStatusListResponse = await POStatusListApi({});
    const { Error, get_dc_status } = DCStatusListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const dcStatusList = get_dc_status.map((status) => {
      const { id, name } = status || {};
      status.label = name;
      status.value = id;
      return status;
    });
    setDCStatusList(dcStatusList ? dcStatusList : []);
  }

  useEffect(() => {
    // getCustomerList();
    getDCStatusList();
  }, [id, isUpdateSingleCustomerDetails]);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  async function getRecentFiveOpenPO(customerDetails) {
    if (userRole !== "Admin") {
      const [waitingStatus] = DCStatusList.filter((status) => {
        return status.name === "Waiting For Approval";
      });
      const [issueStatus] = DCStatusList.filter((status) => {
        return status.name === "Approved";
      });
      const [partialStatus] = DCStatusList.filter((status) => {
        return status.name === "Partially Fulfilled";
      });
      const sortColumnName = "date";
      enableLoadingRecentFivePO();
      const top5CustomerResponse = await getRecentFiveDCApi({
        sortColumnName,
        waitingStatus: waitingStatus.id,
        issueStatus: issueStatus.id,
        partialStatus: partialStatus.id,
        contact_name: customerDetails.contact_name
      });
      disableLoadingRecentFivePO();
      const { Error, data } = top5CustomerResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setRecentFiveCustomer(data);
    }
  }

  async function getRecentFiveFulfilledPO(customerDetails) {
    if (userRole !== "Admin") {
      const [waitingStatus] = DCStatusList.filter((status) => {
        return status.name === "Delivered";
      });
      const sortColumnName = "date";
      enableLoadingRecentFivePO();
      const top5CustomerResponse = await getRecentFiveDCCompletedApi({
        sortColumnName,
        status: waitingStatus.id,
        contact_name: customerDetails.contact_name
      });
      disableLoadingRecentFivePO();
      const { Error, data } = top5CustomerResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setRecentFiveFulfilledPO(data);
    }
  }

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const enableLoadingEditCustomer = () => {
    setLoadingEditCustomer(true);
  };

  const disableLoadingEditCustomer = () => {
    setLoadingEditCustomer(false);
  };

  const enableLoadingRecentFivePO = () => {
    setLoadingRecentFivePO(true);
  };

  const disableLoadingRecentFivePO = () => {
    setLoadingRecentFivePO(false);
  };

  const enableLoadingDeleteCustomer = () => {
    setLoadingDeleteCustomer(true);
  };

  const disableLoadingDeleteCustomer = () => {
    setLoadingDeleteCustomer(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const addNewCustomerModalShow = () => {
    setOpenAddNewCustomerModal(true);
  };

  const addNewCustomerModalHide = () => {
    setOpenAddNewCustomerModal(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const onClickOfDeleteButton = () => {
    deleteCustomer();
  };

  const handleUpdateSingleCustomerDetails = () => {
    setUpdateSingleCustomerDetails(Math.random());
  };

  const handleEditVendorStatusModalShow = () => {
    setEditVendorCustomerStatusModal(true);
  };

  const handleEditVendorStatusModalHide = () => {
    setEditVendorCustomerStatusModal(false);
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const editCustomerModalShow = () => {
    setOpenEditCustomerModal(true);
  };

  const editCustomerModalHide = () => {
    setOpenEditCustomerModal(false);
  };

  const handlePaginationOfHistoryListView = () => {
    const { pageNumber, pageSize } = queryParamsLoc || {};
    setQueryParamsLoc({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationHistoryListDetails(Math.random());
  };

  const handleCloseOfAddNewCustomerModal = (addNewVendorDetails) => {
    setUpdateSingleCustomerDetails(Math.random());
    setCustomerList([]);
    setUpdateCustomerList(Math.random());
    addNewCustomerModalHide();
  };

  const handleSelectedVendorCustomerStatus = (data) => {
    setSelectedVendorCustomerStatus(data);
  };

  const handleClickOfEditStatusVendorCustomerUpdateButton = () => {
    handleSaveOfStatus();
  };

  const onClickOfEditStatusVendorCustomerCancelButton = () => {
    handleEditVendorStatusModalHide();
  };

  const updateVendorDataOnClick = (id) => {
    const path = `/customers/view-customer-details/${id}`;
    onClickChangeRoute(path);
  };

  const CreateNewPO = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Create New Customer
    </Tooltip>
  );

  const editTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Edit
    </Tooltip>
  );

  const formikEditCustomer = useFormik({
    initialValues: EditCustomerInitialValues,
    validationSchema: EditCustomerRemarksSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableSaveLoading();
      const { remarks } = values || {};
      let obj = {
        id,
        remarks
      };

      const editCustomerResponse = await editCustomerApi(obj);
      const { Error } = editCustomerResponse || {};

      if (Error) {
        disableSaveLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      if (customerDetails?.remarks) {
        setSuccessMessage(
          intl.formatMessage({ id: "TITLE.REMARKS.EDITED.SUCCESSFULLY" })
        );
      } else {
        setSuccessMessage(
          intl.formatMessage({ id: "TITLE.REMARKS.ADDED.SUCCESSFULLY" })
        );
      }
      onOpenSuccessModal();
      window.scrollTo(0, 0);
      setCustomerList([]);
      handleUpdateSingleCustomerDetails();
      disableSaveLoading();
    }
  });

  const attachDocument = async (event) => {
    let files = {
      file: event,
      containername: "product"
    };
    const uploadDocumentResponse = await uploadDocumentApi(files);
    const { Error } = uploadDocumentResponse;
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.FILE.ATTACH.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    // setUploadDocument(uploadDocumentResponse);
    const [uploadedDoc] = uploadDocumentResponse;
    const { filename, mimetype } = uploadedDoc || {};
    let editCustomerObj = {
      id: id,
      purchaseorder_document: {
        filename: filename,
        file_type: mimetype
      }
    };
    const editCustomerResponse = await editCustomerApi(editCustomerObj);
    const { Error: editCustomerEror } = editCustomerResponse || {};
    if (editCustomerEror) {
      unauthorization(editCustomerEror);
      setErrorMessage(getErrorMessageBasedOnLanguage(editCustomerEror));
      handleErrorModalShow();
      return;
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    attachDocument(fileUploaded);
  };

  const handlePaginationOfCustomerListView = () => {
    const { pageNumber, pageSize } = queryParams || {};
    setQueryParams({ pageNumber: pageNumber + 1, pageSize });
  };

  const handleCloseOfEditNewCustomerModal = () => {
    setCustomerList([]);
    // setQueryParams({ pageNumber: 1, pageSize: 20 });
    // setUpdateCustomerList(Math.random());
    setUpdateSingleCustomerDetails(Math.random());
    editCustomerModalHide();
  };

  const handleSaveOfStatus = async () => {
    enableLoadingEditCustomer();
    const { value } = selectedVendorCustomerStatus;
    const editStatus = await editCustomerApi({
      id,
      status: parseInt(value)
    });
    const { Error } = editStatus || {};
    if (Error) {
      disableLoadingEditCustomer();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }

    if (value === "1") {
      setSuccessMessage(
        intl.formatMessage({
          id: "TITLE.EMPLOYEE.SUCCESSFULLY.MARKED.AS.ACTIVE"
        })
      );
    } else {
      setSuccessMessage(
        intl.formatMessage({
          id: "TITLE.EMPLOYEE.SUCCESSFULLY.MARKED.AS.INACTIVE"
        })
      );
    }

    onOpenSuccessModal();
    handleEditVendorStatusModalHide();
    setQueryParams(initialFilter);
    setCustomerList([]);
    setUpdateSingleCustomerDetails(Math.random());
    disableLoadingEditCustomer();
  };

  const deleteCustomer = async () => {
    enableLoadingDeleteCustomer();
    const deleteCustomerResponse = await deleteCustomerApi({ id });
    const { Error } = deleteCustomerResponse || {};
    if (Error) {
      disableLoadingDeleteCustomer();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.EMPLOYEE.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableLoadingDeleteCustomer();
      const path = "/customers/customer-listing";
      onClickChangeRoute(path);
    }, 2500);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Card className='p-0 mb-0'>
        {isLoading && <SectionLoader />}
        <div className='details-view d-flex'>
          {/* Listing */}
          <div className='column list-column list-column-small border-right d-none d-lg-block'>
            <div className='column-header d-flex align-items-center justify-content-between'>
              <h2 className='modal-title'>All Employees</h2>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={CreateNewPO}
              >
                <Button
                  variant='primary btn-inventory btn-icon'
                  size='sm'
                  onClick={() => addNewCustomerModalShow()}
                >
                  <Plus
                    size={14}
                    className='text-secondary'
                    onClick={() => addNewCustomerModalShow()}
                  ></Plus>{" "}
                  <FormattedMessage id='TITLE.NEW' />
                </Button>
              </OverlayTrigger>
            </div>
            {isLoadingCustomerListView && <SectionLoader />}
            <div id='scrollableDiv' className='column-body custom-scrollbar'>
              {!isLoadingCustomerListView && (
                <InfiniteScroll
                  scrollableTarget='scrollableDiv'
                  dataLength={customerList.length}
                  next={() => {
                    handlePaginationOfCustomerListView();
                  }}
                  hasMore={isLoadMoreCustomerListView}
                  loader={
                    <h4 style={{ textAlign: "center" }} className='p-1'>
                      <FormattedMessage id='TITLE.LOADING' />
                    </h4>
                  }
                >
                  {customerList.map((customer, index) => (
                    <div
                      key={index}
                      className='column-box cursor-pointer'
                      onClick={() => {
                        updateVendorDataOnClick(customer.id);
                        // history.push(
                        //   `/customers/view-customer-details/${customer.id}`
                        // );
                      }}
                    >
                      <div className='d-flex flex-column'>
                        <h5
                          className={`fs-14 text-primary font-weight-bold mb-0 cursor-pointer ${
                            customer.id === id ? "text-secondary" : ""
                          }`}
                        >
                          {capitalizeFirstLetter(customer?.contact_name)}{" "}
                        </h5>
                        <div className='d-flex align-items-center'>
                          <span className='small-text pr-2 text-dark'>
                            {intl.formatMessage({
                              id: "TITLE.OUTSTANDING.PRODUCTS"
                            })}
                            {customer?.outstanding_products
                              ? customer?.outstanding_products
                              : "0"}
                          </span>
                          <span className='text-capitalize py-1 px-2 ml-auto'>
                            {renderStatus(customer?.status)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </div>

          <div className='column details-column'>
            <div className='column-header d-flex align-items-center justify-content-between'>
              <h2 className='modal-title'>
                {capitalizeFirstLetter(customerDetails?.contact_name)}
              </h2>
              <div className='d-flex'>
                <span
                  className={`btn btn-icon btn-sm mr-2 text-capitalize ${getActiveDeactiveColorClass(
                    customerDetails
                  )}`}
                  onClick={() => {
                    const { status } = customerDetails || {};
                    if (status === 1) {
                      setSelectedVendorCustomerStatus({
                        value: "1",
                        label: "Active"
                      });
                    } else {
                      setSelectedVendorCustomerStatus({
                        value: "0",
                        label: "Inactive"
                      });
                    }
                    handleEditVendorStatusModalShow();
                  }}
                >
                  {customerDetails?.status === 1 ? (
                    <FormattedMessage id='TITLE.ACTIVE' />
                  ) : (
                    <FormattedMessage id='TITLE.INACTIVE' />
                  )}
                  {/* {isLoadingEditCustomer && (
                    <ButtonLoader className="mr-1 ml-1" />
                  )} */}
                </span>
                <ButtonGroup className='mr-2' aria-label='First group'>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={editTooltip}
                  >
                    <Button
                      variant='outline-tertiary btn-sm btn-icon'
                      onClick={() => editCustomerModalShow()}
                    >
                      <Edit2 size={16} className='p-0 m-0'></Edit2>
                    </Button>
                  </OverlayTrigger>

                  {/* <div>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={attachefileTooltip}
                    >
                      <Button
                        onClick={handleClick}
                        variant="outline-tertiary btn-sm btn-icon"
                      >
                        <Upload size={16} className="p-0 m-0"></Upload>
                      </Button>
                    </OverlayTrigger>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                  </div> */}
                </ButtonGroup>
                {userRole !== "Admin" && (
                  <Button
                    variant='secondary btn-icon btn-sm mr-2'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      const path = "/outwards/add-outward";
                      onClickChangeRoute(path);
                    }}
                  >
                    <FormattedMessage id='TITLE.NEW.CHECKOUT.REQUEST' />
                  </Button>
                )}

                {userRole !== "Inward Manager" &&
                  userRole !== "Outward Manager" && (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='dropdown-sm'
                        id='dropdown-basic'
                      >
                        <FormattedMessage id='TITLE.MORE' />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            handleDeleteModalShow();
                          }}
                        >
                          <FormattedMessage id='TITLE.DELETE.EMPLOYEE' />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                <Button
                  variant='close btn-icon btn-sm'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    const path = "/customers/customer-listing";
                    onClickChangeRoute(path);
                  }}
                >
                  <X size={20} className='p-0 m-0 text-dark'></X>
                </Button>
              </div>
            </div>
            <div className='column-body custom-scrollbar'>
              <Tabs>
                <TabList className='nav nav-tabs tab-coupon mb-0'>
                  <Tab className='nav-link'>
                    <FormattedMessage id='TITLE.OVERVIEW' />
                  </Tab>
                  <Tab className='nav-link'>
                    <FormattedMessage id='TITLE.REMARKS' />
                  </Tab>
                </TabList>
                <TabPanel>
                  <DefaultCustomerView
                    customerDetails={customerDetails}
                    id={id}
                    recentFivePO={recentFivePO}
                    isLoading={isLoadingRecentFivePO}
                    recentFiveFulfilledPO={recentFiveFulfilledPO}
                    handleUpdateSingleCustomerDetails={() => {
                      setCustomerList([]);
                      handleUpdateSingleCustomerDetails();
                    }}
                    historyList={historyList}
                    isLoadingHistoryView={isLoadingHistoryView}
                    handlePaginationOfHistoryListView={
                      handlePaginationOfHistoryListView
                    }
                    isLoadMoreLocHistoryView={isLoadMoreLocHistoryView}
                  />
                </TabPanel>
                <TabPanel>
                  <div className='view-body'>
                    <Form.Group>
                      <Form.Label htmlFor='remarks'>
                        <FormattedMessage id='TITLE.REMARKS' />
                      </Form.Label>
                      <Form.Control
                        id='remarks'
                        name='remarks'
                        as='textarea'
                        placeholder={intl.formatMessage({
                          id: "TITLE.REMARKS"
                        })}
                        className='form-control'
                        resize='none'
                        maxLength={512}
                        {...formikEditCustomer.getFieldProps("remarks")}
                      />
                      {formikEditCustomer.touched.remarks &&
                      formikEditCustomer.errors.remarks ? (
                        <div className='text-danger'>
                          {formikEditCustomer.errors.remarks}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Button
                      variant='success mr-3 btn-inventory'
                      onClick={() => {
                        formikEditCustomer.handleSubmit();
                      }}
                      disabled={isSaveLoading}
                    >
                      {customerDetails?.remarks ? (
                        <FormattedMessage id='TITLE.EDIT.REMARKS' />
                      ) : (
                        <FormattedMessage id='TITLE.ADD.REMARKS' />
                      )}
                      {isSaveLoading && (
                        <ButtonLoader className={"mr-1 ml-1"} />
                      )}
                    </Button>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </Card>

      {isDeleteModalOpen && (
        <DeleteModal
          isLoading={isLoadingDeleteCustomer}
          title={intl.formatMessage({ id: "TITLE.DELETE.EMPLOYEE" })}
          description={intl.formatMessage({
            id: "TITLE.EMPLOYEE.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
          })}
          isOpen={isDeleteModalOpen}
          onCloseModal={handleDeleteModalHide}
          id={capitalizeFirstLetter(customerDetails?.contact_name)}
          onClickOfDeleteButton={onClickOfDeleteButton}
        />
      )}

      {isOpenEditCustomerModal && (
        <CustomerEditModal
          data={customerDetails}
          isEditModal={isOpenEditCustomerModal}
          onCloseModal={editCustomerModalHide}
          id={customerDetails.id}
          handleCloseOfEditCustomerModal={handleCloseOfEditNewCustomerModal}
        />
      )}

      {isOpenAddNewCustomerModal && (
        <AddNewCustomerModal
          searchedCustomerName={searchedCustomerName}
          open={isOpenAddNewCustomerModal}
          onCloseModal={addNewCustomerModalHide}
          handleCloseOfAddNewCustomerModal={handleCloseOfAddNewCustomerModal}
        />
      )}
      {isEditVendorCustomerStatusModal && (
        <EditStatusVendorCustomerModal
          isLoadingEditVendorCustomerStatus={isLoadingEditCustomer}
          isEditVendorCustomerStatusModal={isEditVendorCustomerStatusModal}
          data={customerDetails}
          selectedVendorCustomerStatus={selectedVendorCustomerStatus}
          setSelectedVendorCustomerStatus={handleSelectedVendorCustomerStatus}
          handleClickOfEditStatusVendorCustomerUpdateButton={
            handleClickOfEditStatusVendorCustomerUpdateButton
          }
          onClickOfEditStatusVendorCustomerCancelButton={
            onClickOfEditStatusVendorCustomerCancelButton
          }
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          message={successMessage}
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
        />
      )}
    </>
  );
};

export default ViewCustomerDetails;
