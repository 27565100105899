import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { X } from "react-feather";

function ViewSingleDetailModal({
  message,
  isConfirmModal,
  handleConfirmHide,
  onClick,
  buttonLabel,
  buttonprintLabel,
  title,
  dataArray
}) {
  return (
    <>
      <Modal
        size='lg'
        show={isConfirmModal}
        onHide={handleConfirmHide}
        aria-labelledby='example-modal-sizes-title-lg'
        dialogClassName='addvendor-modal modal'
        scrollable={true}
      >
        <Modal.Header className='d-flex justify-content-between align-items-center'>
          <h3
            className='modal-title'
            id='exampleModalLabel2'
            style={{ overflowWrap: "anywhere" }}
          >
            {title}
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => handleConfirmHide()}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body className='text-center pb-0'>
          <div className='row'>
            {dataArray &&
              dataArray.length > 0 &&
              dataArray.map((item, index) => (
                <div className='col-6 mb-4' key={index}>
                  <h5 className='fs-14 font-weight-normal text-left'>
                    {item?.key}
                  </h5>
                  <p
                    className='text-dark fs-14 text-left font-weight-bold'
                    style={{ overflowWrap: "anywhere" }}
                  >
                    {item?.value}
                  </p>
                </div>
              ))}
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
export default ViewSingleDetailModal;
