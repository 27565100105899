/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { amountFormatter } from "../constants/utils";
const AmountColumnFormatterForDeprecatedAssets = (
  cellContent,
  row,
  rowIndex
) => {
  const { product } = row;
  const { price } = product;
  return (
    <>
      <div>{amountFormatter(price)}</div>
      <> </>
    </>
  );
};

export default AmountColumnFormatterForDeprecatedAssets;
