import React, { useMemo } from "react";
import { Button, ButtonGroup, Card } from "react-bootstrap";
import { Filter, Upload } from "react-feather";
import { FormattedMessage } from "react-intl";
import { inventoryListExportApi } from "../../axios/services/services";
import { useProductUIContext } from "./ProductUIContext";
import { ProductFilter } from "./product-filter/ProductFilter";
import { ProductTable } from "./product-table/ProductTable";

export function ProductCard({
  isLoading,
  productList,
  isLoadingFilter,
  isFilterOpen,
  exportCSVButton,
  transactionListExport,
  productListTotalCount,
  currentPage,
  setCurrentPage,
  filterValues,
  handleClickOfRemoveFilterButton,
  handleCLickOfFilterButtonFilter
}) {
  const productUIContext = useProductUIContext();
  const productUIProps = useMemo(() => {
    return {
      ids: productUIContext.ids,
      queryParams: productUIContext.queryParams,
      setQueryParams: productUIContext.setQueryParams,
      openViewDialog: productUIContext.openViewDialog,
      openCheckoutDialog: productUIContext.openCheckoutDialog,
      openEditDialog: productUIContext.openEditDialog,
      openDeleteDialog: productUIContext.openDeleteDialog
    };
  }, [productUIContext]);

  async function handleExport() {
    await inventoryListExportApi({
      filename: "Inventory"
    });
  }

  return (
    <>
      <Card className='card-custom card-stretch make-payment-wrapper'>
        <Card.Header className='align-items-center d-flex justify-content-between'>
          <h3 className='card-title page-title text-black mb-0'>
            <FormattedMessage id='TITLE.PRODUCTS' />
          </h3>
          <div className='d-flex'>
            <ButtonGroup className='mr-3'>
              <Button
                variant='tertiary d-flex align-items-center btn-inventory'
                onClick={() => {
                  handleClickOfRemoveFilterButton();
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className='d-none d-md-flex'>
                  {isFilterOpen ? (
                    <FormattedMessage id='TITLE.REMOVE' />
                  ) : (
                    <FormattedMessage id='TITLE.FILTER' />
                  )}
                </span>
              </Button>

              <Button
                variant='tertiary d-flex align-items-center btn-inventory'
                onClick={() => {
                  handleExport();
                }}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className='d-none d-md-flex'>
                  <FormattedMessage id='TITLE.EXPORT' />
                </span>
              </Button>
            </ButtonGroup>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && (
            <ProductFilter
              isLoadingFilter={isLoadingFilter}
              handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            />
          )}
          <ProductTable
            isLoading={isLoading}
            productList={productList}
            filterValues={filterValues}
            productListTotalCount={productListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
