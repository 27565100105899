import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import {
  getSingleBrandDetailsApi,
  editBrandApi
} from "../../../axios/services/services";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { useFormik } from "formik";
import { EditBrandSchema } from "../../../helper/FormikSchema";
import { unauthorization } from "../../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { getErrorMessageBasedOnLanguage } from "../../../constants/utils";

//TODO ACTIVE AND DEACTIVE STATUS IN ARABIC
const statusName = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" }
];

let EditBrandInitialValues = {
  name: "",
  description: ""
};

export function EditBrandModal({
  onCloseModal,
  open,
  data,
  id,
  handleCloseOfEditNewBrandModal
}) {
  const [selectedOption, setSelectedOption] = useState({});
  const [isLoadingSingleBrand, setLoadingSingleBrand] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [brandDetails, setBrandDetails] = useState({});
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [brandEdited, setBrandEdited] = useState(false);
  const intl = useIntl();

  const formikEditBrand = useFormik({
    initialValues: EditBrandInitialValues,
    validationSchema: EditBrandSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableSaveLoading();
      const { name, description } = values || {};
      const { value: status } = selectedOption;
      let obj = {
        id,
        name,
        description,
        status
      };

      const editBrandResponse = await editBrandApi(obj);
      const { data, Error } = editBrandResponse || {};
      if (Error) {
        disableSaveLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setBrandEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableSaveLoading();
        handleCloseOfEditNewBrandModal(data);
      }, 2500);
    }
  });

  async function getSingleBrandDetails() {
    enableLoadingSingleBrand();
    const singleBrandDetails = await getSingleBrandDetailsApi({ id });
    disableLoadingSingleBrand(false);
    const { Error, data } = singleBrandDetails || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setBrandDetails(data ? data : []);
    const { name, description, status } = data || {};
    if (status === 1) {
      setSelectedOption({ value: "1", label: "Active" });
    } else {
      setSelectedOption({ value: "0", label: "Inactive" });
    }
    formikEditBrand.setFieldValue("name", name);
    formikEditBrand.setFieldValue("description", description);
  }

  useEffect(() => {
    getSingleBrandDetails();
  }, []);

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const enableLoadingSingleBrand = () => {
    setLoadingSingleBrand(true);
  };

  const disableLoadingSingleBrand = () => {
    setLoadingSingleBrand(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <>
      <Modal
        size='md'
        show={open}
        onHide={onCloseModal}
        dialogClassName='inventory-small-modal'
      >
        <Modal.Header
          className='d-flex justify-content-between align-items-center'
          toggle={onCloseModal}
        >
          <h3 className='modal-title' id='exampleModalLabel2'>
            <FormattedMessage id='TITLE.EDIT.BRAND' />
            {isLoadingSingleBrand && (
              <Spinner animation='border' size='sm' className='ml-1' />
            )}
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          {brandEdited && (
            <div className='notification icon inline success fs-16'>
              <Check size={8} className='text-white'></Check>
              <FormattedMessage id='TITLE.BRAND.HAS.BEEN.SUCCESSFULL.EDITED' />
            </div>
          )}
          <Form autoComplete='off'>
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='name'>
                    <FormattedMessage id='TITLE.BRAND.NAME' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='name'
                    required
                    placeholder={intl.formatMessage({
                      id: "TITLE.BRAND.NAME"
                    })}
                    {...formikEditBrand.getFieldProps("name")}
                  />
                  {formikEditBrand.touched.name &&
                  formikEditBrand.errors.name ? (
                    <div className='text-danger'>
                      {formikEditBrand.errors.name}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='description'>
                    <FormattedMessage id='TITLE.DESCRIPTION' />
                    <span className='mr-1 ml-1 text-muted'>
                      (
                      <FormattedMessage id='TITLE.MAX.512.CHARACTERS' />)
                    </span>
                  </Form.Label>
                  <Form.Control
                    id='description'
                    as='textarea'
                    placeholder={intl.formatMessage({
                      id: "TITLE.DESCRIPTION"
                    })}
                    className='form-control'
                    resize='none'
                    {...formikEditBrand.getFieldProps("description")}
                  />
                  {formikEditBrand.touched.description &&
                  formikEditBrand.errors.description ? (
                    <div className='text-danger'>
                      {formikEditBrand.errors.description}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='status'>
                    <FormattedMessage id='TITLE.STATUS' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Select
                    value={selectedOption}
                    onChange={(value) => {
                      handleOptionChange(value);
                    }}
                    isSearchable={true}
                    options={statusName}
                    placeholder={intl.formatMessage({
                      id: "TITLE.SELECT.STATUS"
                    })}
                    id='status'
                    className='react-select-container'
                    classNamePrefix='react-select'
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success mr-3 btn-inventory'
            onClick={() => {
              formikEditBrand.handleSubmit();
            }}
            disabled={isSaveLoading}
          >
            <FormattedMessage id='TITLE.SAVE' />

            {isSaveLoading && (
              <Spinner animation='border' size='sm' className='ml-1' />
            )}
          </Button>
          <Button
            type='button'
            variant='tertiary text-dark'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id='TITLE.CANCEL' />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
