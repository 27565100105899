import React from "react";
import { FormattedMessage } from "react-intl";

const AssetUnderMaintanceToColumnFormatter = (cellContent, row, rowIndex) => {
  const under_maintance = row?.under_maintance 
    ? <FormattedMessage id='TITLE.ASSET.LIST.MAINTANCE.STATUS.UNDER.MAINTANCE' /> 
    : <FormattedMessage id='TITLE.ASSET.LIST.MAINTANCE.STATUS.READY.TO.USE' /> 

  return (
    <>
      <div className={row?.under_maintance ? "text-danger" : "text-success"}>{under_maintance || "-"}</div>
    </>
  );
};

export default AssetUnderMaintanceToColumnFormatter;
