import React from "react";
import { capitalizeFirstLetter } from "../constants/utils";

const OnlyCategoryNameColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { redirectToCategoryViewPage }
) => {
  const { category } = row;
  const { name = "-" } = category || [];
  return (
    <div>
      <>
        <div className='text p-0 '>{capitalizeFirstLetter(name)}</div>
      </>
    </div>
  );
};

export default OnlyCategoryNameColumnFormatter;
