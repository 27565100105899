import React from "react";
import { AddEditNewAuditModal } from "./AddEditNewAuditModal";

export function AddNewAuditModal({
  onCloseModal,
  open,
  handleCloseOfAddNewAuditModal
}) {

  return (
    <>
      <AddEditNewAuditModal
        onCloseModal={onCloseModal}
        open={open}
        handleCloseOfAddNewAuditModal={handleCloseOfAddNewAuditModal}
      />
    </>
  );
}
