import React, { useState } from "react";
import Sidebar from "./common/sidebar_components/sidebar";
import RightSidebar from "./common/right-sidebar";
import Header from "./common/header_components/header";
import { isMobile } from "react-device-detect";

const App = (props) => {
  // const initialState = {
  //   ltr: false,
  //   divName: "RTL",
  // };

  // const [side, setSide] = useState(initialState);
  const [sidebar, setSidebar] = useState(true);

  // const ChangeRtl = (divName) => {
  //   if (divName === "RTL") {
  //     document.body.classList.add("rtl");
  //     setSide({ divName: "LTR" });
  //   } else {
  //     document.body.classList.remove("rtl");
  //     setSide({ divName: "RTL" });
  //   }
  // };

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(false);
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    } else {
      setSidebar(true);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".page-sidebar").classList.remove("open");
    }
  };

  const openSidebar = () => {
    if (isMobile) {
      if (sidebar) {
        setSidebar(false);
        document.querySelector(".page-main-header").classList.add("open");
        document.querySelector(".page-sidebar").classList.add("open");
      } else {
        setSidebar(true);
        document.querySelector(".page-main-header").classList.remove("open");
        document.querySelector(".page-sidebar").classList.remove("open");
      }
    } else {
      setSidebar(true);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".page-sidebar").classList.remove("open");
    }
  };

  return (
    <div>
      <div className='page-wrapper'>
        <Header openCloseSidebar={openCloseSidebar} />
        <div className='page-body-wrapper'>
          <Sidebar
            openSidebar={openSidebar}
            openCloseSidebar={openCloseSidebar}
          />
          <RightSidebar />
          <div className='page-body'>{props.children}</div>
          {/* <Footer /> */}
        </div>
      </div>
      {/* <div
				className="btn-light custom-theme"
				onClick={() => ChangeRtl(side.divName)}
			>
				{side.divName}
			</div> */}
    </div>
  );
};
export default App;
