import React from "react";
import { STRING_CONSTANTS } from "../../constants/const";
import { getCheckInOutStatusName } from "../../constants/utils";

const AssetCheckStatusColumnFormatter = (cellContent, row, rowIndex) => {
  const { check_in_out_status } = row;

  return (
    <>
      <div
        className={`${
          getCheckInOutStatusName(check_in_out_status) ===
          STRING_CONSTANTS.CHECK_IN
            ? "text-primary"
            : "text-secondary"
        }`}
      >
        {getCheckInOutStatusName(check_in_out_status, false)}
      </div>
    </>
  );
};

export default AssetCheckStatusColumnFormatter;
