import React, { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { amountFormatter } from "../../constants/utils";
import { FormattedMessage } from "react-intl";

function InventorySummary({ qtyInHand, qtyToReceive, isLoading }) {
  return (
    <>
      <h4 className='modal-title mb-3'>
        <FormattedMessage id='TITLE.ASSETS.SUMMARY' />
      </h4>
      <Row>
        <Col lg={12} md={6}>
          <Card className='inventory-summary mb-3'>
            <Card.Body className='bg-primary-gradient'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <h6 className='mb-0 text-white small-text'>
                  <FormattedMessage id='TITLE.QTY.IN.HAND' />
                </h6>
                <div className='box-counter'>
                  <h4 className='text-white'>{qtyInHand}</h4>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={12} md={6}>
          <Card className='inventory-summary'>
            <Card.Body className='bg-success-gradient'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <h6 className='mb-0 text-white small-text'>
                  <FormattedMessage id='TITLE.QTY.TO.BE.RECEIVED' />
                </h6>
                <div className='box-counter'>
                  <h4 className='text-white'>{qtyToReceive}</h4>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default InventorySummary;
