import { createContext, useState } from "react";

export const UIContext = createContext();

// This context provider is passed to any component requiring the context
export const UIProvider = ({ children }) => {
  const [currentPath, setCurrentPath] = useState("");

  return (
    <UIContext.Provider
      value={{
        currentPath,
        setCurrentPath
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
