import React, { useEffect, useState } from "react";
import { Button, Modal, Col, Form } from "react-bootstrap";
import { Trash2 } from "react-feather";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ChevronDownMaster,
  DropdownMaster,
  DropdownIndicatorMaster
} from "../../../helper/helper";
import CreatableSelect from "react-select/creatable";
import ButtonAtl from "@atlaskit/button";
import { capitalizeFirstLetter } from "../../../constants/utils";
import { AddNewBrandModal } from "../../brand-module/add-new-brand/AddNewBrandModal";

export function DeleteBrandModal({
  title,
  description,
  reassignDescription,
  id,
  onCloseModal,
  isOpen,
 // onClickOfDeleteButton,
  brandList,
  formikDeleteBrand,
  brandDetails,
  isLoading,
  addNewBrandModalFromDelete
}) {
  const intl = useIntl();
  const [isOpenBrand, setOpenBrand] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [isOpenAddNewBrandModal, setOpenAddNewBrandModal] =
    useState(false);
  const [searchedBrandName, setSearchedBrandName] = useState("");
  const [brandListFiltered, setBrandListFiltered] = useState([]);

  useEffect(() => {
    const { id: brandId } = brandDetails || {};
    const clist =
      brandList &&
      brandList
        .map((brand) => {
          const { name, id } = brand || {};
          brand.value = id;
          brand.label = name;
          return brand;
        })
        .filter((brand) => brand.id !== brandId);
    setBrandListFiltered(clist);
  }, [brandList]);

  const toggleOpenBrand = () => {
    setOpenBrand(!isOpenBrand);
  };

  const addNewBrandModalShow = () => {
    setOpenAddNewBrandModal(true);
  };

  const addNewBrandModalHide = () => {
    setOpenAddNewBrandModal(false);
  };

  const onSelectChange = (e) => {
    toggleOpenBrand();
    if (e) {
      setSelectedBrand(e);
      formikDeleteBrand.setFieldValue("brandId", e.value);
    } else {
      setSelectedBrand(null);
      formikDeleteBrand.setFieldValue("brandId", "");
    }
  };

  const formatCreateLabel = (inputValue) => {
    setSearchedBrandName(capitalizeFirstLetter(inputValue));
    return (
      "+" +
      intl.formatMessage({
        id: "TITLE.ADD.NEW.Brand"
      })
    );
  };

  const handleInputChange = (event) => {};

  const handleCloseOfAddNewBrandModal = () => {
    addNewBrandModalHide();
    addNewBrandModalFromDelete();
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onCloseModal}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <form
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault();
            window.scrollTo(0, 0);
            formikDeleteBrand.handleSubmit();
          }}
          onReset={() => {
            formikDeleteBrand.handleReset();
          }}
        >
          <Modal.Body>
            <div className='text-center p-3'>
              <h3 className='modal-title text-center mb-3 text-danger'>
                {`${id}`}
              </h3>
              <Trash2
                size={60}
                className='text-danger mb-2'
                style={{ strokeWidth: "1.5px" }}
              ></Trash2>
              <h3 className='modal-title text-center mb-3 text-danger'>
                {`${title}`}
              </h3>
              <p className='text-center text-dark mb-0'>{description}</p>
              <p className='text-center text-dark mt-2 mb-0'>
                {reassignDescription}
              </p>
            </div>

            <div className='text-center p-3'>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='brand'>
                    <FormattedMessage id='TITLE.SELECT.BRAND' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <DropdownMaster
                    isOpen={isOpenBrand}
                    onClose={toggleOpenBrand}
                    target={
                      <ButtonAtl
                        iconAfter={<ChevronDownMaster />}
                        onClick={() => {
                          toggleOpenBrand();
                        }}
                        isSelected={isOpenBrand}
                        className='form-control w-100 bg-white custom-select-btn mb-0'
                      >
                        {selectedBrand ? (
                          `${selectedBrand.label}`
                        ) : (
                          <FormattedMessage id='TITLE.SELECT.BRAND' />
                        )}
                      </ButtonAtl>
                    }
                  >
                    <CreatableSelect
                      onChange={(e) => onSelectChange(e)}
                      onInputChange={handleInputChange}
                      options={brandListFiltered}
                      createOptionPosition='top'
                      formatCreateLabel={formatCreateLabel}
                      isSearchable
                      onCreateOption={() => {
                        addNewBrandModalShow();
                        setOpenBrand(false);
                      }}
                      autoFocus
                      backspaceRemovesValue={true}
                      components={{
                        DropdownIndicatorMaster,
                        IndicatorSeparator: null
                      }}
                      controlShouldRenderValue={true}
                      hideSelectedOptions={false}
                      isClearable={true}
                      menuIsOpen
                      placeholder={intl.formatMessage({
                        id: "TITLE.SELECT.BRAND"
                      })}
                      tabSelectsValue={true}
                      value={selectedBrand}
                      className='react-custom-select-container'
                      classNamePrefix='react-select'
                    />
                  </DropdownMaster>
                  {formikDeleteBrand.touched.brandId &&
                  formikDeleteBrand.errors.brandId ? (
                    <div className='text-danger'>
                      {formikDeleteBrand.errors.brandId}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </div>

            <div className='d-flex align-items-center justify-content-center mb-3'>
              <Button
                type='submit'
                variant='danger btn-inventory mr-3 btn-inventory'
                disabled={isLoading}
              >
                <FormattedMessage id='TITLE.YES.DELETE' />
                {isLoading && <ButtonLoader className='mr-1 ml-1' />}
              </Button>
              <Button
                variant='tertiary btn-inventory text-dark btn-inventory'
                onClick={() => onCloseModal()}
              >
                <FormattedMessage id='TITLE.CANCEL' />
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
      {isOpenAddNewBrandModal && (
        <AddNewBrandModal
          searchedBrandName={searchedBrandName}
          open={isOpenAddNewBrandModal}
          onCloseModal={addNewBrandModalHide}
          handleCloseOfAddNewBrandModal={handleCloseOfAddNewBrandModal}
        />
      )}
    </>
  );
}
