/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import { DD_MM_YYYY } from "../constants/const";
const AuditDateTimeEndDateColumnFormatter = (cellContent, row, rowIndex) => {
  const { end_date } = row || {};
  
  const renderDate = () => {
    if (end_date) return <Moment date={end_date} format={DD_MM_YYYY} />;
    return "-";
  };
  return <>{renderDate()}</>;
};

export default AuditDateTimeEndDateColumnFormatter;
