import React from "react";
import { capitalizeFirstLetter } from "../../constants/utils";

const AssetProductCategoryNameColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { redirectToCategoryViewPage }
) => {
  const { product } = row;
  const { category } = product || {};
  const { name = "-" } = category || [];

  return (
    <>
      <div
        className='text-primary btn-link p-0 cursor-pointer'
        onClick={() => redirectToCategoryViewPage(row)}
      >
        {capitalizeFirstLetter(name)}
      </div>
    </>
  );
};

export default AssetProductCategoryNameColumnFormatter;
