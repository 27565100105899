import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Spinner
} from "react-bootstrap";
import { Edit2, Plus, X } from "react-feather";
import { useIntl } from "react-intl";
import { AddNewLocationModal } from "../../../global-modal/AddNewLocationModal";
import { EditLocationModal } from "../../../global-modal/EditLocationModal";
import { DeleteModal } from "../../common/modals/DeleteModal";
import LocationProductView from "./LocationProductView";

const ViewLocationDetails = ({ history, handlePDFViewValues }) => {
  const intl = useIntl();
  const [data, setData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isOpenAddNewLocationModal, setOpenAddNewLocationModal] =
    useState(false);
  const [isAdjustStock, setAdjustStock] = useState(false);

  const addNewLocationModalShow = () => {
    setOpenAddNewLocationModal(true);
  };

  const addNewLocationModalHide = () => {
    setOpenAddNewLocationModal(false);
  };

  const adjustStockShow = () => {
    setAdjustStock(true);
  };

  const adjustStockHide = () => {
    setAdjustStock(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const CreateLocation = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Create New Location
    </Tooltip>
  );

  const editTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Edit Location
    </Tooltip>
  );
  const downloadTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Adjust Stock
    </Tooltip>
  );
  const print = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Adjust Stcok
    </Tooltip>
  );
  const attachefileTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Attache File
    </Tooltip>
  );

  const recivealertmessage = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      You cannot record receives for service items.
    </Tooltip>
  );

  const [isPDFViewOpen, setPDFViewOpen] = useState(false);
  const [isReceiveviewOpen, setReceiveviewOpen] = useState(false);
  const [isPDFView, setPDFView] = useState("Print View");
  const [isPageLoaderShow, setPageLoaderShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaderShow(false);
    }, 500);
  }, []);

  const [isItemReceived, setItemReceived] = useState(false);

  const handleOnClickOfcancle = () => {
    setReceiveviewOpen(false);
  };

  const handleEditLocationModalShow = () => {
    setEditModalOpen(true);
  };

  const handleEditLocationModalHide = () => {
    setEditModalOpen(false);
  };

  return (
    <>
      <Card className='p-0 mb-0'>
        {isPageLoaderShow && (
          <div className='section-loader'>
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
            <Spinner animation='grow' size='sm' className='mr-1' />
          </div>
        )}
        <div className='details-view d-block d-md-flex'>
          {/* Listing */}
          <div className='column list-column list-column-small border-right d-none d-lg-block'>
            <div className='column-header d-flex align-items-center justify-content-between'>
              <h2 className='modal-title'>All Location</h2>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={CreateLocation}
              >
                <Button
                  variant='primary btn-inventory btn-icon'
                  size='sm'
                  onClick={() => {
                    addNewLocationModalShow();
                  }}
                >
                  <Plus size={14} className='text-secondary'></Plus> New
                </Button>
              </OverlayTrigger>
            </div>
            <div className='column-body custom-scrollbar'>
              {/* Column Box */}
              <div className='column-box'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column'>
                    <h5 className='fs-14 text-secondary font-weight-bold mb-0 cursor-pointer'>
                      Location 1 <small> (10 products)</small>
                    </h5>
                  </div>
                </div>
              </div>
              {/* Column Box */}
              <div className='column-box'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column'>
                    <h5 className='fs-14 text-primary font-weight-bold mb-0 cursor-pointer'>
                      Location 2<small> (5 products)</small>
                    </h5>
                  </div>
                </div>
              </div>
              {/* Column Box */}
              <div className='column-box'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column'>
                    <h5 className='fs-14 text-primary font-weight-bold mb-0 cursor-pointer'>
                      Location 3<small> (2 products)</small>
                    </h5>
                  </div>
                </div>
              </div>
              {/* Column Box */}
              <div className='column-box'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column'>
                    <h5 className='fs-14 text-primary font-weight-bold mb-0 cursor-pointer'>
                      Location 4<small> (5 products)</small>
                    </h5>
                  </div>
                </div>
              </div>
              {/* Column Box */}
              <div className='column-box'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column'>
                    <h5 className='fs-14 text-primary font-weight-bold mb-0 cursor-pointer'>
                      Location 5<small> (1 products)</small>
                    </h5>
                  </div>
                </div>
              </div>
              {/* Column Box */}
              <div className='column-box'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column'>
                    <h5 className='fs-14 text-primary font-weight-bold mb-0 cursor-pointer'>
                      Location 6 <small> (10 products)</small>
                    </h5>
                  </div>
                </div>
              </div>
              {/* Column Box */}
              <div className='column-box'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column'>
                    <h5 className='fs-14 text-primary font-weight-bold mb-0 cursor-pointer'>
                      Location 7<small> (5 products)</small>
                    </h5>
                  </div>
                </div>
              </div>
              {/* Column Box */}
              <div className='column-box'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column'>
                    <h5 className='fs-14 text-primary font-weight-bold mb-0 cursor-pointer'>
                      Location 8<small> (2 products)</small>
                    </h5>
                  </div>
                </div>
              </div>
              {/* Column Box */}
              <div className='column-box'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column'>
                    <h5 className='fs-14 text-primary font-weight-bold mb-0 cursor-pointer'>
                      Location 9<small> (5 products)</small>
                    </h5>
                  </div>
                </div>
              </div>
              {/* Column Box */}
              <div className='column-box'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column'>
                    <h5 className='fs-14 text-primary font-weight-bold mb-0 cursor-pointer'>
                      Location 10
                      <span> (1 products)</span>
                    </h5>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
          {/* Details */}
          {!isReceiveviewOpen && (
            <div className='column details-column'>
              <div className='column-header d-flex align-items-center justify-content-between'>
                <h2 className='modal-title'> </h2>
                <div className='d-flex'>
                  <ButtonGroup className='mr-2' aria-label='First group'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={editTooltip}
                    >
                      <Button
                        variant='outline-tertiary btn-sm btn-icon'
                        onClick={handleEditLocationModalShow}
                      >
                        <Edit2 size={16} className='p-0 m-0'></Edit2>
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                  <Button
                    variant='secondary btn-icon btn-sm mr-2'
                    onClick={() => {
                      addNewLocationModalShow();
                    }}
                  >
                    New Location
                  </Button>
                  {/* <Dropdown>
                    <Dropdown.Toggle variant="dropdown-sm" id="dropdown-basic">
                      More
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>Mark as Inactive</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Button
                    variant='close btn-icon btn-sm'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push("/master/locations/locations");
                    }}
                  >
                    <X size={20} className='p-0 m-0 text-dark'></X>
                  </Button>
                </div>
              </div>
              <div className='column-body custom-scrollbar'>
                <LocationProductView />
              </div>
            </div>
          )}
          {/*  */}
        </div>
      </Card>

      {isDeleteModalOpen && (
        <DeleteModal
          title={"Delete Location?"}
          description={intl.formatMessage({
            id: "TITLE.CATEGORY.IS.INACTIVE.NOW.AND.ALL.THE.PRODUCT.ASSOCIATED.WITH.THIS.CATEGORY.WILL.BE.MARKED.AS.INACTIVE"
          })}
          isOpen={isDeleteModalOpen}
          onCloseModal={handleDeleteModalHide}
        />
      )}

      {isOpenAddNewLocationModal && (
        <AddNewLocationModal
          open={isOpenAddNewLocationModal}
          onCloseModal={addNewLocationModalHide}
        />
      )}

      {isEditModalOpen && (
        <EditLocationModal
          open={isEditModalOpen}
          data={data}
          onCloseModal={handleEditLocationModalHide}
        />
      )}
    </>
  );
};

export default ViewLocationDetails;
