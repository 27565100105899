import React from 'react';
import { Table, Button } from 'react-bootstrap';

const DocumentTable = ({ documents, handleDownload, handleDelete }) => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Document Name</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {documents.length > 0 ? documents.map((doc) => (
                    <tr key={doc.id}>
                        <td>{doc.name}</td>
                        <td>
                            <Button variant='btn link-btn p-2'
                                size="sm" className="mr-2" onClick={() => handleDownload(doc.name)}>
                                <i className='fa fa-download text-success' />
                            </Button>

                            <Button variant='btn link-btn p-2'
                                size="sm" onClick={() => handleDelete(doc.id)}>
                                <i className='fa fa-trash text-danger' />
                            </Button>
                        </td>
                    </tr>
                )) : "No Documents Uploaded"}
            </tbody>
        </Table >
    );
};

export default DocumentTable;
