import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const InwardUIContext = createContext();

export function useInwardUIContext() {
  return useContext(InwardUIContext);
}

export const InwardUIConsumer = InwardUIContext.Consumer;

export function InwardUIProvider({
  inwardUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: inwardUIEvents.openViewDialog,
    openEditDialog: inwardUIEvents.openEditDialog,
    openEditStatusDialog: inwardUIEvents.openEditStatusDialog,
    openDeleteDialog: inwardUIEvents.openDeleteDialog,
    redirectToViewPage: inwardUIEvents.redirectToViewPage,
    redirectToVendorViewPage: inwardUIEvents.redirectToVendorViewPage,
    openAgreeStatusDialog: inwardUIEvents.openAgreeStatusDialog,
    openDisagreeStatusDialog: inwardUIEvents.openDisagreeStatusDialog
  };

  return (
    <InwardUIContext.Provider value={value}>
      {children}
    </InwardUIContext.Provider>
  );
}
