import { useFormik } from "formik";
import React, { useState } from "react";
import {
  Button,
  ButtonToolbar,
  Modal,
  Row,
  Col,
  Form,
  InputGroup
} from "react-bootstrap";
import { Check } from "react-feather";
import { ViewPasswordRequestUserApi } from "../axios/services/services";
import { ErrorModal } from "../components/common/modals/ErrorModal";
import {
  getErrorMessageBasedOnLanguage,
  unauthorization
} from "../constants/utils";
import { ButtonLoader } from "../helper/ButtonLoader";
import { ViewPasswordRequestSchema } from "../helper/FormikSchema";
import { FormattedMessage, useIntl } from "react-intl";
import { USER_DETAILS } from "../constants/const";
import ErrorMessage from "../helper/ErrorMessage";

let ViewPasswordRequestInitialValues = {
  password: "",
  confirmpassword: ""
};

export function ViewPasswordRequestModal({
  isOpenViewPasswordRequestModal,
  userData,
  handleCloseOfViewPasswordRequestModal,
  handleSuccessOfViewPasswordRequestModal
}) {
  const { id, full_name } = userData || {};
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isViewPasswordRequestSuccess, setViewPasswordRequestSuccess] =
    useState(false);
  const [
    isLoadingViewPasswordRequestStatus,
    setLoadingViewPasswordRequestStatus
  ] = useState(false);
  const [isLoadingChangePassword, setLoadingChangePassword] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const [isSaveLoading, setSaveLoading] = useState(false);

  const enableViewPasswordRequestStatus = () => {
    setLoadingViewPasswordRequestStatus(true);
  };

  const disableViewPasswordRequestStatus = () => {
    setLoadingViewPasswordRequestStatus(false);
  };

  const enableLoadingChangePassword = () => {
    setLoadingChangePassword(true);
  };

  const disableLoadingChangePassword = () => {
    setLoadingChangePassword(false);
  };

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  let ChangePasswordInitialValues = {
    password: "",
    confirmPassword: ""
  };

  const intl = useIntl();
  const formikViewPasswordRequest = useFormik({
    initialValues: ViewPasswordRequestInitialValues,
    validationSchema: ViewPasswordRequestSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const { password } = values || {};
      let viewPasswordRequestUserObj = {
        id,
        password
      };
      enableSaveLoading();
      enableViewPasswordRequestStatus();
      const ViewPasswordRequestUserResponse = await ViewPasswordRequestUserApi(
        viewPasswordRequestUserObj
      );
      disableViewPasswordRequestStatus();
      disableSaveLoading();
      const { Error } = ViewPasswordRequestUserResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setViewPasswordRequestSuccess(true);
      window.scrollTo(0, 0);
      handleSuccessOfViewPasswordRequestModal();
    },
    onReset: (values, { resetForm }) => {}
  });

  // const formikChangePassword = useFormik({
  //   initialValues: ChangePasswordInitialValues,
  //   validationSchema: ViewPasswordRequestSchema,
  //   onSubmit: async (values, { setStatus, setSubmitting }) => {
  //     const { password } = values || {};

  //     let changePasswordObj = {
  //       id: id,
  //       password,
  //     };
  //     enableSaveLoading();
  //     enableLoadingChangePassword();
  //     const changePasswordResponse = await changePasswordApi(changePasswordObj);
  //     disableLoadingChangePassword();
  //     disableSaveLoading();

  //     const { data, Error } = changePasswordResponse || {};

  //     if (Error) {
  //       unauthorization(Error);
  //       setErrorMessage(getErrorMessageBasedOnLanguage(Error));
  //       handleErrorModalShow();
  //       return;
  //     }
  //     setSuccessMessage(
  //       intl.formatMessage({ id: "TITLE.PASSWORD.CHANGED.SUCCESSFULLY" })
  //     );
  //     onOpenSuccessModal();
  //     setViewPasswordRequestSuccess(true);
  //     window.scrollTo(0, 0);
  //     setTimeout(() => {
  //       handleSuccessOfViewPasswordRequestModal(data);
  //     }, 2500);
  //     formikChangePassword.handleReset();
  //   },
  //   onReset: (values, { resetForm }) => {},
  // });

  return (
    <>
      <Modal
        show={isOpenViewPasswordRequestModal}
        onHide={handleCloseOfViewPasswordRequestModal}
        dialogClassName='modal-90w status-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>
            <FormattedMessage id='TITLE.CHANGE.PASSWORD.REQUEST' />
            {` - ${full_name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <Form autoComplete='off'>
            {isViewPasswordRequestSuccess && (
              <div className='notification icon inline success fs-16'>
                <Check size={8} className='text-white'></Check>
                <FormattedMessage id='TITLE.USER.PASSWORD.CHANGED.SUCCESSFULLY' />
              </div>
            )}
            <Row>
              <Col lg={8} className='mb-2 mb-md-0'>
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id='TITLE.NEW.PASSWORD' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type='password'
                      className='form-control'
                      id='password'
                      placeholder={intl.formatMessage({
                        id: "TITLE.NEW.PASSWORD"
                      })}
                      name='password'
                      {...formikViewPasswordRequest.getFieldProps("password")}
                    />
                  </InputGroup>
                  {formikViewPasswordRequest.touched.password &&
                  formikViewPasswordRequest.errors.password ? (
                    <div className='text-danger'>
                      <ErrorMessage
                        id={formikViewPasswordRequest.errors.password}
                      />
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={8} className='mb-2 mb-md-0'>
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id='TITLE.CONFIRM.PASSWORD' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type='password'
                      className='form-control'
                      id='confirmPassword'
                      placeholder={intl.formatMessage({
                        id: "TITLE.CONFIRM.PASSWORD"
                      })}
                      name='confirmPassword'
                      {...formikViewPasswordRequest.getFieldProps(
                        "confirmPassword"
                      )}
                    />
                  </InputGroup>

                  {formikViewPasswordRequest.errors.confirmPassword ? (
                    <div className='text-danger'>
                      <ErrorMessage
                        id={formikViewPasswordRequest.errors.confirmPassword}
                      />
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className='mt-3'>
          <ButtonToolbar className='d-flex justify-content-end button-wrap mb-8'>
            <Button
              type='button'
              variant='success mr-3 btn-inventory'
              onClick={() => {
                formikViewPasswordRequest.handleSubmit();
              }}
              disabled={isLoadingViewPasswordRequestStatus}
            >
              <FormattedMessage id='TITLE.SAVE' />
              {isLoadingViewPasswordRequestStatus && (
                <ButtonLoader className='mr-1 ml-1' />
              )}
            </Button>
            <Button
              type='reset'
              variant='tertiary text-dark'
              onClick={() => {
                formikViewPasswordRequest.handleReset();
                handleCloseOfViewPasswordRequestModal();
              }}
            >
              <FormattedMessage id='TITLE.CANCEL' />
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
