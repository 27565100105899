import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const InventoryAgingUIContext = createContext();

export function useInventoryAgingUIContext() {
  return useContext(InventoryAgingUIContext);
}

export const InventoryAgingUIConsumer = InventoryAgingUIContext.Consumer;

export function InventoryAgingUIProvider({
  inventoryagingUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: inventoryagingUIEvents.openViewDialog,
    openEditDialog: inventoryagingUIEvents.openEditDialog,
    openDeleteDialog: inventoryagingUIEvents.openDeleteDialog
  };

  return (
    <InventoryAgingUIContext.Provider value={value}>
      {children}
    </InventoryAgingUIContext.Provider>
  );
}
