import React, { useEffect, useState } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { locationListMastersApi } from "../../../../axios/services/services";

export function LocationWiseFilter({ filterValues }) {
  const [filterStatus, setFilterStatus] = useState(" ");
  const [parentLocation, setParentLocation] = useState([]);
  const [storeLocation, setStoreLocation] = useState([]);

  const applyFilter = (values) => {
    filterValues(values);
  };
  const [startDate, setStartDate] = useState(null);

  async function getlocations() {
    // enableLoading();
    // if (isFilter) {
    //   enableLoadingFilter();
    // }
    const queryParams = { pageSize: 10, pageNumber: 1 };
    const reportActivePOResponse = await locationListMastersApi({
      queryParams
    });
    // disableLoading();
    // if (isFilter) {
    //   disableLoadingFilter();
    // }
    // setFilter(false);
    const { Error, data, count, total } = reportActivePOResponse || {};
    // if (Error) {
    //   unauthorization(Error);
    //   setErrorMessage(getErrorMessageBasedOnLanguage(Error));
    //   handleErrorModalShow();
    //   return;
    // }

    const locations = data.map((location) => {
      const { id, store_location, parent_location, locationId } =
        location || {};
      if (parent_location) {
        const {
          store_location: depth_one_store_location,
          parent_location: depth_one_parent_location,
          locationId: depth_one_locationId
        } = locationId || {};

        if (depth_one_parent_location) {
          const { store_location: depth_two_store_location } =
            depth_one_locationId || {};
          location.value = id;
          location.label =
            depth_two_store_location +
            ", " +
            depth_one_store_location +
            ", " +
            store_location;
        } else {
          location.value = id;
          location.label = depth_one_store_location + ", " + store_location;
        }
      } else {
        location.value = id;
        location.label = store_location;
      }

      return location;
    });
    setParentLocation(locations ? locations : []);
    setStoreLocation(data ? data : []);
    // setTransactionListTotalCount( count? count : 0)
    // setStartDate(startdate);
    // setEndDate(enddate);
    // setReportActivePOTotalCount(count);
    // setReportActivePOTotalAmount(total);
  }

  useEffect(() => {
    getlocations();
  }, []);

  return (
    <>
      <Form className='form form-label-right filter-card'>
        <Form.Group className='form-group mb-md-0 filter-form-group'>
          <div className='filter-item'>
            <div className=''>
              <small className='form-text text-muted mb-1'>
                <b>Date</b>
              </small>
              <DatePicker
                selected={startDate}
                dateFormat='dd/MM/yyyy'
                onChange={(date) => setStartDate(date)}
                maxDate={startDate}
                placeholderText='DD/MM/YYYY'
                className='form-control w-100'
                closeOnScroll={(e) => e.target === document}
                style={{ zIndex: "10" }}
              />
            </div>
            <div className=''>
              <small className='form-text text-muted mb-1'>
                <b>PO/DC Number</b>
              </small>
              <Form.Control
                type='text'
                className='form-control'
                name='dcnumber'
                placeholder='PO Number'
              />
            </div>
            <div className=''>
              <small className='form-text text-muted mb-1'>
                <b>PO NAME</b>
              </small>
              <Form.Control
                type='text'
                className='form-control'
                name='dcnumber'
                placeholder='PO Number'
              />
            </div>
            <div className=''>
              <small className='form-text text-muted mb-1'>
                <b>Qty.</b>
              </small>
              <Form.Control
                type='text'
                className='form-control'
                name='dcnumber'
                placeholder='PO Number'
              />
            </div>

            <div className=''>
              <small className='form-text text-muted mb-1'>
                <b>Location</b>
              </small>
              <Form.Control
                as='select'
                className='select-control'
                onChange={(event) => {
                  setFilterStatus(event.target.value);
                }}
                value={filterStatus}
              >
                <option value=''>All</option>
                {storeLocation.map((item) => {
                  return (
                    <option value={`${item.id}`}>{item.store_location}</option>
                  );
                })}
                <option value='received'>Waiting For Approval </option>
                <option value='partially-fulfilled'>Issued</option>
                <option value='issued'>Delivered</option>
              </Form.Control>
            </div>
            <div className=''>
              <small className='form-text text-muted mb-1'>
                <b>Parent Location</b>
              </small>
              <Form.Control
                as='select'
                className='select-control'
                onChange={(event) => {
                  setFilterStatus(event.target.value);
                }}
                value={filterStatus}
              >
                <option value=''>All</option>
                {parentLocation.map((item) => {
                  return (
                    <option value={`${item.store_location}`}>
                      {item.store_location}
                    </option>
                  );
                })}
              </Form.Control>
            </div>

            {/* <div className="">
              <small className="form-text text-muted mb-1">
                <b>Date</b>
              </small>
              <DatePicker
                selected={startDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setStartDate(date)}
                maxDate={startDate}
                placeholderText="DD/MM/YYYY"
                className="form-control w-100"
                closeOnScroll={(e) => e.target === document}
                style={{ zIndex: "10" }}
              />
            </div>
            <div className="">
              <small className="form-text text-muted mb-1">
                <b>Expected Delivery Date</b>
              </small>
              <DatePicker
                selected={startDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setStartDate(date)}
                maxDate={startDate}
                placeholderText="DD/MM/YYYY"
                className="form-control w-100"
                closeOnScroll={(e) => e.target === document}
                style={{ zIndex: "10" }}
              />
            </div>
            <div className="">
              <small className="form-text text-muted mb-1">
                <b>PO Number</b>
              </small>
              <Form.Control
                type="text"
                className="form-control"
                name="dcnumber"
                placeholder="PO Number"
              />
            </div>

            <div className="">
              <small className="form-text text-muted mb-1">
                <b>Vendor Name</b>
              </small>
              <Form.Control
                type="text"
                className="form-control"
                name="vendorname"
                placeholder="Vendor Name"
              />
            </div> */}
          </div>
          <div className='filter-action d-flex align-items-center mt-3'>
            <Button variant='secondary d-flex align-items-center btn-inventory mr-2'>
              <Search
                className='m-0'
                size={20}
                style={{ color: "#fff", marginRight: "0" }}
              />
            </Button>
            <Button variant='tertiary d-flex align-items-center btn-inventory'>
              <RefreshCw
                size={20}
                style={{ color: "#CAA25C", marginRight: "0" }}
                className='m-0'
              />
            </Button>
          </div>
        </Form.Group>
      </Form>
    </>
  );
}
