// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useIntl } from "react-intl";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import OnlyCategoryNameColumnFormatter from "../../../columnformatter/OnlyCategoryNameColumnFormatter";
import PriceColumnFormatter from "../../../columnformatter/PriceColumnFormatter";
import ProductNameColumnFormatter from "../../../columnformatter/ProductNameColumnFormatter";
import StatusTypeFormatter from "../../../columnformatter/StatusTypeFormatter";
import { USER_DETAILS } from "../../../constants/const";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import { useProductUIContext } from "../ProductUIContext";
import * as uiHelpers from "../ProductUIHelpers";

export function ProductTable({
  isLoading,
  productList,
  productListTotalCount,
  currentPage,
  setCurrentPage
}) {
  const intl = useIntl();
  // Product UI Context
  const productUIContext = useProductUIContext();
  const productUIProps = useMemo(() => {
    return {
      ids: productUIContext.ids,
      setIds: productUIContext.setIds,
      queryParams: productUIContext.queryParams,
      setQueryParams: productUIContext.setQueryParams,
      openViewDialog: productUIContext.openViewDialog,
      openCheckoutDialog: productUIContext.openCheckoutDialog,
      openEditDialog: productUIContext.openEditDialog,
      openDeleteDialog: productUIContext.openDeleteDialog,
      redirectToViewPage: productUIContext.redirectToViewPage,
      redirectToCategoryViewPage: productUIContext.redirectToCategoryViewPage
    };
  }, [productUIContext]);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  // Table columns
  const columns = [
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "TITLE.PRODUCT.NAME"
      }),
      sort: true,
      style: {
        minWidth: "170px",
        maxWidth: "350px"
      },
      sortCaret: sortCaret,
      formatter: ProductNameColumnFormatter,
      formatExtraData: {
        redirectToViewPage: productUIProps.redirectToViewPage
      }
    },

    {
      dataField: "category_name",
      text: intl.formatMessage({
        id: "TITLE.CATEGORY"
      }),
      sort: true,
      style: {
        minWidth: "170px"
      },
      sortCaret: sortCaret,
      formatter: OnlyCategoryNameColumnFormatter,

      formatExtraData: {
        redirectToCategoryViewPage: productUIProps.redirectToCategoryViewPage
      }
    },
    {
      dataField: "stock_keeping_unit",
      text: intl.formatMessage({
        id: "TITLE.SKU"
      }),
      sort: true,
      style: {
        minWidth: "190px"
      },
      sortCaret: sortCaret
    },
    {
      dataField: "price",
      text: intl.formatMessage({
        id: "TITLE.AMOUNT"
      }),
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "150px"
      },
      formatter: PriceColumnFormatter
    },
    {
      dataField: "available_quantity",
      text: intl.formatMessage({
        id: "TITLE.STOCK.ON.HAND"
      }),
      sort: true,
      style: {
        minWidth: "170px"
      },
      sortCaret: sortCaret
    },
    {
      dataField: "status",
      text: intl.formatMessage({
        id: "TITLE.STATUS"
      }),
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px"
      },
      formatter: StatusTypeFormatter
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "TITLE.ACTIONS"
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: productUIProps.openViewDialog,
        openCheckoutDialog: productUIProps.openCheckoutDialog,
        openEditDialog: productUIProps.openEditDialog,
        openDeleteDialog: productUIProps.openDeleteDialog,
        isVisibleViewButton: true,
        isVisibleEditButton:
          userRole === "Inward Manager" || userRole === "Outward Manager"
            ? false
            : true,
        isVisibleDeteletButton:
          userRole === "Inward Manager" || userRole === "Outward Manager"
            ? false
            : true,
        isVisibleCheckoutButton:
          userRole === "Inward Manager" || userRole === "Outward Manager"
            ? false
            : true
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px"
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: productListTotalCount ? productListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-head-custom table-head-bg table-borderless table-vertical-center product-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={productList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  productUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={productList}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
