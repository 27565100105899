import React, { useContext, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";
import { AUTH_TOKEN, DD_MM_YYYY } from "../../constants/const";
import {
  amountFormatter,
  capitalizeFirstLetter,
  getProductMediaUrl,
  getProductStatusTextClass,
  getStatusClass,
  setActiveMenu
} from "../../constants/utils";
import { ImageWrapper } from "../../helper/ImageWrapper";
import { FormattedMessage } from "react-intl";
import { downloadAttachmentApi } from "../../axios/services/services";
import { UIContext } from "../../helper/UIContext";
import { MENUITEMS } from "../../constants/menu";
import { Download } from "react-feather";

export function DefaultPOView({
  PODetails,
  purchaseOrderProductList,
  purchaseOrderReceiveList,
  POScanNotes,
  id
}) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const {
    purchaseorder_number,
    purchaseorderstatus,
    date,
    expected_delivery,
    contact,
    totalamount,
    totalquantity,
    contactId,
    notes,
    purchaseorder_receive
  } = PODetails || {};

  const { purchase_media } = PODetails || {};
  // const [firstFile] = purchase_media || [];
  // const { filename } = firstFile || {};

  const { note: scanNote } = purchaseorder_receive || {};

  const { name: statusName } = purchaseorderstatus || {};

  const {
    contact_name = "",
    billingaddress = "",
    shippingaddress = ""
  } = contact || {};
  const {
    building_no: billingBuildingNo = "",
    zipcode: billingZipcode = "",
    city: billingCity
  } = billingaddress || {};
  const { name: nameCityBilling = "" } = billingCity || {};
  const {
    building_no: shippingBuildingNo = "",
    zipcode: shippingZipcode = "",
    city: shippingCity
  } = shippingaddress || {};
  const { name: nameCityShipping = "" } = shippingCity || {};

  const renderProductImage = (productItem) => {
    const { product } = productItem || {};
    const { product_media } = product || {};
    const { name } = product_media || {};
    return getProductMediaUrl(name);
  };

  const downloadAttachedFile = async (filename) => {
    await downloadAttachmentApi({
      id,
      containername: "product",
      filename: filename,
      token: AUTH_TOKEN
    });
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <div className='view-body'>
        <Row className='mt-4 mb-5'>
          <Col md={6} lg={7}>
            <div>
              <h3 className='page-title'>
                <FormattedMessage id='TITLE.PURCHASE.ORDER' />
              </h3>
              <div className='po-number text-dark mb-2'>
                <FormattedMessage id='TITLE.PURCHASE.ORDER' />#{" "}
                <span className='f-w-600'>{purchaseorder_number}</span>
              </div>
              <span
                className={`${getStatusClass(
                  statusName
                )} status-color text-capitalize py-1 px-3`}
              >
                {statusName}
              </span>
            </div>
            <div className='address'>
              <Row>
                <Col md={6}>
                  <h6 className='text-dark'>
                    <FormattedMessage id='TITLE.ORDER.DATE' />:
                  </h6>
                </Col>
                <Col md={6} className='text-dark'>
                  <Moment date={date} format={DD_MM_YYYY} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h6 className='text-dark'>
                    <FormattedMessage id='TITLE.EXPECTED.DELIVERY.DATE' />:
                  </h6>
                </Col>
                <Col md={6} className='text-dark'>
                  {expected_delivery ? (
                    <Moment date={expected_delivery} format={DD_MM_YYYY} />
                  ) : (
                    "-"
                  )}
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6} lg={5} className='mt-3 mt-md-0'>
            <div className='address pt-0'>
              <h6 className='text-black f-w-600'>
                <FormattedMessage id='TITLE.VENDOR.ADDRESS' />
              </h6>
              <Link
                to={`/vendors/view-vendor-details/${contactId}`}
                className='text-primary modal-title font-weight-normal d-block'
                onClick={() => {
                  const path = `/vendors/view-vendor-details/${contactId}`;
                  onClickChangeRoute(path);
                }}
              >
                {contact_name ? contact_name : ""}
              </Link>
              <span className='text-dark d-block pt-2'>
                {`${billingBuildingNo ? billingBuildingNo : ""} ${
                  billingZipcode ? billingZipcode : ""
                }`}{" "}
                <br />
                {`${capitalizeFirstLetter(nameCityBilling)}`}
              </span>
            </div>
            <div className='address'>
              <h6 className='text-black mb-2 f-w-600'>
                <FormattedMessage id='TITLE.DELIVERY.ADDRESS' />
              </h6>
              <span className='text-dark d-block'>
                {`${shippingBuildingNo ? shippingBuildingNo : ""} ${
                  shippingZipcode ? shippingZipcode : ""
                }`}{" "}
                <br />
                {`${capitalizeFirstLetter(nameCityShipping)}`}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Table responsive className='viewpo-table'>
              <thead>
                <tr>
                  <th style={{ Width: "28%" }}>
                    <FormattedMessage id='TITLE.PRODUCT' />
                  </th>
                  <th style={{ Width: "15%" }}>
                    <FormattedMessage id='TITLE.ORDERED' />
                  </th>
                  <th style={{ Width: "20%" }}>
                    <FormattedMessage id='TITLE.STATUS' />
                  </th>
                  <th style={{ Width: "17%" }}>
                    <FormattedMessage id='TITLE.RATE' />
                  </th>
                  <th style={{ Width: "20%" }}>
                    <FormattedMessage id='TITLE.AMOUNT' />
                  </th>
                </tr>
              </thead>
              <tbody>
                {purchaseOrderProductList &&
                  purchaseOrderProductList.map((product, index) => (
                    <tr key={index}>
                      <td>
                        <div className='table-product-view'>
                          <div className='product-thumb'>
                            <div>
                              <ImageWrapper
                                className={
                                  "img-radius align-top blur-up lazyloaded"
                                }
                                src={renderProductImage(product)}
                              />
                            </div>
                          </div>
                          <div className='product-name pl-3'>
                            <Link
                              to={`/inventoryproducts/view-inventoryproduct-details/${product?.product?.id}`}
                              className='text-primary fs-14 mb-2 text-capitalize'
                              onClick={() => {
                                const path = `/inventoryproducts/view-inventoryproduct-details/${product?.product?.id}`;
                                onClickChangeRoute(path);
                              }}
                            >
                              {capitalizeFirstLetter(product?.product?.name)}
                            </Link>
                            <div className='small-description small-text text-dark'>
                              {product.description}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className='text-nowrap'>
                        {product?.quantity}{" "}
                        {product?.product?.productunit?.name} <br />
                        {/* {product?.product_status === "Partially Fulfilled" && (
                          <>{`${product?.received_quantity
                            ? `${product?.received_quantity} Received`
                            : ""
                            }`}</>
                        )} */}
                        <div className='small-description small-text text-dark'>
                          <FormattedMessage id='TITLE.RECEIVED.QUANTITY' /> :{" "}
                          {product.received_quantity}
                        </div>
                      </td>
                      <td
                        className={`${getProductStatusTextClass(
                          product?.product_status
                        )} text-nowrap`}
                      >
                        {product?.product_status}
                      </td>
                      <td className='text-nowrap'>
                        {amountFormatter(product?.price)}
                      </td>
                      <td className='text-nowrap'>
                        {amountFormatter(product?.totalamount)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <div className='group pb-5'>
          <Row>
            <Col md={3} lg={6}></Col>
            <Col md={9} lg={6}>
              <div className='total-section'>
                <Row>
                  <Col md={6}>
                    <h6 className='modal-title f-w-900'>
                      <FormattedMessage id='TITLE.TOTAL' />
                    </h6>
                    <span className='text-muted small-text text-dark'>
                      <FormattedMessage id='TITLE.TOTAL.QUANTITY' /> :{" "}
                      {totalquantity}
                    </span>
                  </Col>
                  <Col md={6}>
                    <h6 className='modal-title f-w-900 text-right'>
                      {amountFormatter(totalamount)}
                    </h6>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={12}>
            <h6 className='text-black mb-2 f-w-600'>
              <FormattedMessage id='TITLE.NOTES' />
            </h6>
            <p>{notes}</p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h6 className='text-black mb-2 f-w-600'>
              <FormattedMessage id='TITLE.ITEM.SCAN.NOTES' />
            </h6>
            {POScanNotes &&
              POScanNotes.map((scanNote, snIndex) => {
                return scanNote?.notes ? (
                  <div
                    key={snIndex}
                    className='d-flex text-muted justify-content-between p-2 border mb-1'
                  >
                    {scanNote?.notes}
                    <span className='text-dark d-flex align-items-center'>
                      <Moment date={scanNote?.created} format={DD_MM_YYYY} />
                    </span>
                  </div>
                ) : (
                  <></>
                );
              })}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h6 className='text-black mb-2 f-w-600'>
              <FormattedMessage id='TITLE.ATTACHED.FILE' />
            </h6>
            {purchase_media &&
              purchase_media.map((purchaseMedia, index) => {
                return (
                  <div
                    key={index}
                    className='cursor-pointer d-flex justify-content-between p-2 border mb-1'
                    onClick={() =>
                      downloadAttachedFile(purchaseMedia?.filename)
                    }
                  >
                    {purchaseMedia?.filename}
                    <Download />
                  </div>
                );
              })}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DefaultPOView;
