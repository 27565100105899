import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { X, Check, XCircle, Plus } from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { FieldArray, Formik } from "formik";
import { AddVendorSchema } from "../helper/FormikSchema";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  InitLanguage,
  unauthorization
} from "../constants/utils";
import { ErrorModal } from "../components/common/modals/ErrorModal";
import { addVendorApi, cityListAllApi } from "../axios/services/services";
import { ButtonLoader } from "../helper/ButtonLoader";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorMessage from "../helper/ErrorMessage";

export function AddNewVendorModal({
  searchedVendorName,
  onCloseModal,
  open,
  handleCloseOfAddNewVendorModal
}) {
  const [isLoading, setLoading] = useState(false);
  const [selectedBillingCity, setSelectedBillingCity] = useState("");
  const [selectedShippingCity, setSelectedShippingCity] = useState("");
  const [isNewVendorAdded, setNewVendorAdded] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isAddContactPersonTable, setAddContactPersonTable] = useState(true);
  const [multipleContactDetails, setMultipleContactDetails] = useState({
    contact_name: searchedVendorName,
    email: "",
    phone_number: "",
    mobile_number: "",
    currency: "AED",
    billingAddress: "",
    billingCity: "",
    billingZipcode: "",
    shippingAddress: "",
    shippingCity: "",
    shippingZipcode: "",
    contact_persons: [
      {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        mobile: ""
      }
    ],
    remarks: ""
  });

  const handleAddContactPersonRef = useRef([]);
  const intl = useIntl();
  async function getCityList() {
    const cityListResponse = await cityListAllApi({});
    const { Error, data } = cityListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const cities = data.map((city) => {
      const { id, name } = city || {};
      city.value = id;
      city.label = capitalizeFirstLetter(name);
      return city;
    });
    setCityList(cities ? cities : []);
  }

  useEffect(() => {
    getCityList();
  }, []);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleOnClickOfCopyBillingAddress = (values, setFieldValue) => {
    const { billingAddress, billingCity, billingZipcode } = values || {};
    setFieldValue("shippingAddress", billingAddress);
    setFieldValue("shippingCity", billingCity);
    setFieldValue("shippingZipcode", billingZipcode);
    setSelectedShippingCity(selectedBillingCity);
  };

  const renderFirstNameValue = (values, index) => {
    const value = values.contact_persons[index].first_name;
    return value;
  };

  const renderLastNameValue = (values, index) => {
    const value = values.contact_persons[index].last_name;
    return value;
  };

  const renderContactEmailValue = (values, index) => {
    const value = values.contact_persons[index].email;
    return value;
  };

  const renderMobileValue = (values, index) => {
    const value = values.contact_persons[index].mobile;
    return value;
  };

  const renderPhoneValue = (values, index) => {
    const value = values.contact_persons[index].phone;
    return value;
  };

  const addNewVendor = async (values) => {
    const {
      contact_name,
      email,
      phone_number,
      mobile_number,
      currency,
      billingAddress,
      billingCity,
      billingZipcode,
      shippingAddress,
      shippingCity,
      shippingZipcode,
      remarks,
      contact_persons
    } = values || {};

    let addVendorObj = {
      contact_type: "vendor",
      email,
      phone_number,
      mobile_number,
      contact_name,
      currency,
      billingaddressId: billingAddress,
      billing_cityId: billingCity,
      billing_zipcode: billingZipcode,
      shippingaddressId: shippingAddress,
      shipping_cityId: shippingCity,
      shipping_zipcode: shippingZipcode,
      contact_person: contact_persons,
      remarks
    };
    enableLoading();
    const addVendorResponse = await addVendorApi(addVendorObj);
    const { data, Error } = addVendorResponse || {};
    if (Error) {
      disableLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setNewVendorAdded(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableLoading();
      handleCloseOfAddNewVendorModal(data);
    }, 2500);
  };

  return (
    <>
      <Formik
        initialValues={multipleContactDetails}
        validationSchema={AddVendorSchema}
        onSubmit={(values) => {
          addNewVendor(values);
        }}
        onReset={(values) => {}}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue,
          errors,
          touched
        }) => (
          <Form
            className='form form-label-right filter-card'
            autoComplete='off'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(multipleContactDetails);
              handleReset();
            }}
          >
            <Modal
              size='lg'
              show={open}
              onHide={onCloseModal}
              aria-labelledby='example-modal-sizes-title-lg'
              dialogClassName='addvendor-modal modal'
              scrollable={true}
            >
              <Modal.Header className='d-flex justify-content-between align-items-center'>
                <h3 className='modal-title' id='exampleModalLabel2'>
                  <FormattedMessage id='TITLE.ADD.NEW.VENDOR' />
                </h3>
                <Button
                  variant='link p-0 btn-icon'
                  onClick={() => onCloseModal()}
                >
                  <X size={20} className='text-dark mr-0 pr-0' />
                </Button>
              </Modal.Header>
              <Modal.Body className='pb-0'>
                {isNewVendorAdded && (
                  <div className='notification icon inline success fs-16'>
                    <Check size={8} className='text-white'></Check>
                    <FormattedMessage id='TITLE.NEW.VENDOR.HAS.BEEN.SUCCESSFULLY.CREATED' />
                  </div>
                )}

                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='vendorname'>
                        <FormattedMessage id='TITLE.VENDOR.DISPLAY.NAME' />
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        id='vendorname'
                        name='contact_name'
                        placeholder={intl.formatMessage({
                          id: "TITLE.VENDOR.DISPLAY.NAME"
                        })}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[0-9]/g, "");
                          setFieldValue(`contact_name`, value);
                        }}
                        value={values.contact_name}
                        autoComplete='off'
                      />
                      {touched.contact_name && errors.contact_name ? (
                        <div className='text-danger'>
                          {InitLanguage(errors.contact_name)}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='vendoremail'>
                        <FormattedMessage id='TITLE.VENDOR.EMAIL.ADDRESS' />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        type='email'
                        id='vendoremail'
                        name='email'
                        placeholder={intl.formatMessage({
                          id: "TITLE.VENDOR.EMAIL.ADDRESS"
                        })}
                        onChange={(e) => {
                          setFieldValue(`email`, e.target.value);
                        }}
                        value={values.email}
                        autoComplete='off'
                      />
                      {touched.email && errors.email ? (
                        <div className='text-danger'>{errors.email}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='vendormobile'>
                        <FormattedMessage id='TITLE.VENDOR.MOBILE.NUMBER' />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        type='number'
                        id='vendormobile'
                        name='mobile_number'
                        placeholder={intl.formatMessage({
                          id: "TITLE.VENDOR.MOBILE.NUMBER"
                        })}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={(e) => {
                          setFieldValue(`mobile_number`, e.target.value);
                        }}
                        onPaste={(evt) => {
                          const values = evt.clipboardData.getData("text");
                          const nonAlpha = values.replace(/[^\d.-]/g, "");
                          evt.preventDefault();
                          setFieldValue("mobile_number", nonAlpha);
                        }}
                        value={values.mobile_number}
                        autoComplete='off'
                      />
                      {errors.mobile_number ? (
                        <div className='text-danger'>
                          {InitLanguage(errors.mobile_number)}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type='submit'
                  variant='success'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    handleSubmit();
                  }}
                  disabled={isLoading}
                >
                  <FormattedMessage id='TITLE.SAVE' />
                  {isLoading && <ButtonLoader className='mr-1 ml-1' />}
                </Button>
                <Button
                  type='button'
                  variant='tertiary text-dark'
                  onClick={() => onCloseModal()}
                >
                  <FormattedMessage id='TITLE.CANCEL' />
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
