import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { EditUserSchema } from "../../../helper/FormikSchema";
import { UserStatus } from "../UserUIHelpers";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { editUserApi } from "../../../axios/services/services";
import {
  getErrorMessageBasedOnLanguage,
  unauthorization,
  InitLanguage,
  setActiveMenu
} from "../../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";

let EditUserInitialValues = {
  full_name: "",
  emp_id: "",
  email: "",
  cellnumber: "",
  role_id: "",
  status: ""
};

export function EditUserModal({
  onCloseModal,
  open,
  userDeta,
  roleList,
  handleCloseOfEditUserModal
}) {
  const intl = useIntl();
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isUserEdited, setUserEdited] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  useEffect(() => {
    const { full_name, emp_id, email, cellnumber, role, status } =
      userDeta || {};
    const { name, id } = role || {};
    role.label = name;
    role.value = id;
    let statusObj = {};
    if (status === 1) {
      statusObj.label = "Active";
      statusObj.value = 1;
    } else {
      statusObj.label = "Inactive";
      statusObj.value = 0;
    }

    formikEditUser.setFieldValue("full_name", full_name);
    formikEditUser.setFieldValue("emp_id", emp_id);
    formikEditUser.setFieldValue("email", email);
    formikEditUser.setFieldValue("cellnumber", cellnumber);
    formikEditUser.setFieldValue("role_id", role.id);
    formikEditUser.setFieldValue("status", status);
    setSelectedRole(role);
    setSelectedStatus(statusObj);
  }, [userDeta]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleRoleChange = (data) => {
    setSelectedRole(data);
    formikEditUser.setFieldValue("role_id", data.value);
  };

  const handleStatusChange = (data) => {
    setSelectedStatus(data);
    formikEditUser.setFieldValue("status", data.value);
  };

  const formikEditUser = useFormik({
    initialValues: EditUserInitialValues,
    validationSchema: EditUserSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const { full_name, emp_id, email, cellnumber, role_id, status } =
        values || {};
      let editUserObj = {
        id: userDeta.id,
        full_name,
        emp_id: "" + emp_id,
        email,
        cellnumber: "" + cellnumber,
        role_id,
        status: parseInt(status)
      };
      const editUserResponse = await editUserApi(editUserObj);
      const { data, Error } = editUserResponse || {};
      if (Error) {
        disableLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setUserEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableLoading();
        const path = "/users/user-listing";
        onClickChangeRoute(path);
        handleCloseOfEditUserModal(data);
      }, 2500);
    },
    onReset: (values, { resetForm }) => {}
  });

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Modal
        size='lg'
        show={open}
        onHide={onCloseModal}
        aria-labelledby='example-modal-sizes-title-lg'
        dialogClassName='addvendor-modal modal'
        scrollable={true}
      >
        <Modal.Header className='d-flex justify-content-between align-items-center'>
          <h3 className='modal-title' id='exampleModalLabel2'>
            <FormattedMessage id='TITLE.EDIT.USER' />
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          {isUserEdited && (
            <div className='notification icon inline success fs-16'>
              <Check size={8} className='text-white'></Check>{" "}
              <FormattedMessage id='TITLE.USER.EDITED.SUCCESSFULLY' />
            </div>
          )}
          <Form autoComplete='off'>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.USER.DISPLAY.NAME' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='full_name'
                    name='full_name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.USER.DISPLAY.NAME"
                    })}
                    {...formikEditUser.getFieldProps("full_name")}
                  />
                  {formikEditUser.touched.full_name &&
                  formikEditUser.errors.full_name ? (
                    <div className='text-danger'>
                      {InitLanguage(formikEditUser.errors.full_name)}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.EMPLOYEE.ID' />{" "}
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='emp_id'
                    name='emp_id'
                    placeholder={intl.formatMessage({
                      id: "TITLE.EMPLOYEE.ID"
                    })}
                    {...formikEditUser.getFieldProps("emp_id")}
                  />
                  {formikEditUser.touched.emp_id &&
                  formikEditUser.errors.emp_id ? (
                    <div className='text-danger'>
                      {formikEditUser.errors.emp_id}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.EMAIL.ADDRESS' />{" "}
                    <span className='text-danger'></span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='email'
                    name='email'
                    placeholder={intl.formatMessage({
                      id: "TITLE.EMAIL.ADDRESS"
                    })}
                    {...formikEditUser.getFieldProps("email")}
                  />
                  {formikEditUser.touched.email &&
                  formikEditUser.errors.email ? (
                    <div className='text-danger'>
                      {formikEditUser.errors.email}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.MOBILE.NUMBER' />
                    <span className='text-danger'></span>
                  </Form.Label>
                  <Form.Control
                    type='number'
                    id='cellnumber'
                    name='cellnumber'
                    placeholder={intl.formatMessage({
                      id: "TITLE.MOBILE.NUMBER"
                    })}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikEditUser.setFieldValue("cellnumber", nonAlpha);
                    }}
                    {...formikEditUser.getFieldProps("cellnumber")}
                  />
                  {formikEditUser.touched.cellnumber &&
                  formikEditUser.errors.cellnumber ? (
                    <div className='text-danger'>
                      {InitLanguage(formikEditUser.errors.cellnumber)}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.ROLE' />
                    <span className='text-danger'></span>
                  </Form.Label>
                  <Select
                    value={selectedRole}
                    onChange={(data) => {
                      handleRoleChange(data);
                    }}
                    isSearchable={true}
                    options={roleList}
                    placeholder={intl.formatMessage({
                      id: "TITLE.SELECT.ROLE"
                    })}
                    className='react-select-container'
                    classNamePrefix='react-select'
                  />
                  {formikEditUser.touched.role_id &&
                  formikEditUser.errors.role_id ? (
                    <div className='text-danger'>
                      {formikEditUser.errors.role_id}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='status'>
                    <FormattedMessage id='TITLE.STATUS' />{" "}
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Select
                    onChange={handleStatusChange}
                    isSearchable={false}
                    options={UserStatus}
                    placeholder={intl.formatMessage({ id: "TITLE.PASSWORD" })}
                    id='status'
                    className='react-select-container'
                    classNamePrefix='react-select'
                    value={selectedStatus}
                  />
                  {formikEditUser.touched.status &&
                  formikEditUser.errors.status ? (
                    <div className='text-danger'>
                      {formikEditUser.errors.status}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            variant='success'
            onClick={() => {
              window.scrollTo(0, 0);
              formikEditUser.handleSubmit();
            }}
            disabled={isLoading}
          >
            <FormattedMessage id='TITLE.SAVE' />
            {isLoading && <ButtonLoader className='mr-1 ml-1' />}
          </Button>
          <Button
            type='button'
            variant='tertiary text-dark'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id='TITLE.CANCEL' />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
