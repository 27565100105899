import React from "react";
import { Modal } from "react-bootstrap";
import { X } from "react-feather";

export function ErrorModal({ message, isErrorModalOpen, hideErrorModal }) {
  const showText = () => {
    try{
      let text = message;
      return text.split("\n").map((str,ukey) => <p key={ukey}>{str}</p>);
    }
    catch(e){
      return ""
    }
  }
  return (
    <>
      <Modal
        show={isErrorModalOpen}
        onHide={hideErrorModal}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header
          closeButton
          className='p-0 pr-2 border-bottom-0 order'
        ></Modal.Header>
        <Modal.Body className='p-4'>
          <div className='d-flex justify-content-center mb-3'>
            <div className='avatar-icon bg-danger w-44'>
              <X size={24} />
            </div>
          </div>

          <p className='text-center fs-16 text-dark confirmation-content mb-0'>
            {showText()}
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
