// date format
export const DDD_DD_MMM_YYYY = "ddd DD MMM YYYY";
export const HH_MM_SS_A = "HH:MM:SS A";
export const DDD_DD_MMM_YYYY_HH_MM_SS_A = "ddd DD MMM YYYY HH:MM:SS A";
export const MM_DD_YY = "MM/DD/YY";
export const DD_MM_YYYY = "DD/MM/YYYY";
export const D_M_YYYY = "D/M/YYYY";
export const YYYY_MM_DD = "YYYY-MM-DD";
export const YYYY_MM_DD_SLASH = "YYYY/MM/DD";
export const dd_MM_yyyy = "dd/MM/yyyy";
export const DD_MM_YY = "DD/MM/YY";
export const YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:MM:ss";
export const DD_MM_YYYY_HH_MM_A = "DD/MM/YYYY hh:mm A";
export const FILE_EXTENTION =
  "image/jpeg,image/jpg,image/png,application/pdf,.doc,.docx,.xlsx,.xls";
export const FILE_EXTENTION_IMAGE = "image/jpeg,image/jpg,image/png";
export const STR_SUCCESS = "Success";
export const STR_PDF = "pdf";
export const STR_EXCEL = "xlsx";
export const STR_CSV = "csv";
export const SET_TIMEOUT = 2500;
export const MB_20 = 20971520;
export const MB_5 = 5242880;
export const I18N_CONFIG_KEY =
  process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";
export const USER_DETAILS = "userDetails";
export const AUTH_TOKEN = "auth_token";
export const i18nConfig = "i18nConfig";
export const VERSION = "1.0.0";
export const STRING_CONSTANTS = {
  CHECK_IN: "Checked In",
  CHECK_OUT: "Checked Out"
};
