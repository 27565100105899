// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../StateUIHelpers";
import { useStateUIContext } from "../StateUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import StatusActiveDeactiveTypeFormatter from "../../../columnformatter/StatusActiveDeactiveTypeFormatter";

export function StateTable({
  isLoading,
  stateList,
  stateListTotalCount,
  currentPage,
  setCurrentPage
}) {
  // state UI Context
  const stateUIContext = useStateUIContext();
  const stateUIProps = useMemo(() => {
    return {
      ids: stateUIContext.ids,
      setIds: stateUIContext.setIds,
      queryParams: stateUIContext.queryParams,
      setQueryParams: stateUIContext.setQueryParams,
      openViewDialog: stateUIContext.openViewDialog,
      openEditDialog: stateUIContext.openEditDialog,
      openDeleteDialog: stateUIContext.openDeleteDialog
    };
  }, [stateUIContext]);

  // Table columns
  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style: {
    //     minWidth: "150px",
    //   },
    // },
    {
      dataField: "name",
      text: "State Name",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "170px"
      }
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "170px"
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px"
      },
      formatter: StatusActiveDeactiveTypeFormatter
    },
    {
      dataField: "action",
      text: "ACTIONS",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: stateUIProps.openViewDialog,
        openEditDialog: stateUIProps.openEditDialog,
        openDeleteDialog: stateUIProps.openDeleteDialog,
        isVisibleViewButton: false
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px"
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: stateListTotalCount ? stateListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: stateUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-head-custom table-head-bg table-borderless table-vertical-center state-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={stateList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  stateUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage entities={stateList} />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
