// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../OutwardCustomerUIHelpers";
import { useOutwardCustomerUIContext } from "../OutwardCustomerUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../../helper/helper";
import { Pagination } from "../../../../pagination/Pagination";
import AmountColumnFormatter from "../../../../columnformatter/AmountColumnFormatter";
import ActionsViewColumnFormatter from "../../../../columnformatter/ActionsViewColumnFormatter";
import { useIntl } from "react-intl";

export function OutwardCustomerTable({
  isLoading,
  reportOutwardByCustomer,
  filterValues,
  reportOutwardByCustomerTotalCount
}) {
  // outwardcustomer UI Contex
  const intl = useIntl();

  const outwardcustomerUIContext = useOutwardCustomerUIContext();
  const outwardcustomerUIProps = useMemo(() => {
    return {
      ids: outwardcustomerUIContext.ids,
      setIds: outwardcustomerUIContext.setIds,
      queryParams: outwardcustomerUIContext.queryParams,
      setQueryParams: outwardcustomerUIContext.setQueryParams,
      openViewDialog: outwardcustomerUIContext.openViewDialog,
      openEditDialog: outwardcustomerUIContext.openEditDialog,
      openDeleteDialog: outwardcustomerUIContext.openDeleteDialog
    };
  }, [outwardcustomerUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "contact_name",
      text: intl.formatMessage({
        id: "TITLE.EMPLOYEE.NAME"
      }),
      sort: false,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px"
      }
    },
    {
      dataField: "phone_number",
      text: intl.formatMessage({
        id: "TITLE.PHONE.NUMBER"
      }),
      classes: "ltr-direction",
      sort: false,
      sortCaret: sortCaret,
      style: {
        minWidth: "130px"
      }
    },
    {
      dataField: "count",
      text: intl.formatMessage({
        id: "TITLE.NO.OF.ORDERS"
      }),
      sort: false,
      sortCaret: sortCaret,
      style: {
        minWidth: "130px"
      },
      formatter: ActionsViewColumnFormatter,
      formatExtraData: {
        openViewDialog: outwardcustomerUIProps.openViewDialog
      }
    },
    {
      dataField: "amount",
      text: intl.formatMessage({
        id: "TITLE.AMOUNT"
      }),
      sort: false,
      sortCaret: sortCaret,
      style: {
        minWidth: "150px"
      },
      formatter: AmountColumnFormatter
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: reportOutwardByCustomerTotalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: outwardcustomerUIProps.queryParams.pageSize,
    page: outwardcustomerUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-bordered table-vertical-center inward-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={reportOutwardByCustomer}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  outwardcustomerUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                isLoading={isLoading}
                entities={reportOutwardByCustomer}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
