import React, { useContext, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { X, Check } from "react-feather";
import { useFormik } from "formik";
import { AddBrandSchema } from "../../../helper/FormikSchema";
import { addBrandApi } from "../../../axios/services/services";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getErrorMessageBasedOnLanguage,
  setActiveMenu
} from "../../../constants/utils";
import { unauthorization } from "../../../constants/utils";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";
import { SET_TIMEOUT } from "../../../constants/const";

export function AddNewBrandModal({
  searchedBrandName,
  onCloseModal,
  open,
  handleCloseOfAddNewBrandModal
}) {
  let AddBrandInitialValues = {
    name: searchedBrandName,
    description: ""
  };

  const intl = useIntl();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isNewbrandAdded, setNewBrandAdded] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const uiContext = useContext(UIContext);

  const formikAddBrand = useFormik({
    // enableReinitialize: true,
    initialValues: AddBrandInitialValues,
    validationSchema: AddBrandSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const { name, description } = values || {};
      let addBrandObj = {
        name,
        description
      };
      const addBrandResponse = await addBrandApi(addBrandObj);
      const { data, Error } = addBrandResponse || {};
      if (Error) {
        disableLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setNewBrandAdded(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableLoading();
        handleCloseOfAddNewBrandModal(data);
      }, SET_TIMEOUT);
    },
    onReset: (values, { resetForm }) => {}
  });

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Modal
        size='md'
        show={open}
        onHide={onCloseModal}
        dialogClassName='inventory-small-modal'
        animation={false}
      >
        <Modal.Header className='d-flex justify-content-between align-items-center'>
          <h3 className='modal-title' id='exampleModalLabel2'>
            <FormattedMessage id='TITLE.ADD.NEW.BRAND' />
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {isNewbrandAdded && (
            <div className='notification icon inline success fs-16'>
              <Check size={8} className='text-white'></Check>{" "}
              <FormattedMessage id='TITLE.NEW.BRAND.HAS.BEEN.SUCCESSFULLY.CREATED' />
            </div>
          )}
          <form
            autoComplete='off'
            onSubmit={(e) => {
              e.preventDefault();
              window.scrollTo(0, 0);
              formikAddBrand.handleSubmit();
            }}
            onReset={() => {
              formikAddBrand.handleReset();
            }}
          >
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='brandname'>
                    <FormattedMessage id='TITLE.BRAND.NAME' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='brandname'
                    name='name'
                    required
                    placeholder={intl.formatMessage({
                      id: "TITLE.BRAND.NAME"
                    })}
                    autoFocus
                    {...formikAddBrand.getFieldProps("name")}
                  />
                  {formikAddBrand.touched.name &&
                  formikAddBrand.errors.name ? (
                    <div className='text-danger'>
                      {formikAddBrand.errors.name}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className='mb-0'>
                  <Form.Label htmlFor='Status'>
                    <FormattedMessage id='TITLE.DESCRIPTION' />
                    <span className='mr-1 ml-1 text-muted'>
                      (
                      <FormattedMessage id='TITLE.MAX.512.CHARACTERS' />)
                    </span>
                  </Form.Label>
                  <Form.Control
                    id='description'
                    name='description'
                    as='textarea'
                    placeholder={intl.formatMessage({
                      id: "TITLE.DESCRIPTION"
                    })}
                    className='form-control'
                    resize='none'
                    maxLength={512}
                    {...formikAddBrand.getFieldProps("description")}
                  />
                  {formikAddBrand.touched.description &&
                  formikAddBrand.errors.description ? (
                    <div className='text-danger'>
                      {formikAddBrand.errors.description}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            variant='success'
            onClick={() => {
              window.scrollTo(0, 0);
              formikAddBrand.handleSubmit();
            }}
            disabled={isLoading}
          >
            <FormattedMessage id='TITLE.SAVE' />
            {isLoading && <ButtonLoader className='mr-1 ml-1' />}
          </Button>
          <Button
            type='reset'
            variant='tertiary text-dark'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id='TITLE.CANCEL' />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
