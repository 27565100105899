import React, { useMemo } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { Filter, Plus, Upload } from "react-feather";
import { InwardFilter } from "./inward-filter/InwardFilter";
import { InwardTable } from "./inward-table/InwardTable";
import { useInwardUIContext } from "./InwardUIContext";
import { exportInwardListingApi } from "../../axios/services/services";
import { FormattedMessage } from "react-intl";

export function InwardCard({
  isLoading,
  isLoadingFilter,
  isFilterOpen,
  POList,
  POStatusList,
  POListTotalCount,
  currentPage,
  onClickOfCreateNew,
  handleClickOfRemoveFilterButton,
  handleCLickOfFilterButtonFilter
}) {
  const inwardUIContext = useInwardUIContext();
  const inwardUIProps = useMemo(() => {
    return {
      ids: inwardUIContext.ids,
      queryParams: inwardUIContext.queryParams,
      setQueryParams: inwardUIContext.setQueryParams,
      openViewDialog: inwardUIContext.openViewDialog,
      openEditDialog: inwardUIContext.openEditDialog,
      openEditStatusDialog: inwardUIContext.openEditStatusDialog,
      openDeleteDialog: inwardUIContext.openDeleteDialog,
      redirectToViewPage: inwardUIContext.redirectToViewPage
    };
  }, [inwardUIContext]);

  const exportOutwardListing = async () => {
    await exportInwardListingApi({ filename: "Inwards" });
  };

  return (
    <>
      <Card className='card-custom card-stretch make-payment-wrapper'>
        <Card.Header className='align-items-center d-flex justify-content-between'>
          <h3 className='card-title page-title text-black mb-0'>
            <FormattedMessage id='TITLE.ALL.PURCHASE.ORDERS' />
          </h3>
          <div className='d-flex'>
            <ButtonGroup className='mr-2'>
              <Button
                variant='tertiary d-flex align-items-center btn-inventory'
                onClick={() => {
                  handleClickOfRemoveFilterButton();
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className='d-none d-md-flex'>
                  {isFilterOpen ? (
                    <FormattedMessage id='TITLE.REMOVE' />
                  ) : (
                    <FormattedMessage id='TITLE.FILTER' />
                  )}
                </span>
              </Button>
              <div
                className='btn btn-tertiary d-flex align-items-center btn-inventory'
                onClick={() => exportOutwardListing()}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className='d-none d-md-flex'>
                  <FormattedMessage id='TITLE.EXPORT' />
                </span>
              </div>
            </ButtonGroup>
            <Button
              variant='primary d-flex align-items-center btn-inventory'
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className='d-none d-md-flex'>
                <FormattedMessage id='TITLE.CREATE.NEW' />
              </span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && (
            <InwardFilter
              isLoadingFilter={isLoadingFilter}
              POStatusList={POStatusList}
              handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            />
          )}
          <InwardTable
            isLoading={isLoading}
            POList={POList}
            POListTotalCount={POListTotalCount}
            currentPage={currentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
