import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { X, Check } from "react-feather";
import CreatableSelect from "react-select/creatable";
import { useFormik } from "formik";
// import { AddProductSchema } from "../helper/FormikSchema";
import { ButtonLoader } from "../helper/ButtonLoader";
import { addCustomFieldApi, categoryListApi } from "../axios/services/services";
import {
  USER_DETAILS
} from "../constants/const";
import { FormattedMessage, useIntl } from "react-intl";
import {
  capitalizeFirstLetter,
  customFieldTypeList,
  getErrorMessageBasedOnLanguage
} from "../constants/utils";
import { unauthorization } from "../constants/utils";
import { AddNewUnitModal } from "./AddNewUnitModal";
import Select from "react-select"
import { AddEditCustomFieldSchema } from "../helper/FormikSchema";

export function AddNewCustomFieldModal({
  searchedProductName,
  categoryId,
  onCloseModal,
  open,
  handleCloseOfAddNewProductModal
}) {
  
  let AddCustomFieldsInitialValues = {
    field_name: '',
    field_name_in_arabic: '',
    field_type: '',
    show_in_filter: '',
    field_value: [],
    categoryId: [],
  };

  const intl = useIntl();

  const [isLoading, setLoading] = useState(false);
  const [isNewCustomFieldAdded, setNewCustomFieldAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const createOption = (label) => ({
    label,
    value: label,
  });

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);

  async function getCategoryList() {
    const status = 1;
    const categoryListResponse = await categoryListApi({ status });
    const { data, Error } = categoryListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const categories = data.map((category, index) => {
      return {
        label: category.name,
        value: category.id
      };
    });
    setCategoryList(categories);
  }


  useEffect(() => {
    getCategoryList();
  }, []);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const formikAddCustomFields = useFormik({
    initialValues: AddCustomFieldsInitialValues,
    validationSchema: AddEditCustomFieldSchema,
    onSubmit: async (values, { setStatus }) => {
      try{
        setLoading(true);
        let addCustomFieldResponse = await addCustomFieldApi(values);
        let { data, Error } = addCustomFieldResponse || {};
        if (Error) {
          setLoading(false);
          unauthorization(Error);
          setErrorMessage(getErrorMessageBasedOnLanguage(Error));
          handleErrorModalShow();
          return;
        }
        setNewCustomFieldAdded(true);
        window.scrollTo(0, 0);
        setTimeout(()=>{
          onCloseModal("VaryingMdo")
          handleCloseOfAddNewProductModal(data);
          setLoading(false);
        },500)
      }
      catch(err){
        console.log("error",err)
        setTimeout(()=>{
          setLoading(false);
        },500)
      }
    }
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        formikAddCustomFields.setFieldValue('field_value',[...value,createOption(inputValue)]) 
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  const components = {
    DropdownIndicator: null,
  };

  return (
    <>
      <Modal
        size='lg'
        show={open}
        onHide={onCloseModal}
        aria-labelledby='example-modal-sizes-title-lg'
        dialogClassName='addvendor-modal modal'
        scrollable={true}
      >
        <Modal.Header className='d-flex justify-content-between align-items-center'>
          <h3 className='modal-title' id='exampleModalLabel2'>
            <FormattedMessage id='TITLE.CUSTOMFIELDS.ADD.NEW.CUSTOM.FIELD' />
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          {isNewCustomFieldAdded && (
            <div className='notification icon inline success fs-16'>
              <Check size={8} className='text-white'></Check>
              <FormattedMessage id='TITLE.NEW.CUSTOMFIELDS.HAS.BEEN.SUCCESSFULLY.CREATED'/>
            </div>
          )}
          <Form autoComplete='off'>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='productName'>
                    <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.FIELD.NAME.IN.ENGLISH' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='name'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.CUSTOMFIELDS.ADDFORM.FIELD.NAME.IN.ENGLISH"
                    })}
                    maxLength='70'
                    value={formikAddCustomFields.values.field_name}
                    onChange={(e)=>{formikAddCustomFields.setFieldValue('field_name',e.target.value.trimStart())}}
                  />
                  {formikAddCustomFields.touched.field_name &&
                  formikAddCustomFields.errors.field_name ? (
                    <div className='text-danger p-2'>
                      {formikAddCustomFields.errors.field_name}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='productName'>
                    <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.FIELD.NAME.IN.ARABIC' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='name'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.CUSTOMFIELDS.ADDFORM.FIELD.NAME.IN.ARABIC"
                    })}
                    maxLength='70'
                    value={formikAddCustomFields.values.field_name_in_arabic}
                    onChange={(e)=>{formikAddCustomFields.setFieldValue('field_name_in_arabic',e.target.value.trimStart())}}
                  />
                  {formikAddCustomFields.touched.field_name_in_arabic &&
                  formikAddCustomFields.errors.field_name_in_arabic ? (
                    <div className='text-danger p-2'>
                      {formikAddCustomFields.errors.field_name_in_arabic}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='category'>
                    <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.FIELD.TYPE' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Select
                    options={customFieldTypeList}
                    onChange={(e)=>{
                      if(e.value !== "select"){
                        setValue([])
                        formikAddCustomFields.setFieldValue('field_value',[])
                      }
                      formikAddCustomFields.setFieldValue('field_type',e.value)
                    }}
                    value={customFieldTypeList.find((x)=>x.value === formikAddCustomFields?.values?.field_type) ?? []}
                  /> 
                  {/* < */}
                  {formikAddCustomFields.touched.field_type &&
                  formikAddCustomFields.errors.field_type ? (
                    <div className='text-danger p-2'>
                      {formikAddCustomFields.errors.field_type}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              {formikAddCustomFields?.values?.field_type === "select" ? 
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='category'>
                    <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.FIELD.VALUE'/>
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(newValue) => {
                      setValue(newValue)
                      formikAddCustomFields.setFieldValue('field_value',newValue)
                    }}
                    onInputChange={(newValue) => {
                      setInputValue(newValue)
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter field value"
                    value={value}
                  />
                  {/* < */}
                  {formikAddCustomFields.touched.field_value &&
                  formikAddCustomFields.errors.field_value ? (
                    <div className='text-danger p-2'>
                      {formikAddCustomFields.errors.field_value}
                    </div>
                  ) : null}
                </Form.Group>
              </Col> : "" }
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='category'>
                    <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.SHOW.FIELD.ON.CATEGORY'/>
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Select
                    value={formikAddCustomFields.values.categoryId}
                    isMulti
                    name="Category"
                    options={categoryList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e)=>{
                      formikAddCustomFields.setFieldValue('categoryId', e)
                    }}
                  />
                  {formikAddCustomFields.touched.categoryId &&
                  formikAddCustomFields.errors.categoryId ? (
                    <div className='text-danger p-2'>
                      {formikAddCustomFields.errors.categoryId}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Check // prettier-ignore
                    type={'checkbox'}
                    id={`show_in_filter`}
                    label={`Show In Filter`}
                    onChange={(e)=>{
                      formikAddCustomFields.setFieldValue('show_in_filter', e.target.checked)
                    }}
                    checked={formikAddCustomFields.values.show_in_filter}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            variant='success'
            onClick={() => {
              formikAddCustomFields.handleSubmit();
            }}
            disabled={isLoading}
          >
            <FormattedMessage id='TITLE.SAVE' />
            {isLoading && <ButtonLoader className='mr-1 ml-1' />}
          </Button>
          <Button
            type='button'
            variant='tertiary text-dark'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id='TITLE.CANCEL' />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
