/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

const ActionsViewColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openViewDialog }
) => (
  <>
    <div
      className='text-primary cursor-pointer btn btn-link'
      onClick={() => openViewDialog(row)}
    >
      {row.count}
    </div>
    <> </>
  </>
);

export default ActionsViewColumnFormatter;
