import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { Edit2, Plus, X } from "react-feather";
import { DeleteModal } from "../../common/modals/DeleteModal";
import { AddNewCategoryModal } from "../add-new-category/AddNewCategoryModal";
import ProductView from "./ProductView";
import {
  categoryListMastersApi,
  getSingleCategoryDetailsApi,
  deleteCategoryApi,
  editCategoryApi
} from "../../../axios/services/services";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { toAbsoluteUrl } from "../../../helper/helper";
import SVG from "react-inlinesvg";
import { SectionLoader } from "../../../helper/SectionLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization
} from "../../../constants/utils";
import { EditCategoryModal } from "../edit-new-category/EditCategoryModal";
import { SuccessModal } from "../../common/modals/SuccessModal";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { AddNewProductModal } from "../../../global-modal/AddNewProductModal ";
import { FormattedMessage, useIntl } from "react-intl";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";
import { useFormik } from "formik";
import { DeleteCategorySchema } from "../../../helper/FormikSchema";
import { DeleteCategoryModal } from "../../common/modals/DeleteCategoryModal";

const initialQueryParams = {
  pageNumber: 1,
  pageSize: 20
};

const DeleteCategoryInitialValues = {
  categoryId: ""
};

const ViewCategoryDetails = ({ history, handlePDFViewValues, ...props }) => {
  const intl = useIntl();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpenAddNewCategoryModal, setOpenAddNewCategoryModal] =
    useState(false);
  const { id } = props.match.params;
  const [isLoading, setLoading] = useState(false);
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [isLoadMoreCategoryListView, setLoadMoreCategoryListView] =
    useState(false);
  const [isLoadingCategoryListView, setLoadingCategoryListView] =
    useState(false);
  const [isLoadingProductListView, setLoadingProductListView] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState({});
  const [isUpdateSingleCategoryDetails, setUpdateSingleCategoryDetails] =
    useState(0);
  const [isOpenEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const [isLoadingDeleteCategory, setLoadingDeleteCategory] = useState(false);
  const [isLoadingEditCategory, setLoadingEditCategory] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isUpdateCategoryDetails, setUpdateCategoryDetails] = useState(0);
  const [isOpenAddNewProductModal, setOpenAddNewProductModal] = useState(false);
  const [isLoadMoreProductListView, setLoadMoreProductListView] =
    useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const uiContext = useContext(UIContext);

  useEffect(() => {
    getCategoryList();
  }, [queryParams, isUpdateCategoryDetails]);

  async function getCategoryList() {
    const { pageNumber } = queryParams || {};
    if (pageNumber === 1) {
      enableLoadingCategoryList();
    }

    const CategoryListResponse = await categoryListMastersApi({
      queryParams
    });
    if (pageNumber === 1) {
      disableLoadingCategoryList();
    }

    const { Error, data } = CategoryListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setCategoryList((categoryList) => [...categoryList, ...data]);
    if (data.length >= 20) {
      setLoadMoreCategoryListView(true);
      setLoadMoreProductListView(true);
    } else {
      setLoadMoreCategoryListView(false);
      setLoadMoreProductListView(false);
    }
  }

  useEffect(() => {
    getSingleCategoryDetails();
  }, [id, isUpdateSingleCategoryDetails]);

  const handleSaveOfStatus = async () => {
    enableLoadingEditCategory();
    let status = categoryDetails.status === 0 ? 1 : 0;
    const editStatus = await editCategoryApi({
      id,
      status
    });
    const { Error } = editStatus || {};
    if (Error) {
      disableLoadingEditCategory();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setSuccessMessage(
      ` ${
        status === 0
          ? intl.formatMessage({
              id: "TITLE.CATEGORY.IS.INACTIVE.NOW.AND.ALL.THE.PRODUCT.ASSOCIATED.WITH.THIS.CATEGORY.WILL.BE.MARKED.AS.INACTIVE"
            })
          : intl.formatMessage({ id: "TITLE.STATUS.MARKED.AS.ACTIVE" })
      }`
    );
    onOpenSuccessModal();
    setUpdateSingleCategoryDetails(Math.random());
    disableLoadingEditCategory();
  };

  async function getSingleCategoryDetails() {
    setLoading(true);
    setLoadingProductListView(true);

    const singleCategoryDetails = await getSingleCategoryDetailsApi({
      id
    });
    setLoadingProductListView(false);
    setLoading(false);
    const { Error, data } = singleCategoryDetails || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setCategoryDetails(data ? data : []);
  }

  const deleteCategory = async (values) => {
    const { categoryId } = values || {};
    enableLoadingDeleteCategory();
    const deleteCategoryResponse = await deleteCategoryApi({ id, categoryId });
    const { Error } = deleteCategoryResponse || {};
    if (Error) {
      disableLoadingDeleteCategory();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.CATEGORY.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    setTimeout(() => {
      disableLoadingDeleteCategory();
      onClickChangeRoute("/master/categories/category-listing");
    }, 1000);
    window.scrollTo(0, 0);
    setUpdateCategoryDetails(Math.random());
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const enableLoadingCategoryList = () => {
    setLoadingCategoryListView(true);
  };

  const disableLoadingCategoryList = () => {
    setLoadingCategoryListView(false);
  };

  const enableLoadingDeleteCategory = () => {
    setLoadingDeleteCategory(true);
  };

  const disableLoadingDeleteCategory = () => {
    setLoadingDeleteCategory(false);
  };

  const enableLoadingEditCategory = () => {
    setLoadingEditCategory(true);
  };

  const disableLoadingEditCategory = () => {
    setLoadingEditCategory(false);
  };

  const editCategoryModalShow = () => {
    setOpenEditCategoryModal(true);
  };

  const editCategoryModalHide = () => {
    setOpenEditCategoryModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const addNewCategoryModalShow = () => {
    setOpenAddNewCategoryModal(true);
  };

  const addNewCategoryModalHide = () => {
    setOpenAddNewCategoryModal(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handlePaginationOfcategoryListView = () => {
    const { pageNumber, pageSize } = queryParams || {};
    setQueryParams({ pageNumber: pageNumber + 1, pageSize });
  };

  const handlePaginationOfProductListView = () => {
    const { pageNumber, pageSize } = queryParams || {};
    setQueryParams({ pageNumber: pageNumber + 1, pageSize });
  };

  const addNewProductModalShow = () => {
    setOpenAddNewProductModal(true);
  };

  const addNewProductModalHide = () => {
    setOpenAddNewProductModal(false);
  };

  const CreateNewPO = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.CREATE.NEW.CATEGORY' />)
    </Tooltip>
  );

  const editTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.EDIT.CATEGORY' />
    </Tooltip>
  );

  const renderCategoryName = (category) => {
    const { name } = category || {};
    return <>{capitalizeFirstLetter(name === null ? "-" : name)}</>;
  };

  const getSecondaryColorClass = (category) => {
    var className = "fs-14 text-primary font-weight-bold mb-0 cursor-pointer";

    const { id: PID } = category;
    if (PID === id) {
      className = className + " text-secondary";
    }
    return className;
  };

  const handleCloseOfEditNewCategoryModal = () => {
    setQueryParams(initialQueryParams);
    setCategoryList([]);
    setUpdateCategoryDetails(Math.random());
    setUpdateSingleCategoryDetails(Math.random());
    editCategoryModalHide();
  };

  const handleCloseOfAddNewProductModal = () => {
    setUpdateSingleCategoryDetails(Math.random());
    setCategoryList([]);
    setQueryParams(initialQueryParams);
    setUpdateCategoryDetails(Math.random());
    addNewProductModalHide();
  };

  const handleCloseOfAddNewCategoryModal = () => {
    setCategoryList([]);
    setQueryParams(initialQueryParams);
    setUpdateCategoryDetails(Math.random());
    addNewCategoryModalHide();
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const formikDeleteCategory = useFormik({
    initialValues: DeleteCategoryInitialValues,
    validationSchema: DeleteCategorySchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      deleteCategory(values);
    },
    onReset: (values, { resetForm }) => {}
  });

  const onClickOfDeleteButton = () => {
    deleteCategory({});
  };

  const addNewCategoryModalFromDelete = () => {
    setCategoryList([]);
    setQueryParams(initialQueryParams);
    setUpdateCategoryDetails(Math.random());
  };

  return (
    <>
      <Card className='p-0 mb-0'>
        <div className='details-view d-block d-md-flex'>
          {/* Listing */}
          <div className='column list-column border-right d-none d-lg-block'>
            <div className='column-header d-flex align-items-center justify-content-between'>
              <h2 className='modal-title'>
                <FormattedMessage id='TITLE.ALL.CATEGORY' />
              </h2>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={CreateNewPO}
              >
                <Button
                  variant='primary btn-inventory btn-icon'
                  size='sm'
                  onClick={() => {
                    addNewCategoryModalShow();
                  }}
                >
                  <Plus size={14} className='text-secondary'></Plus>{" "}
                  <FormattedMessage id='TITLE.NEW' />
                </Button>
              </OverlayTrigger>
            </div>
            {isLoadingCategoryListView && <SectionLoader />}
            <div id='scrollableDiv' className='column-body custom-scrollbar'>
              {!isLoadingCategoryListView && (
                <InfiniteScroll
                  scrollableTarget='scrollableDiv'
                  dataLength={categoryList.length}
                  next={() => {
                    handlePaginationOfcategoryListView();
                  }}
                  hasMore={isLoadMoreCategoryListView}
                  loader={
                    <h4 style={{ textAlign: "center" }} className='p-1'>
                      <FormattedMessage id='TITLE.LOADING' />
                      ...
                    </h4>
                  }
                >
                  {categoryList.map((category, index) => (
                    <div
                      className='column-box cursor-pointer'
                      key={index}
                      onClick={() => {
                        onClickChangeRoute(
                          `/master/categories/view-category-details/${category.id}`
                        );
                      }}
                    >
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-column'>
                          <h5 className={getSecondaryColorClass(category)}>
                            {renderCategoryName(category)}
                            {category.total_products && (
                              <small className='mr-1 ml-1'>
                                {`(${
                                  category.total_products
                                } ${intl.formatMessage({
                                  id: "TITLE.PRODUCTS"
                                })} )`}
                              </small>
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>{" "}
          </div>
          {/* Details */}

          <div className='column details-column'>
            <div className='column-header d-flex align-items-center justify-content-between'>
              <h2 className='modal-title'> </h2>
              <div className='d-flex'>
                <ButtonGroup className='mr-2' aria-label='First group'>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={editTooltip}
                  >
                    <Button
                      variant='outline-tertiary btn-sm btn-icon'
                      onClick={() => editCategoryModalShow()}
                    >
                      <Edit2 size={16} className='p-0 m-0'></Edit2>
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
                <Button
                  variant='secondary btn-icon btn-sm mr-2'
                  onClick={() => {
                    addNewProductModalShow();
                  }}
                >
                  <FormattedMessage id='TITLE.NEW.PRODUCT' />
                </Button>
                <Dropdown>
                  <Dropdown.Toggle variant='dropdown-sm' id='dropdown-basic'>
                    <FormattedMessage id='TITLE.MORE' />
                    {isLoadingEditCategory && (
                      <ButtonLoader className='mr-1 ml-1' />
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        handleDeleteModalShow();
                      }}
                    >
                      <FormattedMessage id='TITLE.DELETE' />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleSaveOfStatus();
                      }}
                      disabled={isLoadingEditCategory}
                    >
                      {isLoadingEditCategory && (
                        <ButtonLoader className='mr-1 ml-1' />
                      )}
                      {categoryDetails.status === 1
                        ? intl.formatMessage({
                            id: "TITLE.MARK.AS.INACTIVE"
                          })
                        : intl.formatMessage({
                            id: "TITLE.MARK.AS.ACTIVE"
                          })}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  variant='close btn-icon btn-sm'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    onClickChangeRoute("/master/categories/category-listing");
                  }}
                >
                  <X size={20} className='p-0 m-0 text-dark'></X>
                </Button>
              </div>
            </div>
            {isLoading && <SectionLoader />}
            <div className='column-body custom-scrollbar'>
              <ProductView
                categoryDetails={categoryDetails}
                isLoadingCategoryListView={isLoadingProductListView}
                handlePaginationOfProductListView={
                  handlePaginationOfProductListView
                }
                isLoadMoreProductListView={isLoadMoreProductListView}
              />
            </div>
          </div>

          {/*  */}
        </div>
      </Card>

      {isDeleteModalOpen && categoryDetails?.total_products && (
        <DeleteCategoryModal
          isLoading={isLoadingDeleteCategory}
          title={`${intl.formatMessage({
            id: "TITLE.DELETE.CATEGORY"
          })}`}
          description={intl.formatMessage({
            id: "TITLE.CATEGORY.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
          })}
          reassignDescription={intl.formatMessage({
            id: "TITLE.ALL.THE.PRODUCTS.BELONGING.TO.THIS.CATEGORY.WILL.BE.ASSIGNED.TO.THE.SELECTED.CATEGORY.BELOW"
          })}
          isOpen={isDeleteModalOpen}
          onCloseModal={handleDeleteModalHide}
          id={capitalizeFirstLetter(categoryDetails?.name)}
          categoryDetails={categoryDetails}
          categoryList={categoryList}
          formikDeleteCategory={formikDeleteCategory}
          addNewCategoryModalFromDelete={addNewCategoryModalFromDelete}
        />
      )}

      {isDeleteModalOpen && !categoryDetails?.total_products && (
        <DeleteModal
          isLoading={isLoadingDeleteCategory}
          title={`${intl.formatMessage({
            id: "TITLE.DELETE.CATEGORY"
          })}`}
          description={intl.formatMessage({
            id: "TITLE.CATEGORY.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
          })}
          isOpen={isDeleteModalOpen}
          onCloseModal={handleDeleteModalHide}
          id={capitalizeFirstLetter(categoryDetails?.name)}
          onClickOfDeleteButton={onClickOfDeleteButton}
        />
      )}

      {isOpenAddNewCategoryModal && (
        <AddNewCategoryModal
          open={isOpenAddNewCategoryModal}
          onCloseModal={addNewCategoryModalHide}
          handleCloseOfAddNewCategoryModal={handleCloseOfAddNewCategoryModal}
        />
      )}

      {isOpenAddNewProductModal && (
        <AddNewProductModal
          categoryId={id}
          open={isOpenAddNewProductModal}
          onCloseModal={addNewProductModalHide}
          handleCloseOfAddNewProductModal={handleCloseOfAddNewProductModal}
        />
      )}
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className='danger-icon-wrapper text-center'>
            <span className='svg-icon menu-icon svg-icon-white svg-icon-4x mr-0'>
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      {isOpenEditCategoryModal && (
        <EditCategoryModal
          open={isOpenEditCategoryModal}
          data={categoryDetails}
          onCloseModal={editCategoryModalHide}
          id={id}
          handleCloseOfEditNewCategoryModal={handleCloseOfEditNewCategoryModal}
        />
      )}
      {openSuccessModal && (
        <SuccessModal
          message={successMessage}
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
        />
      )}
    </>
  );
};

export default ViewCategoryDetails;
