// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../CityUIHelpers";
import { useCityUIContext } from "../CityUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import StatusActiveDeactiveTypeFormatter from "../../../columnformatter/StatusActiveDeactiveTypeFormatter";

export function CityTable({
  isLoading,
  cityList,
  cityListTotalCount,
  currentPage,
  setCurrentPage
}) {
  // City UI Context
  const cityUIContext = useCityUIContext();
  const cityUIProps = useMemo(() => {
    return {
      ids: cityUIContext.ids,
      setIds: cityUIContext.setIds,
      queryParams: cityUIContext.queryParams,
      setQueryParams: cityUIContext.setQueryParams,
      openViewDialog: cityUIContext.openViewDialog,
      openEditDialog: cityUIContext.openEditDialog,
      openDeleteDialog: cityUIContext.openDeleteDialog
    };
  }, [cityUIContext]);

  // Table columns
  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style: {
    //     minWidth: "150px",
    //   },
    // },
    {
      dataField: "name",
      text: "City Name",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "170px"
      }
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "170px"
      }
    },
    {
      dataField: "status",
      text: "State",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px"
      },
      formatter: StatusActiveDeactiveTypeFormatter
    },
    {
      dataField: "action",
      text: "ACTIONS",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: cityUIProps.openViewDialog,
        openEditDialog: cityUIProps.openEditDialog,
        openDeleteDialog: cityUIProps.openDeleteDialog,
        isVisibleViewButton: false
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px"
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: cityListTotalCount ? cityListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: cityUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-head-custom table-head-bg table-borderless table-vertical-center city-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={cityList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  cityUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage entities={cityList} />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
