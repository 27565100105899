import React, { useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import { countriesList, statusName } from "../components/states/StateUIHelpers";

export function AddNewStateModal({ onCloseModal, open }) {
  const [selectedOption, setSelectedOption] = useState({
    value: 1,
    label: "Active"
  });
  const [selectedCountry, setSelectedCountry] = useState({
    value: "UAE",
    label: "UAE"
  });
  const [isFormSuccess, setFormSuccess] = useState(false);

  return (
    <>
      <Modal
        size='md'
        show={open}
        onHide={onCloseModal}
        aria-labelledby='example-modal-sizes-title-sm'
        dialogClassName='inventory-small-modal'
        scrollable={true}
      >
        <Modal.Header
          className='d-flex justify-content-between align-items-center'
          // toggle={onCloseModal}
        >
          <h3 className='modal-title' id='exampleModalLabel2'>
            Add New State
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          {isFormSuccess && (
            <div className='notification icon inline success fs-16'>
              <Check size={8} className='text-white'></Check>New state added
              successfully
            </div>
          )}
          <Form autoComplete='off'>
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='categoryname'>
                    State Name <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='categoryname'
                    required
                    placeholder='State Name'
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='country'>Country</Form.Label>
                  <Select
                    defaultValue={selectedCountry}
                    onChange={setSelectedCountry}
                    isSearchable={true}
                    options={countriesList}
                    placeholder='Select Country'
                    id='country'
                    className='react-select-container'
                    classNamePrefix='react-select'
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='status'>Status</Form.Label>
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    isSearchable={true}
                    options={statusName}
                    placeholder='Select Status'
                    id='status'
                    className='react-select-container'
                    classNamePrefix='react-select'
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            variant='success'
            onClick={() => {
              window.scrollTo(0, 0);
              setFormSuccess(!isFormSuccess);
              setTimeout(() => {
                onCloseModal("VaryingMdo");
              }, 1500);
            }}
          >
            Save
          </Button>
          <Button
            type='button'
            variant='tertiary text-dark'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
