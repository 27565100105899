/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import { DD_MM_YYYY } from "../constants/const";

const ExpectedDateColumnFormatter = (cellContent, row, rowIndex) => {
  const { expected_delivery = "-" } = row || {};

  return (
    <>
      <div className='text-primary p-0'>
        {expected_delivery ? (
          <Moment date={expected_delivery} format={DD_MM_YYYY} />
        ) : (
          "-"
        )}
      </div>
      <> </>
    </>
  );
};

export default ExpectedDateColumnFormatter;
