import React, { useContext, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { PhoneCall, Edit2, Plus, Mail, Smartphone } from "react-feather";
import { AddShippingAddressModal } from "./AddShippingAddressModal";
import { AddContactPersonModal } from "./AddContactPersonModal";
import { EditContactPersonModal } from "./EditContactPersonModal";
import {
  capitalizeFirstLetter,
  amountFormatter,
  dateFormatter,
  setActiveMenu,
  getStatusPOClass
} from "../../constants/utils";
import { FormattedMessage } from "react-intl";
import { EditBillingAddressModal } from "./EditBillingAddressModal";
import { USER_DETAILS } from "../../constants/const";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";

export function DefaultVendorView({
  vendorDetails,
  id,
  recentFivePO,
  isLoading,
  recentFiveFulfilledPO,
  handleUpdateSingleVendorDetails
}) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [isOpenAddBillingAddressModal, setOpenAddBillingAddressModal] =
    useState(false);
  const [isOpenAddShippingAddressModal, setOpenAddShippingAddressModal] =
    useState(false);
  const [isOpenAddContactPersonModal, setOpenAddContactPersonModal] =
    useState(false);
  const [isOpenEditContactPersonModal, setOpenEditContactPersonModal] =
    useState(false);
  const [selectedContactPerson, setSelectedContactPerson] = useState({});
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const addBillingAddressModalShow = () => {
    setOpenAddBillingAddressModal(true);
  };

  const addBillingAddressModalHide = () => {
    setOpenAddBillingAddressModal(false);
  };

  const addShippingAddressModalShow = () => {
    setOpenAddShippingAddressModal(true);
  };

  const addShippingAddressModalHide = () => {
    setOpenAddShippingAddressModal(false);
  };
  const addContactPersonModalShow = () => {
    setOpenAddContactPersonModal(true);
  };

  const addContactPersonModalHide = () => {
    setOpenAddContactPersonModal(false);
  };

  const editContactPersonModalShow = () => {
    setOpenEditContactPersonModal(true);
  };

  const editContactPersonModalHide = () => {
    setOpenEditContactPersonModal(false);
  };

  const renderContactName = (contact) => {
    const { first_name, last_name } = contact;
    let name = first_name ? first_name : "";
    let space = " ";
    const lastName = last_name ? last_name : "";
    if (last_name) name = name.concat(space, lastName);
    return capitalizeFirstLetter(name);
  };

  const handleCloseOfEditBillingAddressVendorModal = () => {
    handleUpdateSingleVendorDetails();
    addBillingAddressModalHide();
  };

  const handleCloseOfEditShippingAddressVendorModal = () => {
    handleUpdateSingleVendorDetails();
    addShippingAddressModalHide();
  };

  const handleCloseOfAddNewContactPersonVendorModal = () => {
    handleUpdateSingleVendorDetails();
    addContactPersonModalHide();
  };

  const handleCloseOfEditContactPersonVendorModal = () => {
    handleUpdateSingleVendorDetails();
    editContactPersonModalHide();
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const renderStatusName = (purchaseorderstatus) => {
    const { name: status } = purchaseorderstatus || {};
    return (
      <span
        className={`${getStatusPOClass(
          status
        )} status-color text-capitalize cursor-pointer`}
      >
        {status ? status : "-"}
      </span>
    );
  };

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  return (
    <>
      <div className=''>
        <div className='highlighted-row'>
          <div className='d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row'>
            <div className='left'>
              <h3 className='modal-title mb-2'>
                {capitalizeFirstLetter(vendorDetails?.contact_name)}
              </h3>
              <div className='text-dark mb-2'>
                <Mail size={12} className='text-dark mr-2' />
                {vendorDetails?.email ? vendorDetails?.email : "-"}
              </div>
              <div className='text-dark mb-2'>
                <Smartphone size={12} className='text-dark mr-2' />
                <span className='ltr-direction'>
                  {vendorDetails?.mobile_number
                    ? vendorDetails?.mobile_number
                    : "-"}
                </span>
              </div>
            </div>
            <div className='right'>
              <h3 className='text-primary f-w-900 page-title text-right'>
                <small className='text-dark fs-14'>
                  {/* {vendorDetails && vendorDetails.outstanding_products && (
                    
                  )} */}
                  <FormattedMessage id='TITLE.TOTAL.OUTSTANDING.PRODUCTS' />
                </small>
                <br />
                {vendorDetails?.outstanding_products}
              </h3>
              <h5 className='fs-14 text-right text-dark'>
                {/* {vendorDetails && vendorDetails.product_quantity_till_date && (
                
                )} */}
                <FormattedMessage id='TITLE.PRODUCT.QUANTITY.RECEIVED.TILL.DATE' />
                <br />
                <span className='f-w-900 fs-16 text-primary'>
                  {vendorDetails?.product_quantity_till_date}
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DefaultVendorView;
