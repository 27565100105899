/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import { DD_MM_YYYY } from "../constants/const";
const DateTimeColumnFormatter = (cellContent, row, rowIndex) => {
  const { date } = row || {};
  const renderDate = () => {
    if (date) return <Moment date={date} format={DD_MM_YYYY} />;
    return "-";
  };
  return <>{renderDate()}</>;
};

export default DateTimeColumnFormatter;
