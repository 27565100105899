// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import Moment from "react-moment";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../../helper/helper";
import { Pagination } from "../../../../pagination/Pagination";
import { useLocationWiseUIContext } from "../LocationWiseUIContext";
import * as uiHelpers from "../LocationWiseUIHelpers";
// import { DD_MM_YYYY } from "../constants/const";
import { DD_MM_YYYY } from "../../../../constants/const";

const ProductdateFormatter = (cellContent, row, rowIndex) => {
  const { purchaseorder = "-" } = row || {};
  const { date = "-" } = purchaseorder || {};

  return (
    <>
      <div className='text-primary p-0'>
        {date ? <Moment date={date} format={DD_MM_YYYY} /> : "-"}
      </div>
      <> </>
    </>
  );
};

export function LocationWiseTable({
  isLoading,
  transactionList,
  filterValues,
  transactionListTotalCount,
  currentPage,
  setCurrentPage
}) {
  // LocationWise UI Context
  const LocationWiseUIContext = useLocationWiseUIContext();
  const LocationWiseUIProps = useMemo(() => {
    return {
      ids: LocationWiseUIContext.ids,
      setIds: LocationWiseUIContext.setIds,
      queryParams: LocationWiseUIContext.queryParams,
      setQueryParams: LocationWiseUIContext.setQueryParams,
      openViewDialog: LocationWiseUIContext.openViewDialog,
      openEditDialog: LocationWiseUIContext.openEditDialog,
      openDeleteDialog: LocationWiseUIContext.openDeleteDialog
    };
  }, [LocationWiseUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "date",
      text: "Date",
      footer: "",
      sort: true,
      sortCaret: sortCaret,
      style: { minWidth: "100px" },
      formatter: ProductdateFormatter
    },
    {
      dataField: "purchaseorder_number",
      text: "Purchase Order No.",
      footer: "",
      sort: true,
      sortCaret: sortCaret,
      style: { minWidth: "130px" },
      formatter: (cellContent, row, rowIndex) =>
        row?.purchaseorder?.purchaseorder_number ?? "-"
    },
    {
      dataField: "product.name",
      text: "Product Name",
      footer: "",
      sort: true,
      sortCaret: sortCaret,
      style: { minWidth: "130px" },

      formatter: (cellContent, row, rowIndex) => row?.product?.name ?? "-"
    },
    {
      dataField: "quantity",
      text: "Qty.",
      footer: "",
      sort: true,
      sortCaret: sortCaret,
      style: { minWidth: "100px" },
      formatter: (cellContent, row) => row?.quantity ?? "-"
    },
    {
      dataField: "TYPE",
      text: "TYPE",
      footer: "",
      sort: true,
      sortCaret: sortCaret,
      style: { minWidth: "100px" },
      formatter: (cellContent, row, rowIndex) => row?.purchaseorder?.type ?? "-"
    },
    {
      dataField: "store_location",
      text: "Location",
      footer: "",
      sort: true,
      sortCaret: sortCaret,

      style: { minWidth: "150px" },

      formatter: (cellContent, row, rowIndex) =>
        row?.location_Id?.store_location ?? "-"
    },
    {
      dataField: "store_location",
      text: "Parent Location",
      footer: "",
      sort: true,
      sortCaret: sortCaret,
      style: { minWidth: "150px" },
      formatter: (cellContent, row, rowIndex) =>
        row?.location_Id?.locationId?.store_location ?? "-"
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: transactionListTotalCount ? transactionListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: LocationWiseUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-bordered table-vertical-center inward-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={transactionList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  LocationWiseUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage entities={transactionList} />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
