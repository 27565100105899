import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const InwardValuationUIContext = createContext();

export function useInwardValuationUIContext() {
  return useContext(InwardValuationUIContext);
}

export const InwardValuationUIConsumer = InwardValuationUIContext.Consumer;

export function InwardValuationUIProvider({
  inwardvaluationUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: inwardvaluationUIEvents.openViewDialog,
    openEditDialog: inwardvaluationUIEvents.openEditDialog,
    openDeleteDialog: inwardvaluationUIEvents.openDeleteDialog
  };

  return (
    <InwardValuationUIContext.Provider value={value}>
      {children}
    </InwardValuationUIContext.Provider>
  );
}
