import React, { useMemo, useState } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { Upload, Filter, Plus } from "react-feather";
import { UserFilter } from "./user-filter/UserFilter";
import { UserTable } from "./user-table/UserTable";
import { useUserUIContext } from "./UserUIContext";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { USER_DETAILS } from "../../constants/const";
import { FormattedMessage } from "react-intl";
import { userListExportApi } from "../../axios/services/services";

export function UserCard({
  isLoading,
  userList,
  isFilterOpen,
  isLoadingFilter,
  exportCSVButton,
  userListTotalCount,
  passwordChangeRequestCount,
  onClickOfCreateNew,
  handleClickOfRemoveFilterButton,
  handleCLickOfFilterButtonFilter,
  selectedTab,
  handleSelectedTab
}) {
  const userUIContext = useUserUIContext();
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};
  const userUIProps = useMemo(() => {
    return {
      ids: userUIContext.ids,
      queryParams: userUIContext.queryParams,
      setQueryParams: userUIContext.setQueryParams,
      openViewDialog: userUIContext.openViewDialog,
      openEditDialog: userUIContext.openEditDialog,
      openDeleteDialog: userUIContext.openDeleteDialog,
      redirectToViewPage: userUIContext.redirectToViewPage
    };
  }, [userUIContext]);

  const [userExportList, setUserExportList] = useState();

  async function handleExport() {
    const userListExportResponse = await userListExportApi({
      filename: "User"
    });
    const { data } = userListExportResponse || {};
    setUserExportList(data);
  }

  return (
    <>
      <Card className='card-custom card-stretch make-payment-wrapper'>
        <Tabs
          onSelect={(data) => {
            handleSelectedTab(data);
          }}
        >
          <Card.Header className='align-items-md-center flex-column flex-md-row d-flex  justify-content-between'>
            <h3 className='card-title page-title text-black mb-0'>
              <FormattedMessage id='TITLE.USERS' />
            </h3>
            <div className='d-flex mt-2 mt-md-3 '>
              <ButtonGroup className='mr-3'>
                <Button
                  variant='tertiary d-flex align-items-center btn-inventory'
                  onClick={() => {
                    handleClickOfRemoveFilterButton();
                  }}
                >
                  <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                  <span className='d-none d-md-flex'>
                    {isFilterOpen ? (
                      <FormattedMessage id='TITLE.REMOVE' />
                    ) : (
                      <FormattedMessage id='TITLE.FILTER' />
                    )}
                  </span>
                </Button>
                <Button
                  variant='tertiary d-flex align-items-center btn-inventory'
                  onClick={() => {
                    handleExport();
                  }}
                >
                  <Upload size={16} style={{ color: "#CAA25C" }} />
                  <span className='d-none d-md-flex'>
                    <FormattedMessage id='TITLE.EXPORT' />
                  </span>
                </Button>
                {/* <CSVLink
                  ref={exportCSVButton}
                  className="btn btn-tertiary d-flex align-items-center btn-inventory"
                  data={userList}
                  filename={`users-${new Date().getTime()}.csv`}
                  headers={[
                    { label: "Name", key: "full_name" },
                    { label: "Role", key: "role.name" },
                    { label: "Emp ID", key: "emp_id" },
                    { label: "Email", key: "email" },
                    { label: "Cellnumber", key: "cellnumber" },
                    { label: "Status", key: "status_name" },
                  ]}
                >
                  <Upload size={16} style={{ color: "#CAA25C" }} />
                  <span className="d-none d-md-flex">
                    <FormattedMessage id="TITLE.EXPORT" />
                  </span>
                </CSVLink> */}
              </ButtonGroup>
              <Button
                variant='primary d-flex align-items-center btn-inventory'
                onClick={() => {
                  onClickOfCreateNew();
                }}
              >
                <Plus size={16} style={{ color: "#CAA25C" }} />
                <span className='d-none d-md-flex'>
                  <FormattedMessage id='TITLE.CREATE.NEW' />
                </span>
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <TabList className='nav nav-tabs tab-coupon'>
              <Tab className='nav-link'>
                <FormattedMessage id='TITLE.ALL.USER' />
              </Tab>
              {(userRole === "Admin" || userRole === "Asset Manager") && (
                <Tab className='nav-link'>
                  <FormattedMessage id='TITLE.PASSWORD.CHANGE.REQUESTED.USER' />
                  <span className='badge rounded-pill bg-danger small-text ml-1'>
                    {/* {selectedTab === 1 ? userListTotalCount : ""} */}
                    {passwordChangeRequestCount}
                  </span>
                </Tab>
              )}
            </TabList>
            <TabPanel>
              {isFilterOpen && (
                <UserFilter
                  isLoadingFilter={isLoadingFilter}
                  handleCLickOfFilterButtonFilter={
                    handleCLickOfFilterButtonFilter
                  }
                />
              )}
              <UserTable
                isLoading={isLoading}
                userList={userList}
                userListTotalCount={userListTotalCount}
                selectedTab={selectedTab}
              />
            </TabPanel>
            {(userRole === "Admin" || userRole === "Asset Manager") && (
              <TabPanel>
                {isFilterOpen && (
                  <UserFilter
                    isLoadingFilter={isLoadingFilter}
                    handleCLickOfFilterButtonFilter={
                      handleCLickOfFilterButtonFilter
                    }
                  />
                )}
                <UserTable
                  isLoading={isLoading}
                  userList={userList}
                  userListTotalCount={userListTotalCount}
                  selectedTab={selectedTab}
                />
              </TabPanel>
            )}
          </Card.Body>
        </Tabs>
      </Card>
    </>
  );
}
