import React, { useMemo, useState } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
import { useCustomerUIContext } from "../CustomerUIContext";
import { isEqual } from "lodash";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

const initialFilterValues = {
  contact_name: "",
  mobile_number: "",
  status: "",
  currency: "",
  email: ""
};

export function CustomerFilter({
  isLoadingFilter,
  handleCLickOfFilterButtonFilter
}) {
  const [filterStatus, setFilterStatus] = useState(" ");
  const [startDate, setStartDate] = useState(null);
  const customerUIContext = useCustomerUIContext();
  const customerUIProps = useMemo(() => {
    return {
      queryParams: customerUIContext.queryParams,
      setQueryParams: customerUIContext.setQueryParams
    };
  }, [customerUIContext]);

  const prepareFilter = (queryParams, values) => {
    const { contact_name, mobile_number, status, currency, email } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.contact_name = contact_name !== "" ? contact_name : undefined;
    filter.mobile_number = mobile_number !== "" ? mobile_number : undefined;
    filter.status = status !== "" ? status : undefined;
    filter.currency = currency !== "" ? currency : undefined;
    filter.email = email !== "" ? email : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customerUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customerUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customerUIProps.setQueryParams(newQueryParams);
    }
  };
  const intl = useIntl();
  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue
        }) => (
          <Form
            className='form form-label-right filter-card'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialFilterValues);
              setStartDate(null);
              setFilterStatus("");
              handleReset();
            }}
          >
            <Form.Group className='form-group mb-md-0 filter-form-group'>
              <div className='filter-item'>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.EMPLOYEE.NAME' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='contact_name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.EMPLOYEE.NAME"
                    })}
                    onChange={(e) => {
                      setFieldValue("contact_name", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.EMAIL.ADDRESS' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='email'
                    placeholder={intl.formatMessage({
                      id: "TITLE.EMAIL.ADDRESS"
                    })}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.MOBILE.NUMBER' />
                    </b>
                  </small>
                  <Form.Control
                    type='number'
                    className='form-control'
                    name='mobile_number'
                    placeholder={intl.formatMessage({
                      id: "TITLE.MOBILE.NUMBER"
                    })}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      setFieldValue("mobile_number", nonAlpha);
                    }}
                    onChange={(e) => {
                      setFieldValue("mobile_number", e.target.value);
                    }}
                    value={values.mobile_number}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.STATUS' />
                    </b>
                  </small>
                  <Form.Control
                    name='status'
                    as='select'
                    className='select-control'
                    onChange={(event) => {
                      setFieldValue("status", event.target.value);
                      setFilterStatus(event.target.value);
                    }}
                    value={filterStatus}
                  >
                    <option value=''>
                      {intl.formatMessage({ id: "TITLE.ALL" })}
                    </option>
                    <option value='1'>
                      {intl.formatMessage({ id: "TITLE.ACTIVE" })}
                    </option>
                    <option value='0'>
                      {intl.formatMessage({ id: "TITLE.INACTIVE" })}
                    </option>
                  </Form.Control>
                </div>
              </div>
              <div className='filter-action d-flex align-items-center mt-3'>
                <Button
                  variant='secondary d-flex align-items-center btn-inventory mr-2'
                  type='submit'
                >
                  <Search
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader className={"mr-1 ml-1"} />}
                </Button>
                <Button
                  variant='tertiary d-flex align-items-center btn-inventory'
                  type='reset'
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
}
