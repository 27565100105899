export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 }
  // { text: "20", value: 20 },
  // { text: "50", value: 50 },
];
export const initialFilter = {
  filter: {},
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};

export const statusName = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" }
];

export const countriesList = [
  { value: "UAE", label: "UAE" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Qatar", label: "Qatar" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Oman", label: "Oman" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Iraq", label: "Iraq" },
  { value: "Afghanistan", label: "Afghanistan" }
];
