import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const LocationWiseUIContext = createContext();

export function useLocationWiseUIContext() {
  return useContext(LocationWiseUIContext);
}

export const LocationWiseUIConsumer = LocationWiseUIContext.Consumer;

export function LocationWiseUIProvider({
  activepoUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: activepoUIEvents.openViewDialog,
    openEditDialog: activepoUIEvents.openEditDialog,
    openDeleteDialog: activepoUIEvents.openDeleteDialog
  };

  return (
    <LocationWiseUIContext.Provider value={value}>
      {children}
    </LocationWiseUIContext.Provider>
  );
}
