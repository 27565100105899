import React from "react";
import { capitalizeFirstLetter } from "../constants/utils";
const NameColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { redirectToCustomerViewPage }
) => {
  const { contact } = row || {};
  const { contact_name } = contact || {};
  return (
    <>
      <div
        className='text-primary btn btn-link p-0'
        onClick={() => {
          redirectToCustomerViewPage(row);
        }}
      >
        {capitalizeFirstLetter(contact_name === null ? "-" : contact_name)}
      </div>
      <> </>
    </>
  );
};

export default NameColumnFormatter;
