import React, { useState, useMemo } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
import { useCategoryUIContext } from "../CategoryUIContext";
import { isEqual } from "lodash";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

const initialFilterValues = {
  purchaseorder_number: "",
  date: "",
  name: "",
  status: "",
  totalamount: ""
};

export function CategoryFilter({
  isLoadingFilter,
  handleCLickOfFilterButtonFilter,
  handleCLickOfResetFilterButtonFilter
}) {
  const intl = useIntl();
  const [filterStatus, setFilterStatus] = useState(" ");
  const [startDate, setStartDate] = useState(null);
  const inwardUIContext = useCategoryUIContext();
  const categoryUIProps = useMemo(() => {
    return {
      queryParams: inwardUIContext.queryParams,
      setQueryParams: inwardUIContext.setQueryParams
    };
  }, [inwardUIContext]);

  const prepareFilter = (queryParams, values) => {
    const { name, status } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.name = name !== "" ? name : undefined;
    filter.status = status !== "" ? status : undefined;

    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(categoryUIProps.queryParams, values);
    if (!isEqual(newQueryParams, categoryUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      categoryUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue
        }) => (
          <Form
            className='form form-label-right filter-card'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialFilterValues);
              setStartDate(null);
              setFilterStatus("");
              handleReset();
            }}
          >
            <Form.Group className='form-group mb-md-0 filter-form-group'>
              <div className='filter-item'>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      {" "}
                      <FormattedMessage id='TITLE.CATEGORY.NAME' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.CATEGORY.NAME"
                    })}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      {" "}
                      <FormattedMessage id='TITLE.STATUS' />
                    </b>
                  </small>
                  <Form.Control
                    name='status'
                    as='select'
                    className='select-control'
                    onChange={(event) => {
                      setFieldValue("status", event.target.value);
                      setFilterStatus(event.target.value);
                    }}
                    value={filterStatus}
                  >
                    <option value=''>
                      {intl.formatMessage({
                        id: "TITLE.ALL"
                      })}
                    </option>
                    <option value='1'>
                      {intl.formatMessage({
                        id: "TITLE.ACTIVE"
                      })}
                    </option>
                    <option value='0'>
                      {intl.formatMessage({
                        id: "TITLE.INACTIVE"
                      })}
                    </option>
                  </Form.Control>
                </div>
              </div>
              <div className='filter-action d-flex'>
                <Button
                  variant='secondary d-flex align-items-center btn-inventory mr-2'
                  type='submit'
                >
                  <Search
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader className={"mr-1 ml-1"} />}
                </Button>
                <Button
                  variant='tertiary d-flex align-items-center btn-inventory'
                  type='reset'
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
}
