import React, { useRef, useState, useEffect, useContext } from "react";
import { VendorLoadingDialog } from "./vendor-loading-dialog/VendorLoadingDialog";
import { VendorCard } from "./VendorCard";
import { VendorUIProvider } from "./VendorUIContext";
import { initialFilter } from "./VendorUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { DeleteModal } from "../common/modals/DeleteModal";
import { deleteVendorApi, vendorListApi } from "../../axios/services/services";
import { SuccessModal } from "../common/modals/SuccessModal";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization
} from "../../constants/utils";
import { AddNewVendorModal } from "../../global-modal/AddNewVendorModal";
import { EditVendorModal } from "./edit-vendor/EditVendorModal";
import { FormattedMessage, useIntl } from "react-intl";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";

export function VendorPage({ history }) {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [isOpenAddNewVendorModal, setOpenAddNewVendorModal] = useState(false);
  const [isOpenEditVendorModal, setOpenEditVendorModal] = useState(false);
  const exportCSVButton = useRef();
  const [vendorList, setVendorList] = useState([]);
  const [vendorListTotalCount, setVendorListTotalCount] = useState();
  const [isUpdateVendorDetails, setUpdateVendorDetails] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isLoadingDeleteVendor, setLoadingDeleteVendor] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  async function getVendorList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const VendorListResponse = await vendorListApi({
      queryParams
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count } = VendorListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setVendorList(data ? data : []);
    setVendorListTotalCount(count ? count : 0);
  }

  const deleteVendor = async () => {
    const { id } = data;
    enableLoadingDeleteVendor();
    const deleteVendorResponse = await deleteVendorApi({ id });
    const { Error } = deleteVendorResponse || {};
    if (Error) {
      disableLoadingDeleteVendor();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.VENDOR.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    window.scrollTo(0, 0);
    setUpdateVendorDetails(Math.random());
    disableLoadingDeleteVendor();
  };

  useEffect(() => {
    getVendorList();
  }, [queryParams, isUpdateVendorDetails]);

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const enableLoadingDeleteVendor = () => {
    setLoadingDeleteVendor(true);
  };

  const disableLoadingDeleteVendor = () => {
    setLoadingDeleteVendor(false);
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const addNewVendorModalShow = () => {
    setOpenAddNewVendorModal(true);
  };

  const addNewVendorModalHide = () => {
    setOpenAddNewVendorModal(false);
  };

  const editVendorModalShow = () => {
    setOpenEditVendorModal(true);
  };

  const editVendorModalHide = () => {
    setOpenEditVendorModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  // const handleFilterValues = (values) => {
  //   setFilterValues(values);
  // };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const vendorUIEvents = {
    openViewDialog: (row) => {
      const path = `/vendors/view-vendor-details/${row.id}`;
      onClickChangeRoute(path);
    },
    openEditDialog: (row) => {
      setData(row);
      editVendorModalShow();
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    },
    redirectToVendorViewPage: (row) => {
      setData(row);
      const path = `/vendors/view-vendor-details/${row.id}`;
      onClickChangeRoute(path);
    }
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    addNewVendorModalShow();
  };

  const onClickOfDeleteButton = () => {
    deleteVendor();
  };

  const handleCloseOfAddNewVendorModal = (addNewVendorDetails) => {
    setUpdateVendorDetails(Math.random());
    addNewVendorModalHide();
  };

  const handleCloseOfEditNewVendorModal = (editNewVendorDetails) => {
    setUpdateVendorDetails(Math.random());
    editVendorModalHide();
  };

  const handleCLickOfFilterButtonFilter = (values) => {
    setFilter(true);
  };

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <div>
      <>
        <VendorUIProvider
          vendorUIEvents={vendorUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <VendorLoadingDialog />

          {isOpenAddNewVendorModal && (
            <AddNewVendorModal
              open={isOpenAddNewVendorModal}
              onCloseModal={addNewVendorModalHide}
              handleCloseOfAddNewVendorModal={handleCloseOfAddNewVendorModal}
            />
          )}

          {isOpenEditVendorModal && (
            <EditVendorModal
              open={isOpenEditVendorModal}
              data={data}
              onCloseModal={editVendorModalHide}
              id={data.id}
              handleCloseOfEditNewVendorModal={handleCloseOfEditNewVendorModal}
            />
          )}

          <VendorCard
            isLoading={isLoading}
            vendorList={vendorList}
            loadingExportButton={loadingExportButton}
            transactionListExport={vendorList}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            vendorListTotalCount={vendorListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            isLoadingFilter={isLoadingFilter}
            isFilterOpen={isFilterOpen}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
          />
        </VendorUIProvider>
        {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteVendor}
            title={intl.formatMessage({ id: "TITLE.DELETE.VENDOR" })}
            description={
              <>
                <FormattedMessage id='TITLE.VENDOR.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER' />{" "}
                <FormattedMessage id='TITLE.ARE.YOU.SURE.ABOUT.DELETING.IT' />
              </>
            }
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={capitalizeFirstLetter(data?.contact_name)}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}
        {isErrorModalOpen && (
          <ErrorModal
            message={errorMessage}
            isErrorModalOpen={isErrorModalOpen}
            hideErrorModal={handleErrorModalHide}
          />
        )}
        {openSuccessModal && (
          <SuccessModal
            message={successMessage}
            open={openSuccessModal}
            onCloseModal={onCloseSuccessModal}
          />
        )}
      </>
    </div>
  );
}
