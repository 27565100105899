import React, { useEffect, useRef, useState } from "react";
import { UserCard } from "./UserCard";
import { UserUIProvider } from "./UserUIContext";
import { initialFilter } from "./UserUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { DeleteModal } from "../common/modals/DeleteModal";
import { AddNewUserModal } from "./add-new-user/AddNewUserModal";
import { UserViewModal } from "./user-view-modal/UserViewModal";
import { EditUserModal } from "./edit-user/EditUserModal";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  unauthorization
} from "../../constants/utils";
import {
  deletePasswordRequestApi,
  deleteUserApi,
  roleListApi,
  userListApi
} from "../../axios/services/services";
import { SuccessModal } from "../common/modals/SuccessModal";
import { ViewPasswordRequestModal } from "../../global-modal/ViewPasswordRequestModal";
import { useIntl } from "react-intl";

export function UserPage({ history }) {
  const intl = useIntl();
  const [data, setData] = useState({});
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [isFilter, setFilter] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [
    isDeletePasswordRequestModalOpen,
    setIsDeletePasswordRequestModalOpen
  ] = useState(false);
  const [isLoadingDeleteUser, setLoadingDeleteUser] = useState(false);
  const [isLoadingDeletePasswordRequest, setLoadingDeletePasswordRequest] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [userListTotalCount, setUserListTotalCount] = useState(0);
  const [passwordChangeRequestCount, setPasswordChangeRequestCount] =
    useState(0);
  const [isUpdateUserDetails, setUpdateUserDetails] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [isOpenAddNewUserModal, setOpenAddNewUserModal] = useState(false);
  const [isOpenViewUserModal, setOpenViewUserModal] = useState(false);
  const [isOpenViewPasswordRequestModal, setOpenViewPasswordRequestModal] =
    useState(false);
  const [isOpenEditUserModal, setOpenEditUserModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const exportCSVButton = useRef();

  async function getUserList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const userListResponse = await userListApi({
      queryParams,
      selectedTab
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count, password_changerequest_count } =
      userListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setUserList(data ? data : []);
    setUserListTotalCount(count ? count : 0);
    setPasswordChangeRequestCount(
      password_changerequest_count ? password_changerequest_count : 0
    );
  }

  const getRoleList = async () => {
    const roleListResponse = await roleListApi();
    const { data, Error } = roleListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const roles = data.map((role) => {
      role.label = role.name;
      role.value = role.id;
      return role;
    });
    setRoleList(roles ? roles : []);
  };

  useEffect(() => {
    getUserList();
  }, [queryParams, isUpdateUserDetails]);

  useEffect(() => {
    getRoleList();
  }, []);

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const enableLoadingDeleteUser = () => {
    setLoadingDeleteUser(true);
  };

  const disableLoadingDeleteUser = () => {
    setLoadingDeleteUser(false);
  };

  const enableLoadingDeletePasswordRequest = () => {
    setLoadingDeletePasswordRequest(true);
  };

  const disableLoadingDeletePasswordRequest = () => {
    setLoadingDeletePasswordRequest(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeletePasswordRequestModalShow = () => {
    setIsDeletePasswordRequestModalOpen(true);
  };

  const handleDeletePasswordRequestModalHide = () => {
    setIsDeletePasswordRequestModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const viewUserModalShow = () => {
    setOpenViewUserModal(true);
  };

  const viewUserModalHide = () => {
    setOpenViewUserModal(false);
  };

  const viewPasswordRequestModalShow = () => {
    setOpenViewPasswordRequestModal(true);
  };

  const viewPasswordRequestModalHide = () => {
    setOpenViewPasswordRequestModal(false);
  };

  const editUserModalShow = () => {
    setOpenEditUserModal(true);
  };

  const editUserModalHide = () => {
    setOpenEditUserModal(false);
  };

  const addNewUserModalShow = () => {
    setOpenAddNewUserModal(true);
  };

  const addNewUserModalHide = () => {
    setOpenAddNewUserModal(false);
  };

  const handleSelectedTab = (currentTab) => {
    setSelectedTab(currentTab);
    setFilterOpen(false);
    setFilter(false);
    if (currentTab === 1) {
      setUserListTotalCount(0);
      initialFilter.filter.ispasswordrequested = 1;
      setQueryParamsBase(initialFilter);
    } else {
      initialFilter.filter.ispasswordrequested = 0;
      setQueryParamsBase(initialFilter);
    }
    setUpdateUserDetails(Math.random());
  };

  const userUIEvents = {
    openViewDialog: (row) => {
      setData(row);
      if (selectedTab === 1) {
        // Password Request
        viewPasswordRequestModalShow();
      } else {
        // All User
        viewUserModalShow();
      }
    },
    openEditDialog: (row) => {
      setData(row);
      if (selectedTab === 1) {
        // Password Request
        viewPasswordRequestModalShow();
      } else {
        // All User
        editUserModalShow();
      }
    },
    openDeleteDialog: (row) => {
      setData(row);
      if (selectedTab === 1) {
        // Password Request
        handleDeletePasswordRequestModalShow();
      } else {
        // All User
        handleDeleteModalShow();
      }
    },
    redirectToViewPage: (row) => {
      setData(row);
      viewUserModalShow();
    }
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleOnClickOfCreateNew = () => {
    addNewUserModalShow();
  };

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const handleCLickOfFilterButtonFilter = (values) => {
    setFilter(true);
  };

  const handleCloseOfAddNewUserModal = () => {
    setUpdateUserDetails(Math.random());
    addNewUserModalHide();
  };

  const handleCloseOfEditUserModal = () => {
    setUpdateUserDetails(Math.random());
    editUserModalHide();
  };

  const handleSuccessOfViewPasswordRequestModal = () => {
    setUpdateUserDetails(Math.random());
    viewPasswordRequestModalHide();
  };

  const handleCloseOfViewPasswordRequestModal = () => {
    viewPasswordRequestModalHide();
  };

  const onClickOfDeleteButton = () => {
    deleteUser();
  };

  const onClickOfDeletePasswordRequestButton = () => {
    deleteDeletePasswordRequest();
  };

  const deleteUser = async () => {
    const { id } = data;
    enableLoadingDeleteUser();
    const deleteUserResponse = await deleteUserApi({ id });
    const { Error } = deleteUserResponse || {};
    if (Error) {
      disableLoadingDeleteUser();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.USER.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    window.scrollTo(0, 0);
    setUpdateUserDetails(Math.random());
    disableLoadingDeleteUser();
  };

  const deleteDeletePasswordRequest = async () => {
    const { id } = data;
    enableLoadingDeletePasswordRequest();
    const deletePasswordRequestResponse = await deletePasswordRequestApi({
      id
    });
    const { Error } = deletePasswordRequestResponse || {};
    if (Error) {
      disableLoadingDeletePasswordRequest();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    handleDeletePasswordRequestModalHide(false);
    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.PASSWORD.CHANGE.REQUEST.HAS.BEEN.DELETED.SUCCESSFULLY"
      })
    );
    onOpenSuccessModal();
    window.scrollTo(0, 0);
    setUpdateUserDetails(Math.random());
    disableLoadingDeletePasswordRequest();
  };

  return (
    <div>
      <>
        <UserUIProvider
          userUIEvents={userUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <UserCard
            isLoading={isLoading}
            selectedTab={selectedTab}
            userList={userList}
            isFilterOpen={isFilterOpen}
            isLoadingFilter={isLoadingFilter}
            exportCSVButton={exportCSVButton}
            userListTotalCount={userListTotalCount}
            passwordChangeRequestCount={passwordChangeRequestCount}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            handleSelectedTab={handleSelectedTab}
          />
        </UserUIProvider>

        {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteUser}
            title={intl.formatMessage({ id: "TITLE.DELETE.USER" })}
            description={intl.formatMessage({
              id: "TITLE.USER.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
            })}
            id={capitalizeFirstLetter(data?.full_name)}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}

        {isDeletePasswordRequestModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeletePasswordRequest}
            title={intl.formatMessage({
              id: "TITLE.DELETE.PASSWORD.CHANGE.REQUEST"
            })}
            description={intl.formatMessage({
              id: "TITLE.PASSWORD.CHANGE.REQUEST.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
            })}
            id={capitalizeFirstLetter(data?.full_name)}
            isOpen={isDeletePasswordRequestModalOpen}
            onCloseModal={handleDeletePasswordRequestModalHide}
            onClickOfDeleteButton={onClickOfDeletePasswordRequestButton}
          />
        )}

        {isOpenAddNewUserModal && (
          <AddNewUserModal
            open={isOpenAddNewUserModal}
            onCloseModal={addNewUserModalHide}
            roleList={roleList}
            handleCloseOfAddNewUserModal={handleCloseOfAddNewUserModal}
          />
        )}

        {isOpenViewUserModal && (
          <UserViewModal
            isConfirmModal={isOpenViewUserModal}
            handleConfirmHide={viewUserModalHide}
            title={"View User Details"}
            data={data}
          />
        )}

        {isOpenViewPasswordRequestModal && (
          <ViewPasswordRequestModal
            isOpenViewPasswordRequestModal={isOpenViewPasswordRequestModal}
            userData={data}
            handleCloseOfViewPasswordRequestModal={
              handleCloseOfViewPasswordRequestModal
            }
            handleSuccessOfViewPasswordRequestModal={
              handleSuccessOfViewPasswordRequestModal
            }
          />
        )}

        {isOpenEditUserModal && (
          <EditUserModal
            open={isOpenEditUserModal}
            userDeta={data}
            roleList={roleList}
            onCloseModal={editUserModalHide}
            handleCloseOfEditUserModal={handleCloseOfEditUserModal}
          />
        )}

        {isErrorModalOpen && (
          <ErrorModal
            message={errorMessage}
            isErrorModalOpen={isErrorModalOpen}
            hideErrorModal={handleErrorModalHide}
          />
        )}

        {openSuccessModal && (
          <SuccessModal
            message={successMessage}
            open={openSuccessModal}
            onCloseModal={onCloseSuccessModal}
          />
        )}
      </>
    </div>
  );
}
