import React, { Fragment, useContext, useState } from "react";
import { Card, Image, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ContentLoader } from "../../helper/ContentLoader";
import { amountFormatter, setActiveMenu } from "../../constants/utils";
import nodatafoundImage from "../../assets/images/dashboard/nodata-found.svg";
import { FormattedMessage } from "react-intl";
import { USER_DETAILS } from "../../constants/const";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";

function TopCustomerByAmountWidgets({ top5Customer, isLoading }) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Card style={{ height: "calc(100% - 15px)" }}>
        <Card.Header>
          <Card.Title className='mb-0 modal-title'>
            <FormattedMessage id='TITLE.TOP.5.EMPLOYEES.BY.QUANTITY' />
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className='table-responsive'>
            {isLoading && (
              <ContentLoader isLoading={isLoading} pleaseWait={true} />
            )}
            <Table className='viewpo-table table'>
              <thead>
                <tr>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.NAME' />
                  </th>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.PHONE' />
                  </th>
                  <th scope='col' className='text-right'>
                    <FormattedMessage id='TITLE.TOTAL.QUANTITY' />
                  </th>
                </tr>
              </thead>
              <tbody>
                {top5Customer &&
                  top5Customer.map((customer, index) => {
                    return (
                      <tr
                        key={index}
                        className='cursor-pointer'
                        onClick={() => {
                          if (userRole !== "Outward Manager") {
                            const path = `/customers/view-customer-details/${customer?.contact?.id}`;
                            onClickChangeRoute(path);
                            window.scrollTo(0, 0);
                          }
                        }}
                      >
                        <td>
                          <span className='text-info'>
                            {customer?.contact?.contact_name}
                          </span>
                        </td>
                        <td className='ltr-direction'>
                          {customer?.contact?.phone_number}
                        </td>
                        <td className='digits text-right'>
                          {customer?.totalquantity}
                        </td>
                      </tr>
                    );
                  })}
                {top5Customer && top5Customer.length === 0 && !isLoading && (
                  <>
                    <tr>
                      <td className='no-record text-center' colSpan={3}>
                        <Image
                          src={nodatafoundImage}
                          alt='No Data Found'
                          className='img-fluid'
                          style={{ maxHeight: "200px" }}
                        />
                        <span className='d-block'>
                          <FormattedMessage id='TITLE.NO.RECORDS.FOUND' />
                        </span>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default TopCustomerByAmountWidgets;
