import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Check, X } from "react-feather";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import {
  editVendorApi,
  getSingleVendorDetailsApi
} from "../../../axios/services/services";
import {
  InitLanguage,
  getErrorMessageBasedOnLanguage,
  unauthorization
} from "../../../constants/utils";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { EditVendorSchema } from "../../../helper/FormikSchema";
import { ErrorModal } from "../../common/modals/ErrorModal";

export function EditVendorModal({
  onCloseModal,
  open,
  data,
  id,
  handleCloseOfEditNewVendorModal
}) {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoadingSingleVendor, setLoadingSingleVendor] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [isVendorEdited, setVendorEdited] = useState(false);
  const [initialValues, setInitialValues] = useState({
    contact_name: "",
    email: "",
    phone_number: "",
    mobile_number: "",
    currency: "",
    billingAddress: "",
    billingCity: "",
    billingZipcode: "",
    shippingAddress: "",
    shippingCity: "",
    shippingZipcode: "",
    contact_persons: [
      {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        mobile: ""
      }
    ],
    remarks: ""
  });
  const statusName = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" }
  ];
  const intl = useIntl();

  async function getSingleVendorDetails() {
    enableLoadingSingleVendor();
    const singleVendorDetails = await getSingleVendorDetailsApi({ id });
    disableLoadingSingleVendor(false);
    const { Error, data } = singleVendorDetails || {};
    const { get_contact } = data || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const {
      contact_name,
      email,
      status,
      phone_number,
      mobile_number,
      currency,
      billingaddress,
      shippingaddress,
      remarks,
      contact_person
    } = get_contact || {};
    const { building_no, city, zipcode } = billingaddress || {};
    const {
      building_no: shippingBuildingNo,
      city: shippingCity,
      zipcode: shippingZipcode
    } = shippingaddress || {};

    if (status === 1) {
      setSelectedOption({ value: "1", label: "Active" });
    } else {
      setSelectedOption({ value: "0", label: "Inactive" });
    }

    const contactPersonsPrevs = [];
    contact_person.map((contactPerson) => {
      const { first_name, last_name, email, phone, mobile, id } =
        contactPerson || {};
      let obj = {
        id,
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        email: email ? email : "",
        phone: phone ? phone : "",
        mobile: mobile ? mobile : ""
      };
      contactPersonsPrevs.push(obj);
      return contactPerson;
    });

    let vendorDetail = {
      contact_name: contact_name ? contact_name : "",
      email: email ? email : "",
      phone_number: phone_number ? phone_number : "",
      mobile_number: mobile_number ? mobile_number : "",
      currency: currency,
      billingAddress: building_no ? building_no : "",
      billingCity: city && city?.id,
      billingZipcode: zipcode ? zipcode : "",
      shippingAddress: shippingBuildingNo ? shippingBuildingNo : "",
      shippingCity: shippingCity && shippingCity?.id,
      shippingZipcode: shippingZipcode ? shippingZipcode : "",
      remarks: remarks ? remarks : "",
      contact_persons: contactPersonsPrevs
    };
    setInitialValues(vendorDetail);
  }

  useEffect(() => {
    getSingleVendorDetails();
  },[open]);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingSingleVendor = () => {
    setLoadingSingleVendor(true);
  };

  const disableLoadingSingleVendor = () => {
    setLoadingSingleVendor(false);
  };

  const editVendor = async (values) => {
    enableLoading();
    const {
      contact_name,
      email,
      phone_number,
      mobile_number,
      currency,
      billingAddress,
      billingCity,
      billingZipcode,
      shippingAddress,
      shippingCity,
      shippingZipcode,
      remarks,
      contact_persons
    } = values || {};

    const { value: status } = selectedOption;

    let obj = {
      id,
      contact_name,
      email,
      phone_number,
      mobile_number,
      currency,
      remarks,
      address: {
        billingaddressId: billingAddress,
        billing_zipcode: billingZipcode,
        billing_cityId: billingCity,
        shippingaddressId: shippingAddress,
        shipping_cityId: shippingCity,
        shipping_zipcode: shippingZipcode
      },
      contact_person: contact_persons,
      status: parseInt(status)
    };

    const editCategoryResponse = await editVendorApi(obj);
    const { data, Error } = editCategoryResponse || {};

    if (Error) {
      disableLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setVendorEdited(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableLoading();
      handleCloseOfEditNewVendorModal(data);
    }, 2500);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={EditVendorSchema}
      >
        {({
          values,
          resetForm,
          handleReset,
          setFieldValue,
          errors,
          touched
        }) => (
          <Form
            autoComplete='off'
            className='form form-label-right filter-card'
            onReset={() => {
              resetForm(initialValues);
              handleReset();
            }}
          >
            <Modal
              size='lg'
              show={open}
              onHide={onCloseModal}
              aria-labelledby='example-modal-sizes-title-lg'
              dialogClassName='addvendor-modal modal'
              scrollable={true}
            >
              <Modal.Header className='d-flex justify-content-between align-items-center'>
                <h3 className='modal-title' id='exampleModalLabel2'>
                  <FormattedMessage id='TITLE.EDIT.VENDOR' />
                  {isLoadingSingleVendor && (
                    <Spinner animation='border' size='sm' className='ml-1' />
                  )}
                </h3>
                <Button
                  variant='link p-0 btn-icon'
                  onClick={() => onCloseModal()}
                >
                  <X size={20} className='text-dark mr-0 pr-0' />
                </Button>
              </Modal.Header>
              <Modal.Body className='pb-0'>
                {isVendorEdited && (
                  <div className='notification icon inline success fs-16'>
                    <Check size={8} className='text-white'></Check>{" "}
                    <FormattedMessage id='TITLE.VENDOR.EDITED.SUCCESSFULLY' />
                  </div>
                )}

                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='vendorname'>
                        <FormattedMessage id='TITLE.VENDOR.DISPLAY.NAME' />
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        id='contact_name'
                        name='contact_name'
                        placeholder={intl.formatMessage({
                          id: "TITLE.VENDOR.DISPLAY.NAME"
                        })}
                        className='form-control'
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[0-9]/g, "");
                          setFieldValue(`contact_name`, value);
                        }}
                        value={values?.contact_name}
                        autoComplete='off'
                      />
                      {touched.contact_name && errors.contact_name ? (
                        <div className='text-danger'>
                          {InitLanguage(errors.contact_name)}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='vendorname'>
                        <FormattedMessage id='TITLE.VENDOR.EMAIL.ADDRESS' />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        id='email'
                        name='email'
                        //  as="textarea"
                        placeholder={intl.formatMessage({
                          id: "TITLE.VENDOR.EMAIL.ADDRESS"
                        })}
                        className='form-control'
                        onChange={(e) => {
                          setFieldValue(`email`, e.target.value);
                        }}
                        value={values?.email}
                        autoComplete='off'
                      />
                      {touched.email && errors.email ? (
                        <div className='text-danger'>{errors.email}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='vendorname'>
                        <FormattedMessage id='TITLE.VENDOR.MOBILE.NUMBER' />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        id='mobile_number'
                        type='number'
                        name='mobile_number'
                        //  as="textarea"
                        placeholder={intl.formatMessage({
                          id: "TITLE.VENDOR.MOBILE.NUMBER"
                        })}
                        className='form-control'
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={(e) => {
                          setFieldValue(`mobile_number`, e.target.value);
                        }}
                        onPaste={(evt) => {
                          const values = evt.clipboardData.getData("text");
                          const nonAlpha = values.replace(/[^\d.-]/g, "");
                          evt.preventDefault();
                          setFieldValue("mobile_number", nonAlpha);
                        }}
                        value={values?.mobile_number}
                        autoComplete='off'
                      />
                      {errors.mobile_number ? (
                        <div className='text-danger'>
                          {InitLanguage(errors.mobile_number)}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='status'>
                        <FormattedMessage id='TITLE.STATUS' />
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Select
                        value={selectedOption}
                        onChange={(value) => {
                          handleOptionChange(value);
                        }}
                        isSearchable={true}
                        options={statusName}
                        placeholder={intl.formatMessage({
                          id: "TITLE.SELECT.STATUS"
                        })}
                        id='status'
                        className='react-select-container'
                        classNamePrefix='react-select'
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type='submit'
                  variant='success'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    console.log("Form submitted with values:", values);
                    editVendor(values);
                  }}
                  disabled={isLoading}
                >
                  <FormattedMessage id='TITLE.SAVE' />
                  {isLoading && <ButtonLoader className='mr-1 ml-1' />}
                </Button>
                <Button
                  type='button'
                  variant='tertiary text-dark'
                  onClick={() => onCloseModal()}
                >
                  <FormattedMessage id='TITLE.CANCEL' />
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
