/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { getStatusForAuditClass } from "../constants/utils";
import { AuditStatus } from "../components/audit-module/AuditUIHelpers";
const AuditStatusTypeFormatter = (cellContent, row, rowIndex) => {
  const { audit_status } = row;

  const renderStatus = () => {
    return (
      <span
        className={`${getStatusForAuditClass(audit_status)} status-color text-capitalize`}
      >
        {AuditStatus[audit_status]}
      </span>
    );
  };

  return renderStatus();
};

export default AuditStatusTypeFormatter;
