import React, { useMemo, useState } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { Upload, Filter, Plus } from "react-feather";
import { AuditFilter } from "./audit-filter/AuditFilter";
import { AuditTable } from "./audit-table/AuditTable";
import { useAuditUIContext } from "./AuditUIContext";
import { FormattedMessage } from "react-intl";
import { productListExportApi } from "../../axios/services/services";

export function AuditCard({
  isLoading,
  auditList,
  isLoadingFilter,
  isFilterOpen,
  exportCSVButton,
  handleExportButton,
  auditListTotalCount,
  currentPage,
  setCurrentPage,
  onClickOfCreateNew,
  handleFilterValues,
  filterValues,
  handleClickOfRemoveFilterButton,
  handleCLickOfFilterButtonFilter
}) {
  const auditUIContext = useAuditUIContext();
  const auditUIProps = useMemo(() => {
    return {
      ids: auditUIContext.ids,
      queryParams: auditUIContext.queryParams,
      setQueryParams: auditUIContext.setQueryParams,
      openViewDialog: auditUIContext.openViewDialog,
      openEditDialog: auditUIContext.openEditDialog,
      openDeleteDialog: auditUIContext.openDeleteDialog
    };
  }, [auditUIContext]);

  const [productExportList, setProductExportList] = useState();

  async function handleExport() {
    const productListExportResponse = await productListExportApi({
      filename: "Product"
    });

    const { data } = productListExportResponse || {};
    setProductExportList(data);
  }
  return (
    <>
      <Card className='card-custom card-stretch make-payment-wrapper'>
        <Card.Header className='align-items-center d-flex justify-content-between'>
          <h3 className='card-title page-title text-black mb-0'>
            <FormattedMessage id='TITLE.AUDITS' />
          </h3>
          <div className='d-flex'>
            <ButtonGroup className='mr-3'>
              <Button
                variant='tertiary d-flex align-items-center btn-inventory'
                onClick={() => {
                  handleClickOfRemoveFilterButton();
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className='d-none d-md-flex'>
                  {isFilterOpen ? (
                    <FormattedMessage id='TITLE.REMOVE' />
                  ) : (
                    <FormattedMessage id='TITLE.FILTER' />
                  )}{" "}
                </span>
              </Button>
{/* 
              <Button
                variant='tertiary d-flex align-items-center btn-inventory'
                onClick={() => {
                  handleExport();
                }}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className='d-none d-md-flex'>
                  <FormattedMessage id='TITLE.EXPORT' />
                </span>
              </Button> */}
            </ButtonGroup>
            <Button
              variant='primary d-flex align-items-center btn-inventory'
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className='d-none d-md-flex'>
                {" "}
                <FormattedMessage id='TITLE.CREATE.NEW' />
              </span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && (
            <AuditFilter
              isLoadingFilter={isLoadingFilter}
              handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            />
          )}
          <AuditTable
            isLoading={isLoading}
            auditList={auditList}
            filterValues={filterValues}
            auditListTotalCount={auditListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
