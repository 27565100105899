// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../ActivePOUIHelpers";
import { useActivePOUIContext } from "../ActivePOUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../../helper/helper";
import { Pagination } from "../../../../pagination/Pagination";
import DateTimeColumnFormatter from "../../../../columnformatter/DateTimeColumnFormatter";
import ExpectedDateColumnFormatter from "../../../../columnformatter/ExpectedDateColumnFormatter";
import VendorNameColumnWithoutLinkFormatter from "../../../../columnformatter/VendorNameColumnWithoutLinkFormatter";
import POWithLinkColumnFormatter from "../../../../columnformatter/POHyperLinkColumnFormatter";
import TotalAmountColumnFormatter from "../../../../columnformatter/TotalAmountColumnFormatter";
import { useIntl } from "react-intl";
import POStatusTypeFormatter from "../../../../columnformatter/POStatusTypeFormatter";

export function ActivePOTable({
  isLoading,
  reportActivePO,
  reportActivePOTotalCount,
  reportActivePOTotalAmount
}) {
  // ActivePO UI Context
  const intl = useIntl();
  const activepoUIContext = useActivePOUIContext();
  const activepoUIProps = useMemo(() => {
    return {
      ids: activepoUIContext.ids,
      setIds: activepoUIContext.setIds,
      queryParams: activepoUIContext.queryParams,
      setQueryParams: activepoUIContext.setQueryParams,
      openViewDialog: activepoUIContext.openViewDialog,
      openEditDialog: activepoUIContext.openEditDialog,
      openDeleteDialog: activepoUIContext.openDeleteDialog,
      redirectToPODetailsPage: activepoUIContext.redirectToPODetailsPage
    };
  }, [activepoUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "status",
      text: intl.formatMessage({
        id: "TITLE.STATUS"
      }),
      ...(reportActivePOTotalCount &&
        reportActivePOTotalCount > 0 && {
          footer: "Total"
        }),
      style: {
        minWidth: "100px"
      },
      formatter: POStatusTypeFormatter,
      formatExtraData: {
        openEditStatusDialog: () => {}
      }
    },
    {
      dataField: "po_date",
      text: intl.formatMessage({
        id: "TITLE.DATE"
      }),
      ...(reportActivePOTotalCount &&
        reportActivePOTotalCount > 0 && {
          footer: ""
        }),
      style: {
        minWidth: "130px"
      },
      formatter: DateTimeColumnFormatter
    },
    {
      dataField: "expected_delivery",
      text: intl.formatMessage({
        id: "TITLE.EXPECTED.DELIVERY.DATE"
      }),
      ...(reportActivePOTotalCount &&
        reportActivePOTotalCount > 0 && {
          footer: ""
        }),
      style: {
        minWidth: "130px"
      },
      formatter: ExpectedDateColumnFormatter
    },
    {
      dataField: "purchaseorder_number",
      text: intl.formatMessage({
        id: "TITLE.PO.NUMBER"
      }),
      ...(reportActivePOTotalCount &&
        reportActivePOTotalCount > 0 && {
          footer: ""
        }),
      style: {
        minWidth: "150px"
      },
      formatter: POWithLinkColumnFormatter,
      formatExtraData: {
        redirectToPODetailsPage: activepoUIProps.redirectToPODetailsPage
      }
    },
    {
      dataField: "contact_name",
      text: intl.formatMessage({
        id: "TITLE.VENDOR.NAME"
      }),
      ...(reportActivePOTotalCount &&
        reportActivePOTotalCount > 0 && {
          footer: ""
        }),
      style: {
        minWidth: "170px"
      },
      formatter: VendorNameColumnWithoutLinkFormatter
    },
    {
      dataField: "totalamount",
      text: intl.formatMessage({
        id: "TITLE.AMOUNT"
      }),
      ...(reportActivePOTotalCount &&
        reportActivePOTotalCount > 0 && {
          footer: new Intl.NumberFormat("en-EN", {
            style: "currency",
            currency: "AED"
          }).format(reportActivePOTotalAmount)
        }),
      style: {
        minWidth: "150px"
      },
      formatter: TotalAmountColumnFormatter
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: reportActivePOTotalCount ? reportActivePOTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: activepoUIProps.queryParams.pageSize,
    page: activepoUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-bordered table-vertical-center inward-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={reportActivePO}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  activepoUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={reportActivePO}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
