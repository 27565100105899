import React from "react";
import { Image, Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage, useIntl } from "react-intl";
import Moment from "react-moment";
import nodatafoundImage from "../../../assets/images/dashboard/nodata-found.svg";
import { DDD_DD_MMM_YYYY, STRING_CONSTANTS } from "../../../constants/const";
import { getCheckInOutStatusName } from "../../../constants/utils";
import { SectionLoader } from "../../../helper/SectionLoader";

export function AssetHistoryView({
  historyList,
  isLoadingHistoryView,
  isLoadMoreLocHistoryView,
  handlePaginationOfHistoryListView
}) {
  const intl = useIntl();

  return (
    <>
      {isLoadingHistoryView && <SectionLoader />}
      <InfiniteScroll
        scrollableTarget='scrollabledivOverview'
        dataLength={historyList ? historyList.length : 0}
        next={() => {
          handlePaginationOfHistoryListView();
        }}
        hasMore={isLoadMoreLocHistoryView}
        loader={
          <h4 style={{ textAlign: "center" }} className='p-1'>
            <FormattedMessage id='TITLE.LOADING' />
            ...
          </h4>
        }
      >
        <div className='view-body'>
          <Table responsive className='viewpo-table table'>
            <thead>
              <tr>
                <th scope='col'>
                  <FormattedMessage id='TITLE.PRODUCT.NAME' />
                </th>
                <th scope='col'>
                  <FormattedMessage id='TITLE.BARCODE.NUMBER' />
                </th>
                <th scope='col'>
                  <FormattedMessage id='TITLE.ASSIGNED.BY' />
                </th>
                <th scope='col'>
                  <FormattedMessage id='TITLE.ASSIGNED.TO' />
                </th>
                <th scope='col'>
                  <FormattedMessage id='TITLE.CHECKOUT.DATE' />
                </th>
                <th scope='col'>
                  <FormattedMessage id='TITLE.RETURNBY.DATE' />
                </th>
                <th scope='col'>
                  <FormattedMessage id='TITLE.CHECKINOUT' />
                </th>
              </tr>
            </thead>
            <tbody>
              {historyList &&
                historyList.map((location, index) => {
                  return (
                    <tr key={index}>
                      <td className='digits'>{location?.product?.name}</td>
                      <td className='text-dark'>{location?.barcode_no}</td>
                      <td className='text-dark'>{location?.user?.full_name}</td>
                      <td className='text-dark'>
                        {location?.purchaseorder?.contact?.contact_name}
                      </td>

                      <td className='text-dark'>
                        {location?.actiondate ? (
                          <Moment
                            date={location?.actiondate}
                            format={DDD_DD_MMM_YYYY}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className='text-dark'>
                        {location?.purchaseorder?.returnByDate ? (
                          <Moment
                            date={location?.purchaseorder?.returnByDate}
                            format={DDD_DD_MMM_YYYY}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td
                        className={`${
                          getCheckInOutStatusName(
                            location.check_in_out_status
                          ) === STRING_CONSTANTS.CHECK_IN
                            ? "text-primary"
                            : "text-secondary"
                        }`}
                      >
                        {intl.formatMessage({
                          id: getCheckInOutStatusName(
                            location.check_in_out_status,
                            true
                          )
                        })}
                      </td>
                    </tr>
                  );
                })}

              {historyList &&
                historyList.length === 0 &&
                !isLoadingHistoryView && (
                  <tr>
                    <td className='no-record text-center' colSpan={6}>
                      <div className='d-flex align-items-center justify-content-center flex-column'>
                        <Image
                          src={nodatafoundImage}
                          alt='No Data Found'
                          className='img-fluid'
                          style={{ maxHeight: "200px" }}
                        />
                        <span className='d-block text-center'>
                          <FormattedMessage id='TITLE.NO.RECORDS.FOUND' />
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>
        </div>
      </InfiniteScroll>
    </>
  );
}

export default AssetHistoryView;
