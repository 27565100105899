import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ArrowDownCircle } from "react-feather";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { FormattedMessage } from "react-intl";
import Select from "react-select";
import { receivePOLocationListApi } from "../../../axios/services/services";
import {
  getErrorMessageBasedOnLanguage,
  unauthorization
} from "../../../constants/utils";
import moment from "moment";

export function CheckInModal({
  title,
  description,
  id,
  onCloseModal,
  isOpen,
  onClickOfCheckInButton,
  isLoading,
  onChangeOfLocation,
  locationValue,
  selectdescription
}) {
  const [locationList, setLocationList] = useState([]);

  async function getLocationList() {
    const locationListResponse = await receivePOLocationListApi();
    const { Error: ErrorLocation, data: locationData } =
      locationListResponse || {};
    if (ErrorLocation) {
      unauthorization(ErrorLocation);
      return;
    }

    const locations = locationData.map((location) => {
      const { store_location, id, parent_location, locationId } =
        location || {};
      const { locationId: li } = locationId || {};
      let locName = store_location;
      var comma = ",";
      if (parent_location) {
        locName = store_location.concat(comma, locationId?.store_location);
        if (li) {
          locName = locName.concat(comma, li?.store_location);
        }
      }
      location.value = id;
      location.label = locName;
      return location;
    });
    setLocationList(locations ? locations : []);
  }

  useEffect(() => {
    getLocationList();
  }, []);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onCloseModal}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Body>
          <div className='text-center p-3'>
            <h3
              className='modal-title text-center mb-3 text-danger'
              style={{ overflowWrap: "anywhere" }}
            >{`${id}`}</h3>
            <ArrowDownCircle
              size={60}
              className='text-danger mb-2'
              style={{ strokeWidth: "1.5px" }}
            ></ArrowDownCircle>
            <h3
              className='modal-title text-center mb-3 text-danger'
              style={{ overflowWrap: "anywhere" }}
            >{`${title}`}</h3>
            <p className='text-center text-dark mb-4 fs-18'>{description}</p>
            <p className='text-center text-dark mb-0'>{selectdescription}</p>
            <div className='product-name pl-3' style={{ flex: "1" }}>
              <Select
                onChange={(data) => onChangeOfLocation(data)}
                isSearchable={true}
                options={locationList}
                placeholder='Select'
                id='state'
                className='react-select-container receive-product-select'
                classNamePrefix='react-select'
                value={locationValue}
              />
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-center mb-3'>
            <Button
              variant='danger btn-inventory mr-3 btn-inventory'
              onClick={onClickOfCheckInButton}
              disabled={isLoading}
            >
              <FormattedMessage id='TITLE.CHECKIN' />
              {isLoading && <ButtonLoader className='mr-1 ml-1' />}
            </Button>
            <Button
              variant='tertiary btn-inventory text-dark btn-inventory'
              onClick={() => onCloseModal()}
            >
              <FormattedMessage id='TITLE.CANCEL' />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
