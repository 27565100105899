import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { addAuditApi, categoryListApi } from "../axios/services/services";
import { USER_DETAILS } from "../constants/const";
import { FormattedMessage, useIntl } from "react-intl";
import { AuditMethodTypeList, RepeatsNumberTypeList, getErrorMessageBasedOnLanguage, groupTypeList } from "../constants/utils";
import { unauthorization } from "../constants/utils";
import { AddEditAuditSchema } from "../helper/FormikSchema";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment-timezone";
import { Check } from "react-feather";
import { ErrorModal } from "../components/common/modals/ErrorModal";

const borderRounded = {
  borderRadius: "10px",
  border: "2px solid #dddddd",
  padding: "10px"
}

export function AddEditNewAuditModal({
  onCloseModal,
  open,
  handleCloseOfAddNewAuditModal
}) {

  let AddEditAuditInitialValues = {
    audit_name: '',
    audit_methods: '',
    start_date: '',
    end_date: null,
    allow_scanning_after_due_date: false,
    recurring: false,
    repeats_number: 0,
    repeats_number_type: 0,
    stop: false,
    stop_number: 0,
    audit_will_appear: 0,
    record_gps_location: false,
    show_asset_details_after_each_scan: false,
    audit_group: "",
    audit_display_field: "",
    audit_filter_field: "",
    automatically_update_field: false,
    allow_users_to_update_field: false,
    individual_audit: false,
    assign_users: {
      select_all: false,
      basic_user: false,
      company_admin: false,
      tech_manager: false
    },
    assign_templates: {
      select_all: false,
      basic: false,
      manager: false
    },
    assign_emails: {
      select_all: false,
      basic_user: false,
      company_admin: false,
      tech_manager: false
    },
  };

  const intl = useIntl();

  const [isLoading, setLoading] = useState(false);
  const [isNewCustomFieldAdded, setNewCustomFieldAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [formStep, setFormStep] = useState(1);
  const [assignAuditFormStep, setAssignAuditFormStep] = useState(1);
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));

  async function getCategoryList() {
    const status = 1;
    const categoryListResponse = await categoryListApi({ status });
    const { data, Error } = categoryListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const categories = data.map((category, index) => {
      return {
        label: category.name,
        value: category.id
      };
    });
    setCategoryList(categories);
  }

  useEffect(() => {
    getCategoryList();
  }, []);


  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const formikAddEditAudit = useFormik({
    initialValues: AddEditAuditInitialValues,
    validationSchema: AddEditAuditSchema,
    onSubmit: async (values, { setStatus }) => {
      try {
        let reqData = JSON.parse(JSON.stringify(values))
        reqData.assign_users = JSON.stringify(values.assign_users)
        reqData.assign_templates = JSON.stringify(values.assign_templates)
        reqData.assign_emails = JSON.stringify(values.assign_emails)
        reqData.start_date = moment(values.start_date).tz('Asia/Kolkata').format('YYYY-MM-DD')
        reqData.end_date = values.end_date ? moment(values.end_date).tz('Asia/Kolkata').format('YYYY-MM-DD') : null
        reqData.repeat_number = (!values.repeat_number || values.repeat_number == "") ? 0 : parseInt(values.repeat_number)
        reqData.stop_number = (!values.stop_number || values.stop_number == "") ? 0 : parseInt(values.stop_number)
        setLoading(true);
        let addAuditResponse = await addAuditApi(reqData);
        console.log("🚀 ~ onSubmit: ~ addAuditResponse:", addAuditResponse)
        let { data, Error } = addAuditResponse || {};
        if (Error) {
          setLoading(false);
          unauthorization(Error);
          setErrorMessage(getErrorMessageBasedOnLanguage(Error));
          handleErrorModalShow();
          return;
        }
        setNewCustomFieldAdded(true);
        window.scrollTo(0, 0);
        setTimeout(() => {
          formikAddEditAudit.resetForm();
          onCloseModal("VaryingMdo")
          handleCloseOfAddNewAuditModal(data);
          setLoading(false);
          setFormStep(1)
          setAssignAuditFormStep(1);
          setNewCustomFieldAdded(false)
        }, 1000)
      }
      catch (err) {
        console.log("error", err)
        setTimeout(() => {
          setLoading(false);
        }, 500)
      }
    }
  });

  return (
    <>
      <Modal
        show={open}
        onHide={onCloseModal}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton={onCloseModal}>
          <Modal.Title>Create a New Audit</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <nav className="nav nav-pills nav-justified px-5" style={{ fontSize: "20px" }}>
            <a className={`nav-link ${formStep == 1 ? "active" : ""}`} href="#" onClick={() => {
              setFormStep(1)
            }}>Step 1</a>
            <a className={`nav-link ${formStep == 2 ? "active" : ""}`} href="#" onClick={() => {
              setFormStep(2)
            }}>Step 2</a>
            <a className={`nav-link ${formStep == 3 ? "active" : ""}`} href="#" onClick={() => {
              setFormStep(3)
            }}>Step 3</a>
          </nav>
          <div className="container mt-3" style={{ color: "#000000 !important" }}>
            <div className="text-center">
              {formStep == 1 ?
                <h2>Audit Details</h2> :
                formStep == 2 ?
                  <h2>Group Details</h2> :
                  formStep == 3 ?
                    <h2>Assign Audit</h2> : ""
              }
            </div>
            {isNewCustomFieldAdded && (
              <div className='notification icon inline success fs-16'>
                <Check size={8} className='text-white'></Check>{" "}
                <FormattedMessage id='TITLE.NEW.AUDIT.HAS.BEEN.SUCCESSFULLY.CREATED' />
              </div>
            )}
            <Form>
              {formStep == 1 ?
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="TITLE.AUDIT.NAME">
                      <Form.Label>
                        <FormattedMessage id="TITLE.AUDIT.NAME" />
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="audit_name"
                        value={formikAddEditAudit.values.audit_name}
                        onChange={(e) => formikAddEditAudit.setFieldValue('audit_name', e.target.value.trimStart())}
                        placeholder={intl.formatMessage({
                          id: "TITLE.AUDIT.NAME",
                        })}
                      />
                      {formikAddEditAudit.touched.audit_name &&
                        formikAddEditAudit.errors.audit_name ? (
                        <div className="text-danger mt-2">
                          {formikAddEditAudit.errors.audit_name}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group controlId="TITLE.AUDIT.METHOD">
                      <Form.Label>
                        <FormattedMessage id="TITLE.AUDIT.METHOD" />
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Select
                        options={AuditMethodTypeList}
                        value={AuditMethodTypeList.find((x) => x.value == formikAddEditAudit.values.audit_methods) ?? []}
                        onChange={(e) => formikAddEditAudit.setFieldValue('audit_methods', e.value)}
                      />
                      {formikAddEditAudit.touched.audit_methods &&
                        formikAddEditAudit.errors.audit_methods ? (
                        <div className="text-danger mt-2">
                          {formikAddEditAudit.errors.audit_methods}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="TITLE.AUDIT.START.DATE">
                      <Form.Label>
                        <FormattedMessage id="TITLE.AUDIT.START.DATE" />
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <DatePicker
                        selected={formikAddEditAudit.values.start_date}
                        onChange={(date) =>
                          formikAddEditAudit.setFieldValue("start_date", date)
                        }
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        className="form-control"
                        name="start_date"
                        minDate={new Date()}
                      />
                      {formikAddEditAudit.touched.start_date &&
                        formikAddEditAudit.errors.start_date ? (
                        <div className="text-danger mt-2">
                          {formikAddEditAudit.errors.start_date}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="TITLE.AUDIT.DUE.DATE">
                      <Form.Label>
                        <FormattedMessage id="TITLE.AUDIT.DUE.DATE" />
                      </Form.Label>
                      <DatePicker
                        selected={formikAddEditAudit.values.end_date}
                        onChange={(date) =>
                          formikAddEditAudit.setFieldValue("end_date", date)
                        }
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        className="form-control"
                        name="end_date"
                        minDate={formikAddEditAudit.values.start_date}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group controlId="formBasicCheckbox1" className="mt-3">
                      <Form.Check
                        type="checkbox"
                        name="allow_scanning_after_due_date"
                        label="Allow Scanning after due date"
                        checked={formikAddEditAudit.values.allow_scanning_after_due_date}
                        onChange={(e) => {
                          formikAddEditAudit.setFieldValue("allow_scanning_after_due_date", e.target.checked);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <div style={borderRounded}>
                      <Form.Group controlId="TITLE.AUDIT.RECURRING">
                        <Form.Label className="d-flex justify-content-between">
                          <FormattedMessage id="TITLE.AUDIT.RECURRING" />
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label=""
                            checked={formikAddEditAudit.values.recurring}
                            onChange={(e) => {
                              formikAddEditAudit.setFieldValue("recurring", e.target.checked);
                              formikAddEditAudit.setFieldValue("repeats_number", e.target.checked ? 1 : 0);
                              formikAddEditAudit.setFieldValue("repeats_number_type", e.target.checked ? 1 : 0);
                              formikAddEditAudit.setFieldValue("stop", false);
                              formikAddEditAudit.setFieldValue("stop_number", 0);
                              formikAddEditAudit.setFieldValue("audit_will_appear", 0);
                            }}
                          />
                        </Form.Label>
                        <hr></hr>
                      </Form.Group>
                      {formikAddEditAudit.values.recurring ?
                        <div>
                          <p>Repeats every {formikAddEditAudit.values.repeats_number || ""} {RepeatsNumberTypeList.find((x) => x.value == formikAddEditAudit.values.repeats_number_type)?.label ?? "Month"}</p>
                          <Row>
                            <Col md={6}>
                              <Form.Control
                                type="number"
                                name="repeats_number"
                                value={formikAddEditAudit.values.repeats_number}
                                onChange={(e) => {
                                  formikAddEditAudit.setFieldValue("repeats_number", e.target.value.trimStart());
                                }}
                              />
                            </Col>
                            <Col md={6}>
                              <Select
                                className="py-1"
                                options={RepeatsNumberTypeList}
                                onChange={(e) => {
                                  formikAddEditAudit.setFieldValue("repeats_number_type", e.value);
                                }}
                                value={RepeatsNumberTypeList.find((x) => x.value == formikAddEditAudit.values.repeats_number_type) ?? []}
                              />
                            </Col>
                            <Col md={3}></Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={12} className={"my-2"}>
                              <span>Stops</span>
                            </Col>
                            <Col md={6}>
                              <Form>
                                <Form.Group>
                                  <Form.Check
                                    type="radio"
                                    id="radioNever"
                                    name="radioGroup"
                                    label="Never"
                                    checked={formikAddEditAudit.values.stop === false}
                                    onChange={(e) => {
                                      formikAddEditAudit.setFieldValue("stop", false)
                                      formikAddEditAudit.setFieldValue("stop_number", 0)
                                    }}
                                  />
                                </Form.Group>
                                <Form.Group className="d-flex align-items-center">
                                  <Form.Check
                                    type="radio"
                                    id="radioAfter"
                                    name="radioGroup"
                                    label="After"
                                    checked={formikAddEditAudit.values.stop === true}
                                    onChange={(e) => formikAddEditAudit.setFieldValue("stop", true)}
                                    className="mr-3"
                                  />
                                  <Form.Control
                                    type="number"
                                    name="stop_number"
                                    value={formikAddEditAudit.values.stop_number}
                                    onChange={(e) => {
                                      formikAddEditAudit.setFieldValue("stop_number", e.target.value.trimStart())
                                    }}
                                    placeholder={intl.formatMessage({
                                      id: "TITLE.AUDIT.NAME",
                                    })}
                                  />
                                </Form.Group>
                              </Form>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={12} className={"my-2"}>
                              <span>Audit will appear</span>
                            </Col>
                            <Col md={4}>
                              <Form.Control
                                type="number"
                                value={formikAddEditAudit.values.audit_will_appear}
                                onChange={(e) => {
                                  formikAddEditAudit.setFieldValue('audit_will_appear', e.target.value.trimStart())
                                }}
                              />
                            </Col>
                            <Col md={8}>
                              <span>days before start date</span>
                            </Col>
                          </Row>
                        </div>
                        :
                        <p> Does not repeat</p>
                      }
                    </div>
                  </Col>
                  <Col md={12}>
                    <Form.Group controlId="formBasicCheckbox1" className="mt-3">
                      <Form.Check
                        type="checkbox"
                        name="record_gps_location"
                        label="Record GPS Location"
                        checked={formikAddEditAudit.values.record_gps_location}
                        onChange={(e) => {
                          formikAddEditAudit.setFieldValue("record_gps_location", e.target.checked);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group controlId="formBasicCheckbox2" className="mt-3">
                      <Form.Check
                        type="checkbox"
                        name="allow_scanning_after_due_date"
                        label="Allow Scanning after due date"
                        checked={formikAddEditAudit.values.show_asset_details_after_each_scan}
                        onChange={(e) => {
                          formikAddEditAudit.setFieldValue("show_asset_details_after_each_scan", e.target.checked);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row> :
                formStep == 2 ?
                  <>
                    <Row>
                      <Col md={12}>
                        <Form.Group controlId="TITLE.AUDIT.STEP1.1.GROUP">
                          <Form.Label>
                            <FormattedMessage id="TITLE.AUDIT.STEP1.1.GROUP" />
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Select
                            placeholder={intl.formatMessage({
                              id: "TITLE.AUDIT.STEP1.1.SELECT.GROUP",
                            })}
                            options={groupTypeList}
                            onChange={(e) => {
                              formikAddEditAudit.setFieldValue('audit_group', e.value)
                            }}
                            value={groupTypeList.find((x) => x.value == formikAddEditAudit.values.audit_group) ?? []}
                          />
                          {formikAddEditAudit.touched.audit_group &&
                            formikAddEditAudit.errors.audit_group ? (
                            <div className="text-danger mt-2">
                              {formikAddEditAudit.errors.audit_group}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group controlId="TITLE.AUDIT.STEP1.1.DISPLAY.FIELD">
                          <Form.Label>
                            <FormattedMessage id="TITLE.AUDIT.STEP1.1.DISPLAY.FIELD" />
                          </Form.Label>
                          <Select
                            options={[]}
                          />

                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group controlId="TITLE.AUDIT.STEP1.1.FILTER">
                          <Form.Label>
                            <FormattedMessage id="TITLE.AUDIT.STEP1.1.FILTER" />
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Select
                            placeholder={intl.formatMessage({
                              id: "TITLE.AUDIT.STEP1.1.SELECT.FILTER",
                            })}
                            options={categoryList}
                            onChange={(e) => {
                              formikAddEditAudit.setFieldValue('audit_filter_field', e.value)
                            }}
                            value={categoryList.find((x) => x.value == formikAddEditAudit.values.audit_filter_field) ?? []}
                          />
                          {formikAddEditAudit.touched.audit_filter_field &&
                            formikAddEditAudit.errors.audit_filter_field ? (
                            <div className="text-danger mt-2">
                              {formikAddEditAudit.errors.audit_filter_field}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <h4>
                          <FormattedMessage id="TITLE.AUDIT.STEP1.1.AUTOMATICALLY.UPDATE.FIELDS" />
                        </h4>
                        <Form.Group controlId="TITLE.AUDIT.STEP1.1.1.AUTOMATICALLY.UPDATE.FIELDS">
                          <Form.Label className="d-flex justify-content-between">
                            <FormattedMessage id="TITLE.AUDIT.STEP1.1.1.AUTOMATICALLY.UPDATE.FIELDS" />
                            <Form.Check
                              type="switch"
                              id="automatically_update_field"
                              label=""
                              checked={formikAddEditAudit.values.automatically_update_field}
                              onChange={(e) => {
                                formikAddEditAudit.setFieldValue('automatically_update_field', e.target.checked)
                              }}
                            />
                          </Form.Label>
                          <Select
                            options={[]}
                            onChange={(e) => {
                            }}
                            value={''}
                          />
                          <hr></hr>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <h4>
                          <FormattedMessage id="TITLE.AUDIT.STEP1.1.ALLOW.USERS.TO.EDIT.UDPATE.FIELDS" />
                        </h4>
                        <Form.Group controlId="TITLE.AUDIT.STEP1.1.1.AUTOMATICALLY.UPDATE.FIELDS">
                          <Form.Label className="d-flex justify-content-between">
                            <FormattedMessage id="TITLE.AUDIT.STEP1.1.1.AUTOMATICALLY.UPDATE.FIELDS" />
                            <Form.Check
                              type="switch"
                              id="allow_users_to_update_field"
                              label=""
                              checked={formikAddEditAudit.values.allow_users_to_update_field}
                              onChange={(e) => {
                                formikAddEditAudit.setFieldValue('allow_users_to_update_field', e.target.checked)
                              }}
                            />
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </> :
                  formStep == 3 ?
                    <>
                      <Row>
                        <Col md={8}>
                          <h4>
                            <FormattedMessage id="TITLE.AUDIT.STEP1.2.INDIVIDUAL.AUDIT" />
                          </h4>
                          <Form.Group controlId="TITLE.AUDIT.STEP1.2.INDIVIDUAL.AUDIT">
                            <Form.Label className="d-flex justify-content-between">
                              <FormattedMessage id="TITLE.AUDIT.STEP1.2.EACH.USER.HAS.TO.FINISH.THEIR.PART.OF.THE.AUDIT" />
                              <Form.Check
                                type="switch"
                                id="individual_audit"
                                checked={formikAddEditAudit.values.individual_audit}
                                onChange={(e) => {
                                  formikAddEditAudit.setFieldValue('individual_audit', e.target.checked)
                                }}
                              />
                            </Form.Label>
                            <hr></hr>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}>
                          <p>
                            If Individual Audit is enabled, each user will see restricted
                            audit list based on their user permissions. The audit will be
                            finished when all users finish their portion of the audit.
                          </p>
                        </Col>
                      </Row>
                      <Row className="container">
                        <Col md={12} className="mb-4">
                          <ul className="nav nav-tabs d-flex justify-content-between">
                            <li className="nav-item">
                              <a className={`nav-link ${assignAuditFormStep == 1 ? "active" : ""}`} onClick={() => setAssignAuditFormStep(1)} href="#">Assign Users</a>
                            </li>
                            <li className="nav-item">
                              <a className={`nav-link ${assignAuditFormStep == 2 ? "active" : ""}`} onClick={() => setAssignAuditFormStep(2)} href="#">Assign Templates</a>
                            </li>
                            <li className="nav-item">
                              <a className={`nav-link ${assignAuditFormStep == 3 ? "active" : ""}`} onClick={() => setAssignAuditFormStep(3)} href="#">Assign Emails</a>
                            </li>
                          </ul>
                        </Col>
                        {assignAuditFormStep == 1 ?
                          <Col md={8}>
                            <div>
                              <Form.Group controlId="TITLE.AUDIT.STEP1.2.SEARCH">
                                <Form.Control
                                  type="text"
                                  name="audit_name"
                                  placeholder={intl.formatMessage({
                                    id: "TITLE.AUDIT.STEP1.2.SEARCH",
                                  })}
                                />
                              </Form.Group>
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                  value={formikAddEditAudit.values.assign_users.select_all}
                                  onChange={(e) => {
                                    formikAddEditAudit.setFieldValue('assign_users.select_all', e.target.checked)
                                  }}>
                                  Select All
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                  value={formikAddEditAudit.values.assign_users.basic_user}
                                  onChange={(e) => {
                                    formikAddEditAudit.setFieldValue('assign_users.basic_user', e.target.checked)
                                  }}
                                >
                                  Basic User
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                  value={formikAddEditAudit.values.assign_users.company_admin}
                                  onChange={(e) => {
                                    formikAddEditAudit.setFieldValue('assign_users.company_admin', e.target.checked)
                                  }}
                                >
                                  Company Admin (MySelf)
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                  value={formikAddEditAudit.values.assign_users.tech_manager}
                                  onChange={(e) => {
                                    formikAddEditAudit.setFieldValue('assign_users.tech_manager', e.target.checked)
                                  }}
                                >
                                  Tech Manager
                                </label>
                              </div>
                            </div>
                          </Col> : assignAuditFormStep == 2 ?
                            <Col md={8}>
                              <div>
                                <Form.Group controlId="TITLE.AUDIT.STEP1.2.SEARCH">
                                  <Form.Control
                                    type="text"
                                    name="audit_name"
                                    placeholder={intl.formatMessage({
                                      id: "TITLE.AUDIT.STEP1.2.SEARCH",
                                    })}
                                  />
                                </Form.Group>
                                <div className="form-check mb-2">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                    value={formikAddEditAudit.values.assign_templates.select_all}
                                    onChange={(e) => {
                                      formikAddEditAudit.setFieldValue('assign_templates.select_all', e.target.checked)
                                    }}
                                  >
                                    Select All
                                  </label>
                                </div>
                                <div className="form-check mb-2">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                    value={formikAddEditAudit.values.assign_templates.basic}
                                    onChange={(e) => {
                                      formikAddEditAudit.setFieldValue('assign_templates.basic', e.target.checked)
                                    }}
                                  >
                                    Basic
                                  </label>
                                </div>
                                <div className="form-check mb-2">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                    value={formikAddEditAudit.values.assign_templates.manager}
                                    onChange={(e) => {
                                      formikAddEditAudit.setFieldValue('assign_templates.manager', e.target.checked)
                                    }}
                                  >
                                    Manager
                                  </label>
                                </div>
                              </div>
                            </Col> : assignAuditFormStep == 3 ?
                              <Col md={8}>
                                <div>
                                  <Form.Group controlId="TITLE.AUDIT.STEP1.2.SEARCH">
                                    <Form.Control
                                      type="text"
                                      name="audit_name"
                                      placeholder={intl.formatMessage({
                                        id: "TITLE.AUDIT.STEP1.2.SEARCH",
                                      })}
                                    />
                                  </Form.Group>
                                  <div className="form-check mb-2">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                      value={formikAddEditAudit.values.assign_emails.select_all}
                                      onChange={(e) => {
                                        formikAddEditAudit.setFieldValue('assign_emails.select_all', e.target.checked)
                                      }}
                                    >
                                      Select All
                                    </label>
                                  </div>
                                  <div className="form-check mb-2">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                      value={formikAddEditAudit.values.assign_emails.basic_user}
                                      onChange={(e) => {
                                        formikAddEditAudit.setFieldValue('assign_emails.basic_user', e.target.checked)
                                      }}
                                    >
                                      Basic User
                                    </label>
                                  </div>
                                  <div className="form-check mb-2">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                      value={formikAddEditAudit.values.assign_emails.company_admin}
                                      onChange={(e) => {
                                        formikAddEditAudit.setFieldValue('assign_emails.company_admin', e.target.checked)
                                      }}
                                    >
                                      Company Admin (MySelf)
                                    </label>
                                  </div>
                                  <div className="form-check mb-2">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                      value={formikAddEditAudit.values.assign_emails.tech_manager}
                                      onChange={(e) => {
                                        formikAddEditAudit.setFieldValue('assign_emails.tech_manager', e.target.checked)
                                      }}
                                    >
                                      Tech Manager
                                    </label>
                                  </div>
                                </div>
                              </Col> : ""}
                      </Row>
                    </>
                    : ""}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCloseModal}>
            Close
          </Button>
          <Button variant="primary" disabled={isLoading} onClick={() => formikAddEditAudit.handleSubmit()}>{isLoading ? "Please Wait..." : "Submit"}</Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={() => setIsErrorModalOpen(false)}
        />
      )}
    </>
  );
}
