/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { capitalizeFirstLetter } from "../constants/utils";

const CategoryNameFormatterForDeprecatedAssets = (
  cellContent,
  row,
  rowIndex
) => {
  const { product } = row;
  const { category } = product;
  const { name } = category;
  return capitalizeFirstLetter(name);
};

export default CategoryNameFormatterForDeprecatedAssets;
