import React, { useMemo } from "react";
import {
  Card,
  Button,
  OverlayTrigger,
  Dropdown,
  Tooltip
} from "react-bootstrap";
import { Calendar, Upload, Printer } from "react-feather";
import { InventoryAgingTable } from "./inventory-aging-table/InventoryAgingTable";
import { useInventoryAgingUIContext } from "./InventoryAgingUIContext";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { InventoryAgingFilter } from "./inventory-aging-filter/InventoryAgingFilter";
import { DD_MM_YYYY } from "../../../constants/const";
import Moment from "react-moment";
import { customRangeReport } from "../../../constants/utils";
import { FormattedMessage } from "react-intl";
import { useLang } from "../../../i18n";
import moment from "moment";

export function InventoryAgingCard({
  isLoading,
  isLoadingFilter,
  startDate,
  endDate,
  selectedDateOption,
  reportInventoryAging,
  reportInventoryAgingTotalCount,
  handleCLickOfFilterButtonFilter,
  handleCLickOfResetButtonFilter,
  handleClickOfDateFilterButton,
  handleExport,
  handlePrint,
  handleSelectedDateOption
}) {
  const selectedLang = useLang();
  const inventoryagingUIContext = useInventoryAgingUIContext();
  const inventoryagingUIProps = useMemo(() => {
    return {
      ids: inventoryagingUIContext.ids,
      queryParams: inventoryagingUIContext.queryParams,
      setQueryParams: inventoryagingUIContext.setQueryParams,
      openViewDialog: inventoryagingUIContext.openViewDialog,
      openEditDialog: inventoryagingUIContext.openEditDialog,
      openDeleteDialog: inventoryagingUIContext.openDeleteDialog
    };
  }, [inventoryagingUIContext]);

  const printTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.PRINT.REPORT' />
    </Tooltip>
  );

  const handleApplyDatePicker = (event, picker) => {
    const { chosenLabel } = picker || {};
    const fromDate = picker.startDate._d.toISOString();
    const endDate = picker.endDate._d.toISOString();
    handleSelectedDateOption(picker.chosenLabel);
    handleClickOfDateFilterButton(chosenLabel, fromDate, endDate);
  };

  return (
    <>
      <Card className='card-custom card-stretch make-payment-wrapper'>
        <Card.Header className='align-items-center d-flex justify-content-between small-header'>
          <div>
            <DateRangePicker
              onApply={handleApplyDatePicker}
              initialSettings={{
                startDate: moment().startOf("month"),
                endDate: moment().endOf("month"),
                ranges: customRangeReport,
                opens: selectedLang === "ar" ? "left" : "right",
                applyButtonClasses: "btn-secondary"
              }}
            >
              <Button
                variant='tertiary'
                className='btn-icon btn-sm fs-14 f-w-600'
              >
                <Calendar size={16} />
                {selectedDateOption}
              </Button>
            </DateRangePicker>
          </div>
          <div className='d-flex'>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={printTooltip}
            >
              <Button
                variant='outline-tertiary btn-sm fs-14 f-w-600 btn-icon mr-2'
                onClick={handlePrint}
              >
                <Printer size={16} className='p-0 mr-2' />
                <span className='d-none d-md-flex'>
                  {" "}
                  <FormattedMessage id='TITLE.PRINT' />
                </span>
              </Button>
            </OverlayTrigger>
            <Dropdown>
              <Dropdown.Toggle variant='dropdown-sm' id='dropdown-basic'>
                <span className='d-none d-md-flex'>
                  {" "}
                  <FormattedMessage id='TITLE.EXPORT.AS' />
                </span>
                <Upload size={16} className='p-0 mr-2 d-block d-md-none' />
              </Dropdown.Toggle>

              <Dropdown.Menu alignRight>
                <Dropdown.Item
                  onClick={() => {
                    handleExport("PDF");
                  }}
                >
                  <FormattedMessage id='TITLE.PDF' />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleExport("Excel");
                  }}
                >
                  <FormattedMessage id='TITLE.XLSX.MICROSOFT.EXCEL' />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleExport("CSV");
                  }}
                >
                  <FormattedMessage id='TITLE.CSV.COMMA.SEPARATED.VALUE' />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Header>
        <Card.Body>
          <div className='text-center pt-2 pb-5'>
            <h3 className='page-title'>
              <FormattedMessage id='TITLE.ASSETS.AGING.SUMMARY' />
            </h3>
            <h6 className='fs-14 text-black'>
              <span className='mr-1'>
                <FormattedMessage id='TITLE.FROM' />
              </span>
              <span className='mr-1'>
                <Moment date={startDate} format={DD_MM_YYYY} />
              </span>
              <span className='mr-1'>
                <FormattedMessage id='TITLE.TO' />
              </span>
              <span className='mr-1'>
                <Moment date={endDate} format={DD_MM_YYYY} />
              </span>
            </h6>
          </div>
          <InventoryAgingFilter
            isLoadingFilter={isLoadingFilter}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            handleCLickOfResetButtonFilter={handleCLickOfResetButtonFilter}
          />
          <InventoryAgingTable
            isLoading={isLoading}
            reportInventoryAging={reportInventoryAging}
            reportInventoryAgingTotalCount={reportInventoryAgingTotalCount}
          />
        </Card.Body>
      </Card>
    </>
  );
}
