import React, { useEffect, useState } from "react";
import { InventoryAgingCard } from "./InventoryAgingCard";
import { InventoryAgingUIProvider } from "./InventoryAgingUIContext";
import { initialFilter } from "./InventoryAgingUIHelpers";
import { ErrorModal } from "../../common/modals/ErrorModal";
import {
  STR_CSV,
  STR_EXCEL,
  STR_PDF,
  YYYY_MM_DD
} from "../../../constants/const";
import {
  getErrorMessageBasedOnLanguage,
  stringToDateWithFormat,
  unauthorization
} from "../../../constants/utils";
import moment from "moment";
import {
  reportInventoryAgingApi,
  reportInventoryAgingExportApi,
  reportInventoryAgingPrintApi
} from "../../../axios/services/services";

export function InventoryAgingPage({ history }) {
  const [reportInventoryAging, setReportInventoryAging] = useState([]);
  const [reportInventoryAgingTotalCount, setReportInventoryAgingTotalCount] =
    useState(0);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDateOption, setselectedDateOption] = useState("This Month");

  async function getReportInventoryAging() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const reportInventoryAgingResponse = await reportInventoryAgingApi({
      queryParams
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, startdate, enddate, count } =
      reportInventoryAgingResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setReportInventoryAging(data ? data : []);
    setStartDate(startdate);
    setEndDate(enddate);
    setReportInventoryAgingTotalCount(count);
  }

  useEffect(() => {
    getReportInventoryAging();
  }, [queryParams]);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const inventoryagingUIEvents = {
    openViewDialog: (row) => {},
    openEditDialog: (row) => {},
    openDeleteDialog: (row) => {}
  };

  async function getReportInventoryAgingExport(docType) {
    let extention = "";
    if (docType === "Excel") {
      extention = STR_EXCEL;
    }
    if (docType === "PDF") {
      extention = STR_PDF;
    }
    if (docType === "CSV") {
      extention = STR_CSV;
    }
    const reportInventoryAgingResponse = await reportInventoryAgingExportApi({
      queryParams,
      docType,
      filename: "inventory-aging" + new Date(),
      extention: extention
    });

    const { Error } = reportInventoryAgingResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
  }

  async function getReportInventoryAgingPrint() {
    const reportInventoryAgingResponse = await reportInventoryAgingPrintApi({
      queryParams
    });

    const { Error } = reportInventoryAgingResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    var fils = new Blob([reportInventoryAgingResponse.res.data], {
      type: "application/pdf"
    });
    var fileURLs = URL.createObjectURL(fils);
    setPdfUrl(fileURLs);
    setTimeout(() => {
      const iframe = document.getElementById("myframe");
      const iframeWindow = iframe.contentWindow || iframe;
      iframe.focus();
      iframeWindow.print();
    }, 50);
  }

  const handleCLickOfFilterButtonFilter = async () => {
    setFilter(true);
  };

  const handleCLickOfResetButtonFilter = () => {
    setselectedDateOption("This Month");
  };

  const handleClickOfDateFilterButton = (chosenLabel, fromDate, endDate) => {
    let label = chosenLabel;
    label = chosenLabel.toLowerCase();
    if (label === "last 7 days") {
      label = "lastweek";
    }
    if (label === "last 30 days") {
      label = "last30days";
    }
    if (label === "this month") {
      label = "thismonth";
    }
    if (label === "last month") {
      label = "lastmonth";
    }
    if (label === "last year") {
      label = "lastyear";
    }
    if (label === "custom range") {
      label = "customrange";
    }
    const dateFrom = stringToDateWithFormat(fromDate);
    const dateEnd = stringToDateWithFormat(endDate);
    const dateFromMoment = moment(dateFrom).format(YYYY_MM_DD);
    const dateEndMoment = moment(dateEnd).format(YYYY_MM_DD);

    const newQueryParams = { ...queryParams };
    const { filter } = newQueryParams || {};
    filter.date = label;
    filter.fromdate = dateFromMoment;
    filter.todate = dateEndMoment;
    newQueryParams.pageNumber = 1;
    setQueryParamsBase(newQueryParams);
  };

  // Excel, PDF, CSV
  const handleExport = (docType) => {
    getReportInventoryAgingExport(docType);
  };

  const handlePrint = () => {
    getReportInventoryAgingPrint();
  };

  const handleSelectedDateOption = (selectedDateOption) => {
    setselectedDateOption(selectedDateOption);
  };

  return (
    <div>
      <>
        <InventoryAgingUIProvider
          inventoryagingUIEvents={inventoryagingUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <InventoryAgingCard
            isLoading={isLoading}
            isLoadingFilter={isLoadingFilter}
            startDate={startDate}
            endDate={endDate}
            selectedDateOption={selectedDateOption}
            reportInventoryAging={reportInventoryAging}
            reportInventoryAgingTotalCount={reportInventoryAgingTotalCount}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            handleCLickOfResetButtonFilter={handleCLickOfResetButtonFilter}
            handleClickOfDateFilterButton={handleClickOfDateFilterButton}
            handleExport={handleExport}
            handlePrint={handlePrint}
            handleSelectedDateOption={handleSelectedDateOption}
          />
        </InventoryAgingUIProvider>

        {isErrorModalOpen && (
          <ErrorModal
            message={errorMessage}
            isErrorModalOpen={isErrorModalOpen}
            hideErrorModal={handleErrorModalHide}
          />
        )}

        <iframe
          className='d-none'
          src={pdfUrl}
          name='myframe'
          id='myframe'
          title='myframe'
        ></iframe>
      </>
    </div>
  );
}
