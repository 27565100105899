import React, { useContext, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { Box, ShoppingBag, Tag, Trello, Users } from "react-feather";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { USER_DETAILS } from "../../constants/const";
import { MENUITEMS } from "../../constants/menu";
import { setActiveMenu } from "../../constants/utils";
import { UIContext } from "../../helper/UIContext";

const getUserDetails = () => {
  const userDetailsString = localStorage.getItem(USER_DETAILS);
  return userDetailsString ? JSON.parse(userDetailsString) : {};
};

function DashboardStatistics({
  noOfAssets,
  noOfProducts,
  noOfCategories,
  noOfCustomers,
  noOfVendors
}) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const userDetails = getUserDetails();
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const handleCardClick = (routePath) => {
    onClickChangeRoute(routePath);
    window.scrollTo(0, 0);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Row>
        {[
          {
            label: "TITLE.NO.OF.ASSETS",
            icon: <Trello className='font-white' />,
            count: noOfAssets,
            route: "/assets/list"
          },
          {
            label: "TITLE.NO.OF.PRODUCTS",
            icon: <Box className='font-white' />,
            count: noOfProducts,
            route: "/inventoryproducts/products"
          },
          {
            label: "TITLE.NO.OF.CATEGORIES",
            icon: <Tag className='font-white' size={20} />,
            count: noOfCategories,
            route: "/master/categories/category-listing"
          },
          {
            label: "TITLE.NO.OF.EMPLOYEES",
            icon: <Users className='font-white' size={20} />,
            count: noOfCustomers,
            route: "/customers/customer-listing"
          },
          {
            label: "TITLE.NO.OF.VENDORS",
            icon: <ShoppingBag className='font-white' size={30} />,
            count: noOfVendors,
            route: "/vendors/vendor-listing"
          }
        ].map((item, index) => (
          <Col
            key={index}
            lg={2}
            md={3}
            className='col-6 cursor-pointer'
            onClick={() => handleCardClick(item.route)}
          >
            <Card className='statistic'>
              <Card.Body className='bg-white text-center'>
                <div className='icons-widgets d-flex justify-content-center mb-3 '>
                  <div
                    className={`align-self-center text-center bg-${index % 2 === 0 ? "primary" : "info"}`}
                  >
                    {item.icon}
                  </div>
                </div>
                <h3 className='mb-0'>
                  <CountUp
                    className='counter font-weight-bold'
                    end={item.count}
                  />
                </h3>
                <span className='m-0 small-text text-muted'>
                  <FormattedMessage id={item.label} />
                </span>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default DashboardStatistics;
