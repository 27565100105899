/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Badge } from "react-bootstrap";
import { Check, X } from "react-feather";
import { getStatusPOClass } from "../constants/utils";
const POStatusTypeFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    openEditStatusDialog,
    openAgreeStatusDialog,
    openDisagreeStatusDialog,
    userRole
  }
) => {
  const { purchaseorderstatus } = row;
  const { name: status } = purchaseorderstatus || "";

  const renderStatusName = () => {
    return (
      <span
        className={`${getStatusPOClass(
          status
        )} status-color text-capitalize cursor-pointer`}
        onClick={() => {
          openEditStatusDialog(row);
        }}
      >
        {status ? status : "-"}
      </span>
    );
  };

  const renderAgreeDisagree = () => {
    return (
      <span className='d-flex'>
        <Badge
          onClick={() => {
            openAgreeStatusDialog(row);
          }}
          className='d-flex align-items-center justify-content-center bg-success mr-1 cursor-pointer'
          style={{ width: "28px", height: "28px" }}
          bg='success'
        >
          <Check size={14} className='text-white' />
        </Badge>

        <Badge
          onClick={() => {
            openDisagreeStatusDialog(row);
          }}
          className='d-flex align-items-center justify-content-center bg-danger ml-1 cursor-pointer'
          style={{ width: "28px", height: "28px" }}
          bg='danger'
        >
          <X size={14} className='text-white' />
        </Badge>
      </span>
    );
  };

  const renderStatus = () => {
    if (userRole === "Asset Manager" && status === "Waiting For Approval") {
      return renderAgreeDisagree();
    } else {
      return renderStatusName();
    }
  };

  return renderStatus();
};

export default POStatusTypeFormatter;
