import React, { useContext, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Plus } from "react-feather";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { DD_MM_YYYY } from "../../constants/const";
import {
  amountFormatter,
  getStatusPOClass,
  setActiveMenu
} from "../../constants/utils";
import { SectionLoader } from "../../helper/SectionLoader";
import { FormattedMessage } from "react-intl";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";

export function ViewPODetailsListView({
  id,
  POList,
  isLoadingPurchaseOrderListView,
  isLoadingEditHistory,
  isLoadMorePurchaseOrderListView,
  handlePaginationOfPurchaseOrderListView,
  handleClickOfPOList
}) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const CreateNewPO = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.CREATE.NEW.PURCHASE.ORDER' />{" "}
    </Tooltip>
  );

  const renderContactName = (purchaseOrder) => {
    const { contact } = purchaseOrder || {};
    const { contact_name } = contact || {};
    return <>{contact_name === null ? "-" : contact_name}</>;
  };

  const renderDCNumber = (purchaseOrder) => {
    const { purchaseorder_number } = purchaseOrder || {};
    return <>{purchaseorder_number === null ? "-" : purchaseorder_number}</>;
  };

  const renderDCDate = (purchaseOrder) => {
    const { date } = purchaseOrder || {};
    return (
      <>{date === null ? "-" : <Moment date={date} format={DD_MM_YYYY} />}</>
    );
  };

  const renderDCAmount = (purchaseOrder) => {
    const { totalamount } = purchaseOrder || {};
    return <>{amountFormatter(totalamount)}</>;
  };

  const renderDCStatus = (purchaseOrder) => {
    const { purchaseorderstatus } = purchaseOrder || {};
    const { name } = purchaseorderstatus || {};
    return (
      <>
        {name === null ? (
          "-"
        ) : (
          <span
            className={`${getStatusPOClass(
              name
            )} status-color text-capitalize py-1 px-2`}
          >
            {name}
          </span>
        )}
      </>
    );
  };

  const getSecondaryColorClass = (purchaseOrder) => {
    const { id: PCID } = purchaseOrder;
    if (PCID === id) {
      return "text-secondary";
    } else {
      return "text-dark";
    }
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <div className='column list-column border-right d-none d-lg-block'>
        <div className='column-header d-flex align-items-center justify-content-between'>
          <h2 className='modal-title'>
            {" "}
            <FormattedMessage id='TITLE.PURCHASE.ORDERS' />
          </h2>
          <OverlayTrigger
            placement='top'
            delay={{ show: 250, hide: 400 }}
            overlay={CreateNewPO}
          >
            <Button
              variant='primary btn-inventory btn-icon'
              size='sm'
              onClick={() => {
                const path = "/inwards/add-inward";
                onClickChangeRoute(path);
              }}
            >
              <Plus size={14} className='text-secondary'></Plus>{" "}
              <FormattedMessage id='TITLE.NEW' />
            </Button>
          </OverlayTrigger>
        </div>
        {(isLoadingPurchaseOrderListView || isLoadingEditHistory) && (
          <SectionLoader />
        )}
        <div id='scrollableDiv' className='column-body custom-scrollbar'>
          {!isLoadingPurchaseOrderListView && (
            <InfiniteScroll
              dataLength={POList.length}
              scrollableTarget='scrollableDiv'
              next={() => {
                handlePaginationOfPurchaseOrderListView();
              }}
              hasMore={isLoadMorePurchaseOrderListView}
              loader={
                <h4 style={{ textAlign: "center" }} className='p-1'>
                  <FormattedMessage id='TITLE.LOADING' />
                  ...
                </h4>
              }
            >
              {POList.map((purchaseOrder, index) => (
                <div
                  className='column-box cursor-pointer'
                  key={index}
                  onClick={() => {
                    handleClickOfPOList();
                    const path = `/inwards/view-po-details/${purchaseOrder.id}`;
                    onClickChangeRoute(path);
                  }}
                >
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex flex-column'>
                      <h5
                        className={`font-weight-bold mb-1 cursor-pointer ${getSecondaryColorClass(
                          purchaseOrder
                        )}`}
                      >
                        {renderContactName(purchaseOrder)}
                      </h5>
                      <div>
                        <span className='text-primary small-text border-right pr-2'>
                          {renderDCNumber(purchaseOrder)}
                        </span>
                        <span className='small-text pl-2 text-dark'>
                          {renderDCDate(purchaseOrder)}
                        </span>
                      </div>
                    </div>
                    <div className='d-flex flex-column align-items-end'>
                      <h5 className='text-dark fs-14 text-dark'>
                        {renderDCAmount(purchaseOrder)}
                      </h5>
                      <div className='d-flex justify-content-end'>
                        {renderDCStatus(purchaseOrder)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewPODetailsListView;
