// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../ProductUnitUIHelpers";
import { useProductUnitUIContext } from "../ProductUnitUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import StatusTypeFormatter from "../../../columnformatter/StatusTypeFormatter";
import CategorynameFormatter from "../../../columnformatter/CategoryNameFormatter";
import { FormattedMessage } from "react-intl";

export function ProductUnitTable({
  isLoading,
  productUnitList,
  productUnitListTotalCount,
  currentPage,
  setCurrentPage
}) {
  // ProductUnit UI Context
  const productUnitUIContext = useProductUnitUIContext();
  const productUnitUIProps = useMemo(() => {
    return {
      ids: productUnitUIContext.ids,
      setIds: productUnitUIContext.setIds,
      queryParams: productUnitUIContext.queryParams,
      setQueryParams: productUnitUIContext.setQueryParams,
      openViewDialog: productUnitUIContext.openViewDialog,
      openEditDialog: productUnitUIContext.openEditDialog,
      openDeleteDialog: productUnitUIContext.openDeleteDialog
    };
  }, [productUnitUIContext]);

  // Table columns
  const columns = [
    // {
    //   dataField: "productUnitNumber",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,

    //   style: {
    //     minWidth: "150px",
    //   },
    //   formatter: HyperLinkColumnFormatter,
    // },
    {
      dataField: "name",
      text: <FormattedMessage id='TITLE.UNIT.NAME' />,
      sort: true,
      style: {
        minWidth: "170px"
      },
      sortCaret: sortCaret,
      formatter: CategorynameFormatter
    },
    {
      dataField: "status",
      text: <FormattedMessage id='TITLE.STATUS' />,
      sort: true,
      sortCaret: sortCaret,
      // style: {
      //   minWidth: "100px",
      // },
      formatter: StatusTypeFormatter
    },
    {
      dataField: "action",
      text: <FormattedMessage id='TITLE.ACTIONS' />,
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: productUnitUIProps.openViewDialog,
        openEditDialog: productUnitUIProps.openEditDialog,
        openDeleteDialog: productUnitUIProps.openDeleteDialog,
        isVisibleViewButton: false
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px"
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: productUnitListTotalCount ? productUnitListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productUnitUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-head-custom table-head-bg table-borderless table-vertical-center productUnit-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={productUnitList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  productUnitUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={productUnitList}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
