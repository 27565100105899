// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../DeprecatedAssetsUIHelpers";
import { useDeprecatedAssetsUIContext } from "../DeprecatedAssetsUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../../helper/helper";
import { Pagination } from "../../../../pagination/Pagination";
import AmountColumnFormatter from "../../../../columnformatter/AmountColumnFormatter";
import { useIntl } from "react-intl";
import AssetNameWithImageColumnFormatter from "../../../../columnformatter/assetsColumnFormatters/AssetNameWithImageColumnFormatter";
import DeprecationValueFormatter from "../../../../columnformatter/DeprecationValueFormatter";
import CategoryNameFormatterForDeprecatedAssets from "../../../../columnformatter/CategoryNameFormatterForDeprecatedAssets";
import AmountColumnFormatterForDeprecatedAssets from "../../../../columnformatter/AmountColumnFormatterForDeprecatedAssets";

export function DeprecatedAssetsTable({
  isLoading,
  reportDeprecatedAssets,
  reportDeprecatedAssetsTotalCount,
  reportDeprecatedAssetsTotalAmount
}) {
  const intl = useIntl();

  // DeprecatedAssets UI Context
  const deprecatedassetsUIContext = useDeprecatedAssetsUIContext();
  const deprecatedassetsUIProps = useMemo(() => {
    return {
      ids: deprecatedassetsUIContext.ids,
      setIds: deprecatedassetsUIContext.setIds,
      queryParams: deprecatedassetsUIContext.queryParams,
      setQueryParams: deprecatedassetsUIContext.setQueryParams,
      openViewDialog: deprecatedassetsUIContext.openViewDialog,
      openEditDialog: deprecatedassetsUIContext.openEditDialog,
      openDeleteDialog: deprecatedassetsUIContext.openDeleteDialog
    };
  }, [deprecatedassetsUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "product_name",
      text: intl.formatMessage({
        id: "TITLE.PRODUCT.NAME"
      }),
      style: {
        minWidth: "100px"
      },
      formatter: AssetNameWithImageColumnFormatter
    },
    {
      dataField: "deprecationValue",
      text: intl.formatMessage({
        id: "TITLE.DEPRECATED.VALUE"
      }),
      style: {
        minWidth: "100px"
      },
      formatter: DeprecationValueFormatter
    },
    {
      dataField: "categoryName",
      text: intl.formatMessage({
        id: "TITLE.CATEGORY.NAME"
      }),
      style: {
        minWidth: "100px"
      },
      formatter: CategoryNameFormatterForDeprecatedAssets
    },
    {
      dataField: "amount",
      text: intl.formatMessage({
        id: "TITLE.TOTAL.AMOUNT"
      }),
      style: {
        minWidth: "130px"
      },
      formatter: AmountColumnFormatterForDeprecatedAssets
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: reportDeprecatedAssetsTotalCount
      ? reportDeprecatedAssetsTotalCount
      : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: deprecatedassetsUIProps.queryParams.pageSize,
    page: deprecatedassetsUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-bordered table-vertical-center inward-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={reportDeprecatedAssets}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  deprecatedassetsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={reportDeprecatedAssets}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
