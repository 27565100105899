import React from "react";

const AssetAssignedToColumnFormatter = (cellContent, row, rowIndex) => {
  const contactName = row?.check_in_out_product_history?.employee?.contact_name;

  return (
    <>
      <div>{contactName || "-"}</div>
    </>
  );
};

export default AssetAssignedToColumnFormatter;
