import React from "react";

const WarningHeader = () => {
  return (
    <>
      <p
        className='text-white bg-danger w-100 mb-0'
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          padding: "5px 15px",
          zIndex: "999",
          fontSize: "12px"
        }}
      >
        Use Desktop version for better user experience
      </p>
    </>
  );
};

export default WarningHeader;
