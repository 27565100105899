import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Card,
  Container,
  InputGroup
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  changePasswordApi,
  editProfileApi,
  singleUserApi
} from "../../axios/services/services";
import { USER_DETAILS } from "../../constants/const";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  InitialCharacter,
  unauthorization
} from "../../constants/utils";
import { ButtonLoader } from "../../helper/ButtonLoader";
import {
  ChangePasswordSchema,
  EditProfileSchema
} from "../../helper/FormikSchema";
import { SectionLoader } from "../../helper/SectionLoader";
import { ErrorModal } from "../common/modals/ErrorModal";
import { SuccessModal } from "../common/modals/SuccessModal";
import { useIntl } from "react-intl";
import ErrorMessage from "../../helper/ErrorMessage";

let EditProfileInitialValues = {
  full_name: "",
  emp_id: "",
  email: "",
  cellnumber: ""
};

let ChangePasswordInitialValues = {
  currentPassword: "",
  password: "",
  confirmPassword: ""
};

const Profile = () => {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSingleUser, setLoadingSingleUser] = useState(false);
  const [isLoadingChangePassword, setLoadingChangePassword] = useState(false);
  const [singleUserDetails, setSingleUserDetails] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isUpdateUserDetails, setUpdateUserDetails] = useState(0);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));

  async function getSingleUserList() {
    enableLoadingSingleUser();
    const singleUserResponse = await singleUserApi({ id: userDetails.id });
    disableLoadingSingleUser();
    const { Error, data } = singleUserResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setSingleUserDetails(data ? data : []);
    localStorage.setItem(USER_DETAILS, JSON.stringify(data));
    const { full_name, emp_id, email, cellnumber, role } = data || {};
    const { name } = role || {};
    formikEditProfile.setFieldValue("full_name", full_name);
    formikEditProfile.setFieldValue("emp_id", emp_id);
    formikEditProfile.setFieldValue("email", email);
    formikEditProfile.setFieldValue("cellnumber", cellnumber);
    formikEditProfile.setFieldValue("role_id", name);
  }

  useEffect(() => {
    getSingleUserList();
  }, [isUpdateUserDetails]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingSingleUser = () => {
    setLoadingSingleUser(true);
  };

  const disableLoadingSingleUser = () => {
    setLoadingSingleUser(false);
  };

  const enableLoadingChangePassword = () => {
    setLoadingChangePassword(true);
  };

  const disableLoadingChangePassword = () => {
    setLoadingChangePassword(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  // const setPasswordViewShow = () => {
  //   setPasswordView(true);
  // };

  // const setPasswordViewHide = () => {
  //   setPasswordView(false);
  // };

  // const setConfirmPasswordViewShow = () => {
  //   setConfirmPasswordView(true);
  // };

  // const setConfirmPasswordViewHide = () => {
  //   setConfirmPasswordView(false);
  // };

  const formikEditProfile = useFormik({
    initialValues: EditProfileInitialValues,
    validationSchema: EditProfileSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const { full_name, emp_id, email, cellnumber } = values || {};
      let editProfileObj = {
        id: userDetails.id,
        full_name,
        emp_id,
        email,
        cellnumber
      };
      const editProfileResponse = await editProfileApi(editProfileObj);
      const { Error } = editProfileResponse || {};
      if (Error) {
        disableLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setSuccessMessage(
        intl.formatMessage({ id: "TITLE.USER.EDITED.SUCCESSFULLY" })
      );
      onOpenSuccessModal();
      setUpdateUserDetails(Math.random());
      disableLoading();
    },
    onReset: (values, { resetForm }) => {}
  });

  const formikChangePassword = useFormik({
    initialValues: ChangePasswordInitialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoadingChangePassword();
      const { currentPassword, password } = values || {};
      let changePasswordObj = {
        id: userDetails.id,
        currentPassword,
        password
      };
      const changePasswordResponse = await changePasswordApi(changePasswordObj);
      const { Error } = changePasswordResponse || {};
      if (Error) {
        disableLoadingChangePassword();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setSuccessMessage(
        intl.formatMessage({ id: "TITLE.PASSWORD.CHANGED.SUCCESSFULLY" })
      );
      onOpenSuccessModal();
      formikChangePassword.handleReset();
      disableLoadingChangePassword();
    },
    onReset: (values, { resetForm }) => {}
  });

  const { full_name = "", email = "" } = singleUserDetails || {};

  return (
    <>
      {isLoadingSingleUser && <SectionLoader />}
      <Container fluid={true}>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <div className='profile-details d-flex flex-column align-items-center text-center mb-4'>
                  <div className='profile-name bg-secondary'>
                    {InitialCharacter(full_name)}
                  </div>
                  <h5 className='f-w-600 f-16 mb-0'>
                    {capitalizeFirstLetter(full_name)}
                  </h5>
                  <span>{email}</span>
                </div>
                <hr />
                <Form className='mt-4' autoComplete='off'>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor='Username'>
                          <FormattedMessage id='TITLE.USER.NAME' />{" "}
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          id='full_name'
                          name='full_name'
                          placeholder={intl.formatMessage({
                            id: "TITLE.USER.NAME"
                          })}
                          {...formikEditProfile.getFieldProps("full_name")}
                        />
                        {formikEditProfile.touched.full_name &&
                        formikEditProfile.errors.full_name ? (
                          <div className='text-danger'>
                            <ErrorMessage
                              id={formikEditProfile.errors.full_name}
                            />
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor='employeeId'>
                          <FormattedMessage id='TITLE.EMPLOYEE.ID' />{" "}
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          disabled
                          type='text'
                          id='emp_id'
                          name='emp_id'
                          placeholder={intl.formatMessage({
                            id: "TITLE.EMPLOYEE.ID"
                          })}
                          {...formikEditProfile.getFieldProps("emp_id")}
                        />
                        {formikEditProfile.touched.emp_id &&
                        formikEditProfile.errors.emp_id ? (
                          <div className='text-danger'>
                            {formikEditProfile.errors.emp_id}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor='Categoryphone'>
                          <FormattedMessage id='TITLE.EMAIL' />
                        </Form.Label>
                        <Form.Control
                          type='text'
                          id='email'
                          name='email'
                          placeholder={intl.formatMessage({
                            id: "TITLE.EMAIL"
                          })}
                          {...formikEditProfile.getFieldProps("email")}
                        />
                        {formikEditProfile.touched.email &&
                        formikEditProfile.errors.email ? (
                          <div className='text-danger'>
                            {formikEditProfile.errors.email}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor='phoneNumber'>
                          <FormattedMessage id='TITLE.PHONE.NUMBER' />
                        </Form.Label>
                        <Form.Control
                          type='number'
                          id='cellnumber'
                          name='cellnumber'
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onPaste={(evt) => {
                            const values = evt.clipboardData.getData("text");
                            const nonAlpha = values.replace(/[^\d.-]/g, "");
                            evt.preventDefault();
                            formikEditProfile.setFieldValue(
                              "cellnumber",
                              nonAlpha
                            );
                          }}
                          placeholder={intl.formatMessage({
                            id: "TITLE.PHONE.NUMBER"
                          })}
                          {...formikEditProfile.getFieldProps("cellnumber")}
                        />
                        {formikEditProfile.touched.cellnumber &&
                        formikEditProfile.errors.cellnumber ? (
                          <div className='text-danger'>
                            <ErrorMessage
                              id={formikEditProfile.errors.cellnumber}
                            />
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor='role'>
                          <FormattedMessage id='TITLE.ROLE' />
                        </Form.Label>
                        <Form.Control
                          type='text'
                          id='role_id'
                          name='role_id'
                          {...formikEditProfile.getFieldProps("role_id")}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className='d-flex mt-2s rtl-start-flex'>
                    <div className='col-md-12 d-flex justify-content-end '>
                      <Button
                        variant='success mr-3 btn-inventory'
                        onClick={() => {
                          formikEditProfile.handleSubmit();
                        }}
                        disabled={isLoading}
                      >
                        <FormattedMessage id='TITLE.SAVE' />
                        {isLoading && <ButtonLoader className={"mr-1 ml-1"} />}
                      </Button>
                      <Button
                        variant='tertiary text-dark btn-inventory'
                        onClick={() => {
                          formikEditProfile.handleReset();
                          setUpdateUserDetails(Math.random());
                        }}
                      >
                        <FormattedMessage id='TITLE.CANCEL' />
                      </Button>
                    </div>
                  </Row>

                  <hr />
                  <Row className='mt-4'>
                    <Col>
                      <b>
                        <Form.Label htmlFor='role'>
                          <FormattedMessage id='TITLE.CHANGE.PASSWORD' />
                        </Form.Label>
                      </b>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} className='mb-2 mb-md-0'>
                      <Form.Group>
                        <Form.Label>
                          <FormattedMessage id='TITLE.CURRENT.PASSWORD' />
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={"password"}
                            className='form-control'
                            id='currentPassword'
                            placeholder={intl.formatMessage({
                              id: "TITLE.CURRENT.PASSWORD"
                            })}
                            name='currentPassword'
                            {...formikChangePassword.getFieldProps(
                              "currentPassword"
                            )}
                          />
                        </InputGroup>
                        {formikChangePassword.touched.currentPassword &&
                        formikChangePassword.errors.currentPassword ? (
                          <div className='text-danger'>
                            <ErrorMessage
                              id={formikChangePassword.errors.currentPassword}
                            />
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col lg={4} className='mb-2 mb-md-0'>
                      <Form.Group>
                        <Form.Label>
                          <FormattedMessage id='TITLE.NEW.PASSWORD' />
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={"password"}
                            className='form-control'
                            id='password'
                            placeholder={intl.formatMessage({
                              id: "TITLE.NEW.PASSWORD"
                            })}
                            name='password'
                            {...formikChangePassword.getFieldProps("password")}
                          />
                        </InputGroup>
                        {formikChangePassword.touched.password &&
                        formikChangePassword.errors.password ? (
                          <div className='text-danger'>
                            <ErrorMessage
                              id={formikChangePassword.errors.password}
                            />
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col lg={4} className='mb-2 mb-md-0'>
                      <Form.Group>
                        <Form.Label>
                          <FormattedMessage id='TITLE.CONFIRM.PASSWORD' />
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={"password"}
                            className='form-control'
                            id='confirmPassword'
                            placeholder={intl.formatMessage({
                              id: "TITLE.CONFIRM.PASSWORD"
                            })}
                            name='confirmPassword'
                            {...formikChangePassword.getFieldProps(
                              "confirmPassword"
                            )}
                          />
                        </InputGroup>
                        {formikChangePassword.touched.confirmPassword &&
                        formikChangePassword.errors.confirmPassword ? (
                          <div className='text-danger'>
                            <ErrorMessage
                              id={formikChangePassword.errors.confirmPassword}
                            />
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
              <Card.Footer className='d-flex justify-content-end'>
                <div className='d-flex justify-content-start mt-2s'>
                  <Button
                    variant='success mr-3 btn-inventory'
                    onClick={() => {
                      formikChangePassword.handleSubmit();
                    }}
                    disabled={isLoadingChangePassword}
                  >
                    <FormattedMessage id='TITLE.SAVE' />
                    {isLoadingChangePassword && (
                      <ButtonLoader className={"mr-1 ml-1"} />
                    )}
                  </Button>
                  <Button
                    variant='tertiary text-dark btn-inventory'
                    onClick={() => {
                      formikChangePassword.handleReset();
                    }}
                  >
                    <FormattedMessage id='TITLE.CANCEL' />
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
      {openSuccessModal && (
        <SuccessModal
          message={successMessage}
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
        />
      )}
    </>
  );
};

export default Profile;
