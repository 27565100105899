import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const BrandUIContext = createContext();

export function useBrandUIContext() {
  return useContext(BrandUIContext);
}

export const BrandUIConsumer = BrandUIContext.Consumer;

export function BrandUIProvider({
  brandUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openEditDialog: brandUIEvents.openEditDialog,
    openDeleteDialog: brandUIEvents.openDeleteDialog
  };

  return (
    <BrandUIContext.Provider value={value}>{children}</BrandUIContext.Provider>
  );
}
