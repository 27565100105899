// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../BrandUlHelpers";
import { useBrandUIContext } from "../BrandUlContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import StatusTypeFormatter from "../../../columnformatter/StatusTypeFormatter";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import BrandnameFormatter from "../../../columnformatter/BrandNameFormatter";
import { useIntl } from "react-intl";

export function BrandTable({
  isLoading,
  brandList,
  brandListTotalCount,
  currentPage,
  setCurrentPage
}) {
  const intl = useIntl();

  // Brand UI Context
  const brandUIContext = useBrandUIContext();
  const brandUIProps = useMemo(() => {
    return {
      ids: brandUIContext.ids,
      setIds: brandUIContext.setIds,
      queryParams: brandUIContext.queryParams,
      setQueryParams: brandUIContext.setQueryParams,
      openEditDialog: brandUIContext.openEditDialog,
      openDeleteDialog: brandUIContext.openDeleteDialog
    };
  }, [brandUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "TITLE.BRAND.NAME"
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: BrandnameFormatter
    },
    // {
    //   dataField: "totalproducts",
    //   text: "Total products",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
    {
      dataField: "status",
      text: intl.formatMessage({
        id: "TITLE.STATUS"
      }),
      sort: true,
      sortCaret: sortCaret,
      // style: {
      //   minWidth: "100px",
      // },
      formatter: StatusTypeFormatter
    },

    {
      dataField: "action",
      text: intl.formatMessage({
        id: "TITLE.ACTIONS"
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditDialog: brandUIProps.openEditDialog,
        openDeleteDialog: brandUIProps.openDeleteDialog,
        isVisibleViewButton: false
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px"
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: brandListTotalCount ? brandListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: brandUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-head-custom table-head-bg table-borderless table-vertical-center brand-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={brandList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  brandUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={brandList}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
