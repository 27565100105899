/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { capitalizeFirstLetter } from "../constants/utils";
const VendorNameColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { redirectToVendorViewPage }
) => {
  const { contact } = row;
  const { contact_name } = contact || {};
  return (
    <>
      <div
        className='text-primary btn-link p-0 cursor-pointer'
        onClick={() => {
          redirectToVendorViewPage(row);
        }}
      >
        {capitalizeFirstLetter(contact_name)}
      </div>
      <> </>
    </>
  );
};

export default VendorNameColumnFormatter;
