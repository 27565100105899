/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import { DD_MM_YYYY } from "../constants/const";
const AuditDateTimeColumnFormatter = (cellContent, row, rowIndex) => {
  const { start_date } = row || {};
  
  const renderDate = () => {
    if (start_date) return <Moment date={start_date} format={DD_MM_YYYY} />;
    return "-";
  };
  return <>{renderDate()}</>;
};

export default AuditDateTimeColumnFormatter;
