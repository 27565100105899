import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
  editProductCustomFieldDataApi, uploadDocumentApi
} from "../../../axios/services/services";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { FormattedMessage, useIntl } from "react-intl";
import { X, Check, File } from "react-feather";
import { getErrorMessageBasedOnLanguage, getMediaUrl } from "../../../constants/utils";
import { useDropzone } from "react-dropzone";
import { FILE_EXTENTION_IMAGE, MB_20, MB_5 } from "../../../constants/const";
import { ContentLoader } from "../../../helper/ContentLoader";
import { ThumbnailWrapper } from "../../../helper/ThumbnailWrapper";

export function EditCustomFieldModal({
  id,
  open,
  onCloseModal,
  customFieldData,
  getCustomFieldData
}) {
  const [editCustomFieldStatus, setEditCustomFieldStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [editCustomFieldData, setEditCustomFieldData] = useState([])
  const [files, setFiles] = useState([]);
  const [uploadDocument, setUploadDocument] = useState([]);
  const [isFileUploadLoading, setFileUploadLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setEditCustomFieldData(customFieldData.map((x)=> {
      x.loading = false
      return x
    }) ?? [])
  }, [customFieldData])

  const modelSize = () => {
    if (editCustomFieldData.length > 14) {
      return "xl"
    } else if (editCustomFieldData.length > 6) {
      return "lg"
    } else {
      return "md"
    }
  }

  const jsonDecode = (jsonData) => {
    try {
      let fieldValue = JSON.parse(jsonData) ?? []
      return fieldValue.map(x => ({ label: x, value: x }))
    }
    catch (error) {
      return []
    }
  }

  const editData = async () => {
    try {
      setSaveLoading(true)

      let responseData = await editProductCustomFieldDataApi({
        productDetails: editCustomFieldData.map((x) => {
          return {
            label: x.id,
            value: x.field_value
          }
        })
      })

      let Error = { responseData }

      if (responseData.data.count === 1) {
        setEditCustomFieldStatus(true)
        getCustomFieldData()
        setTimeout(() => {
          onCloseModal()
        }, 2000)
      } else {
        setErrorMessage(getErrorMessageBasedOnLanguage({
          Error: {
            message: Error.message ?? "Something went wrong"
          }
        }))
        handleErrorModalShow()
      }
      setSaveLoading(false)
    }
    catch (error) {
      setErrorMessage(getErrorMessageBasedOnLanguage({
        Error: {
          message: error.message ?? "Something went wrong"
        }
      }))
      handleErrorModalShow()
      setSaveLoading(true)
    }
  }

  const enableLoadingFileUpload = () => {
    setFileUploadLoading(true);
  };

  const disableLoadingFileUpload = () => {
    setFileUploadLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const attachDocument = async (acceptedFiles) => {
    const uploadedFilesArray = [];
    const maxLengthForDocUpload = 5 - files.length;
    enableLoadingFileUpload();
    for (let index = 0; index < acceptedFiles.length; index++) {
      if (maxLengthForDocUpload === index) {
        break;
      }
      if (MB_20 <= acceptedFiles[index].size) {
        setErrorMessage(
          intl.formatMessage({ id: "TITLE.ATTACHED.FILE.IS.TOO.LARGE" })
        );
        handleErrorModalShow();
        continue;
      }
      let files = {
        file: acceptedFiles[index],
        containername: "product"
      };
      const uploadDocumentResponse = await uploadDocumentApi(files);
      const [uploadedDoc] = uploadDocumentResponse || {};
      uploadedFilesArray.push(uploadedDoc);
    }
    disableLoadingFileUpload();
    // const filesTemp = [...files, ...uploadedFilesArray];
    const filesTemp = uploadedFilesArray;
    setFiles(filesTemp);
    setUploadDocument([...uploadDocument, ...uploadedFilesArray]);
  }

  const uploadFile = async (index, event) => {
    if(!event.target.files[0]){
      return
    }
    try {
      editCustomFieldData[index].loading = true
      setEditCustomFieldData([...editCustomFieldData])
      let files = {
        file: event.target.files[0],
        containername: "product"
      };
      const uploadDocumentResponse = await uploadDocumentApi(files);
      const [uploadedDoc] = uploadDocumentResponse || {};
      editCustomFieldData[index].field_value = uploadedDoc?.filename ?? ""
      editCustomFieldData[index].loading = false
      setEditCustomFieldData([...editCustomFieldData])
    }
    catch (error) {
      editCustomFieldData[index].loading = false
      setEditCustomFieldData([...editCustomFieldData])
      console.error(error)
    }
  }

  // Function to handle file download
  const handleDownload = (filename) => {
    let assetUrl = getMediaUrl(filename, "product");
    window.open(assetUrl);
  };

  return (
    <>
      <Modal
        size={modelSize()}
        show={open}
        onHide={onCloseModal}
        dialogClassName='inventory-small-modal'
      >
        <Modal.Header
          className='d-flex justify-content-between align-items-center'
          toggle={onCloseModal}
        >
          <h3 className='modal-title' id='exampleModalLabel2'>
            <FormattedMessage id='TITLE.EDIT.PRODUCT' />
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => onCloseModal()}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          {editCustomFieldStatus && (
            <div className='notification icon inline success fs-16'>
              <Check size={8} className='text-white'></Check>
              <FormattedMessage id='TITLE.PRODUCT.HAS.BEEN.SUCCESSFULLY.EDITED' />
            </div>
          )}
          <Form autoComplete='off'>
            <Row>
              {editCustomFieldData.map((item, index) => {
                return <Col md={modelSize() === "xl" ? 4 : modelSize() === "lg" ? 6 : 12}>
                  <Form.Group>
                    <Form.Label htmlFor='name'>
                      {item?.customFieldInfo?.field_name}
                    </Form.Label>
                    {item.customFieldInfo.field_type === "select" ?
                    <Select
                      options={jsonDecode(item.customFieldInfo.field_value)}
                      onChange={(e) => {
                        editCustomFieldData[index].field_value = e.value
                        setEditCustomFieldData([...editCustomFieldData])
                      }}
                      value={jsonDecode(item.customFieldInfo.field_value).find((x) => x.value === item.field_value) ?? []}
                    /> :
                    item.customFieldInfo.field_type === "file" ? (
                      <>
                        <Form.Control
                          type='file'
                          id='file'
                          onChange={(e) => {
                            uploadFile(index, e)
                          }}
                          disabled={item.loading}
                        />
                        {item?.field_value ? <a href={"#"} onClick={()=>handleDownload(item?.field_value)}>{item?.field_value}</a> : ""}
                      </>
                      )
                      :
                      item.customFieldInfo.field_type === "date" ?
                      <Form.Control
                        type='date'
                        id='date'
                        onChange={(e) => {
                          editCustomFieldData[index].field_value = e.target.value
                          setEditCustomFieldData([...editCustomFieldData])
                        }}
                        value={item.field_value}
                      />
                      :
                      item.customFieldInfo.field_type === "datetime" ?
                      <Form.Control
                        type='datetime-local'
                        id='datetime'
                        onChange={(e) => {
                          editCustomFieldData[index].field_value = e.target.value
                          setEditCustomFieldData([...editCustomFieldData])
                        }}
                        value={item.field_value}
                      /> : item.customFieldInfo.field_type === "textarea" ?
                      <Form.Control as="textarea" rows={3} 
                        onChange={(e) => {
                          editCustomFieldData[index].field_value = e.target.value
                          setEditCustomFieldData([...editCustomFieldData])
                        }} 
                        value={item.field_value}
                      />
                       :
                      <Form.Control
                        type='text'
                        id='text'
                        onChange={(e) => {
                          editCustomFieldData[index].field_value = e.target.value
                          setEditCustomFieldData([...editCustomFieldData])
                        }}
                        value={item.field_value}
                      />
                    }
                  </Form.Group>
                </Col>
              })}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success mr-3 btn-inventory'
            onClick={() => {
              editData()
            }}
            disabled={isSaveLoading}
          >
            <FormattedMessage id='TITLE.SAVE' />

            {isSaveLoading && (
              <Spinner animation='border' size='sm' className='ml-1' />
            )}
          </Button>
          <Button
            type='button'
            variant='tertiary text-dark'
            onClick={() => onCloseModal()}
          >
            <FormattedMessage id='TITLE.CANCEL' />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
