// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../AuditUIHelpers";
import { useAuditUIContext } from "../AuditUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import { useIntl } from "react-intl";
import AuditStatusTypeFormatter from "../../../columnformatter/AuditStatusColumnFormatter";
import AuditDateTimeColumnFormatter from "../../../columnformatter/AuditDateTimeCoulmnFormatter";
import AuditDateTimeEndDateColumnFormatter from "../../../columnformatter/AuditDateTimeEndDateCoulmnFormatter";

export function AuditTable({
  isLoading,
  auditList,
  filterValues,
  auditListTotalCount,
  currentPage,
  setCurrentPage
}) {
  const intl = useIntl();

  // Product UI Context
  const productUIContext = useAuditUIContext();
  const productUIProps = useMemo(() => {
    return {
      ids: productUIContext.ids,
      setIds: productUIContext.setIds,
      queryParams: productUIContext.queryParams,
      setQueryParams: productUIContext.setQueryParams,
      openViewDialog: productUIContext.openViewDialog,
      openEditDialog: productUIContext.openEditDialog,
      openDeleteDialog: productUIContext.openDeleteDialog,
      redirectToViewPage: productUIContext.redirectToViewPage,
      redirectToCategoryViewPage: productUIContext.redirectToCategoryViewPage
    };
  }, [productUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "audit_name",
      text: intl.formatMessage({
        id: "TITLE.AUDIT.NAME"
      }),
      sort: true,
      style: {
        minWidth: "170px"
      },
      sortCaret: sortCaret
      // formatter: SkuColumnFormatter,
    },
    {
      dataField: "audit_status",
      text: intl.formatMessage({
        id: "TITLE.AUDIT.STATUS"
      }),
      sort: true,
      style: {
        minWidth: "170px"
      },
      sortCaret: sortCaret,
      formatter: AuditStatusTypeFormatter,
    },
    {
      dataField: "assignee",
      text: intl.formatMessage({
        id: "TITLE.AUDIT.ASSIGNEE"
      }),
      sort: false,
      style: {
        minWidth: "170px"
      },
      sortCaret: sortCaret,
      formatter:  (cell, row, rowIndex, ) => {
        return (
          <div>
            Basic User, <span className="rounded-pill bg-info text-white p-1 px-6">{'+2'}</span>
          </div>
        ); 
      },
    },
    {
      dataField: "object",
      text: intl.formatMessage({
        id: "TITLE.AUDIT.OBJECTS"
      }),
      sort: false,
      style: {
        minWidth: "170px"
      },
      sortCaret: sortCaret,
      formatter: (cell, row, rowIndex, ) => {
        return "0/15"; 
      }
    },
    {
      dataField: "start_date",
      text: intl.formatMessage({
        id: "TITLE.AUDIT.START.DATE"
      }),
      sort: true,
      style: {
        minWidth: "170px"
      },
      sortCaret: sortCaret,
      formatter: AuditDateTimeColumnFormatter,
    },
    {
      dataField: "end_date",
      text: intl.formatMessage({
        id: "TITLE.AUDIT.DUE.DATE"
      }),
      sort: true,
      style: {
        minWidth: "170px"
      },
      sortCaret: sortCaret,
      formatter: AuditDateTimeEndDateColumnFormatter,
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "TITLE.ACTIONS"
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: productUIProps.openViewDialog,
        openDeleteDialog: productUIProps.openDeleteDialog,
        isVisibleViewButton: true,
        isVisibleEditButton: false
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px"
      }
    }
  ];
  
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: auditListTotalCount ? auditListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    }
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-head-custom table-head-bg table-borderless table-vertical-center product-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={auditList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  productUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={auditList}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
