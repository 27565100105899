import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ButtonLoader } from "../../../helper/ButtonLoader";

export function ConfirmationModal({
  isLoading,
  title,
  icon,
  variant,
  isSuccess,
  description,
  cancelText,
  logoutText,
  isOpen,
  onCloseModal,
  id,
  onClickOfConfirmationButton
}) {
  return (
    <>
      <Modal
        show={isOpen}
        onHide={onCloseModal}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Body>
          <div className='text-center p-3'>
            <h3 className={`modal-title text-center mb-3 ${isSuccess}`}>
              {`${id}`}
            </h3>
            {icon}
            <h3 className={`modal-title text-center mb-3 ${isSuccess}`}>
              {`${title}`}
            </h3>
            <p className='text-center text-dark mb-0'>{description}</p>
          </div>
          <div className='d-flex align-items-center justify-content-center mb-3'>
            <Button
              variant={`${variant} btn-inventory mr-3 btn-inventory`}
              onClick={onClickOfConfirmationButton}
              disabled={isLoading}
            >
              {logoutText}
              {isLoading && <ButtonLoader className='mr-1 ml-1' />}
            </Button>
            <Button
              disabled={isLoading}
              variant='tertiary btn-inventory text-dark btn-inventory'
              onClick={() => onCloseModal()}
            >
              {cancelText}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
