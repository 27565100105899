import React, { useEffect, useState } from "react";
import { Image, Table, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import nodatafoundImage from "../../../assets/images/dashboard/nodata-found.svg";
import { maintanceListHistoryApi, editMaintanceHistoryApi } from "../../../axios/services/services";
import { SectionLoader } from "../../../helper/SectionLoader";
import { useParams } from "react-router-dom";

export function MaintanceLogView() {
  const [historyList, setHistoryList] = useState([]);
  const [isLoadingHistoryView, setIsLoadingHistoryView] = useState(true);
  const intl = useIntl();
  const params = useParams();
  const assetId = params.id;

  useEffect(() => {
    if (assetId) {
      fetchHistoryList();
    }
  }, [assetId]);

  const fetchHistoryList = async () => {
    setIsLoadingHistoryView(true);
    try {
      const response = await maintanceListHistoryApi({ assetId });
      if (response.data) {
        const formattedData = response.data.map((item) => ({
          ...item,
          repair_date: formatDate(item.repair_date),
          repair_completed_date: formatDate(item.repair_completed_date),
          isCompleted: item.completed_status === 1,
          originalItem: { ...item } 
        }));
        setHistoryList(formattedData);
      }
      setIsLoadingHistoryView(false);
    } catch (error) {
      console.error("Error fetching maintenance history:", error);
      setIsLoadingHistoryView(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const markCompleted = async (id) => {
    
    try {
      await editMaintanceHistoryApi({id})
      const response = await maintanceListHistoryApi({ assetId });
      if (response.data) {
        const formattedData = response.data.map((item) => ({
          ...item,
          repair_date: formatDate(item.repair_date),
          repair_completed_date: formatDate(item.repair_completed_date),
          isCompleted: item.completed_status === 1,
          originalItem: { ...item } 
        }));
        setHistoryList(formattedData);
      }
    } catch (error) {
      console.error("Error marking maintenance record as completed:", error);
    }
  };

  return (
    <>
      {isLoadingHistoryView && <SectionLoader />}
      <div className='view-body'>
        <Table responsive className='viewpo-table table'>
          <thead>
            <tr>
              <th scope='col'>
                <FormattedMessage id='TITLE.ASSET.NEED.TO.REPAIR.FIELD.NAME.REASON' />
              </th>
              <th scope='col'>
                <FormattedMessage id='TITLE.ASSET.NEED.TO.REPAIR.FIELD.NAME.REASON.DATE' />
              </th>
              <th scope='col'>
                <FormattedMessage id='TITLE.ASSET.NEED.TO.REPAIR.FIELD.NAME.COMPLETED.DATE' />
              </th>
              <th scope='col'>
                <FormattedMessage id='TITLE.CREATED.BY' />
              </th>
              <th scope='col'>
                <FormattedMessage id='TITLE.ACTIONS' />
              </th>
            </tr>
          </thead>
          <tbody>
            {historyList && historyList.length > 0 ? (
              historyList.map((record, index) => (
                <tr key={index}>
                  <td className='digits'>{record?.repair_reason ?? "-"}</td>
                  <td className='digits'>{record?.repair_date  ?? "-"}</td>
                  <td className='digits'>{record?.repair_completed_date  ?? "-"}</td>
                  <td className='digits'>{record?.userDetailInfo?.full_name ?? "-"}</td>
                  <td className='digits'>
                    {record.isCompleted ? (
                      <span>Completed</span>
                    ) : (
                      <span style={{color:"#0056b3", cursor:"pointer"}} onClick={() => markCompleted(record.id)}>
                        Mark as Completed
                      </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              !isLoadingHistoryView && (
                <tr>
                  <td className='no-record text-center' colSpan={5}>
                    <div className='d-flex align-items-center justify-content-center flex-column'>
                      <Image
                        src={nodatafoundImage}
                        alt='No Data Found'
                        className='img-fluid'
                        style={{ maxHeight: "200px" }}
                      />
                      <span className='d-block text-center'>
                        <FormattedMessage id='TITLE.NO.RECORDS.FOUND' />
                      </span>
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default MaintanceLogView;
