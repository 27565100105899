import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { X, Check } from "react-feather";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { AddUserSchema } from "../../../helper/FormikSchema";
import { ErrorModal } from "../../common/modals/ErrorModal";
import {
  addUserApi,
  checkUserExistApi
} from "../../../axios/services/services";
import {
  getErrorMessageBasedOnLanguage,
  InitLanguage,
  setActiveMenu,
  unauthorization
} from "../../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";

let AddUserInitialValues = {
  full_name: "",
  emp_id: "",
  email: "",
  cellnumber: "",
  role_id: "",
  password: ""
};

export function AddNewUserModal({
  onCloseModal,
  open,
  roleList,
  handleCloseOfAddNewUserModal
}) {
  const intl = useIntl();
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [selectedOption, setSelectedOption] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingCheckUserExist, setLoadingCheckUserExist] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isNewUserAdded, setNewUserAdded] = useState(false);
  const [isCheckCellnumberExist, setCheckCellnumberExist] = useState(0);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  useEffect(() => {
    async function checkUserExist() {
      enableLoadingCheckUserExist();
      const { cellnumber } = formikAddUser.values || {};
      const checkUserExistResponse = await checkUserExistApi({
        cellnumber
      });
      disableLoadingCheckUserExist();
      const { Error, data } = checkUserExistResponse || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
    }

    if (isCheckCellnumberExist !== 0) {
      checkUserExist();
    }
  }, [isCheckCellnumberExist]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingCheckUserExist = () => {
    setLoadingCheckUserExist(true);
  };

  const disableLoadingCheckUserExist = () => {
    setLoadingCheckUserExist(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleRoleChange = (data) => {
    setSelectedOption(data);
    formikAddUser.setFieldValue("role_id", data.value);
  };

  const formikAddUser = useFormik({
    initialValues: AddUserInitialValues,
    validationSchema: AddUserSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const { full_name, emp_id, email, cellnumber, role_id, password } =
        values || {};

      let addUserObj = {
        full_name,
        emp_id,
        email,
        cellnumber: "" + cellnumber,
        role_id,
        password
      };
      const addUserResponse = await addUserApi(addUserObj);

      const { data, Error } = addUserResponse || {};
      if (Error) {
        disableLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setNewUserAdded(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableLoading();
        const path = "/users/user-listing";
        onClickChangeRoute(path);
        handleCloseOfAddNewUserModal(data);
      }, 2500);
    },
    onReset: (values, { resetForm }) => {}
  });

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Modal
        size='lg'
        show={open}
        onHide={onCloseModal}
        aria-labelledby='example-modal-sizes-title-lg'
        dialogClassName='addvendor-modal modal'
        scrollable={true}
      >
        <Modal.Header className='d-flex justify-content-between align-items-center'>
          <h3 className='modal-title' id='exampleModalLabel2'>
            <FormattedMessage id='TITLE.ADD.NEW.USER' />
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          {isNewUserAdded && (
            <div className='notification icon inline success fs-16'>
              <Check size={8} className='text-white'></Check>
              <FormattedMessage id='TITLE.NEW.USER.CREATED.SUCCESSFULLY' />
            </div>
          )}
          <Form autoComplete='off'>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.USER.DISPLAY.NAME' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='full_name'
                    name='full_name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.USER.DISPLAY.NAME"
                    })}
                    {...formikAddUser.getFieldProps("full_name")}
                  />
                  {formikAddUser.touched.full_name &&
                  formikAddUser.errors.full_name ? (
                    <div className='text-danger'>
                      {InitLanguage(formikAddUser.errors.full_name)}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.EMPLOYEE.ID' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='emp_id'
                    name='emp_id'
                    placeholder={intl.formatMessage({
                      id: "TITLE.EMPLOYEE.ID"
                    })}
                    {...formikAddUser.getFieldProps("emp_id")}
                  />
                  {formikAddUser.touched.emp_id &&
                  formikAddUser.errors.emp_id ? (
                    <div className='text-danger'>
                      {formikAddUser.errors.emp_id}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.EMAIL.ADDRESS' />
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='email'
                    name='email'
                    placeholder={intl.formatMessage({
                      id: "TITLE.EMAIL.ADDRESS"
                    })}
                    {...formikAddUser.getFieldProps("email")}
                  />
                  {formikAddUser.touched.email && formikAddUser.errors.email ? (
                    <div className='text-danger'>
                      {formikAddUser.errors.email}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.MOBILE.NUMBER' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='number'
                    id='cellnumber'
                    name='cellnumber'
                    placeholder={intl.formatMessage({
                      id: "TITLE.MOBILE.NUMBER"
                    })}
                    onKeyDown={(evt) => {
                      return (
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      );
                    }}
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      formikAddUser.setFieldValue("cellnumber", nonAlpha);
                    }}
                    {...formikAddUser.getFieldProps("cellnumber")}
                    // onChange={() => {
                    //   const { cellnumber } = formikAddUser.values || {};
                    //   const test = cellnumber.toString().length;
                    //   if (test === 8) {
                    //     setCheckCellnumberExist(Math.random());
                    //   }

                    // }}
                  />
                  {formikAddUser.touched.cellnumber &&
                  formikAddUser.errors.cellnumber ? (
                    <div className='text-danger'>
                      {InitLanguage(formikAddUser.errors.cellnumber)}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.ROLE' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Select
                    defaultValue={selectedOption}
                    onChange={(data) => {
                      handleRoleChange(data);
                    }}
                    isSearchable={true}
                    options={roleList}
                    placeholder={intl.formatMessage({
                      id: "TITLE.SELECT.ROLE"
                    })}
                    className='react-select-container'
                    classNamePrefix='react-select'
                  />
                  {formikAddUser.touched.role_id &&
                  formikAddUser.errors.role_id ? (
                    <div className='text-danger'>
                      {formikAddUser.errors.role_id}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='username'>
                    <FormattedMessage id='TITLE.PASSWORD' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='password'
                    id='password'
                    name='password'
                    placeholder={intl.formatMessage({ id: "TITLE.PASSWORD" })}
                    {...formikAddUser.getFieldProps("password")}
                  />
                  {formikAddUser.touched.password &&
                  formikAddUser.errors.password ? (
                    <div className='text-danger'>
                      {InitLanguage(formikAddUser.errors.password)}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            variant='success'
            onClick={() => {
              window.scrollTo(0, 0);
              formikAddUser.handleSubmit();
            }}
            disabled={isLoading}
          >
            <FormattedMessage id='TITLE.SAVE' />
            {isLoading && <ButtonLoader className='mr-1 ml-1' />}
          </Button>
          <Button
            type='button'
            variant='tertiary text-dark'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id='TITLE.CANCEL' />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
