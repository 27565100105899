/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { useIntl } from "react-intl";
import {
  deleteBarcodeApi,
  fetchAssetsListAPI
} from "../../axios/services/services";
import { MENUITEMS } from "../../constants/menu";
import {
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization
} from "../../constants/utils";

import { AddNewProductModal } from "../../global-modal/AddNewProductModal ";
import { UIContext } from "../../helper/UIContext";
import { toAbsoluteUrl } from "../../helper/helper";
import { DeleteModal } from "../common/modals/DeleteModal";
import { ErrorModal } from "../common/modals/ErrorModal";
import { SuccessModal } from "../common/modals/SuccessModal";

import { AssetCard } from "./AssetCard";
import { AssetUIProvider } from "./AssetUIContext";
import { initialFilter } from "./AssetUIHelpers";
import { AssetLoadingDialog } from "./asset-loading-dialog/AssetLoadingDialog";
import { EditAssetModal } from "./edit-asset/EditAssetModal";

export function AssetPage({ history }) {
  const { formatMessage } = useIntl();
  const uiContext = useContext(UIContext);

  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [isOpenAddNewAssetModal, setOpenAddNewAssetModal] = useState(false);
  const [isOpenEditAssetModal, setOpenEditAssetModal] = useState(false);
  const exportCSVButton = useRef();
  const [assetList, setAssetList] = useState([]);
  const [assetListTotalCount, setAssetListTotalCount] = useState();
  const [isUpdateAssetDetails, setUpdateAssetDetails] = useState(0);
  const [isFilter, setFilter] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isLoadingDeleteAsset, setLoadingDeleteAsset] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  async function getAssetList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }

    try {
      const assetListResponse = await fetchAssetsListAPI({ queryParams });
      disableLoading();

      if (isFilter) {
        disableLoadingFilter();
      }

      setFilter(false);
      const { Error, data, count } = assetListResponse || {};

      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }

      setAssetList(data ? data : []);
      setAssetListTotalCount(count ? count : 0);
    } catch (error) {
      console.error("Error fetching asset list:", error);
    }
  }

  const deleteAsset = async () => {
    enableLoadingDeleteAsset();
    const { id } = data;

    try {
      const deleteBarcodeResponse = await deleteBarcodeApi({ id });
      const { Error } = deleteBarcodeResponse || {};

      if (Error) {
        disableLoadingDeleteAsset();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }

      setIsDeleteModalOpen(false);
      setSuccessMessage(
        formatMessage({ id: "TITLE.ASSET.DELETED.SUCCESSFULLY" })
      );
      onOpenSuccessModal();
      window.scrollTo(0, 0);
      setUpdateAssetDetails(Math.random());
      disableLoadingDeleteAsset();
    } catch (error) {
      console.error("Error deleting asset:", error);
    }
  };

  useEffect(() => {
    getAssetList();
  }, [queryParams, isUpdateAssetDetails]);

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const enableLoadingDeleteAsset = () => {
    setLoadingDeleteAsset(true);
  };

  const disableLoadingDeleteAsset = () => {
    setLoadingDeleteAsset(false);
  };

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const addNewAssetModalShow = () => {
    setOpenAddNewAssetModal(true);
  };

  const addNewAssetModalHide = () => {
    setOpenAddNewAssetModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleFilterValues = (values) => {
    setFilterValues(values);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleSetCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const EditAssetModalShow = () => {
    setOpenEditAssetModal(true);
  };

  const EditAssetModalHide = () => {
    setOpenEditAssetModal(false);
  };

  const assetUIEvents = {
    openViewDialog: (row) =>
      onClickChangeRoute(`/assets/view-asset-details/${row?.id}`, {
        barcodeNo: row?.barcode_no
      }),

    openEditDialog: (row) => {
      EditAssetModalShow();
      setData(row);
    },

    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    },

    redirectToViewPage: (row) => {
      onClickChangeRoute(
        `/inventoryproducts/view-inventoryproduct-details/${row?.product?.id}`,
        { barcodeNo: row.barcode_no }
      );
    },

    redirectToCategoryViewPage: (row) =>
      onClickChangeRoute(
        `/master/categories/view-category-details/${row?.product?.category?.id}`
      )
  };

  const onClickOfDeleteButton = () => deleteAsset();

  const handleExportButton = async () => { };

  const handleOnClickOfCreateNew = () => addNewAssetModalShow();

  const handleCLickOfFilterButtonFilter = (values) => setFilter(true);

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const handleCloseOfEditNewAssetModal = (editNewVendorDetails) => {
    setUpdateAssetDetails(Math.random());
    EditAssetModalHide();
  };

  const handleCloseOfAddNewAssetModal = () => {
    setAssetList([]);
    setUpdateAssetDetails(Math.random());
    addNewAssetModalHide();
  };

  const onClickChangeRoute = (routePath, options) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push({ pathname: routePath, state: options });
  };

  return (
    <div>
      <>
        <AssetUIProvider
          assetUIEvents={assetUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <AssetLoadingDialog />
          <AssetCard
            isLoading={isLoading}
            assetList={assetList}
            handleFilterValues={handleFilterValues}
            filterValues={filterValues}
            transactionListExport={assetList}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            assetListTotalCount={assetListTotalCount}
            currentPage={currentPage}
            setCurrentPage={handleSetCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            isLoadingFilter={isLoadingFilter}
            isFilterOpen={isFilterOpen}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
          />
        </AssetUIProvider>

        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className='danger-icon-wrapper text-center'>
              <span className='svg-icon menu-icon svg-icon-white svg-icon-4x mr-0'>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />

        {isOpenAddNewAssetModal && (
          <AddNewProductModal
            open={isOpenAddNewAssetModal}
            onCloseModal={addNewAssetModalHide}
            handleCloseOfAddNewProductModal={handleCloseOfAddNewAssetModal}
          />
        )}

        {isOpenEditAssetModal && (
          <EditAssetModal
            open={isOpenEditAssetModal}
            data={data}
            onCloseModal={EditAssetModalHide}
            id={data.id}
            handleCloseOfEditNewProductModal={handleCloseOfEditNewAssetModal}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteAsset}
            title={formatMessage({ id: "TITLE.DELETE.ASSET" })}
            description={formatMessage({
              id: "TITLE.ASSET.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
            })}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={data?.barcode_no}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}
        {openSuccessModal && (
          <SuccessModal
            message={successMessage}
            open={openSuccessModal}
            onCloseModal={onCloseSuccessModal}
          />
        )}
      </>
    </div>
  );
}
