import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import DatePicker from "react-datepicker";
import { X, Check } from "react-feather";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getMediaUrl } from "../../../constants/utils";
import { maintanceHistoryApi, uploadDocumentApi } from "../../../axios/services/services";
import "react-datepicker/dist/react-datepicker.css";

export function RepairModal({ id, open, onCloseModal }) {
  const intl = useIntl();
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isRepairSaved, setRepairSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // State for error message
  const [fileName, setFileName] = useState(""); // State for uploaded file name
  const [isFileUploadLoading, setFileUploadLoading] = useState(false); // State for file upload loading

  const validationSchema = Yup.object().shape({
    repair_reason: Yup.string().required(
      intl.formatMessage({ id: "Reason is required" })
    ),
    repair_date: Yup.date()
      .nullable()
      .required(intl.formatMessage({ id: "Repair date is required" })),
    repair_completed_date: Yup.date()
      .nullable()
      .required(intl.formatMessage({ id: "Completed date is required" }))
      .test(
        "is-greater",
        intl.formatMessage({ id: "Completed date must be after repair date" }),
        function (value) {
          const { repair_date } = this.parent;
          return !repair_date || !value || value >= repair_date;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      repair_reason: "",
      repair_date: null,
      repair_completed_date: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setSaveLoading(true);
      setErrorMessage(null);
      const { repair_reason, repair_date, repair_completed_date } = values;
      const attachment = fileName; 
      let addObj = {
        repair_reason,
        repair_date,
        repair_completed_date,
        attachment,
        assetId: id,
      };

      try {
        const response = await maintanceHistoryApi(addObj);
        console.log("Repair details saved:", response);
        setSaveLoading(false);
        setRepairSaved(true);
        setTimeout(() => {
          onCloseModal();
        }, 2000);
      } catch (error) {
        console.error("Error saving repair details:", error);
        setSaveLoading(false);
        setErrorMessage(
          intl.formatMessage({ id: "Error saving repair details" })
        );
      }
    },
  });

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);

      // Upload the file and get the file name
      setFileUploadLoading(true);
      try {
        let files = {
          file: selectedFile,
          containername: "product"
        };
        const uploadDocumentResponse = await uploadDocumentApi(files);
        const [uploadedDoc] = uploadDocumentResponse || {};
        setFileName(uploadedDoc?.filename ?? ""); // Set the file name
        setFileUploadLoading(false);
      } catch (error) {
        console.error("Error uploading file:", error);
        setFileUploadLoading(false);
      }
    }
  };

  // Function to handle file download
  const handleDownload = (filename) => {
    let assetUrl = getMediaUrl(filename, "product");
    window.open(assetUrl);
  };

  return (
    <Modal
      show={open}
      onHide={onCloseModal}
      dialogClassName="inventory-small-modal"
      animation={false}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "TITLE.ASSET.REPAIR.DETAILS" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isRepairSaved && (
          <div className="notification icon inline success fs-16">
            <Check size={8} className="text-white"></Check>{" "}
            <FormattedMessage id="TITLE.DETAILS.SUCESS.SAVE" />
          </div>
        )}
        {errorMessage && (
          <div className="notification icon inline danger fs-16">
            <X size={8} className="text-white"></X>{" "}
            {errorMessage}
          </div>
        )}
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Form.Group controlId="formRepairReason">
                <Form.Label>
                  <FormattedMessage id="Reason" />
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="repair_reason"
                  value={formik.values.repair_reason}
                  onChange={formik.handleChange}
                  placeholder={intl.formatMessage({
                    id: "Enter reason for repair",
                  })}
                  isInvalid={
                    !!formik.errors.repair_reason &&
                    formik.touched.repair_reason
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.repair_reason}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="formRepairDate">
                <Form.Label>
                  <FormattedMessage id="Repair Date" />
                  <span className="text-danger">*</span>
                </Form.Label>
                <DatePicker
                  selected={formik.values.repair_date}
                  onChange={(date) => formik.setFieldValue("repair_date", date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  className="form-control"
                  name="repair_date"
                  isInvalid={
                    !!formik.errors.repair_date && formik.touched.repair_date
                  }
                  maxDate={new Date()}
                />
                {formik.touched.repair_date && formik.errors.repair_date ? (
                  <div className="text-danger">{formik.errors.repair_date}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="formRepairCompletedDate">
                <Form.Label>
                  <FormattedMessage id="Completed Date" />
                  <span className="text-danger">*</span>
                </Form.Label>
                <DatePicker
                  selected={formik.values.repair_completed_date}
                  onChange={(date) =>
                    formik.setFieldValue("repair_completed_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  className="form-control"
                  name="repair_completed_date"
                  isInvalid={
                    !!formik.errors.repair_completed_date &&
                    formik.touched.repair_completed_date
                  }
                  minDate={formik.values.repair_date}
                />
                {formik.touched.repair_completed_date &&
                formik.errors.repair_completed_date ? (
                  <div className="text-danger">
                    {formik.errors.repair_completed_date}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="formFileUpload">
                <Form.Label>
                  <FormattedMessage id="Upload Bill" 
                  name="attachment" />
                </Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
                {isFileUploadLoading && (
                  <Spinner animation="border" size="sm" className="ml-1" />
                )}
                {fileName && (
                  <a href={"#"} onClick={() => handleDownload(fileName)}>{fileName}</a>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success mr-3 btn-inventory"
          onClick={formik.handleSubmit}
          disabled={isSaveLoading}
        >
          <FormattedMessage id="TITLE.SAVE" />
          {isSaveLoading && (
            <Spinner animation="border" size="sm" className="ml-1" />
          )}
        </Button>
        <Button
          type="button"
          variant="tertiary text-dark"
          onClick={onCloseModal}
        >
          <FormattedMessage id="TITLE.CANCEL" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
