import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const CustomerUIContext = createContext();

export function useCustomerUIContext() {
  return useContext(CustomerUIContext);
}

export const CustomerUIConsumer = CustomerUIContext.Consumer;

export function CustomerUIProvider({
  customerUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: customerUIEvents.openViewDialog,
    openEditDialog: customerUIEvents.openEditDialog,
    openDeleteDialog: customerUIEvents.openDeleteDialog,
    redirectToViewPage: customerUIEvents.redirectToViewPage
  };

  return (
    <CustomerUIContext.Provider value={value}>
      {children}
    </CustomerUIContext.Provider>
  );
}
