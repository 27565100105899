import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { XCircle, Plus, X, Check } from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { FieldArray, Formik } from "formik";
import { AddCustomerSchema } from "../helper/FormikSchema";
import { addCustomerApi, cityListAllApi } from "../axios/services/services";
import { ButtonLoader } from "../helper/ButtonLoader";
import { ErrorModal } from "../components/common/modals/ErrorModal";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  InitLanguage,
  unauthorization
} from "../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorMessage from "../helper/ErrorMessage";

export function AddNewCustomerModal({
  searchedCustomerName,
  onCloseModal,
  open,
  handleCloseOfAddNewCustomerModal
}) {
  const [isLoading, setLoading] = useState(false);
  const [isNewCustomerAdded, setNewCustomerAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [selectedShippingCity, setSelectedShippingCity] = useState("");
  const [cityList, setCityList] = useState([]);
  const [selectedBillingCity, setSelectedBillingCity] = useState("");
  const [isAddContactPersonTable, setAddContactPersonTable] = useState(true);
  const [multipleContactDetails, setMultipleContactDetails] = useState({
    contact_name: searchedCustomerName,
    email: "",
    phone_number: "",
    mobile_number: "",
    currency: "AED",
    billingAddress: "",
    billingCity: "",
    billingZipcode: "",
    shippingAddress: "",
    shippingCity: "",
    shippingZipcode: "",
    contact_persons: [
      {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        mobile: ""
      }
    ],
    remarks: ""
  });

  const handleAddContactPersonRef = useRef([]);

  async function getCityList() {
    const cityListResponse = await cityListAllApi({});
    const { Error, data } = cityListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const cities = data.map((city) => {
      const { id, name } = city || {};
      city.value = id;
      city.label = capitalizeFirstLetter(name);
      return city;
    });
    setCityList(cities ? cities : []);
  }

  useEffect(() => {
    getCityList();
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleOnClickOfCopyBillingAddress = (values, setFieldValue) => {
    const { billingAddress, billingCity, billingZipcode } = values || {};
    setFieldValue("shippingAddress", billingAddress);
    setFieldValue("shippingCity", billingCity);
    setFieldValue("shippingZipcode", billingZipcode);
    setSelectedShippingCity(selectedBillingCity);
  };

  const renderFirstNameValue = (values, index) => {
    const value = values.contact_persons[index].first_name;
    return value;
  };

  const renderLastNameValue = (values, index) => {
    const value = values.contact_persons[index].last_name;
    return value;
  };

  const renderContactEmailValue = (values, index) => {
    const value = values.contact_persons[index].email;
    return value;
  };

  const renderMobileValue = (values, index) => {
    const value = values.contact_persons[index].mobile;
    return value;
  };

  const renderPhoneValue = (values, index) => {
    const value = values.contact_persons[index].phone;
    return value;
  };

  // const formikAddCustomer = useFormik({
  //   initialValues: AddCustomerInitialValues,
  //   validationSchema: AddCustomerSchema,
  //   onSubmit: async (values, { setStatus, setSubmitting }) => {
  //     addCustomer(values, setSubmitting);
  //   },
  //   onReset: (values, { resetForm }) => {},
  // });

  const addCustomer = async (values) => {
    const {
      contact_name,
      email,
      phone_number,
      mobile_number,
      currency,
      billingAddress,
      billingCity,
      billingZipcode,
      shippingAddress,
      shippingCity,
      shippingZipcode,
      remarks,
      contact_persons,
      emp_id,
      password
    } = values || {};

    let addCustomerObj = {
      contact_type: "customer",
      email: email ? email : "",
      phone_number: phone_number ? phone_number : "",
      mobile_number: mobile_number ? mobile_number : "",
      contact_name: contact_name ? contact_name : "",
      currency,
      billingaddressId: billingAddress ? billingAddress : "",
      billing_cityId: billingCity ? billingCity : "",
      billing_zipcode: billingZipcode ? billingZipcode : "",
      shippingaddressId: shippingAddress ? shippingAddress : "",
      shipping_cityId: shippingCity ? shippingCity : "",
      shipping_zipcode: shippingZipcode ? shippingZipcode : "",
      contact_person: contact_persons,
      remarks,
      password,
      emp_id,
      role_id: "ddeab2f8-cd2d-42f4-8ce1-4f165b876d67"
    };
    enableLoading();
    const addCustomerResponse = await addCustomerApi(addCustomerObj);
    const { data, Error } = addCustomerResponse || {};
    if (Error) {
      disableLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setNewCustomerAdded(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableLoading();
      handleCloseOfAddNewCustomerModal(data);
    }, 2500);
  };
  const intl = useIntl();
  return (
    <>
      <Formik
        initialValues={multipleContactDetails}
        validationSchema={AddCustomerSchema}
        onSubmit={(values) => {
          addCustomer(values);
        }}
        onReset={(values) => {}}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue,
          errors,
          touched
        }) => (
          <Form
            className='form form-label-right filter-card'
            autoComplete='off'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(multipleContactDetails);

              handleReset();
            }}
          >
            <Modal
              size='lg'
              show={open}
              onHide={onCloseModal}
              aria-labelledby='example-modal-sizes-title-lg'
              dialogClassName='addvendor-modal modal'
              scrollable={true}
            >
              <Modal.Header
                className='d-flex justify-content-between align-items-center'
                onHide={onCloseModal}
              >
                <h3 className='modal-title' id='exampleModalLabel2'>
                  <FormattedMessage id='TITLE.ADD.NEW.EMPLOYEE' />
                </h3>
                <Button
                  variant='link p-0 btn-icon'
                  onClick={() => onCloseModal()}
                >
                  <X size={20} className='text-dark mr-0 pr-0' />
                </Button>
              </Modal.Header>
              <Modal.Body className='pb-0'>
                {isNewCustomerAdded && (
                  <div className='notification icon inline success fs-16'>
                    <Check size={8} className='text-white'></Check>
                    <FormattedMessage id='TITLE.NEW.EMPLOYEE.HAS.BEEN.SUCCESSFULLY.CREATED' />
                  </div>
                )}
                <Form autoComplete='off'>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor='vendorname'>
                          <FormattedMessage id='TITLE.EMPLOYEE.DISPLAY.NAME' />
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          id='customername'
                          name='contact_name'
                          placeholder={intl.formatMessage({
                            id: "TITLE.EMPLOYEE.DISPLAY.NAME"
                          })}
                          onChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(/[0-9]/g, "");
                            setFieldValue(`contact_name`, value);
                          }}
                          value={values.contact_name}
                        />
                        {touched.contact_name && errors.contact_name ? (
                          <div className='text-danger'>
                            {InitLanguage(errors.contact_name)}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor='customeremail'>
                          <FormattedMessage id='TITLE.EMPLOYEE.EMAIL.ADDRESS' />
                          {/* <span className="text-danger">*</span> */}
                        </Form.Label>
                        <Form.Control
                          type='email'
                          id='customeremail'
                          name='email'
                          placeholder={intl.formatMessage({
                            id: "TITLE.EMPLOYEE.EMAIL.ADDRESS"
                          })}
                          onChange={(e) => {
                            setFieldValue(`email`, e.target.value);
                          }}
                          value={values.email}
                        />
                        {touched.email && errors.email ? (
                          <div className='text-danger'>{errors.email}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor='customermobile'>
                          <FormattedMessage id='TITLE.EMPLOYEE.MOBILE.NUMBER' />
                          {/* <span className="text-danger">*</span> */}
                        </Form.Label>
                        <Form.Control
                          type='number'
                          id='customermobile'
                          name='mobile_number'
                          placeholder={intl.formatMessage({
                            id: "TITLE.EMPLOYEE.MOBILE.NUMBER"
                          })}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) => {
                            setFieldValue(`mobile_number`, e.target.value);
                          }}
                          onPaste={(evt) => {
                            const values = evt.clipboardData.getData("text");
                            const nonAlpha = values.replace(/[^\d.-]/g, "");
                            evt.preventDefault();
                            setFieldValue("mobile_number", nonAlpha);
                          }}
                          value={values.mobile_number}
                        />
                        {errors.mobile_number ? (
                          <div className='text-danger'>
                            {InitLanguage(errors.mobile_number)}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor='username'>
                          <FormattedMessage id='TITLE.EMPLOYEE.ID' />
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          id='emp_id'
                          name='emp_id'
                          placeholder={intl.formatMessage({
                            id: "TITLE.EMPLOYEE.ID"
                          })}
                          onChange={(e) => {
                            setFieldValue(`emp_id`, e.target.value);
                          }}
                          onPaste={(evt) => {
                            const values = evt.clipboardData.getData("text");
                            evt.preventDefault();
                            setFieldValue("emp_id", values);
                          }}
                        />
                        {errors.emp_id ? (
                          <div className='text-danger'>
                            {InitLanguage(errors.emp_id)}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label htmlFor='username'>
                          <FormattedMessage id='TITLE.PASSWORD' />
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='password'
                          id='password'
                          name='password'
                          placeholder={intl.formatMessage({
                            id: "TITLE.PASSWORD"
                          })}
                          onChange={(e) => {
                            setFieldValue(`password`, e.target.value);
                          }}
                        />
                        {errors.password ? (
                          <div className='text-danger'>
                            {InitLanguage(errors.password)}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='success'
                  type='button'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    handleSubmit();
                  }}
                  disabled={isLoading}
                >
                  <FormattedMessage id='TITLE.SAVE' />
                  {isLoading && <ButtonLoader className='mr-1 ml-1' />}
                </Button>
                <Button
                  type='button'
                  variant='tertiary text-dark'
                  onClick={() => onCloseModal("VaryingMdo")}
                >
                  <FormattedMessage id='TITLE.CANCEL' />
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
