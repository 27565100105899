import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { withRouter } from "react-router-dom";
import { Form, Button, InputGroup } from "react-bootstrap";
import { SuccessModal } from "../common/modals/SuccessModal";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from "formik";
import { forgotPasswordApi, loginApi } from "../../axios/services/services";
import {
  ForgotPasswordSchema,
  LoginSchema
} from "../../../src/helper/FormikSchema";
import { ErrorModal } from "../common/modals/ErrorModal";
import { ButtonLoader } from "../../helper/ButtonLoader";
import { AUTH_TOKEN, USER_DETAILS } from "../../constants/const";
import {
  getErrorMessageBasedOnLanguage,
  InitLanguage,
  unauthorization
} from "../../constants/utils";
import { useHistory } from "react-router-dom";

let LoginInitialValues = {
  emp_id: "",
  password: ""
};

let ForgotPasswordInitialValues = {
  emp_id: ""
};

const LoginTabset = () => {
  const intl = useIntl();
  const history = useHistory();

  const [isChangePasswordForm, setChangePasswordForm] = useState(false);
  const [isPasswordView, setPasswordView] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingForgotPassword, setLoadingForgotPassword] = useState(false);

  const setChangePasswordFormShow = () => {
    setChangePasswordForm(true);
  };

  const setChangePasswordFormHide = () => {
    setChangePasswordForm(false);
  };

  const setPasswordViewShow = () => {
    setPasswordView(true);
  };

  const setPasswordViewHide = () => {
    setPasswordView(false);
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingForgotPassword = () => {
    setLoadingForgotPassword(true);
  };

  const disableLoadingForgotPassword = () => {
    setLoadingForgotPassword(false);
  };

  const formikLogin = useFormik({
    enableReinitialize: true,
    initialValues: LoginInitialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      try{
        const { emp_id, password } = values || {};
        let loginObj = {
          emp_id,
          password
        };
        const loginResponse = await loginApi(loginObj);
        const { data, accessibility, Error } = loginResponse || {};
        if (Error) {
          disableLoading();
          setErrorMessage(getErrorMessageBasedOnLanguage(Error));
          handleErrorModalShow();
          return;
        }
        const { id } = accessibility || {};
        localStorage.setItem(AUTH_TOKEN, id);
        localStorage.setItem(USER_DETAILS, JSON.stringify(data));
        disableLoading();
        if (data.role?.name === "Employee") {
          history.push("/outwards/outward-listing");
        }
        window.location.reload();
      }
      catch (e) {
        console.log("error")
        setErrorMessage(getErrorMessageBasedOnLanguage(e.message ?? "SomeThing Went Wrong"))
      }
    },
    onReset: (values, { resetForm }) => {}
  });

  const formikForgotPassword = useFormik({
    enableReinitialize: true,
    initialValues: ForgotPasswordInitialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoadingForgotPassword();
      const { emp_id } = values || {};
      let forgotPasswordObj = {
        emp_id
      };
      const forgotPasswordResponse = await forgotPasswordApi(forgotPasswordObj);
      const { Error } = forgotPasswordResponse || {};
      if (Error) {
        disableLoadingForgotPassword();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setSuccessMessage(
        intl.formatMessage({
          id: "TITLE.FORGOT.PASSWORD.REQUEST.SUBMITTED.SUCCESSFULLY"
        })
      );
      onOpenSuccessModal();
      formikForgotPassword.setFieldValue("emp_id", "");
      disableLoadingForgotPassword();
    },
    onReset: (values, { resetForm }) => {}
  });

  return (
    <div>
      {isChangePasswordForm ? (
        <>
          <h2 className='f-w-900 modal-title'>
            <FormattedMessage id='TITLE.REQUEST.FOR.CHANGE.PASSWORD' />
          </h2>
          <p>
            <FormattedMessage id='TITLE.TO.CHANGE.ASSET.SYSTEM.PASSWORD' />
          </p>
          <Form
            className='form-horizontal auth-form mt-4'
            autoComplete='off'
            onSubmit={(e) => {
              e.preventDefault();
              formikForgotPassword.handleSubmit();
            }}
            onReset={formikForgotPassword.handleReset}
          >
            <Form.Group className='mt-4'>
              <Form.Label>
                <FormattedMessage id='TITLE.EMPLOYEE.ID' />
                <span className='text-danger'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                className='form-control'
                id='emp_id'
                placeholder={intl.formatMessage({ id: "TITLE.EMPLOYEE.ID" })}
                name='emp_id'
                {...formikForgotPassword.getFieldProps("emp_id")}
              />
              {formikForgotPassword.touched.emp_id &&
              formikForgotPassword.errors.emp_id ? (
                <div className='text-danger'>
                  {formikForgotPassword.errors.emp_id}
                </div>
              ) : null}
            </Form.Group>
            <div className='form-terms'>
              <div className='custom-control custom-checkbox pl-0'>
                <Form.Label className='d-block'>
                  <span className='pull-right'>
                    <div
                      className='d-flex align-items-center p-0 cursor-pointer'
                      onClick={() => setChangePasswordFormHide()}
                    >
                      <FormattedMessage id='TITLE.BACK' />
                    </div>
                  </span>
                </Form.Label>
              </div>
            </div>
            <div className='form-button'>
              <Button
                variant='primary'
                type='submit'
                className='btn-block f-w-600'
                disabled={isLoadingForgotPassword}
              >
                <FormattedMessage id='TITLE.SUBMIT' />
                {isLoadingForgotPassword && (
                  <ButtonLoader className={"mr-1 ml-1"} />
                )}
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <>
          <h2 className='f-w-900 modal-title'>
            <FormattedMessage id='TITLE.SIGN.IN' />
          </h2>
          <p>
            <FormattedMessage id='TITLE.TO.ACCESS.ASSET.SYSTEM' />
          </p>
          <form
            className='form-horizontal auth-form mt-4'
            onSubmit={formikLogin.handleSubmit}
            autoComplete='off'
          >
            <Form.Group className='mt-4'>
              <Form.Label>
                <FormattedMessage id='TITLE.EMPLOYEE.ID' />
                <span className='text-danger'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                className='form-control'
                id='emp_id'
                placeholder={intl.formatMessage({ id: "TITLE.EMPLOYEE.ID" })}
                name='emp_id'
                {...formikLogin.getFieldProps("emp_id")}
              />
              {formikLogin.touched.emp_id && formikLogin.errors.emp_id ? (
                <div className='text-danger'>
                  {InitLanguage(formikLogin.errors.emp_id)}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>
                <FormattedMessage id='TITLE.PASSWORD' />
                <span className='text-danger'>*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type={isPasswordView ? "text" : "password"}
                  className='form-control'
                  id='password'
                  placeholder={intl.formatMessage({ id: "TITLE.PASSWORD" })}
                  name='password'
                  {...formikLogin.getFieldProps("password")}
                />
                <InputGroup.Text id='basic-addon1'>
                  {isPasswordView ? (
                    <EyeOff
                      size={16}
                      onClick={() => setPasswordViewHide()}
                    ></EyeOff>
                  ) : (
                    <Eye size={16} onClick={() => setPasswordViewShow()}></Eye>
                  )}
                </InputGroup.Text>
              </InputGroup>
              {formikLogin.touched.password && formikLogin.errors.password ? (
                <div className='text-danger'>
                  {InitLanguage(formikLogin.errors.password)}
                </div>
              ) : null}
            </Form.Group>
            <div className='form-terms'>
              <div className='custom-control custom-checkbox pl-0'>
                <Form.Label className='d-block'>
                  <span className='pull-right'>
                    <div
                      className='text-primary p-0 cursor-pointer'
                      onClick={() => setChangePasswordFormShow()}
                    >
                      <FormattedMessage id='TITLE.REQUEST.FOR.PASSWORD.CHANGE' />
                    </div>
                  </span>
                </Form.Label>
              </div>
            </div>
            <div className='form-button'>
              <Button
                variant='primary'
                type='submit'
                className='btn-block f-w-600 text-uppercase'
                disabled={isLoading}
              >
                <FormattedMessage id='TITLE.SIGN.IN' />
                {isLoading && <ButtonLoader className={"mr-1 ml-1"} />}
              </Button>
            </div>
          </form>
        </>
      )}
      {openSuccessModal && (
        <SuccessModal
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
          message={successMessage}
        />
      )}
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </div>
  );
};

export default withRouter(LoginTabset);
