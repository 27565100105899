import React, { useRef, useState, useEffect, useContext } from "react";
import { BrandLoadingDialog } from "./brand-loading-dialog/BrandLoadingDialog";
import { BrandCard } from "./BrandCard";
import { BrandUIProvider } from "./BrandUlContext";
import SVG from "react-inlinesvg";
import { initialFilter } from "./BrandUlHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { toAbsoluteUrl } from "../../helper/helper";
import { EditBrandModal } from "./edit-new-brand/EditBrandModal";
import { AddNewBrandModal } from "./add-new-brand/AddNewBrandModal";
import {
  brandListMastersApi,
  deleteBrandApi
} from "../../axios/services/services";
import { SuccessModal } from "../common/modals/SuccessModal";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization
} from "../../constants/utils";
import { useIntl } from "react-intl";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { DeleteBrandModal } from "../common/modals/DeleteBrandModal";
import { useFormik } from "formik";
import { DeleteBrandSchema } from "../../helper/FormikSchema";
import { DeleteModal } from "../common/modals/DeleteModal";

const DeleteBrandInitialValues = {
  brandId: ""
};

export function BrandPage({ history }) {
  const intl = useIntl();
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [isOpenEditBrandModal, setOpenEditBrandModal] = useState(false);
  const [ids, setIds] = useState([]);
  const [isOpenAddNewBrandModal, setOpenAddNewBrandModal] = useState(false);
  const exportCSVButton = useRef();
  const [brandList, setBrandList] = useState([]);
  const [brandListTotalCount, setBrandListTotalCount] = useState();
  const [isUpdateBrandDetails, setUpdateBrandDetails] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isLoadingDeleteBrand, setLoadingDeleteBrand] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const uiContext = useContext(UIContext);

  async function getBrandList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const BrandListResponse = await brandListMastersApi({
      queryParams
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count } = BrandListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setBrandList(data ? data : []);
    setBrandListTotalCount(count ? count : 0);
  }

  const deleteBrand = async (values) => {
    const { brandId } = values || {};
    const { id } = data;
    enableLoadingDeleteBrand();
    const deleteBrandResponse = await deleteBrandApi({ id, brandId });
    const { Error } = deleteBrandResponse || {};
    if (Error) {
      disableLoadingDeleteBrand();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.BRAND.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    window.scrollTo(0, 0);
    setUpdateBrandDetails(Math.random());
    disableLoadingDeleteBrand();
  };

  useEffect(() => {
    getBrandList();
  }, [queryParams, isUpdateBrandDetails]);

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const enableLoadingDeleteBrand = () => {
    setLoadingDeleteBrand(true);
  };

  const disableLoadingDeleteBrand = () => {
    setLoadingDeleteBrand(false);
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const addNewBrandModalShow = () => {
    setOpenAddNewBrandModal(true);
  };

  const addNewBrandModalHide = () => {
    setOpenAddNewBrandModal(false);
  };

  const editBrandModalShow = () => {
    setOpenEditBrandModal(true);
  };

  const editBrandModalHide = () => {
    setOpenEditBrandModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const brandUIEvents = {
    openEditDialog: (row) => {
      setData(row);
      editBrandModalShow();
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    }
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    addNewBrandModalShow();
  };

  const handleCloseOfAddNewBrandModal = (addNewVendorDetails) => {
    setUpdateBrandDetails(Math.random());
    addNewBrandModalHide();
  };

  const addNewBrandModalFromDelete = () => {
    setBrandList([]);
    setUpdateBrandDetails(Math.random());
  };

  const handleCloseOfEditNewBrandModal = (editNewVendorDetails) => {
    setUpdateBrandDetails(Math.random());
    editBrandModalHide();
  };

  const handleCLickOfFilterButtonFilter = (values) => {
    setFilter(true);
  };
  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const onClickOfDeleteButton = () => {
    deleteBrand({});
  };

  const formikDeleteBrand = useFormik({
    initialValues: DeleteBrandInitialValues,
    validationSchema: DeleteBrandSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      deleteBrand(values);
    },
    onReset: (values, { resetForm }) => {}
  });

  return (
    <div>
      <>
        <BrandUIProvider
          brandUIEvents={brandUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <BrandLoadingDialog />
          {isOpenAddNewBrandModal && (
            <AddNewBrandModal
              open={isOpenAddNewBrandModal}
              onCloseModal={addNewBrandModalHide}
              handleCloseOfAddNewBrandModal={handleCloseOfAddNewBrandModal}
            />
          )}

          {isOpenEditBrandModal && (
            <EditBrandModal
              open={isOpenEditBrandModal}
              data={data}
              onCloseModal={editBrandModalHide}
              id={data.id}
              handleCloseOfEditNewBrandModal={handleCloseOfEditNewBrandModal}
            />
          )}

          <BrandCard
            isLoading={isLoading}
            brandList={brandList}
            // handleFilterValues={handleFilterValues}
            //filterValues={filterValues}
            loadingExportButton={loadingExportButton}
            transactionListExport={brandList}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            brandListTotalCount={brandListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            isLoadingFilter={isLoadingFilter}
            isFilterOpen={isFilterOpen}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
          />
        </BrandUIProvider>

        {isDeleteModalOpen && data?.total_products && (
          <DeleteBrandModal
            isLoading={isLoadingDeleteBrand}
            title={`${intl.formatMessage({
              id: "TITLE.DELETE.BRAND"
            })}`}
            description={intl.formatMessage({
              id: "TITLE.BRAND.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
            })}
            reassignDescription={intl.formatMessage({
              id: "TITLE.ALL.THE.PRODUCTS.BELONGING.TO.THIS.BRAND.WILL.BE.ASSIGNED.TO.THE.SELECTED.BRAND.BELOW"
            })}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={capitalizeFirstLetter(data?.name)}
            brandDetails={data}
            brandList={brandList}
            formikDeleteBrand={formikDeleteBrand}
            addNewBrandModalFromDelete={addNewBrandModalFromDelete}
          />
        )}

        {isDeleteModalOpen && !data?.total_products && (
          <DeleteModal
            isLoading={isLoadingDeleteBrand}
            title={`${intl.formatMessage({
              id: "TITLE.DELETE.BRAND"
            })}`}
            description={intl.formatMessage({
              id: "TITLE.BRAND.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
            })}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={capitalizeFirstLetter(data?.name)}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className='danger-icon-wrapper text-center'>
              <span className='svg-icon menu-icon svg-icon-white svg-icon-4x mr-0'>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />
        {openSuccessModal && (
          <SuccessModal
            message={successMessage}
            open={openSuccessModal}
            onCloseModal={onCloseSuccessModal}
          />
        )}
      </>
    </div>
  );
}
