import React, { useContext, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { X, Check } from "react-feather";
import { useFormik } from "formik";
import { AddCategorySchema } from "../../../helper/FormikSchema";
import { addCategoryApi } from "../../../axios/services/services";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getErrorMessageBasedOnLanguage,
  setActiveMenu
} from "../../../constants/utils";
import { unauthorization } from "../../../constants/utils";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";
import { SET_TIMEOUT } from "../../../constants/const";

export function AddNewCategoryModal({
  searchedCategoryName,
  onCloseModal,
  open,
  handleCloseOfAddNewCategoryModal
}) {
  let AddCategoryInitialValues = {
    name: searchedCategoryName,
    description: "",
    depreciation_value: 0,
    hasDeprecationValue: false
  };

  const intl = useIntl();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isNewCategoryAdded, setNewCategoryAdded] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const uiContext = useContext(UIContext);

  const formikAddCategory = useFormik({
    initialValues: AddCategoryInitialValues,
    validationSchema: AddCategorySchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const { name, description, depreciation_value, hasDeprecationValue } =
        values || {};
      let addCategoryObj = {
        name,
        description
      };
      if (hasDeprecationValue) {
        addCategoryObj = {
          ...addCategoryObj,
          depreciation_value: Number(depreciation_value)
        };
      }
      const addCategoryResponse = await addCategoryApi(addCategoryObj);
      const { data, Error } = addCategoryResponse || {};
      if (Error) {
        disableLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setNewCategoryAdded(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableLoading();
        handleCloseOfAddNewCategoryModal(data);
      }, SET_TIMEOUT);
    },
    onReset: (values, { resetForm }) => {}
  });

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Modal
        size='md'
        show={open}
        onHide={onCloseModal}
        dialogClassName='inventory-small-modal'
        animation={false}
      >
        <Modal.Header className='d-flex justify-content-between align-items-center'>
          <h3 className='modal-title' id='exampleModalLabel2'>
            <FormattedMessage id='TITLE.ADD.NEW.CATEGORY' />
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {isNewCategoryAdded && (
            <div className='notification icon inline success fs-16'>
              <Check size={8} className='text-white'></Check>{" "}
              <FormattedMessage id='TITLE.NEW.CATEGORY.HAS.BEEN.SUCCESSFULLY.CREATED' />
            </div>
          )}
          <form
            autoComplete='off'
            onSubmit={(e) => {
              e.preventDefault();
              window.scrollTo(0, 0);
              formikAddCategory.handleSubmit();
            }}
            onReset={() => {
              formikAddCategory.handleReset();
            }}
          >
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='categoryname'>
                    <FormattedMessage id='TITLE.CATEGORY.NAME' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='categoryname'
                    name='name'
                    required
                    placeholder={intl.formatMessage({
                      id: "TITLE.CATEGORY.NAME"
                    })}
                    autoFocus
                    {...formikAddCategory.getFieldProps("name")}
                  />
                  {formikAddCategory.touched.name &&
                  formikAddCategory.errors.name ? (
                    <div className='text-danger'>
                      {formikAddCategory.errors.name}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='Status'>
                    <FormattedMessage id='TITLE.DESCRIPTION' />
                    <span className='mr-1 ml-1 text-muted'>
                      (
                      <FormattedMessage id='TITLE.MAX.512.CHARACTERS' />)
                    </span>
                  </Form.Label>
                  <Form.Control
                    id='description'
                    name='description'
                    as='textarea'
                    placeholder={intl.formatMessage({
                      id: "TITLE.DESCRIPTION"
                    })}
                    className='form-control'
                    resize='none'
                    maxLength={512}
                    {...formikAddCategory.getFieldProps("description")}
                  />
                  {formikAddCategory.touched.description &&
                  formikAddCategory.errors.description ? (
                    <div className='text-danger'>
                      {formikAddCategory.errors.description}
                    </div>
                  ) : null}
                </Form.Group>
                {/* Add Checkbox */}
                <Form.Check
                  type='checkbox'
                  id='hasDeprecationValue'
                  label='Has deprecation value?'
                  name='hasDeprecationValue'
                  {...formikAddCategory.getFieldProps("hasDeprecationValue")}
                />
              </Col>
              {formikAddCategory.values.hasDeprecationValue && (
                <Col md={12} className='mt-3'>
                  <Form.Group>
                    <Form.Label htmlFor='depreciation_value'>
                      <FormattedMessage id='TITLE.DEPRECATION.VALUE' />
                      <span className='text-danger'>*</span>
                    </Form.Label>
                    {/* Step 3: Conditionally render based on checkbox state */}
                    <Form.Control
                      type='text'
                      id='depreciation_value'
                      name='depreciation_value'
                      required
                      placeholder={intl.formatMessage({
                        id: "TITLE.DEPRECATION.VALUE"
                      })}
                      {...formikAddCategory.getFieldProps("depreciation_value")}
                    />
                    {formikAddCategory.touched.depreciation_value &&
                    formikAddCategory.errors.depreciation_value ? (
                      <div className='text-danger'>
                        {formikAddCategory.errors.depreciation_value}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              )}
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            variant='success'
            onClick={() => {
              window.scrollTo(0, 0);
              formikAddCategory.handleSubmit();
            }}
            disabled={isLoading}
          >
            <FormattedMessage id='TITLE.SAVE' />
            {isLoading && <ButtonLoader className='mr-1 ml-1' />}
          </Button>
          <Button
            type='reset'
            variant='tertiary text-dark'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id='TITLE.CANCEL' />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
