import { isEqual, isFunction } from "lodash";
import React, { createContext, useCallback, useContext } from "react";

const AssetUIContext = createContext();

export function useAssetUIContext() {
  return useContext(AssetUIContext);
}

export const AssetUIConsumer = AssetUIContext.Consumer;

export function AssetUIProvider({
  assetUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: assetUIEvents.openViewDialog,
    openEditDialog: assetUIEvents.openEditDialog,
    openDeleteDialog: assetUIEvents.openDeleteDialog,
    redirectToViewPage: assetUIEvents.redirectToViewPage,
    redirectToCategoryViewPage: assetUIEvents.redirectToCategoryViewPage
  };

  return (
    <AssetUIContext.Provider value={value}>{children}</AssetUIContext.Provider>
  );
}
