import React, { useContext, useEffect, useState } from "react";
import { DelayedPODCCard } from "./DelayedPODCCard";
import { DelayedPODCUIProvider } from "./DelayedPODCUIContext";
import { initialFilter } from "./DelayedPODCUIHelpers";
import { ErrorModal } from "../../common/modals/ErrorModal";
import {
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  stringToDateWithFormat,
  unauthorization
} from "../../../constants/utils";
import moment from "moment";
import {
  STR_CSV,
  STR_EXCEL,
  STR_PDF,
  YYYY_MM_DD
} from "../../../constants/const";
import {
  reportDelayedPODCApi,
  reportDelayedPODCExportApi,
  reportDelayedPODCPrintApi
} from "../../../axios/services/services";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";

export function DelayedPODCPage({ history }) {
  const uiContext = useContext(UIContext);
  const [reportDelayedPODC, setReportDelayedPODC] = useState([]);
  const [reportDelayedPODCTotalCount, setReportDelayedPODCTotalCount] =
    useState(0);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDateOption, setselectedDateOption] = useState("This Month");
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  async function getReportDelayedPODC() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const reportDelayedPODCResponse = await reportDelayedPODCApi({
      queryParams
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, startdate, enddate, count } =
      reportDelayedPODCResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setReportDelayedPODC(data ? data : []);
    setStartDate(startdate);
    setEndDate(enddate);
    setReportDelayedPODCTotalCount(count);
  }

  useEffect(() => {
    getReportDelayedPODC();
  }, [queryParams]);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const delayedpodcUIEvents = {
    openViewDialog: (row) => {},
    openEditDialog: (row) => {},
    openDeleteDialog: (row) => {},
    redirectToPODetailsPage: (row) => {
      if (row?.type) {
        if (row?.type === "dc") {
          const path = `/outwards/view-dc-details/${row.id}`;
          onClickChangeRoute(path);
        }
        if (row?.type === "po") {
          const path = `/inwards/view-po-details/${row.id}`;
          onClickChangeRoute(path);
        }
      }
    }
  };

  // Excel, PDF, CSV
  const handleExport = (docType) => {
    getReportDelayedPODCExport(docType);
  };

  const handlePrint = () => {
    getReportDelayedPODCPrint();
  };

  async function getReportDelayedPODCExport(docType) {
    let extention = "";
    if (docType === "Excel") {
      extention = STR_EXCEL;
    }
    if (docType === "PDF") {
      extention = STR_PDF;
    }
    if (docType === "CSV") {
      extention = STR_CSV;
    }
    const reportDelayedPODCResponse = await reportDelayedPODCExportApi({
      queryParams,
      docType,
      filename: "delayed-PODC" + new Date(),
      extention: extention
    });
    const { Error } = reportDelayedPODCResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
  }

  async function getReportDelayedPODCPrint() {
    const reportDelayedPODCResponse = await reportDelayedPODCPrintApi({
      queryParams
    });

    const { Error } = reportDelayedPODCResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    var fils = new Blob([reportDelayedPODCResponse.res.data], {
      type: "application/pdf"
    });
    var fileURLs = URL.createObjectURL(fils);
    setPdfUrl(fileURLs);
    setTimeout(() => {
      const iframe = document.getElementById("myframe");
      const iframeWindow = iframe.contentWindow || iframe;
      iframe.focus();
      iframeWindow.print();
    }, 50);
  }

  const handleCLickOfFilterButtonFilter = async () => {
    setFilter(true);
  };

  const handleCLickOfResetButtonFilter = () => {
    setselectedDateOption("This Month");
  };

  const handleSelectedDateOption = (selectedDateOption) => {
    setselectedDateOption(selectedDateOption);
  };

  const handleClickOfDateFilterButton = (chosenLabel, fromDate, endDate) => {
    let label = chosenLabel;
    label = chosenLabel.toLowerCase();
    if (label === "last 7 days") {
      label = "lastweek";
    }
    if (label === "last 30 days") {
      label = "last30days";
    }
    if (label === "this month") {
      label = "thismonth";
    }
    if (label === "last month") {
      label = "lastmonth";
    }
    if (label === "last year") {
      label = "lastyear";
    }
    if (label === "custom range") {
      label = "customrange";
    }
    const dateFrom = stringToDateWithFormat(fromDate);
    const dateEnd = stringToDateWithFormat(endDate);
    const dateFromMoment = moment(dateFrom).format(YYYY_MM_DD);
    const dateEndMoment = moment(dateEnd).format(YYYY_MM_DD);

    const newQueryParams = { ...queryParams };
    const { filter } = newQueryParams || {};
    filter.date = label;
    filter.fromdate = dateFromMoment;
    filter.todate = dateEndMoment;
    newQueryParams.pageNumber = 1;
    setQueryParamsBase(newQueryParams);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <div>
      <>
        <DelayedPODCUIProvider
          delayedpodcUIEvents={delayedpodcUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <DelayedPODCCard
            isLoading={isLoading}
            isLoadingFilter={isLoadingFilter}
            startDate={startDate}
            endDate={endDate}
            selectedDateOption={selectedDateOption}
            reportDelayedPODC={reportDelayedPODC}
            reportDelayedPODCTotalCount={reportDelayedPODCTotalCount}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            handleCLickOfResetButtonFilter={handleCLickOfResetButtonFilter}
            handleClickOfDateFilterButton={handleClickOfDateFilterButton}
            handleExport={handleExport}
            handlePrint={handlePrint}
            handleSelectedDateOption={handleSelectedDateOption}
          />
        </DelayedPODCUIProvider>

        {isErrorModalOpen && (
          <ErrorModal
            message={errorMessage}
            isErrorModalOpen={isErrorModalOpen}
            hideErrorModal={handleErrorModalHide}
          />
        )}

        <iframe
          className='d-none'
          src={pdfUrl}
          name='myframe'
          id='myframe'
          title='myframe'
        ></iframe>
      </>
    </div>
  );
}
