import React, { useContext, useEffect, useState } from "react";
import { OutwardLoadingDialog } from "./outward-loading-dialog/OutwardLoadingDialog";
import { OutwardCard } from "./OutwardCard";
import { OutwardUIProvider } from "./OutwardUIContext";
import { initialFilter } from "./OutwardUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { DeleteModal } from "../common/modals/DeleteModal";
import {
  agreeStatusPOApi,
  DCListApi,
  deleteDCApi,
  editDCStatusApi,
  POStatusListApi
} from "../../axios/services/services";
import { SuccessModal } from "../common/modals/SuccessModal";
import { EditStatusDCModal } from "../../global-modal/EditStatusDCModal";
import { setActiveMenu, unauthorization } from "../../constants/utils";
import { useIntl } from "react-intl";
import { getErrorMessageBasedOnLanguage } from "../../constants/utils";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { USER_DETAILS } from "../../constants/const";
import { ConfirmationModal } from "../common/modals/ConfirmationModal";
import { Check, X } from "react-feather";

export function OutwardPage({ history, ...props }) {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingConfirmationAgreeStatus, setLoadingConfirmationAgreeStatus] =
    useState(false);
  const [data, setData] = useState({});
  const [DCList, setDCList] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditStatusModal, setEditStatusModal] = useState(false);
  const [
    isConfirmationAgreeStatusModalOpen,
    setConfirmationAgreeStatusModalOpen
  ] = useState(false);
  const [
    isConfirmationDisagreeStatusModalOpen,
    setConfirmationDisagreeStatusModalOpen
  ] = useState(false);
  const [
    isLoadingConfirmationDisagreeStatus,
    setLoadingConfirmationDisagreeStatus
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [DCListTotalCount, setDCListTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [isUpdateDCDetails, setUpdateDCDetails] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [DCStatusList, setDCStatusList] = useState(null);
  const [isLoadingDeleteOutward, setLoadingDeleteOutward] = useState(false);
  const [isLoadingEditDCStatus, setLoadingEditDCStatus] = useState(false);
  const [isEditDCStatusSuccess, setEditDCStatusSuccess] = useState(false);
  const [selectedDCStatus, setSelectedDCStatus] = useState(null);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  async function getDCStatusList() {
    // enableLoading();
    const DCStatusListResponse = await POStatusListApi({});
    const { Error, get_dc_status } = DCStatusListResponse || {};
    // disableLoading();
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const dcStatusList =
      get_dc_status &&
      get_dc_status.length &&
      get_dc_status.map((status) => {
        const { id, name } = status || {};
        status.label = name;
        status.value = id;
        return status;
      });
    setDCStatusList(dcStatusList ? dcStatusList : []);
  }

  async function getDCList() {
    if (isFilter) {
      enableLoadingFilter();
    }
    enableLoading();
    var path = window.location.pathname;
    var id = path.split("/");

    var customerIdViaFilter = id[3];

    const [deliveredStatus] =
      DCStatusList &&
      DCStatusList.filter((status) => {
        return status.name === "Delivered";
      });

    const DCListResponse = await DCListApi({
      queryParams,
      customerIdViaFilter,
      statusId: deliveredStatus?.id
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count } = DCListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setDCList(data ? data : []);
    setDCListTotalCount(count ? count : 0);
  }

  useEffect(() => {
    getDCStatusList();
  }, []);

  useEffect(() => {
    if (DCStatusList) {
      getDCList();
    }
  }, [queryParams, isUpdateDCDetails, DCStatusList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const deleteOutward = async () => {
    const { id } = data;
    enableLoadingDeleteOutward();
    const deleteCustmerResponse = await deleteDCApi({ id });
    const { Error } = deleteCustmerResponse || {};
    if (Error) {
      disableLoadingDeleteOutward();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.CHECKOUT.REQUEST.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    setUpdateDCDetails(Math.random());
    disableLoadingDeleteOutward();
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const enableLoadingDeleteOutward = () => {
    setLoadingDeleteOutward(true);
  };

  const disableLoadingDeleteOutward = () => {
    setLoadingDeleteOutward(false);
  };

  const enableLoadingEditDCStatus = () => {
    setLoadingEditDCStatus(true);
  };

  const disableLoadingEditDCStatus = () => {
    setLoadingEditDCStatus(false);
  };

  const enableLoadingConfirmationAgree = () => {
    setLoadingConfirmationAgreeStatus(true);
  };

  const disableLoadingConfirmationAgree = () => {
    setLoadingConfirmationAgreeStatus(false);
  };

  const enableLoadingConfirmationDisagree = () => {
    setLoadingConfirmationDisagreeStatus(true);
  };

  const disableLoadingConfirmationDisagree = () => {
    setLoadingConfirmationDisagreeStatus(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleEditStatusModalShow = () => {
    setEditStatusModal(true);
  };

  const handleEditStatusModalHide = () => {
    setEditStatusModal(false);
  };

  const handleConfirmationAgreeStatusModalShow = () => {
    setConfirmationAgreeStatusModalOpen(true);
  };

  const handleConfirmationAgreeStatusModalHide = () => {
    setConfirmationAgreeStatusModalOpen(false);
  };

  const handleConfirmationDisagreeStatusModalShow = () => {
    setConfirmationDisagreeStatusModalOpen(true);
  };

  const handleConfirmationDisagreeStatusModalHide = () => {
    setConfirmationDisagreeStatusModalOpen(false);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleCLickOfFilterButtonFilter = () => {
    setFilter(true);
  };

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const handleClickOfEditStatusDCUpdateButton = () => {
    editDeliveryChallanStatus();
  };

  const handleOnClickOfCreateNew = () => {
    const path = "/outwards/add-outward";
    onClickChangeRoute(path);
  };

  const onClickOfConfirmationAgreeStatusButton = () => {
    AgreePurchaseOrderStatus();
  };

  const onClickOfConfirmationDisagreeStatusButton = () => {
    DisagreePurchaseOrderStatus();
  };

  const onClickOfDeleteButton = () => {
    deleteOutward();
  };

  const editDeliveryChallanStatus = async () => {
    const { id } = data || {};
    enableLoadingEditDCStatus();
    const editDCStatusResponse = await editDCStatusApi({
      id,
      purchaseorderstatusId: selectedDCStatus.value
    });
    const { Error } = editDCStatusResponse || {};
    if (Error) {
      disableLoadingEditDCStatus();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setEditDCStatusSuccess(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableLoadingEditDCStatus();
      setEditStatusModal(false);
      setEditDCStatusSuccess(false);
      setUpdateDCDetails(Math.random());
    }, 2500);
  };

  const AgreePurchaseOrderStatus = async () => {
    const { id } = data || {};
    const [DCStatus] = DCStatusList.filter((status) => {
      return status.name === "Approved";
    });
    enableLoadingConfirmationAgree();
    const agreeStatusPOResponse = await agreeStatusPOApi({
      id,
      purchaseorderstatusId: DCStatus.id
    });
    const { Error } = agreeStatusPOResponse || {};
    if (Error) {
      disableLoadingConfirmationAgree();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    window.scrollTo(0, 0);
    handleConfirmationAgreeStatusModalHide();
    setUpdateDCDetails(Math.random());
    disableLoadingConfirmationAgree();
  };

  const DisagreePurchaseOrderStatus = async () => {
    const { id } = data || {};
    enableLoadingConfirmationDisagree();
    const [DCStatus] = DCStatusList.filter((status) => {
      return status.name === "Rejected";
    });
    const disagreeStatusPOResponse = await agreeStatusPOApi({
      id,
      purchaseorderstatusId: DCStatus.id
    });

    const { Error } = disagreeStatusPOResponse || {};
    if (Error) {
      disableLoadingConfirmationDisagree();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    window.scrollTo(0, 0);
    handleConfirmationDisagreeStatusModalHide();
    setUpdateDCDetails(Math.random());
    disableLoadingConfirmationDisagree();
  };

  const outwardUIEvents = {
    openViewDialog: (row) => {
      setData(row);
      const path = `/outwards/view-dc-details/${row.id}`;
      onClickChangeRoute(path);
    },
    openEditDialog: (row) => {
      setData(row);
      // const { purchaseorderstatus } = row || {};
      // const { name } = purchaseorderstatus || {};
      // if (name === "Received") {
      //   setErrorMessage(
      //     intl.formatMessage({
      //       id: "TITLE.YOU.CANT.EDIT.CHECKOUT.REQUEST.ONCE.RECEIVED",
      //     })
      //   );
      //   handleErrorModalShow();
      //   return;
      // }
      const path = `/outwards/edit-outward/${row.id}`;
      onClickChangeRoute(path);
    },
    openEditStatusDialog: (row) => {
      if (userRole === "Asset Manager") {
        setData(row);
        const { purchaseorderstatusId, purchaseorderstatus } = row || {};
        const { name } = purchaseorderstatus || {};
        if (name === "Waiting For Approval") {
          const [POStatus] = DCStatusList.filter((status) => {
            return status.id === purchaseorderstatusId;
          });
          setSelectedDCStatus(POStatus);
          handleEditStatusModalShow();
        }
      }
    },
    openAgreeStatusDialog: (row) => {
      setData(row);
      handleConfirmationAgreeStatusModalShow();
    },
    openDisagreeStatusDialog: (row) => {
      setData(row);
      handleConfirmationDisagreeStatusModalShow();
    },
    openDeleteDialog: (row) => {
      const { purchaseorderstatus } = row || {};
      const { name: purchaseOrderStatus } = purchaseorderstatus || {};
      if (
        purchaseOrderStatus === "Waiting For Approval" ||
        (userRole === "Asset Manager" && purchaseOrderStatus === "Approved")
      ) {
        setData(row);
        handleDeleteModalShow();
      } else {
        setErrorMessage(
          intl.formatMessage({
            id: "TITLE.YOU.CAN.NOT.DELETE.PO"
          })
        );
        handleErrorModalShow();
      }
    },
    redirectToViewPage: (row) => {
      setData(row);
      const path = `/outwards/view-dc-details/${row.id}`;
      onClickChangeRoute(path);
    },
    redirectToCustomerViewPage: (row) => {
      setData(row);
      const path = `/customers/view-customer-details/${row.contactId}`;
      onClickChangeRoute(path);
    }
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <div>
      <>
        {/* <Breadcrumb title="Product List" parent="Products" /> */}
        <OutwardUIProvider
          outwardUIEvents={outwardUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <OutwardLoadingDialog />

          {isEditStatusModal && (
            <EditStatusDCModal
              isEditStatusModal={isEditStatusModal}
              isLoadingEditDCStatus={isLoadingEditDCStatus}
              isEditDCStatusSuccess={isEditDCStatusSuccess}
              data={data}
              DCStatusList={DCStatusList}
              selectedDCStatus={selectedDCStatus}
              setSelectedDCStatus={setSelectedDCStatus}
              handleClickOfEditStatusDCUpdateButton={
                handleClickOfEditStatusDCUpdateButton
              }
              onClickOfEditStatusDCCancelButton={handleEditStatusModalHide}
            />
          )}

          {isConfirmationAgreeStatusModalOpen && (
            <ConfirmationModal
              isLoading={isLoadingConfirmationAgreeStatus}
              title={intl.formatMessage({ id: "TITLE.APPROVE.CR" })}
              icon={
                <Check
                  size={60}
                  className='text-success mb-2'
                  style={{ strokeWidth: "1.5px" }}
                />
              }
              isSuccess={"text-success"}
              variant={"success"}
              description={intl.formatMessage({
                id: "TITLE.ARE.YOU.SURE.YOU.WANT.TO.APPROVE.CHECKOUT.REQUEST"
              })}
              cancelText={intl.formatMessage({ id: "TITLE.CANCEL" })}
              logoutText={intl.formatMessage({ id: "TITLE.YES" })}
              isOpen={isConfirmationAgreeStatusModalOpen}
              onCloseModal={handleConfirmationAgreeStatusModalHide}
              id={data.purchaseorder_number}
              onClickOfConfirmationButton={
                onClickOfConfirmationAgreeStatusButton
              }
            />
          )}

          {isConfirmationDisagreeStatusModalOpen && (
            <ConfirmationModal
              isLoading={isLoadingConfirmationDisagreeStatus}
              title={intl.formatMessage({ id: "TITLE.REJECT.CR" })}
              icon={
                <X
                  size={60}
                  className='text-danger mb-2'
                  style={{ strokeWidth: "1.5px" }}
                />
              }
              isSuccess={"text-danger"}
              variant={"danger"}
              description={intl.formatMessage({
                id: "TITLE.ARE.YOU.SURE.YOU.WANT.TO.REJECT.CHECKOUT.REQUEST"
              })}
              cancelText={intl.formatMessage({ id: "TITLE.CANCEL" })}
              logoutText={intl.formatMessage({ id: "TITLE.YES" })}
              isOpen={isConfirmationDisagreeStatusModalOpen}
              onCloseModal={handleConfirmationDisagreeStatusModalHide}
              id={data.purchaseorder_number}
              onClickOfConfirmationButton={
                onClickOfConfirmationDisagreeStatusButton
              }
            />
          )}

          <OutwardCard
            isLoading={isLoading}
            isLoadingFilter={isLoadingFilter}
            DCList={DCList}
            DCListTotalCount={DCListTotalCount}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            isFilterOpen={isFilterOpen}
            DCStatusList={DCStatusList}
          />
        </OutwardUIProvider>

        {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteOutward}
            title={intl.formatMessage({
              id: "TITLE.DELETE.OUTWARD"
            })}
            description={intl.formatMessage({
              id: "TITLE.OUTWARD.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
            })}
            id={data.purchaseorder_number}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}

        {isErrorModalOpen && (
          <ErrorModal
            message={errorMessage}
            isErrorModalOpen={isErrorModalOpen}
            hideErrorModal={handleErrorModalHide}
          />
        )}

        {openSuccessModal && (
          <SuccessModal
            message={successMessage}
            open={openSuccessModal}
            onCloseModal={onCloseSuccessModal}
          />
        )}
      </>
    </div>
  );
}
