export const defaultSorted = [{ dataField: "id", order: "asc" }];

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];
export const initialFilter = {
  filter: {},
  sortOrder: "", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 10
};

export const statusName = [
  { value: "Active", label: "Active" },
  { value: "Active", label: "Inactive" }
];

export const parentLocationList = [
  { value: "Block 1", label: "Block 1" },
  { value: "Block 2", label: "Block 2" },
  { value: "Block 3", label: "Block 3" },
  { value: "Block 4", label: "Block 4" },
  { value: "Block 5", label: "Block 5" },
  { value: "Block 6", label: "Block 6" },
  { value: "Block 7", label: "Block 7" },
  { value: "Block 8", label: "Block 8" }
];

export const countriesList = [
  { value: "UAE", label: "UAE" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Qatar", label: "Qatar" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Oman", label: "Oman" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Iraq", label: "Iraq" },
  { value: "Afghanistan", label: "Afghanistan" }
];

export const stateList = [
  { value: "Dubai", label: "Dubai" },
  { value: "Abu Dhabi", label: "Abu Dhabi" },
  { value: "Sharjah", label: "Sharjah" },
  { value: "Ajman", label: "Ajman" },
  { value: "Ras Al Khaimah", label: "Ras Al Khaimah" },
  { value: "Fujairah", label: "Fujairah" },
  { value: "Umm al-Quwain", label: "Umm al-Quwain" }
];

export const cityList = [
  { value: "Dubai", label: "Dubai" },
  { value: "Abu Dhabi", label: "Abu Dhabi" },
  { value: "Sharjah", label: "Sharjah" },
  { value: "Al Ain", label: "Al Ain" },
  { value: "Kalba", label: "Kalba" },
  { value: "Ruwais", label: "Ruwais" },
  { value: "Liwa Oasis", label: "Liwa Oasis" }
];
