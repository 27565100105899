/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from "react-bootstrap";
import { Check, File, Plus } from "react-feather";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useDropzone } from "react-dropzone";
import { ContentLoader } from "../../../helper/ContentLoader";
import { ThumbnailWrapper } from "../../../helper/ThumbnailWrapper";
import {
  addAssetMediaApi,
  checkInBarcodeApi,
  deleteAssetMediaApi,
  deleteBarcodeApi,
  fetchAssetsListAPI,
  fetchHistoryListBasedOnProductBarcodeAPI,
  getAssetDocumentApi,
  getSingleAssetDetailsApi
} from "../../../axios/services/services";
import { USER_DETAILS } from "../../../constants/const";
import { MENUITEMS } from "../../../constants/menu";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  getMediaUrl,
  setActiveMenu,
  unauthorization
} from "../../../constants/utils";
import { AddNewBarcodeModal } from "../../../global-modal/AddNewBarcodeModal";
import { AddNewProductModal } from "../../../global-modal/AddNewProductModal ";
import { SectionLoader } from "../../../helper/SectionLoader";
import { UIContext } from "../../../helper/UIContext";
import { CheckInModal } from "../../common/modals/CheckInModal";
import { DeleteModal } from "../../common/modals/DeleteModal";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { SuccessModal } from "../../common/modals/SuccessModal";
import { EditAssetModal } from "../edit-asset/EditAssetModal";
import { AssetHistoryView } from "./AssetHistoryView";
import { DefaultAssetView } from "./DefaultAssetView";
import { FILE_EXTENTION, MB_20 } from "../../../constants/const";
import { uploadDocumentApi } from "../../../axios/services/services";
import { Formik } from "formik";
import { AddAssetMediaSchema } from "../../../helper/FormikSchema";
import DocumentTable from "./DocumentTable";
import DeprecatedHistoryView from "./DeprecatedHistoryView";
import MaintanceLogView from "./MaintanceLogView";

const initialQueryParams = { pageNumber: 1, pageSize: 20 };
const initialQueryParamsLoc = { pageNumber: 1, pageSize: 20 };

const ViewAssetDetails = ({ handlePDFViewValues, ...props }) => {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const history = useHistory();
  const [isOpenAddNewProductModal, setOpenAddNewProductModal] = useState(false);
  const [isOpenAddNewBarcodeModal, setOpenAddNewBarcodeModal] = useState(false);
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const [queryParamsLoc, setQueryParamsLoc] = useState(initialQueryParamsLoc);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingAssetListView, setLoadingAssetListView] = useState(false);
  const [isLoadingPOView, setLoadingPOView] = useState(false);
  const [isLoadingLocView, setLoadingLocView] = useState(false);
  const [isLoadingHistoryView, setLoadingHistoryView] = useState(false);
  const [isLoadingDCView, setLoadingDCView] = useState(false);
  const [isLoadingDeleteBarcode, setLoadingDeleteBarcode] = useState(false);
  const [isLoadingCheckInBarcode, setLoadingCheckInBarcode] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [isLoadMoreAssetListView, setLoadMoreAssetListView] = useState(false);
  const [isLoadMoreLocHistoryView, setLoadMoreLocHistoryView] = useState(false);
  const { id, barcodeNo } = props.match.params;
  const [assetDetails, setAssetDetails] = useState({ product: {} });
  const [assetDocument, setAssetDocument] = useState([]);

  const [isUpdateProductListing, setUpdateProductListing] = useState(0);
  const [isUpdateLocationList, setUpdateLocationList] = useState(0);
  const [isUpdateHistoryList, setUpdateHistoryList] = useState(0);
  const [isUpdateSingleAssetDetails, setUpdateSingleAssetDetails] = useState(0);
  const [
    isUpdatePaginationProductListDetails,
    setUpdatePaginationProductListDetails
  ] = useState(0);
  const [
    isUpdatePaginationHistoryListDetails,
    setUpdatePaginationHistoryListDetails
  ] = useState(0);
  const [isOpenEditAssetModal, setOpenEditAssetModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [POSummary, setPOSummary] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [isDeleteBarcodeModalOpen, setIsDeleteBarcodeModalOpen] =
    useState(false);
  const [isCheckInBarcodeModalOpen, setCheckInBarcodeModalOpen] =
    useState(false);

  const [selectedLocation, setSelectedLocation] = useState(false);
  const [isFileUploadLoading, setFileUploadLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadDocument, setUploadDocument] = useState([]);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    assetDocument: [
      {
        filename: "",
        file_type: ""
      }
    ]
  });
  const [docDeleted, setDocDeleted] = useState(false);

  const enableLoadingFileUpload = () => {
    setFileUploadLoading(true);
  };

  const disableLoadingFileUpload = () => {
    setFileUploadLoading(false);
  };

  const attachDocument = async (acceptedFiles) => {
    const uploadedFilesArray = [];
    const maxLengthForDocUpload = 5 - files.length;
    enableLoadingFileUpload();
    for (let index = 0; index < acceptedFiles.length; index++) {
      if (maxLengthForDocUpload === index) {
        break;
      }
      if (MB_20 <= acceptedFiles[index].size) {
        setErrorMessage(
          intl.formatMessage({ id: "TITLE.ATTACHED.FILE.IS.TOO.LARGE" })
        );
        handleErrorModalShow();
        continue;
      }
      let files = {
        file: acceptedFiles[index],
        containername: "asset",
        id
      };
      const uploadDocumentResponse = await uploadDocumentApi(files);
      const [uploadDoc] = uploadDocumentResponse || {};
      uploadedFilesArray.push(uploadDoc);
    }
    disableLoadingFileUpload();
    const filesTemp = [...files, ...uploadedFilesArray];
    setFiles(filesTemp);
    setUploadDocument([...uploadDocument, ...uploadedFilesArray]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    attachDocument(acceptedFiles);
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: FILE_EXTENTION,
    disabled: files.length === 5,
    maxFiles: 5,
    maxFilesize: MB_20,
    autoProcessQueue: true,
    multiple: true,
    onDrop
  });

  const handleOnDeleteOfAttachment = (file, index) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    const newUploadedDocument = [...uploadDocument];
    newUploadedDocument.splice(newUploadedDocument[index], 1);
    setUploadDocument(newUploadedDocument);
  };

  async function getAssetList() {
    const { pageNumber } = queryParams || {};
    if (pageNumber === 1) {
      setLoadingAssetListView(true);
    }
    const ProductListResponse = await fetchAssetsListAPI({
      queryParams
    });
    const { Error, data } = ProductListResponse || {};
    if (Error) {
      if (pageNumber === 1) {
        setLoadingAssetListView(false);
      }
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    if (pageNumber === 1) {
      setAssetList(data);
    } else {
      setAssetList((assetList) => [...assetList, ...data]);
    }

    if (data.length >= 20) {
      setLoadMoreAssetListView(true);
    } else {
      setLoadMoreAssetListView(false);
    }
    if (pageNumber === 1) {
      setLoadingAssetListView(false);
    }
  }

  async function getSingleAssetDetails() {
    setLoading(true);
    const singleAssetDetails = await getSingleAssetDetailsApi({
      id
    });
    const { Error, data } = singleAssetDetails || {};
    if (Error) {
      setLoading(false);
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }

    const singleAssetDocument = await getAssetDocumentApi({
      assetId: id
    });
    const { mediaError, data: mediaData } = singleAssetDocument || {};
    if (mediaError) {
      setLoading(false);
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setAssetDocument(mediaData ? mediaData : []);
    setAssetDetails(data ? data : []);
    setLoading(false);
  }

  async function getHistoryDetails() {
    const productId = assetDetails?.product?.id;
    if (historyList && historyList.length === 0) {
      setLoadingHistoryView(true);
    }
    const historyResponse = await fetchHistoryListBasedOnProductBarcodeAPI({
      queryParams: queryParamsLoc,
      productId,
      barcodeNo: props?.location?.state?.barcodeNo
    });
    const { Error, data } = historyResponse || {};
    if (Error) {
      if (historyList && historyList.length === 0) {
        setLoadingHistoryView(false);
      }
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setHistoryList((historyList) => [...historyList, ...data]);
    if (data.length >= 20) {
      setLoadMoreLocHistoryView(true);
    } else {
      setLoadMoreLocHistoryView(false);
    }
    if (historyList && historyList.length === 0) {
      setLoadingHistoryView(false);
    }
  }

  useEffect(() => {
    getAssetList();
  }, [isUpdatePaginationProductListDetails, isUpdateProductListing]);

  useEffect(() => setHistoryList([]), [id]);

  useEffect(() => getSingleAssetDetails(), [id, isUpdateSingleAssetDetails]);

  useEffect(() => {
    if (assetDetails?.product?.id) getHistoryDetails();
  }, [
    id,
    isUpdatePaginationHistoryListDetails,
    isUpdateHistoryList,
    assetDetails
  ]);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const onOpenSuccessModal = () => setOpenSuccessModal(true);

  const onCloseSuccessModal = () => setOpenSuccessModal(false);

  const handleCloseOfEditNewProductModal = () => {
    setQueryParams(initialQueryParams);
    setAssetList([]);
    setUpdateProductListing(Math.random());
    setUpdateSingleAssetDetails(Math.random());
    editAssetModalHide();
  };

  const handleCancelOfAddNewBarcode = () => addNewBarcodeModalHide();

  const handleAddNewBarcode = () => {
    addNewBarcodeModalHide();
    setQueryParamsLoc(initialQueryParamsLoc);
    setHistoryList([]);
    setUpdateLocationList(Math.random());
    setUpdateHistoryList(Math.random());
    setUpdateSingleAssetDetails(Math.random());
  };

  const enableLoadingDeleteBarcode = () => setLoadingDeleteBarcode(true);

  const disableLoadingDeleteBarcode = () => setLoadingDeleteBarcode(false);

  const enableLoadingCheckInBarcode = () => setLoadingCheckInBarcode(true);

  const disableLoadingCheckInBarcode = () => setLoadingCheckInBarcode(false);

  const editAssetModalHide = () => setOpenEditAssetModal(false);

  const handleErrorModalShow = () => setIsErrorModalOpen(true);

  const handleErrorModalHide = () => setIsErrorModalOpen(false);

  const addNewProductModalShow = () => setOpenAddNewProductModal(true);

  const addNewProductModalHide = () => setOpenAddNewProductModal(false);

  const addNewBarcodeModalHide = () => setOpenAddNewBarcodeModal(false);

  const handleDeleteBarcodeModalHide = () => setIsDeleteBarcodeModalOpen(false);

  const handleCheckInBarcodeModalHide = () => setCheckInBarcodeModalOpen(false);

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const CreateNew = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.CREATE.NEW' />
    </Tooltip>
  );

  const [isReceiveviewOpen, setReceiveviewOpen] = useState(false);

  const handlePaginationOfProductListView = () => {
    const { pageNumber, pageSize } = queryParams || {};
    setQueryParams({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationProductListDetails(Math.random());
  };

  const getSecondaryColorClass = (product) => {
    let className = "fs-14 text-primary font-weight-bold mb-0 cursor-pointer";
    const { id: PID } = product;
    if (PID === id) {
      className = className + " text-secondary";
    }
    return className;
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const handleCloseOfAddNewProductModal = () => {
    setAssetList([]);
    setQueryParams(initialQueryParams);
    setUpdateProductListing(Math.random());
    addNewProductModalHide();
  };

  const onClickOfDeleteBarcodeDeleteButton = async () => {
    const { id } = selectedLocation || {};
    enableLoadingDeleteBarcode();
    const deleteBarcodeResponse = await deleteBarcodeApi({
      id
    });
    const { Error } = deleteBarcodeResponse || {};
    if (Error) {
      disableLoadingDeleteBarcode();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }

    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.PRODUCT.BARCODE.IS.DELETED.SUCCESSFULLY"
      })
    );
    onOpenSuccessModal();
    handleDeleteBarcodeModalHide();
    setHistoryList([]);
    setQueryParamsLoc(initialQueryParamsLoc);
    setUpdateLocationList(Math.random());
    setUpdateHistoryList(Math.random());
    setUpdateSingleAssetDetails(Math.random());
    disableLoadingDeleteBarcode();
  };

  const onClickOfCheckInButton = async () => {
    const { id } = selectedLocation || {};
    enableLoadingCheckInBarcode();

    const checkInBarcodeResponse = await checkInBarcodeApi({
      id
    });
    const { Error } = checkInBarcodeResponse || {};
    if (Error) {
      disableLoadingDeleteBarcode();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }

    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.PRODUCT.BARCODE.IS.CHECKEDIN.SUCCESSFULLY"
      })
    );
    onOpenSuccessModal();
    handleCheckInBarcodeModalHide();
    setHistoryList([]);
    setQueryParamsLoc(initialQueryParamsLoc);
    setUpdateLocationList(Math.random());
    setUpdateHistoryList(Math.random());
    setUpdateSingleAssetDetails(Math.random());
    disableLoadingCheckInBarcode();
  };

  const location = useLocation();

  const isDocumentsTabActive = location.pathname.includes("/documents");
  const isHistoryTabActive = location.pathname.includes("/history");

  const defaultActiveTab = isDocumentsTabActive
    ? 1
    : isHistoryTabActive
      ? 2
      : 0;

  const handleTabChange = (index) => {
    const paths = [
      "/overview",
      "/documents",
      "/history",
      "/depreciationHistory"
    ];
    const newPath = paths[index] || "/";

    if (location.pathname !== newPath) {
      const assetId = assetDetails.id;
      history.push(`/assets/view-asset-details/${assetId}${newPath}`);
    }
  };

  const handlePaginationOfHistoryListView = () => {
    const { pageNumber, pageSize } = queryParamsLoc || {};
    setQueryParamsLoc({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationHistoryListDetails(Math.random());
  };

  const addAssestDoc = async (values) => {
    enableSaveLoading();

    if (!files.length) {
      disableSaveLoading();
      return;
    }

    const docs = [];
    files.map((doc) => {
      const { filename, file_type, mimetype } = doc || {};

      let new_file_type;
      if (file_type) {
        new_file_type = file_type;
      }
      if (mimetype) {
        new_file_type = mimetype;
      }

      docs.push({ filename, file_type: new_file_type });
      return doc;
    });

    let obj = {
      assetDocument: docs,
      id
    };

    const addAssetMediaResponse = await addAssetMediaApi(obj);

    if (addAssetMediaResponse.message === "success") {
      setFiles([]);
    }

    window.scrollTo(0, 0);
    setTimeout(() => {
      disableSaveLoading();
      setUpdateSingleAssetDetails(Math.random());
    }, 2500);
  };

  // Function to handle file download
  const handleDownload = (filename) => {
    let assetUrl = getMediaUrl(filename, "asset");
    window.open(assetUrl);
  };

  const handleDelete = async (assetId) => {
    const deleteDocumentResponse = await deleteAssetMediaApi(assetId);
    if (deleteDocumentResponse.data) {
      setDocDeleted(true);
    }
    // Update the state to remove the deleted document
    setAssetDocument((prevDocuments) =>
      prevDocuments.filter((doc) => doc.id !== assetId)
    );
    setTimeout(() => {
      setDocDeleted(false);
    }, 5000);
  };

  return (
    <>
      <Card className='p-0 mb-0'>
        <div className='details-view d-block d-md-flex'>
          {/* Listing */}
          <div className='column list-column border-right d-none d-lg-block'>
            <div className='column-header d-flex align-items-center justify-content-between'>
              <h2 className='modal-title'>
                <FormattedMessage id='TITLE.ALL.ASSETS' />
              </h2>
              {(userRole === "Admin" || userRole === "Asset Manager") && (
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={CreateNew}
                >
                  <Button
                    variant='primary btn-inventory btn-icon'
                    size='sm'
                    onClick={() => {
                      addNewProductModalShow();
                    }}
                  >
                    <Plus size={14} className='text-secondary'></Plus>{" "}
                    <FormattedMessage id='TITLE.NEW' />
                  </Button>
                </OverlayTrigger>
              )}
            </div>
            {(isLoadingAssetListView ||
              isLoadingPOView ||
              isLoading ||
              isLoadingLocView ||
              isLoadingDCView) && <SectionLoader />}
            <div id='scrollableDiva' className='column-body custom-scrollbar'>
              {!isLoadingAssetListView && (
                <InfiniteScroll
                  scrollableTarget='scrollableDiva'
                  dataLength={assetList.length}
                  next={() => {
                    handlePaginationOfProductListView();
                  }}
                  hasMore={isLoadMoreAssetListView}
                  loader={
                    <h4 style={{ textAlign: "center" }} className='p-1'>
                      <FormattedMessage id='TITLE.LOADING' />
                      ...
                    </h4>
                  }
                >
                  {assetList.map((asset, index) => (
                    <div
                      className='column-box cursor-pointer'
                      key={index}
                      onClick={() => {
                        if (id !== asset.id) {
                          setPOSummary([]);
                          setHistoryList([]);
                          setQueryParamsLoc(initialQueryParamsLoc);
                        }
                        const path = `/assets/view-asset-details/${asset.id}`;
                        onClickChangeRoute(path);
                      }}
                    >
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-column'>
                          <h5 className={getSecondaryColorClass(asset)}>
                            {asset?.product?.name} -{" "}
                            {capitalizeFirstLetter(asset?.barcode_no)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>{" "}
          </div>
          {/* Details */}

          {!isReceiveviewOpen && (
            <div className='column details-column'>
              {isLoading && <SectionLoader />}

              <div className='column-header d-flex align-items-center justify-content-between'>
                <h2 className='modal-title'>
                  {assetDetails?.product?.name} -{" "}
                  {capitalizeFirstLetter(assetDetails?.barcode_no)}
                </h2>
              </div>
              <div
                id='scrollabledivOverview'
                className='column-body custom-scrollbar'
              >
                <Tabs
                  defaultIndex={defaultActiveTab}
                  onSelect={handleTabChange}
                >
                  <TabList className='nav nav-tabs tab-coupon mb-0'>
                    <Tab className='nav-link' onClick={()=>getSingleAssetDetails()}>
                      <FormattedMessage id='TITLE.OVERVIEW' />
                    </Tab>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.DOCUMENTS' />
                    </Tab>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.HISTORY' />
                    </Tab>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.DEPRECATED.ASSETS.HISTORY' />
                    </Tab>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.ASSET.LIST.MAINTANCE.LOG' />
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <DefaultAssetView
                      assetDetails={assetDetails}
                      getSingleAssetDetails={getSingleAssetDetails}
                      id={id}
                      POSummary={POSummary}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Card>
                      {isLoading && <SectionLoader />}
                      <Formik
                        initialValues={initialValues}
                        validationSchema={AddAssetMediaSchema}
                        onSubmit={(values) => {
                          addAssestDoc(values);
                        }}
                        onReset={(values) => {}}
                      >
                        {({
                          values,
                          handleSubmit,
                          handleBlur,
                          handleChange,
                          resetForm,
                          handleReset,
                          setFieldValue,
                          errors,
                          touched
                        }) => (
                          <Form
                            className='form form-label-right filter-card'
                            autoComplete='off'
                            onSubmit={handleSubmit}
                            onReset={() => {
                              resetForm(initialValues);

                              handleReset();
                            }}
                          >
                            <Card.Header>
                              <Row>
                                <Col md={1}></Col>
                                <Col md={10}>
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <Form.Label htmlFor='attach-qutation'>
                                      <FormattedMessage id='TITLE.ATTACH.FILE' />
                                    </Form.Label>
                                    <span className='small-text'>
                                      <FormattedMessage id='TITLE.MAXIMUM.SIZE' />
                                      : 20MB
                                    </span>
                                  </div>
                                  <section>
                                    <div
                                      {...getRootProps({
                                        className: "dropzone"
                                      })}
                                    >
                                      <input {...getInputProps()} />
                                      <File
                                        size={32}
                                        className='text-dark'
                                        style={{ strokeWidth: "1.5px" }}
                                      ></File>
                                      <p>
                                        {" "}
                                        <FormattedMessage id='TITLE.DRAG.DROP.FILES.HERE.OR.CLICK.THE.ICON' />
                                      </p>
                                    </div>

                                    {isFileUploadLoading && <ContentLoader />}
                                    <ThumbnailWrapper
                                      files={files}
                                      handleOnDeleteOfAttachment={
                                        handleOnDeleteOfAttachment
                                      }
                                      container='asset'
                                    />
                                  </section>
                                </Col>
                                <Col md={1}></Col>
                              </Row>
                            </Card.Header>
                            <Card.Footer className='d-flex justify-content-end'>
                              <Button
                                variant='success mr-3 btn-inventory'
                                onClick={() => handleSubmit()}
                                disabled={isSaveLoading}
                              >
                                <FormattedMessage id='TITLE.SAVE' />
                                {isSaveLoading && (
                                  <Spinner
                                    animation='border'
                                    size='sm'
                                    className='ml-1'
                                  />
                                )}
                              </Button>
                            </Card.Footer>
                          </Form>
                        )}
                      </Formik>
                    </Card>
                    <Card>
                      <Card.Header>
                        <h3>Documents</h3>
                        {docDeleted && (
                          <div className='notification icon inline success fs-16'>
                            <Check size={8} className='text-white'></Check>
                            <FormattedMessage id='TITLE.ASSET.DOCUMENT.HAS.BEEN.SUCCESSFULLY.DELETED' />
                          </div>
                        )}
                      </Card.Header>
                      <Card.Footer>
                        <DocumentTable
                          documents={assetDocument}
                          handleDownload={handleDownload}
                          handleDelete={handleDelete}
                        />
                      </Card.Footer>
                    </Card>
                  </TabPanel>
                  <TabPanel>
                    <AssetHistoryView
                      historyList={historyList}
                      isLoadingHistoryView={isLoadingHistoryView}
                      isLoadMoreLocHistoryView={isLoadMoreLocHistoryView}
                      handlePaginationOfHistoryListView={
                        handlePaginationOfHistoryListView
                      }
                    />
                  </TabPanel>
                  <TabPanel>
                    <DeprecatedHistoryView
                      historyList={assetDetails?.assets_depreciation_histories}
                      isLoadingHistoryView={isLoadingHistoryView}
                      isLoadMoreLocHistoryView={isLoadMoreLocHistoryView}
                      handlePaginationOfHistoryListView={
                        handlePaginationOfHistoryListView
                      }
                    />
                  </TabPanel>
                  <TabPanel>
                    <MaintanceLogView
                      historyList={historyList}
                      isLoadingHistoryView={isLoadingHistoryView}
                      isLoadMoreLocHistoryView={isLoadMoreLocHistoryView}
                      handlePaginationOfHistoryListView={
                        handlePaginationOfHistoryListView
                      }
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          )}
        </div>
      </Card>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}

      {isDeleteBarcodeModalOpen && (
        <DeleteModal
          isLoading={isLoadingDeleteBarcode}
          title={`${intl.formatMessage({
            id: "TITLE.DELETE.PRODUCT.BARCODE"
          })}?`}
          description={intl.formatMessage({
            id: "TITLE.PRODUCT.BARCODE.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
          })}
          id={selectedLocation.barcode_no}
          isOpen={isDeleteBarcodeModalOpen}
          onCloseModal={handleDeleteBarcodeModalHide}
          onClickOfDeleteButton={onClickOfDeleteBarcodeDeleteButton}
        />
      )}

      {isCheckInBarcodeModalOpen && (
        <CheckInModal
          isLoading={isLoadingCheckInBarcode}
          title={`${intl.formatMessage({
            id: "TITLE.CHECKIN.PRODUCT.BARCODE"
          })}`}
          description={intl.formatMessage({
            id: "TITLE.ARE.YOU.SURE.WANT.TO.CHECK.IN.THIS.EQUIPMENT"
          })}
          id={selectedLocation.barcode_no}
          isOpen={isCheckInBarcodeModalOpen}
          onCloseModal={handleCheckInBarcodeModalHide}
          onClickOfCheckInButton={onClickOfCheckInButton}
        />
      )}

      {isOpenAddNewProductModal && (
        <AddNewProductModal
          open={isOpenAddNewProductModal}
          onCloseModal={addNewProductModalHide}
          handleCloseOfAddNewProductModal={handleCloseOfAddNewProductModal}
        />
      )}

      {isOpenEditAssetModal && (
        <EditAssetModal
          open={isOpenEditAssetModal}
          data={assetDetails}
          onCloseModal={editAssetModalHide}
          id={id}
          handleCloseOfEditNewProductModal={handleCloseOfEditNewProductModal}
        />
      )}

      {isOpenAddNewBarcodeModal && (
        <AddNewBarcodeModal
          onCloseModal={addNewBarcodeModalHide}
          open={isOpenAddNewBarcodeModal}
          product={assetDetails}
          handleCancelOfAddNewBarcode={handleCancelOfAddNewBarcode}
          handleAddNewBarcode={handleAddNewBarcode}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          message={successMessage}
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
        />
      )}
    </>
  );
};

export default ViewAssetDetails;
