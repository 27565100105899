import React, { Fragment, useContext, useState } from "react";
import { Button, Card, Image, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UIContext } from "../../helper/UIContext";
import {
  dateFormatter,
  amountFormatter,
  setActiveMenu,
  capitalizeFirstLetter
} from "../../constants/utils";
import { ContentLoader } from "../../helper/ContentLoader";
import nodatafoundImage from "../../assets/images/dashboard/nodata-found.svg";
import { FormattedMessage } from "react-intl";
import { USER_DETAILS } from "../../constants/const";
import { MENUITEMS } from "../../constants/menu";

function ExpiryProductWidgets({ top5NearExpiryProduct, isLoading }) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Card>
        <Card.Header className='align-items-center d-flex justify-content-between'>
          <Card.Title className='mb-0 modal-title'>
            <FormattedMessage id='TITLE.NEAR.EXPIRY.PRODUCTS.TOP.5' />
          </Card.Title>
          {userRole === "Asset Manager" && (
            <div className='d-flex'>
              <Button
                variant='link'
                className='text-primary p-0'
                onClick={() => {
                  onClickChangeRoute("/inventoryproducts/products");
                }}
              >
                <FormattedMessage id='TITLE.VIEW.ALL' />
              </Button>
            </div>
          )}
        </Card.Header>
        <Card.Body>
          <div className='table-responsive'>
            {isLoading && (
              <ContentLoader isLoading={isLoading} pleaseWait={true} />
            )}
            <Table className='viewpo-table table'>
              <thead>
                <tr>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.DUE.DATE' />
                  </th>
                  <th scope='col'>
                    <FormattedMessage id='TITLE.PRODUCT.NAME' />
                  </th>
                  <th scope='col' className='text-right'>
                    <FormattedMessage id='TITLE.QUANTITY' />
                  </th>
                </tr>
              </thead>

              <tbody>
                {top5NearExpiryProduct &&
                  top5NearExpiryProduct.map((product, index) => {
                    return (
                      <tr
                        key={index}
                        className='cursor-pointer'
                        onClick={() => {
                          if (
                            userRole !== "Inward Manager" &&
                            userRole !== "Outward Manager"
                          ) {
                            const path = `/inventoryproducts/view-inventoryproduct-details/${product?.id}`;
                            onClickChangeRoute(path);
                            uiContext.setCurrentPath(path);
                            window.scrollTo(0, 0);
                          }
                        }}
                      >
                        <td className='text-danger'>
                          {product?.returnByDate
                            ? dateFormatter(product?.returnByDate)
                            : "-"}
                        </td>
                        <td className='digits'>
                          <div className='align-middle image-sm-size d-flex align-items-center'>
                            <div className='d-inline-block mt-0'>
                              <h6 className='text-info font-weight-bold mb-0'>
                                {product?.name} -{" "}
                                {capitalizeFirstLetter(product.barcode_no)}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className='text-right'>{product?.quantity}</td>
                      </tr>
                    );
                  })}
                {top5NearExpiryProduct &&
                  top5NearExpiryProduct.length === 0 &&
                  !isLoading && (
                    <>
                      <tr>
                        <td className='no-record text-center' colSpan={4}>
                          <Image
                            src={nodatafoundImage}
                            alt='No Data Found'
                            className='img-fluid'
                            style={{ maxHeight: "250px" }}
                          />
                          <span className='d-block'>
                            <FormattedMessage id='TITLE.NO.RECORDS.FOUND' />
                          </span>
                        </td>
                      </tr>
                    </>
                  )}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default ExpiryProductWidgets;
