import React, { createContext, useContext, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const VendorUIContext = createContext();

export function useVendorUIContext() {
  return useContext(VendorUIContext);
}

export const VendorUIConsumer = VendorUIContext.Consumer;

export function VendorUIProvider({
  vendorUIEvents,
  children,
  queryParams,
  setQueryParamsBase,
  ids,
  setIds
}) {
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openViewDialog: vendorUIEvents.openViewDialog,
    openEditDialog: vendorUIEvents.openEditDialog,
    openDeleteDialog: vendorUIEvents.openDeleteDialog,
    redirectToVendorViewPage: vendorUIEvents.redirectToVendorViewPage
  };

  return (
    <VendorUIContext.Provider value={value}>
      {children}
    </VendorUIContext.Provider>
  );
}
