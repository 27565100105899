export function loginObj({ emp_id, password }) {
  let obj = {
    emp_id,
    password
  };
  return obj;
}

export function forgotPasswordObj({ emp_id }) {
  let obj = {
    emp_id
  };
  return obj;
}

export function editCustomerObj({
  contact_name,
  phone_number,
  currency,
  status,
  email,
  remarks,
  mobile_number,
  address,
  contact_person,
  emp_id
}) {
  let obj = {
    contact_name,
    phone_number,
    currency,
    status,
    email,
    remarks,
    mobile_number,
    address,
    contact_person,
    emp_id
  };
  return obj;
}

export function addCustomerObj({
  contact_type,
  email,
  phone_number,
  mobile_number,
  contact_name,
  currency,
  billingaddressId,
  billing_cityId,
  billing_zipcode,
  shippingaddressId,
  shipping_cityId,
  shipping_zipcode,
  contact_person,
  remarks,
  password,
  emp_id,
  role_id
}) {
  let obj = {
    contact_type,
    email,
    phone_number,
    mobile_number,
    contact_name,
    currency,
    billingaddressId,
    billing_cityId,
    billing_zipcode,
    shippingaddressId,
    shipping_cityId,
    shipping_zipcode,
    contact_person,
    remarks,
    password,
    emp_id,
    role_id
  };
  return obj;
}

export function editVendorObj({
  contact_name,
  phone_number,
  currency,
  status,
  email,
  remarks,
  mobile_number,
  address,
  contact_person
}) {
  let obj = {
    contact_name,
    phone_number,
    currency,
    status,
    email,
    remarks,
    mobile_number,
    address,
    contact_person
  };
  return obj;
}

export function editBillingAddressVendorObj({ address }) {
  let obj = {
    address
  };
  return obj;
}

export function editBillingAddressCustomerObj({ address }) {
  let obj = {
    address
  };
  return obj;
}

export function editShippingAddressVendorObj({ address }) {
  let obj = {
    address
  };
  return obj;
}

export function editShippingAddressCustomerObj({ address }) {
  let obj = {
    address
  };
  return obj;
}

export function addContactPersonVendorObj({
  contactId,
  first_name,
  last_name,
  email,
  phone,
  mobile
}) {
  let obj = {
    contactId,
    first_name,
    last_name,
    email,
    phone,
    mobile
  };
  return obj;
}

export function addContactPersonCustomerObj({
  contactId,
  first_name,
  last_name,
  email,
  phone,
  mobile
}) {
  let obj = {
    contactId,
    first_name,
    last_name,
    email,
    phone,
    mobile
  };
  return obj;
}

export function editContactPersonVendorObj({
  first_name,
  last_name,
  email,
  phone,
  mobile
}) {
  let obj = {
    first_name,
    last_name,
    email,
    phone,
    mobile
  };
  return obj;
}

export function editContactPersonCustomerObj({
  first_name,
  last_name,
  email,
  phone,
  mobile
}) {
  let obj = {
    first_name,
    last_name,
    email,
    phone,
    mobile
  };
  return obj;
}

export function addVendorObj({
  contact_type,
  email,
  phone_number,
  mobile_number,
  contact_name,
  currency,
  billingaddressId,
  billing_cityId,
  billing_zipcode,
  shippingaddressId,
  shipping_cityId,
  shipping_zipcode,
  contact_person,
  remarks
}) {
  let obj = {
    contact_type,
    email,
    phone_number,
    mobile_number,
    contact_name,
    currency,
    billingaddressId,
    billing_cityId,
    billing_zipcode,
    shippingaddressId,
    shipping_cityId,
    shipping_zipcode,
    contact_person,
    remarks
  };
  return obj;
}

export function uploadDocumentObj({ file, containername }) {
  let obj = {
    file,
    containername
  };
  return obj;
}

export function createDCObj({
  date,
  returnByDate,
  expected_delivery,
  notes,
  purchaseorder_document,
  courier_company_name,
  contactId,
  purchaseorder_item,
  signature,
  type
}) {
  let obj = {
    date,
    returnByDate,
    expected_delivery,
    notes,
    purchaseorder_document,
    courier_company_name,
    contactId,
    purchaseorder_item,
    signature,
    type
  };
  return obj;
}

export function editDCObj({ purchaseorder_document }) {
  let obj = {
    purchaseorder_document
  };
  return obj;
}

export function editPOObj({ purchaseorder_document }) {
  let obj = {
    purchaseorder_document
  };
  return obj;
}

export function editPOStatusObj({ purchaseorderstatusId }) {
  let obj = {
    purchaseorderstatusId
  };
  return obj;
}

export function editDCStatusObj({ purchaseorderstatusId }) {
  let obj = {
    purchaseorderstatusId
  };
  return obj;
}

export function editExpectedDeliveryDateObj({ expected_delivery, notes }) {
  let obj = {
    expected_delivery,
    notes
  };
  return obj;
}

export function addProductObj({
  name,
  categoryId,
  stock_keeping_unit,
  opening_stock,
  reorder_level,
  price,
  shelf_life,
  productunitId,
  product_media,
  description,
  schedule_maintenance_time
}) {
  let obj = {
    name,
    categoryId,
    stock_keeping_unit,
    opening_stock,
    reorder_level,
    price,
    shelf_life,
    productunitId,
    product_media,
    description,
    schedule_maintenance_time
  };
  return obj;
}

export function requestToAddProductObj({
  name,
  categoryId,
  stock_keeping_unit,
  opening_stock,
  reorder_level,
  price,
  shelf_life,
  productunitId,
  product_media,
  description,
  schedule_maintenance_time
}) {
  let obj = {
    name,
    categoryId,
    stock_keeping_unit,
    opening_stock,
    reorder_level,
    price,
    shelf_life,
    productunitId,
    product_media,
    description,
    schedule_maintenance_time
  };
  return obj;
}

export function receiveInwardListObj({
  delivery_date,
  notes,
  product_condition
}) {
  let obj = {
    delivery_date,
    notes,
    product_condition
  };
  return obj;
}
export function addInwardObj({
  date,
  expected_delivery,
  notes,
  purchaseorder_document,
  courier_company_name,
  contactId,
  purchaseorder_item,
  totalamount,
  totalquantity,
  type
}) {
  let obj = {
    date,
    expected_delivery,
    notes,
    purchaseorder_document,
    courier_company_name,
    contactId,
    purchaseorder_item,
    totalamount,
    totalquantity,
    type
  };
  return obj;
}

export function editInwardObj({
  date,
  expected_delivery,
  notes,
  purchaseorder_document,
  courier_company_name,
  contactId,
  purchaseorder_item,
  totalamount,
  totalquantity,
  type
}) {
  let obj = {
    date,
    expected_delivery,
    notes,
    purchaseorder_document,
    courier_company_name,
    contactId,
    purchaseorder_item,
    totalamount,
    totalquantity,
    type
  };
  return obj;
}

export function editOutwardObj({
  date,
  returnByDate,
  expected_delivery,
  notes,
  purchaseorder_document,
  courier_company_name,
  contactId,
  purchaseorder_item,
  totalamount,
  totalquantity,
  type
}) {
  let obj = {
    date,
    returnByDate,
    expected_delivery,
    notes,
    purchaseorder_document,
    courier_company_name,
    contactId,
    purchaseorder_item,
    totalamount,
    totalquantity,
    type
  };
  return obj;
}

export function deliverOutwardListObj({
  delivery_date,
  notes,
  product_condition,
  signature
}) {
  let obj = {
    delivery_date,
    notes,
    product_condition,
    signature
  };
  return obj;
}
export function addBrandObj({ name, description }) {
  let obj = {
    name,
    description
  };
  return obj;
}

export function addCategoryObj({ name, description, depreciation_value }) {
  let obj = {
    name,
    description,
    depreciation_value
  };
  return obj;
}

export function addUserObj({
  full_name,
  emp_id,
  email,
  cellnumber,
  role_id,
  password
}) {
  let obj = {
    full_name,
    emp_id,
    email,
    cellnumber,
    role_id,
    password
  };
  return obj;
}

export function editUserObj({
  full_name,
  emp_id,
  email,
  cellnumber,
  role_id,
  status
}) {
  let obj = {
    full_name,
    emp_id,
    email,
    cellnumber,
    role_id,
    status
  };
  return obj;
}

export function editProfileObj({ full_name, emp_id, email, cellnumber }) {
  let obj = {
    full_name,
    email,
    cellnumber
  };
  return obj;
}

export function changePasswordObj({ id, currentPassword, password }) {
  let obj = {
    id,
    currentPassword,
    password
  };
  return obj;
}

export function ViewPasswordRequestUserObj({ id, password }) {
  let obj = {
    id,
    password
  };
  return obj;
}

export function addLocationObj({ store_location, parent_location }) {
  let obj = {
    store_location,
    parent_location
  };
  return obj;
}

export function addProductUnitObj({ name, description }) {
  let obj = {
    name,
    description
  };
  return obj;
}

export function editBrandObj({ name, description, status }) {
  let obj = {
    name,
    description,
    status
  };
  return obj;
}

export function editCategoryObj({
  name,
  description,
  status,
  depreciation_value
}) {
  let obj = {
    name,
    description,
    status,
    depreciation_value
  };
  return obj;
}

export function editLocationObj({ parent_location, store_location, status }) {
  let obj = {
    parent_location,
    store_location,
    status
  };
  return obj;
}

export function editProductUnitObj({ name, status }) {
  let obj = {
    name,
    status
  };
  return obj;
}

export function editProductObj({
  name,
  stock_keeping_unit,
  status,
  opening_stock,
  reorder_level,
  shelf_life,
  price,
  categoryId,
  product_media,
  date,
  reference_number,
  new_quantity_on_hand,
  quantity_adjusted,
  reason,
  comments,
  adjust_stock,
  description,
  productunitId,
  schedule_maintenance_time
}) {
  let obj = {
    name,
    stock_keeping_unit,
    status,
    opening_stock,
    reorder_level,
    shelf_life,
    price,
    categoryId,
    product_media,
    date,
    reference_number,
    new_quantity_on_hand,
    quantity_adjusted,
    reason,
    comments,
    adjust_stock,
    description,
    productunitId,
    schedule_maintenance_time
  };
  return obj;
}

export function checkUserExistObj({ cellnumber }) {
  let obj = {
    cellnumber
  };
  return obj;
}

export function checkInBarcodeObj({ id, locationId }) {
  let obj = {
    id,
    locationId
  };
  return obj;
}

export function addNewBarcodeObj({ productId, purchaseorder_barcode }) {
  let obj = {
    productId,
    purchaseorder_barcode
  };
  return obj;
}

export function addAssetMediaObj({ assetDocument, id }) {
  let obj = {
    assetDocument,
    id
  };
  return obj;
}
