import React, { useRef, useState } from "react";
import { CityLoadingDialog } from "./city-loading-dialog/CityLoadingDialog";
import { CityCard } from "./CityCard";
import { CityUIProvider } from "./CityUIContext";
import SVG from "react-inlinesvg";
import { initialFilter } from "./CityUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { toAbsoluteUrl } from "../../helper/helper";
import { DeleteModal } from "../common/modals/DeleteModal";
import { EditCityModal } from "../../global-modal/EditCityModal";
import { AddNewCityModal } from "../../global-modal/AddNewCityModal";

export function CityPage({ history }) {
  const [data, setData] = useState({});
  const [cityList, setCityList] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cityListTotalCount, setCityListTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [cityListExport, setCityListExport] = useState([]);
  const exportCSVButton = useRef();

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleAddModalShow = () => {
    setAddModalOpen(true);
  };

  const handleAddModalHide = () => {
    setAddModalOpen(false);
  };

  const handleEditModalShow = () => {
    setEditModalOpen(true);
  };

  const handleEditModalHide = () => {
    setEditModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleFilterValues = (values) => {
    setFilterValues(values);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingExportButton = () => {
    setLoadingExportButton(true);
  };

  const disableLoadingExportButton = () => {
    setLoadingExportButton(false);
  };

  const handleSetCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const cityUIEvents = {
    openViewDialog: (row) => {
      history.push("/view-city-details");
    },
    openEditDialog: (row) => {
      setData(row);
      handleEditModalShow();
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    }
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    handleAddModalShow();
  };

  return (
    <div>
      <>
        {/* <Breadcrumb title="Product List" parent="Products" /> */}
        <CityUIProvider
          cityUIEvents={cityUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <CityLoadingDialog />

          {isAddModalOpen && (
            <AddNewCityModal
              open={isAddModalOpen}
              data={data}
              onCloseModal={handleAddModalHide}
            />
          )}

          {isEditModalOpen && (
            <EditCityModal
              open={isEditModalOpen}
              data={data}
              onCloseModal={handleEditModalHide}
            />
          )}

          <CityCard
            isLoading={isLoading}
            cityList={cityList}
            handleFilterValues={handleFilterValues}
            filterValues={filterValues}
            loadingExportButton={loadingExportButton}
            cityListExport={cityListExport}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            cityListTotalCount={cityListTotalCount}
            currentPage={currentPage}
            setCurrentPage={handleSetCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
          />
        </CityUIProvider>

        {isDeleteModalOpen && (
          <DeleteModal
            title={"Delete City?"}
            description={
              "City will be deleted forever and cannot be retrieved later. Are you sure about deleting it?"
            }
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
          />
        )}

        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className='danger-icon-wrapper text-center'>
              <span className='svg-icon menu-icon svg-icon-white svg-icon-4x mr-0'>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />
      </>
    </div>
  );
}
