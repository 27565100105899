/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { Edit2, Plus, X } from "react-feather";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  DCBasedOnProductListApi,
  POBasedOnProductListApi,
  checkInBarcodeApi,
  deleteBarcodeApi,
  editProductApi,
  getHistoryListBasedOnProductApi,
  getLocationListBasedOnProductApi,
  getPoandDcSummaryApi,
  getSingleProductDetailsApi,
  productListMastersApi
} from "../../../axios/services/services";
import { USER_DETAILS } from "../../../constants/const";
import { MENUITEMS } from "../../../constants/menu";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization
} from "../../../constants/utils";
import { AddNewBarcodeModal } from "../../../global-modal/AddNewBarcodeModal";
import { AddNewProductModal } from "../../../global-modal/AddNewProductModal ";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { SectionLoader } from "../../../helper/SectionLoader";
import { UIContext } from "../../../helper/UIContext";
import { CheckInModal } from "../../common/modals/CheckInModal";
import { DeleteModal } from "../../common/modals/DeleteModal";
import { ErrorModal } from "../../common/modals/ErrorModal";
import { SuccessModal } from "../../common/modals/SuccessModal";
import { EditProductModal } from "../edit-product/EditProductModal";
import DefaultProductView from "./DefaultProductView";
import LocationProductView from "./LocationProductView";
import ProductBarcodeHistoryView from "./ProductBarcodeHistoryView ";
import TransactionDCView from "./TransactionDCView";
import TransactionPOView from "./TransactionPOView";

const initialQueryParams = { pageNumber: 1, pageSize: 20 };
const initialQueryParamsPO = { pageNumber: 1, pageSize: 20 };
const initialQueryParamsDC = { pageNumber: 1, pageSize: 20 };
const initialQueryParamsLoc = { pageNumber: 1, pageSize: 20 };

const ViewProductDetails = ({ handlePDFViewValues, ...props }) => {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const history = useHistory();
  const [isOpenAddNewProductModal, setOpenAddNewProductModal] = useState(false);
  const [isOpenAddNewBarcodeModal, setOpenAddNewBarcodeModal] = useState(false);
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const [queryParamsPO, setQueryParamsPO] = useState(initialQueryParamsPO);
  const [queryParamsDC, setQueryParamsDC] = useState(initialQueryParamsDC);
  const [queryParamsLoc, setQueryParamsLoc] = useState(initialQueryParamsLoc);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingProductListView, setLoadingProductListView] = useState(false);
  const [isLoadingPOView, setLoadingPOView] = useState(false);
  const [isLoadingLocView, setLoadingLocView] = useState(false);
  const [isLoadingHistoryView, setLoadingHistoryView] = useState(false);
  const [isLoadingDCView, setLoadingDCView] = useState(false);
  const [isLoadingEditProduct, setLoadingEditProduct] = useState(false);
  const [isLoadingDeleteBarcode, setLoadingDeleteBarcode] = useState(false);
  const [isLoadingCheckInBarcode, setLoadingCheckInBarcode] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [productList, setProductList] = useState([]);
  const [isLoadMoreProductListView, setLoadMoreProductListView] =
    useState(false);
  const [isLoadMorePOListView, setLoadMorePOListView] = useState(false);
  const [isLoadMoreDCListView, setLoadMoreDCListView] = useState(false);
  const [isLoadMoreLocListView, setLoadMoreLocListView] = useState(false);
  const [isLoadMoreLocHistoryView, setLoadMoreLocHistoryView] = useState(false);
  const { id } = props.match.params;
  const [productDetails, setProductDetails] = useState({});

  const [isUpdateProductListing, setUpdateProductListing] = useState(0);
  const [isUpdateLocationList, setUpdateLocationList] = useState(0);
  const [isUpdateHistoryList, setUpdateHistoryList] = useState(0);
  const [isUpdateSingleProductDetails, setUpdateSingleProductDetails] =
    useState(0);
  const [
    isUpdatePaginationProductListDetails,
    setUpdatePaginationProductListDetails
  ] = useState(0);
  const [isUpdatePaginationPOListDetails, setUpdatePaginationPOListDetails] =
    useState(0);
  const [isUpdatePaginationDCListDetails, setUpdatePaginationDCListDetails] =
    useState(0);
  const [isUpdatePaginationLocListDetails, setUpdatePaginationLocListDetails] =
    useState(0);
  const [
    isUpdatePaginationHistoryListDetails,
    setUpdatePaginationHistoryListDetails
  ] = useState(0);
  const [isOpenEditProductModal, setOpenEditProductModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [POSummary, setPOSummary] = useState([]);
  const [isPOView, setPOView] = useState(false);
  const [POList, setPOList] = useState([]);
  const [DCList, setDCList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [isDeleteBarcodeModalOpen, setIsDeleteBarcodeModalOpen] =
    useState(false);
  const [isCheckInBarcodeModalOpen, setCheckInBarcodeModalOpen] =
    useState(false);

  const [selectedLocation, setSelectedLocation] = useState();
  const [locationValue, setLocationValue] = useState({
    label: "",
    value: ""
  });

  async function getProductList() {
    const { pageNumber } = queryParams || {};
    if (pageNumber === 1) {
      setLoadingProductListView(true);
    }
    const ProductListResponse = await productListMastersApi({
      queryParams
    });
    const { Error, data } = ProductListResponse || {};
    if (Error) {
      if (pageNumber === 1) {
        setLoadingProductListView(false);
      }
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    if (pageNumber === 1) {
      setProductList(data);
    } else {
      setProductList((productList) => [...productList, ...data]);
    }

    if (data.length >= 20) {
      setLoadMoreProductListView(true);
    } else {
      setLoadMoreProductListView(false);
    }
    if (pageNumber === 1) {
      setLoadingProductListView(false);
    }
  }

  async function getSingleProductDetails() {
    setLoading(true);
    const singleProductDetails = await getSingleProductDetailsApi({
      id
    });
    const { Error, data } = singleProductDetails || {};
    if (Error) {
      setLoading(false);
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setProductDetails(data ? data : []);
    setLoading(false);
  }

  async function getPOSummary() {
    setLoading(true);
    const POSummaryResponse = await getPoandDcSummaryApi({
      chosenLabel: "thismonth",
      productId: id
    });
    const { Error, data } = POSummaryResponse || {};
    if (Error) {
      setLoading(false);
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setPOSummary(data);
    setLoading(false);
  }

  async function getPODetails() {
    if (userRole !== "Admin" && userRole !== "Outward Manager") {
      const productId = id;
      if (POList && POList.length === 0) {
        setLoadingPOView(true);
      }
      const POResponse = await POBasedOnProductListApi({
        queryParams: queryParamsPO,
        productId
      });
      const { Error, data } = POResponse || {};
      if (Error) {
        if (POList && POList.length === 0) {
          setLoadingPOView(false);
        }
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setPOList((POList) => [...POList, ...data]);
      if (data.length >= 20) {
        setLoadMorePOListView(true);
      } else {
        setLoadMorePOListView(false);
      }
      if (POList && POList.length === 0) {
        setLoadingPOView(false);
      }
    }
  }

  async function getDCDetails() {
    if (userRole === "Outward Manager" || userRole === "Asset Manager") {
      const productId = id;
      if (DCList && DCList.length === 0) {
        setLoadingDCView(true);
      }
      const POResponse = await DCBasedOnProductListApi({
        queryParams: queryParamsDC,
        productId
      });
      const { Error, data } = POResponse || {};
      if (Error) {
        if (DCList && DCList.length === 0) {
          setLoadingDCView(false);
        }
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setDCList((DCList) => [...DCList, ...data]);
      if (data.length >= 20) {
        setLoadMoreDCListView(true);
      } else {
        setLoadMoreDCListView(false);
      }
      if (DCList && DCList.length === 0) {
        setLoadingDCView(false);
      }
    }
  }

  async function getLocationDetails() {
    const productId = id;
    if (locationList && locationList.length === 0) {
      setLoadingLocView(true);
    }
    const locationResponse = await getLocationListBasedOnProductApi({
      queryParams: queryParamsLoc,
      productId
    });
    const { Error, data } = locationResponse || {};
    if (Error) {
      if (locationList && locationList.length === 0) {
        setLoadingLocView(false);
      }
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setLocationList((locationList) => [...locationList, ...data]);
    if (data.length >= 20) {
      setLoadMoreLocListView(true);
    } else {
      setLoadMoreLocListView(false);
    }
    if (locationList && locationList.length === 0) {
      setLoadingLocView(false);
    }
  }

  async function getHistoryDetails() {
    const productId = id;
    if (historyList && historyList.length === 0) {
      setLoadingHistoryView(true);
    }
    const historyResponse = await getHistoryListBasedOnProductApi({
      queryParams: queryParamsLoc,
      productId
    });
    const { Error, data } = historyResponse || {};
    if (Error) {
      if (historyList && historyList.length === 0) {
        setLoadingHistoryView(false);
      }
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setHistoryList((historyList) => [...historyList, ...data]);
    if (data.length >= 20) {
      setLoadMoreLocHistoryView(true);
    } else {
      setLoadMoreLocHistoryView(false);
    }
    if (historyList && historyList.length === 0) {
      setLoadingHistoryView(false);
    }
  }

  useEffect(() => {
    getProductList();
  }, [isUpdatePaginationProductListDetails, isUpdateProductListing]);

  useEffect(() => {
    setPOList([]);
    setDCList([]);
    setLocationList([]);
    setHistoryList([]);
  }, [id]);

  useEffect(() => {
    getSingleProductDetails();
  }, [id, isUpdateSingleProductDetails]);

  useEffect(() => {
    getPOSummary();
  }, [id, isUpdateSingleProductDetails]);

  useEffect(() => {
    getPODetails();
  }, [id, isUpdatePaginationPOListDetails]);

  useEffect(() => {
    getDCDetails();
  }, [id, isUpdatePaginationDCListDetails]);

  useEffect(() => {
    getLocationDetails();
  }, [id, isUpdatePaginationLocListDetails, isUpdateLocationList]);

  useEffect(() => {
    getHistoryDetails();
  }, [id, isUpdatePaginationHistoryListDetails, isUpdateHistoryList]);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const onClickOfPOView = () => {
    setPOView(true);
  };

  const handleSaveOfStatus = async () => {
    enableLoadingEditProduct();
    let status = productDetails.status === 0 ? 1 : 0;
    const editStatus = await editProductApi({
      id,
      status
    });
    const { Error } = editStatus || {};
    if (Error) {
      disableLoadingEditProduct();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setSuccessMessage(
      `${
        status === 0
          ? intl.formatMessage({
              id: "TITLE.STATUS.MARKED.AS.INACTIVE"
            })
          : intl.formatMessage({
              id: "TITLE.STATUS.MARKED.AS.ACTIVE"
            })
      }`
    );
    onOpenSuccessModal();
    setUpdateSingleProductDetails(Math.random());
    disableLoadingEditProduct();
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleCloseOfEditNewProductModal = () => {
    setQueryParams(initialQueryParams);
    setProductList([]);
    setUpdateProductListing(Math.random());
    setUpdateSingleProductDetails(Math.random());
    editProductModalHide();
  };

  const handleCancelOfAddNewBarcode = () => {
    addNewBarcodeModalHide();
  };

  const handleAddNewBarcode = () => {
    addNewBarcodeModalHide();
    setQueryParamsLoc(initialQueryParamsLoc);
    setLocationList([]);
    setHistoryList([]);
    setUpdateLocationList(Math.random());
    setUpdateHistoryList(Math.random());
    setUpdateSingleProductDetails(Math.random());
  };

  const enableLoadingEditProduct = () => {
    setLoadingEditProduct(true);
  };

  const disableLoadingEditProduct = () => {
    setLoadingEditProduct(false);
  };

  const enableLoadingDeleteBarcode = () => {
    setLoadingDeleteBarcode(true);
  };

  const disableLoadingDeleteBarcode = () => {
    setLoadingDeleteBarcode(false);
  };

  const enableLoadingCheckInBarcode = () => {
    setLoadingCheckInBarcode(true);
  };

  const disableLoadingCheckInBarcode = () => {
    setLoadingCheckInBarcode(false);
  };

  const editProductModalShow = () => {
    setOpenEditProductModal(true);
  };

  const editProductModalHide = () => {
    setOpenEditProductModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const addNewProductModalShow = () => {
    setOpenAddNewProductModal(true);
  };

  const addNewProductModalHide = () => {
    setOpenAddNewProductModal(false);
  };

  const addNewBarcodeModalShow = () => {
    setOpenAddNewBarcodeModal(true);
  };

  const addNewBarcodeModalHide = () => {
    setOpenAddNewBarcodeModal(false);
  };

  const handleDeleteBarcodeModalShow = () => {
    setIsDeleteBarcodeModalOpen(true);
  };

  const handleDeleteBarcodeModalHide = () => {
    setIsDeleteBarcodeModalOpen(false);
  };

  const handleCheckInBarcodeModalShow = () => {
    setCheckInBarcodeModalOpen(true);
  };

  const handleCheckInBarcodeModalHide = () => {
    setCheckInBarcodeModalOpen(false);
  };

  const CreateNew = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.CREATE.NEW' />
    </Tooltip>
  );

  const editTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='TITLE.EDIT' />
    </Tooltip>
  );

  const [isReceiveviewOpen, setReceiveviewOpen] = useState(false);
  const [isItemReceived, setItemReceived] = useState(false);

  const handlePaginationOfProductListView = () => {
    const { pageNumber, pageSize } = queryParams || {};
    setQueryParams({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationProductListDetails(Math.random());
  };

  const handlePaginationOfPOListView = () => {
    const { pageNumber, pageSize } = queryParamsPO || {};
    setQueryParamsPO({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationPOListDetails(Math.random());
  };

  const handlePaginationOfDCListView = () => {
    const { pageNumber, pageSize } = queryParamsDC || {};
    setQueryParamsDC({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationDCListDetails(Math.random());
  };

  const handlePaginationOfLocListView = () => {
    const { pageNumber, pageSize } = queryParamsLoc || {};
    setQueryParamsLoc({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationLocListDetails(Math.random());
  };

  const handlePaginationOfHistoryListView = () => {
    const { pageNumber, pageSize } = queryParamsLoc || {};
    setQueryParamsLoc({ pageNumber: pageNumber + 1, pageSize });
    setUpdatePaginationHistoryListDetails(Math.random());
  };

  const handleDeleteOfBarcodeNumber = (location) => {
    setSelectedLocation(location);
    handleDeleteBarcodeModalShow();
  };

  const handleCheckInOfBarcodeNumber = (location, label, value) => {
    setSelectedLocation(location);
    setLocationValue({ label: label, value: value });
    handleCheckInBarcodeModalShow();
  };

  const handleCheckOut = (location) => {
    const productLocation = { ...productDetails, ...location };
    const createCheckoutPath = "/outwards/add-outward";
    history.push(createCheckoutPath, { data: productLocation });
  };

  const getSecondaryColorClass = (product) => {
    let className = "fs-14 text-primary font-weight-bold mb-0 cursor-pointer";
    const { id: PID } = product;
    if (PID === id) {
      className = className + " text-secondary";
    }
    return className;
  };

  const renderProductName = (product) => {
    const { name } = product || {};
    return <>{capitalizeFirstLetter(name === null ? "-" : name)}</>;
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const handleCloseOfAddNewProductModal = () => {
    setProductList([]);
    setQueryParams(initialQueryParams);
    setUpdateProductListing(Math.random());
    addNewProductModalHide();
  };

  const onClickOfDeleteBarcodeDeleteButton = async () => {
    const { id } = selectedLocation || {};
    enableLoadingDeleteBarcode();
    const deleteBarcodeResponse = await deleteBarcodeApi({
      id
    });
    const { Error } = deleteBarcodeResponse || {};
    if (Error) {
      disableLoadingDeleteBarcode();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }

    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.PRODUCT.BARCODE.IS.DELETED.SUCCESSFULLY"
      })
    );
    onOpenSuccessModal();
    handleDeleteBarcodeModalHide();
    setLocationList([]);
    setHistoryList([]);
    setQueryParamsLoc(initialQueryParamsLoc);
    setUpdateLocationList(Math.random());
    setUpdateHistoryList(Math.random());
    setUpdateSingleProductDetails(Math.random());
    disableLoadingDeleteBarcode();
  };

  const onChangeOfLocation = (data) => {
    const { value, label } = data || {};
    setLocationValue({
      label: label,
      value: value
    });
  };

  const onClickOfCheckInButton = async () => {
    const { id } = selectedLocation || {};
    enableLoadingCheckInBarcode();

    const checkInBarcodeResponse = await checkInBarcodeApi({
      id,
      locationId: locationValue.value
    });
    const { Error } = checkInBarcodeResponse || {};
    if (Error) {
      disableLoadingDeleteBarcode();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }

    setSuccessMessage(
      intl.formatMessage({
        id: "TITLE.PRODUCT.BARCODE.IS.CHECKEDIN.SUCCESSFULLY"
      })
    );
    onOpenSuccessModal();
    handleCheckInBarcodeModalHide();
    setLocationList([]);
    setHistoryList([]);
    setQueryParamsLoc(initialQueryParamsLoc);
    setUpdateLocationList(Math.random());
    setUpdateHistoryList(Math.random());
    setUpdateSingleProductDetails(Math.random());
    disableLoadingCheckInBarcode();
  };

  const location = useLocation();
  // Check if the current URL path is "/checkout"
  const isCheckoutPage = location.pathname.includes("/checkout");
  // Define the default active tab based on the URL path
  const defaultActiveTab = isCheckoutPage ? 2 : 0;
  // Function to handle tab change
  const handleTabChange = (index) => {
    // Determine the path based on the selected tab
    let newPath;

    switch (index) {
      case 0:
        newPath = "/overview";
        break;
      case 1:
        newPath = "/transactions";
        break;
      case 2:
        newPath = "/checkout";
        break;
      case 3:
        newPath = "/history";
        break;
      default:
        newPath = "/";
    }

    // Perform redirection if the new path is different from the current path
    if (location.pathname !== newPath) {
      history.push(
        `/inventoryproducts/view-inventoryproduct-details/${productDetails.id}${newPath}`
      );
    }
  };

  return (
    <>
      <Card className='p-0 mb-0'>
        <div className='details-view d-block d-md-flex'>
          {/* Listing */}
          <div className='column list-column border-right d-none d-lg-block'>
            <div className='column-header d-flex align-items-center justify-content-between'>
              <h2 className='modal-title'>
                <FormattedMessage id='TITLE.ALL.PRODUCTS' />
              </h2>
              {(userRole === "Admin" || userRole === "Asset Manager") && (
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={CreateNew}
                >
                  <Button
                    variant='primary btn-inventory btn-icon'
                    size='sm'
                    onClick={() => {
                      addNewProductModalShow();
                    }}
                  >
                    <Plus size={14} className='text-secondary'></Plus>{" "}
                    <FormattedMessage id='TITLE.NEW' />
                  </Button>
                </OverlayTrigger>
              )}
            </div>
            {(isLoadingProductListView ||
              isLoadingPOView ||
              isLoading ||
              isLoadingLocView ||
              isLoadingDCView) && <SectionLoader />}
            <div id='scrollableDiva' className='column-body custom-scrollbar'>
              {!isLoadingProductListView && (
                <InfiniteScroll
                  scrollableTarget='scrollableDiva'
                  dataLength={productList.length}
                  next={() => {
                    handlePaginationOfProductListView();
                  }}
                  hasMore={isLoadMoreProductListView}
                  loader={
                    <h4 style={{ textAlign: "center" }} className='p-1'>
                      <FormattedMessage id='TITLE.LOADING' />
                      ...
                    </h4>
                  }
                >
                  {productList.map((product, index) => (
                    <div
                      className='column-box cursor-pointer'
                      key={index}
                      onClick={() => {
                        if (id !== product.id) {
                          setPOSummary([]);
                          setPOList([]);
                          setDCList([]);
                          setLocationList([]);
                          setHistoryList([]);
                          setQueryParamsPO(initialQueryParamsPO);
                          setQueryParamsDC(initialQueryParamsDC);
                          setQueryParamsLoc(initialQueryParamsLoc);
                        }
                        const path = `/inventoryproducts/view-inventoryproduct-details/${product.id}`;
                        onClickChangeRoute(path);
                      }}
                    >
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-column'>
                          <h5 className={getSecondaryColorClass(product)}>
                            {renderProductName(product)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>{" "}
          </div>
          {/* Details */}

          {!isReceiveviewOpen && (
            <div className='column details-column'>
              {isLoading && <SectionLoader />}

              <div className='column-header d-flex align-items-center justify-content-between'>
                <h2 className='modal-title'>
                  {capitalizeFirstLetter(productDetails?.name)}
                </h2>
                <div className='d-flex'>
                  {userRole !== "Inward Manager" &&
                    userRole !== "Outward Manager" && (
                      <ButtonGroup className='mr-2' aria-label='First group'>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={editTooltip}
                        >
                          <Button
                            variant='outline-tertiary btn-sm btn-icon'
                            onClick={() => editProductModalShow()}
                          >
                            <Edit2 size={16} className='p-0 m-0'></Edit2>
                          </Button>
                        </OverlayTrigger>
                      </ButtonGroup>
                    )}
                  {userRole !== "Inward Manager" &&
                    userRole !== "Outward Manager" && (
                      <Button
                        variant='success btn-sm btn-icon mr-2'
                        onClick={() => addNewBarcodeModalShow()}
                      >
                        <FormattedMessage id='TITLE.ADJUST.STOCK' />
                      </Button>
                    )}
                  {userRole !== "Inward Manager" &&
                    userRole !== "Outward Manager" && (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='dropdown-sm'
                          id='dropdown-basic'
                        >
                          <FormattedMessage id='TITLE.MORE' />
                          {isLoadingEditProduct && (
                            <ButtonLoader className='mr-1 ml-1' />
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              handleSaveOfStatus();
                            }}
                            disabled={isLoadingEditProduct}
                          >
                            {productDetails?.status === 1 ? (
                              <FormattedMessage id='TITLE.MARK.AS.INACTIVE' />
                            ) : (
                              <FormattedMessage id='TITLE.MARK.AS.ACTIVE' />
                            )}
                            {isLoadingEditProduct && (
                              <ButtonLoader className='mr-1 ml-1' />
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}

                  <Button
                    variant='close btn-icon btn-sm'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      const path = "/inventoryproducts/products";
                      onClickChangeRoute(path);
                    }}
                  >
                    <X size={20} className='p-0 m-0 text-dark'></X>
                  </Button>
                </div>
              </div>
              <div
                id='scrollabledivOverview'
                className='column-body custom-scrollbar'
              >
                <Tabs
                  defaultIndex={defaultActiveTab}
                  onSelect={handleTabChange}
                >
                  <TabList className='nav nav-tabs tab-coupon mb-0'>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.OVERVIEW' />
                    </Tab>
                    {userRole !== "Admin" && (
                      <Tab className='nav-link'>
                        <FormattedMessage id='TITLE.TRANSACTIONS' />
                      </Tab>
                    )}
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.LOCATIONS' />
                    </Tab>
                    <Tab className='nav-link'>
                      <FormattedMessage id='TITLE.HISTORY' />
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <DefaultProductView
                      productDetails={productDetails}
                      id={id}
                      POSummary={POSummary}
                    />
                  </TabPanel>
                  {userRole !== "Admin" && (
                    <TabPanel>
                      <div className='highlighted-row'>
                        <div className='d-flex align-items-center justify-content-between'>
                          {!isItemReceived && (
                            <div className=''>
                              <div className='d-flex justify-content-start'>
                                {userRole !== "Outward Manager" && (
                                  <Button
                                    // variant='secondary btn-icon'
                                    variant={`${
                                      isPOView
                                        ? "secondary btn-icon ml-2"
                                        : "tertiary btn-icon ml-2"
                                    }`}
                                    onClick={() => onClickOfPOView()}
                                  >
                                    <FormattedMessage id='TITLE.PURCHASE.ORDERS' />
                                  </Button>
                                )}
                                {userRole !== "Inward Manager" && (
                                  <Button
                                    // variant="tertiary btn-icon ml-2"
                                    variant={`${
                                      isPOView
                                        ? "tertiary btn-icon ml-2"
                                        : "secondary btn-icon ml-2"
                                    }`}
                                    onClick={() => setPOView(false)}
                                  >
                                    <FormattedMessage id='TITLE.CHECKOUT.REQUEST' />
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {isPOView ? (
                        <TransactionPOView
                          POList={POList}
                          isLoadingPOView={isLoadingPOView}
                          handlePaginationOfPOListView={
                            handlePaginationOfPOListView
                          }
                          isLoadMorePOListView={isLoadMorePOListView}
                        />
                      ) : (
                        <TransactionDCView
                          DCList={DCList}
                          isLoadingDCView={isLoadingDCView}
                          handlePaginationOfDCListView={
                            handlePaginationOfDCListView
                          }
                          isLoadMoreDCListView={isLoadMoreDCListView}
                        />
                      )}
                    </TabPanel>
                  )}

                  <TabPanel>
                    <LocationProductView
                      locationList={locationList}
                      isLoadingLocView={isLoadingLocView}
                      isLoadMoreLocListView={isLoadMoreLocListView}
                      handlePaginationOfLocListView={
                        handlePaginationOfLocListView
                      }
                      handleDeleteOfBarcodeNumber={handleDeleteOfBarcodeNumber}
                      handleCheckOut={handleCheckOut}
                      handleCheckInOfBarcodeNumber={
                        handleCheckInOfBarcodeNumber
                      }
                    />
                  </TabPanel>
                  <TabPanel>
                    <ProductBarcodeHistoryView
                      historyList={historyList}
                      isLoadingHistoryView={isLoadingHistoryView}
                      isLoadMoreLocHistoryView={isLoadMoreLocHistoryView}
                      handlePaginationOfHistoryListView={
                        handlePaginationOfHistoryListView
                      }
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          )}
        </div>
      </Card>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}

      {isDeleteBarcodeModalOpen && (
        <DeleteModal
          isLoading={isLoadingDeleteBarcode}
          title={`${intl.formatMessage({
            id: "TITLE.DELETE.PRODUCT.BARCODE"
          })}?`}
          description={intl.formatMessage({
            id: "TITLE.PRODUCT.BARCODE.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
          })}
          id={selectedLocation.barcode_no}
          isOpen={isDeleteBarcodeModalOpen}
          onCloseModal={handleDeleteBarcodeModalHide}
          onClickOfDeleteButton={onClickOfDeleteBarcodeDeleteButton}
        />
      )}

      {isCheckInBarcodeModalOpen && (
        <CheckInModal
          isLoading={isLoadingCheckInBarcode}
          title={`${intl.formatMessage({
            id: "TITLE.CHECKIN.PRODUCT.BARCODE"
          })}`}
          description={intl.formatMessage({
            id: "TITLE.ARE.YOU.SURE.WANT.TO.CHECK.IN.THIS.EQUIPMENT"
          })}
          selectdescription={intl.formatMessage({
            id: "TITLE.SELECT.CHECKIN.LOCATION"
          })}
          id={selectedLocation.barcode_no}
          isOpen={isCheckInBarcodeModalOpen}
          onCloseModal={handleCheckInBarcodeModalHide}
          onClickOfCheckInButton={onClickOfCheckInButton}
          product={productDetails}
          onChangeOfLocation={onChangeOfLocation}
          selectedLocation={selectedLocation}
          label={selectedLocation?.location_Id}
          locationValue={locationValue}
        />
      )}

      {isOpenAddNewProductModal && (
        <AddNewProductModal
          open={isOpenAddNewProductModal}
          onCloseModal={addNewProductModalHide}
          handleCloseOfAddNewProductModal={handleCloseOfAddNewProductModal}
        />
      )}

      {isOpenEditProductModal && (
        <EditProductModal
          open={isOpenEditProductModal}
          data={productDetails}
          onCloseModal={editProductModalHide}
          id={id}
          handleCloseOfEditNewProductModal={handleCloseOfEditNewProductModal}
        />
      )}

      {isOpenAddNewBarcodeModal && (
        <AddNewBarcodeModal
          onCloseModal={addNewBarcodeModalHide}
          open={isOpenAddNewBarcodeModal}
          product={productDetails}
          handleCancelOfAddNewBarcode={handleCancelOfAddNewBarcode}
          handleAddNewBarcode={handleAddNewBarcode}
        />
      )}

      {openSuccessModal && (
        <SuccessModal
          message={successMessage}
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
        />
      )}
    </>
  );
};

export default ViewProductDetails;
