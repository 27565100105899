import { Chart } from "chart.js";
import React, { Fragment, useContext, useState } from "react";
import { Card, Image } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { ContentLoader } from "../../helper/ContentLoader";
import { FormattedMessage } from "react-intl";
import nodatafoundImage from "../../assets/images/dashboard/nodata-found.svg";
import { USER_DETAILS } from "../../constants/const";
import { setActiveMenu } from "../../constants/utils";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";

function POStatusWidgets({ POStatus, isLoading }) {
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const labels = [];
  const data = [];
  POStatus &&
    POStatus.map((item, index) => {
      if (item.title !== "Delivered") {
        labels.push(item.title);
        data.push(item.data);
      }
    });

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  const poStatus = {
    labels,
    datasets: [
      {
        label: "Purchase Order",
        data,
        backgroundColor: [
          "rgba(212, 64, 1, 1)",
          "rgba(0, 117, 153, 1)",
          "rgba(44, 69, 146, 1)",
          "rgba(0, 153, 112, 1)",
          "rgba(212, 150, 112, 1)"
        ],
        // borderColor: [
        //   "rgba(212, 64, 1, 0.2)",
        //   "rgba(0, 117, 153, 0.2)",
        //   "rgba(44, 69, 146, 0.2)",
        //   "rgba(0, 153, 112, 0.2)",
        // ],
        borderWidth: 1.5
      }
    ]
  };

  const pieOptions = {
    events: true,
    animation: {
      duration: 500,
      easing: "easeOutQuart",
      onComplete: function () {
        var ctx = this.chart.ctx;
        ctx.font = Chart.helpers.fontString(
          Chart.defaults.global.defaultFontFamily,
          "normal",
          Chart.defaults.global.defaultFontFamily
        );
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";

        this.data.datasets.forEach(function (dataset) {
          for (var i = 0; i < dataset.data.length; i++) {
            var model =
                dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
              total = dataset._meta[Object.keys(dataset._meta)[0]].total,
              mid_radius =
                model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
              start_angle = model.startAngle,
              end_angle = model.endAngle,
              mid_angle = start_angle + (end_angle - start_angle) / 2;

            var x = mid_radius * Math.cos(mid_angle);
            var y = mid_radius * Math.sin(mid_angle);

            ctx.fillStyle = "#fff";
            if (i == dataset.data.length) {
              // Darker text color for lighter background
              ctx.fillStyle = "#444";
            }
            var percent = " ";
            ctx.fillText(dataset.data[i], model.x + x, model.y + y);
            // Display percent in another line, line break doesn't work for fillText
            ctx.fillText(percent, model.x + x, model.y + y + 15);
          }
        });

        document.getElementById("pochallanstatuschart").style.cursor =
          "pointer";
      }
    }
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <Card>
        <Card.Header className='d-flex justify-content-between '>
          <Card.Title className='mb-0 modal-title'>
            <FormattedMessage id='TITLE.PURCHASE.ORDERS.STATUS' />
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {isLoading && (
            <ContentLoader isLoading={isLoading} pleaseWait={true} />
          )}
          {/* {data.length === 0 && !isLoading && (
            <ContentLoader isLoading={isLoading} pleaseWait={true} />
          )} */}
          {/* <Pie
            id="pochallanstatuschart"
            data={poStatus}
            options={pieOptions}
            onElementsClick={(elems) => {
              if (elems[0]) {
                history.push("/inwards/inward-listing");
              }
            }}

          /> */}
          {data.length === 0 ? (
            <>
              <div className='d-flex align-items-center justify-content-center flex-column'>
                <Image
                  src={nodatafoundImage}
                  alt='No Data Found'
                  className='img-fluid'
                  style={{ maxHeight: "200px" }}
                />
                <span className='d-block text-center'>
                  <FormattedMessage id='TITLE.NO.RECORDS.FOUND' />
                </span>
              </div>
            </>
          ) : (
            <>
              <Pie
                id='pochallanstatuschart'
                data={poStatus}
                options={pieOptions}
                onElementsClick={(elems) => {
                  if (
                    elems[0] &&
                    userRole !== "Inward Manager" &&
                    userRole !== "Outward Manager"
                  ) {
                    const path = "/inwards/inward-listing";
                    onClickChangeRoute(path);
                  }
                }}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default POStatusWidgets;
