import React from "react";
import { capitalizeFirstLetter, getProductMediaUrl } from "../constants/utils";
import { ImageWrapper } from "../helper/ImageWrapper";
const ProductNameColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { redirectToViewPage }
) => {
  const { name, product_media } = row;
  const { name: productImage } = product_media || {};

  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='product-thumbnail'>
          <div className='border-0'>
            <ImageWrapper
              className={"img-radius align-top blur-up lazyloaded"}
              src={getProductMediaUrl(productImage)}
            />
          </div>
        </div>
        <div
          className='pl-2 text-primary btn-link p-0 cursor-pointer'
          onClick={() => {
            redirectToViewPage(row);
          }}
        >
          {capitalizeFirstLetter(name)}
        </div>
      </div>
    </>
  );
};

export default ProductNameColumnFormatter;
