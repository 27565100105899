import React, { useState, useMemo } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
import { useProductUIContext } from "../CustomFieldUIContext";
import { isEqual } from "lodash";
import { Formik } from "formik";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorModal } from "../../common/modals/ErrorModal";
import Select from "react-select"
import { customFieldTypeList } from "../../../constants/utils";


const initialFilterValues = {
  field_name: '',
  field_type: '',
};
export function ProductFilter({
  isLoadingFilter,
  handleCLickOfFilterButtonFilter,
  handleCLickOfResetFilterButtonFilter
}) {
  const intl = useIntl();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const inwardUIContext = useProductUIContext();
  const categoryUIProps = useMemo(() => {
    return {
      queryParams: inwardUIContext.queryParams,
      setQueryParams: inwardUIContext.setQueryParams
    };
  }, [inwardUIContext]);

  const prepareFilter = (queryParams, values) => {
    const {
      field_name,
      field_type
    } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    
    filter.field_name = field_name !== "" ? field_name : undefined;
    filter.field_type = field_type !== "" ? field_type : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(categoryUIProps.queryParams, values);
    if (!isEqual(newQueryParams, categoryUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      categoryUIProps.setQueryParams(newQueryParams);
    }
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          resetForm,
          handleReset,
          setFieldValue
        }) => (
          <Form
            className='form form-label-right filter-card'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialFilterValues);
              handleReset();
            }}
          >
            <Form.Group className='form-group mb-md-0 filter-form-group'>
              <div className='filter-item'>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.FIELD.NAME' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.CUSTOMFIELDS.ADDFORM.FIELD.NAME"
                    })}
                    onChange={(e) => {
                      setFieldValue("field_name", e.target.value);
                    }}
                    maxLength='70'
                    autoComplete='off'
                    value={values.field_name}
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.FIELD.TYPE' />
                    </b>
                  </small>
                  <Select
                    options={customFieldTypeList}
                    onChange={(e)=>{
                      setFieldValue('field_type',e.value)
                    }}
                    value={customFieldTypeList.find((x)=>x.value === values.field_type) ?? []}
                  />
                </div>
              </div>
              <div className='filter-action d-flex'>
                <Button
                  variant='secondary d-flex align-items-center btn-inventory mr-2'
                  type='submit'
                >
                  <Search
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader />}
                </Button>
                <Button
                  variant='tertiary d-flex align-items-center btn-inventory'
                  type='reset'
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
