import React, { useMemo } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
import { isEqual } from "lodash";
import { useOutwardCustomerUIContext } from "../OutwardCustomerUIContext";
import { Formik } from "formik";
import { ButtonLoader } from "../../../../helper/ButtonLoader";
import { FormattedMessage, useIntl } from "react-intl";

const initialFilterValues = {
  contact_name: "",
  phone_number: ""
};

export function OutwardCustomerFilter({
  isLoadingFilter,
  handleCLickOfFilterButtonFilter,
  handleCLickOfResetButtonFilter
}) {
  const intl = useIntl();

  const outwardCustomerUIContext = useOutwardCustomerUIContext();
  const outwardCustomerUIProps = useMemo(() => {
    return {
      queryParams: outwardCustomerUIContext.queryParams,
      setQueryParams: outwardCustomerUIContext.setQueryParams
    };
  }, [outwardCustomerUIContext]);

  const prepareFilter = (queryParams, values) => {
    const { contact_name, phone_number } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.contact_name = contact_name !== "" ? contact_name : undefined;
    filter.phone_number = phone_number !== "" ? phone_number : undefined;
    newQueryParams.filter = { ...queryParams.filter, ...filter };
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      outwardCustomerUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, outwardCustomerUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      outwardCustomerUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
          handleCLickOfResetButtonFilter();
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue
        }) => (
          <Form
            className='form form-label-right filter-card'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm({ values: initialFilterValues });
              handleReset();
            }}
          >
            <Form.Group className='form-group mb-md-0 filter-form-group'>
              <div className='filter-item'>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      {" "}
                      <FormattedMessage id='TITLE.EMPLOYEE.NAME' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='contact_name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.EMPLOYEE.NAME"
                    })}
                    onChange={(e) => {
                      setFieldValue("contact_name", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      {" "}
                      <FormattedMessage id='TITLE.PHONE.NUMBER' />
                    </b>
                  </small>
                  <Form.Control
                    type='number'
                    className='form-control'
                    name='phone_number'
                    placeholder={intl.formatMessage({
                      id: "TITLE.PHONE.NUMBER"
                    })}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onPaste={(evt) => {
                      const values = evt.clipboardData.getData("text");
                      const nonAlpha = values.replace(/[^\d.-]/g, "");
                      evt.preventDefault();
                      setFieldValue("phone_number", nonAlpha);
                    }}
                    onChange={(e) => {
                      setFieldValue("phone_number", e.target.value);
                    }}
                    value={values.phone_number}
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='filter-action d-flex align-items-center'>
                <Button
                  type='submit'
                  variant='secondary d-flex align-items-center btn-inventory mr-2'
                >
                  <Search
                    className='m-0'
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader />}
                </Button>
                <Button
                  type='reset'
                  variant='tertiary d-flex align-items-center btn-inventory'
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                    className='m-0'
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
}
