import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Check, Plus, XCircle } from "react-feather";
import {
  getSingleDeliverItemByBarcodeApi,
  deliverOutwardApi,
  uploadBlobApi,
  receivePOLocationListApi
} from "../../axios/services/services";
import { ButtonLoader } from "../../helper/ButtonLoader";
import { ErrorModal } from "../common/modals/ErrorModal";
import { FieldArray, Formik } from "formik";
import { DeliverDCSchema } from "../../helper/FormikSchema";
import { useHistory } from "react-router-dom";
import { SET_TIMEOUT } from "../../constants/const";
import { setActiveMenu, unauthorization } from "../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { getErrorMessageBasedOnLanguage } from "../../constants/utils";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { ViewDCProductForDeliverModal } from "../../global-modal/ViewDCProductForDeliverModal";
import SignatureCanvas from "react-signature-canvas";
import Select from "react-select";

export function DeliverOutwardForm({
  id,
  DCDetails,
  singleDCPurchaseItem,
  onClickofcancel
}) {
  const intl = useIntl();
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [DeliveredDate, setDeliveredDate] = useState(new Date());
  const [isLoading, setLoading] = useState(false);
  const [isNewDeliverAdded, setNewDeliverAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSingleBarcodeDetail, setSingleBarcodeDetail] = useState([]);
  const [remainingReceivedQuantity, setRemainingReceivedQuantity] = useState(0);
  const [isViewDCProductDeliverModalOpen, setViewDCProductDeliverModalOpen] =
    useState(false);
  const [isAddPrductTable, setAddPrductTable] = useState(true);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [initialValues, setInitialValues] = useState({
    delivery_date: new Date(),
    notes: "",
    product_condition: [
      {
        barcode_no: "",
        product: "",
        location: "",
        isLoading: false
      }
    ]
  });
  const [locationList, setLocationList] = useState([]);
  const [isLoadingLocationList, setLoadingLocationList] = useState(false);
  const handleAddProductConditionRef = useRef([]);
  const handleAddNewReceiveItemRef = useRef();
  const signatureCanvasRef = useRef(null);

  useEffect(() => {
    let totalProductQuantity = 0;
    let totalProductReceivedQuantity = 0;
    if (singleDCPurchaseItem) {
      const products = singleDCPurchaseItem.map((product) => {
        const { quantity, received_quantity } = product || {};
        totalProductQuantity = totalProductQuantity + quantity;
        totalProductReceivedQuantity =
          totalProductReceivedQuantity + received_quantity;
        return product;
      });
    }
    const remaining = totalProductQuantity - totalProductReceivedQuantity;
    setRemainingReceivedQuantity(remaining);
  }, [singleDCPurchaseItem]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const onChangeOfBarcode = (setFieldValue, value, product, index) => {
    setFieldValue(`product_condition.${index}.barcode_no`, value);
  };

  const handleCloseOfViewDCProductDeliverModalShow = () => {
    setViewDCProductDeliverModalOpen(true);
  };

  const handleCloseOfViewDCProductDeliverModalHide = () => {
    setViewDCProductDeliverModalOpen(false);
  };

  const getSingleDeliverItemByBarcode = async (setFieldValue, value, index) => {
    if (value.length === 14) {
      setFieldValue(`product_condition.${index}.isLoading`, true);
      const singleDeliveryItemByBarcodeResponse =
        await getSingleDeliverItemByBarcodeApi(value);
      setFieldValue(`product_condition.${index}.isLoading`, false);
      const { data, Error } = singleDeliveryItemByBarcodeResponse || {};
      const { product, location_Id } = data || {};
      const { name: productName } = product || {};
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setSingleBarcodeDetail(data ? data : []);
      setFieldValue(`product_condition.${index}.barcode_no`, value);
      setFieldValue(`product_condition.${index}.product`, productName);
      const {
        store_location: name,
        parent_location,
        locationId
      } = location_Id || "-";
      const { locationId: li } = locationId || {};
      var locName = name;
      var comma = ",";
      if (parent_location) {
        locName = name.concat(comma, locationId?.store_location);
        if (li) {
          locName = locName.concat(comma, li?.store_location);
        }
      }
      setFieldValue(`product_condition.${index}.location`, locName);
      if (data === null) {
        setErrorMessage(
          intl.formatMessage({
            id: "TITLE.DATA.NOT.FOUND.WITH.BARCODE"
          }) + value
        );
        handleErrorModalShow();
      }
    }
  };

  const deliverOutward = async (values) => {
    enableLoading();
    const signatureUploadResponse = await handleSignatureChange();
    if (!signatureUploadResponse || !signatureUploadResponse.filename) {
      disableLoading();
      return;
    }
    const { delivery_date, notes, product_condition } = values || {};
    const product_conditionArray = [];
    const productConditions = product_condition.map((product) => {
      const { barcode_no, location, locationId } = product;
      product_conditionArray.push({
        barcode_no,
        location,
        locationId
      });

      return product;
    });

    let outardDeliverObj = {
      id,
      delivery_date,
      notes,
      product_condition: product_conditionArray,
      signature: {
        file_name: signatureUploadResponse.filename,
        file_type: signatureUploadResponse.mimetype
      }
    };
    const deliverOutwardResponse = await deliverOutwardApi(outardDeliverObj);
    const { Error } = deliverOutwardResponse || {};
    if (Error) {
      disableLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    window.scroll(0, 0);
    setNewDeliverAdded(true);
    setTimeout(() => {
      disableLoading();
      const path = "/outwards/outward-listing";
      onClickChangeRoute(path);
    }, SET_TIMEOUT);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const handleSignatureChange = async () => {
    const isEmpty = signatureCanvasRef.current.isEmpty();
    if (isEmpty) {
      // Show an error message or take any appropriate action
      console.error("No signature added.");
      setErrorMessage(
        intl.formatMessage({
          id: "TITLE.SIGNATURE.REQUIRED.TO.ADD.NEW.CHECKOUT.REQUEST"
        })
      );
      handleErrorModalShow();
      return;
    }

    // This function will be called when the user finishes drawing on the canvas
    const imageDataUrl = signatureCanvasRef.current.toDataURL();

    // You can use the imageDataUrl as needed (e.g., save, display, or send to a server)

    // Convert data URL to Blob
    const blob = dataURLtoBlob(imageDataUrl);

    const uploadDocumentResponse = await uploadBlobApi(
      "signature",
      blob,
      "signature.png"
    );
    const [uploadedDoc] = uploadDocumentResponse || {};
    return uploadedDoc;
  };

  const handleClearCanvas = () => {
    // Clear the canvas
    signatureCanvasRef.current.clear();
  };

  const { purchaseorder_number, date } = DCDetails || {};

  const enableLoadingLocationList = () => {
    setLoadingLocationList(true);
  };

  const disableLoadingLocationList = () => {
    setLoadingLocationList(false);
  };

  const renderLocationValue = (product) => {
    let currentlocation = locationList.find(
      (loc) => loc.label === product.location
    );
    const obj = {
      label: currentlocation?.label,
      value: currentlocation?.value
    };
    return obj;
  };

  async function getLocationList() {
    enableLoadingLocationList();
    const locationListResponse = await receivePOLocationListApi();
    const { Error: ErrorLocation, data: locationData } =
      locationListResponse || {};
    if (ErrorLocation) {
      unauthorization(ErrorLocation);
      setErrorMessage(getErrorMessageBasedOnLanguage(ErrorLocation));
      handleErrorModalShow();
      return;
    }

    const locations = locationData.map((location) => {
      const { store_location, id, parent_location, locationId } =
        location || {};

      const { locationId: li } = locationId || {};
      let locName = store_location;
      var comma = ",";
      if (parent_location) {
        locName = store_location.concat(comma, locationId?.store_location);
        if (li) {
          locName = locName.concat(comma, li?.store_location);
        }
      }
      location.value = id;
      location.label = locName;
      return location;
    });
    const [firstLocation] = locations || {};

    let obj = {
      barcode_no: "",
      locationId: firstLocation?.id,
      condition: true,
      quantity: 1,
      selectedLocation: firstLocation
    };
    initialValues.purchaseorder_barcode = [obj];
    setInitialValues(initialValues);
    setLocationList(locations ? locations : []);
    disableLoadingLocationList();
  }

  useEffect(() => {
    getLocationList();
  }, []);

  const onChangeOfLocation = (setFieldValue, data, index) => {
    setFieldValue(`product_condition.${index}.location`, data.label);
    setFieldValue(`product_condition.${index}.locationId`, data.value);
    renderLocationValue({ location: data.label });
  };

  return (
    <>
      <div className='column details-column'>
        <Formik
          initialValues={initialValues}
          validationSchema={DeliverDCSchema}
          onSubmit={(values) => {
            deliverOutward(values);
          }}
          onReset={(values) => {}}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            resetForm,
            handleReset,
            setFieldValue,
            errors,
            touched
          }) => (
            <Form
              className='form form-label-right filter-card'
              autoComplete='off'
              onSubmit={handleSubmit}
              onReset={() => {
                resetForm(initialValues);
                handleReset();
              }}
            >
              <div
                className='w-100 column-header d-flex align-items-center justify-content-between'
                style={{ minHeight: "71px" }}
              >
                <h2 className='modal-title'>
                  <FormattedMessage id='TITLE.ITEM.DELIVER' />
                </h2>
              </div>
              <div className='column-body with-footer custom-scrollbar'>
                <div className='view-body'>
                  {/* <Form> */}
                  {isNewDeliverAdded && (
                    <div className='notification icon inline success fs-16'>
                      <Check size={8} className='text-white'></Check>{" "}
                      <FormattedMessage id='TITLE.ITEMS.CHECKEDOUT.SUCCESSFULLY' />
                    </div>
                  )}
                  <Row className='mb-3'>
                    <Col md={4} lg={3} xs={6}>
                      <span className='text-dark'>
                        <FormattedMessage id='TITLE.CR.NUMBER' />
                      </span>
                    </Col>
                    <Col md={4} lg={3} xs={6}>
                      <span className='text-primary'>
                        {purchaseorder_number}
                      </span>
                    </Col>
                  </Row>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column md='4' lg='3' xs='6'>
                      <FormattedMessage id='TITLE.DELIVERED.DATE' />
                      <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col md={4} lg={3} xs={6}>
                      <ReactDatePicker
                        selected={DeliveredDate}
                        dateFormat='dd/MM/yyyy'
                        onChange={(date) => {
                          if (date) {
                            setDeliveredDate(date);
                            setFieldValue("delivery_date", date);
                          } else {
                            setDeliveredDate(null);
                            setFieldValue("delivery_date", "");
                          }
                        }}
                        placeholderText={intl.formatMessage({
                          id: "TITLE.DD/MM/YYYY"
                        })}
                        maxDate={new Date()}
                        minDate={new Date(date)}
                        className='form-control w-100'
                        closeOnScroll={(e) => e.target === document}
                      />
                      {touched.delivery_date && errors.delivery_date ? (
                        <div className='text-danger'>
                          {errors.delivery_date}
                        </div>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Row className='mb-1 justify-content-end'>
                    <Button
                      variant='link p-0'
                      onClick={() => {
                        handleCloseOfViewDCProductDeliverModalShow();
                      }}
                    >
                      <FormattedMessage id='TITLE.VIEW.DC.PRODUCTS' />
                    </Button>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Table
                        className='receive-item-table viewpo-table'
                        style={{ tableLayout: "fixed" }}
                      >
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage id='TITLE.BARCODE.NO' />
                            </th>
                            <th style={{ minWidth: "200px" }}>
                              <FormattedMessage id='TITLE.PRODUCT' />
                            </th>
                            <th>
                              <FormattedMessage id='TITLE.LOCATIONS' />
                            </th>
                            <th
                              className='text-center'
                              style={{ width: "15%" }}
                            >
                              <FormattedMessage id='TITLE.DELIVERED' />
                            </th>
                            <th
                              className='text-center'
                              style={{ width: "15%" }}
                            >
                              <FormattedMessage id='TITLE.QUANTITY' />
                            </th>
                            <th style={{ width: "5px" }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isAddPrductTable && (
                            <FieldArray
                              name='product_condition'
                              render={(product_condition) =>
                                values.product_condition.map(
                                  (product, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Form.Control
                                          size='sm'
                                          name='barcode_no'
                                          type='text'
                                          className='form-control small-text'
                                          placeholder='00-AAA-0000000'
                                          autoFocus
                                          minLength={14}
                                          maxLength={14}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            onChangeOfBarcode(
                                              setFieldValue,
                                              value,
                                              product,
                                              index
                                            );

                                            getSingleDeliverItemByBarcode(
                                              setFieldValue,
                                              value,
                                              index
                                            );
                                          }}
                                          value={product.barcode_no}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              handleAddNewReceiveItemRef.current.click();
                                            }
                                          }}
                                        />
                                        {touched.product_condition &&
                                        errors.product_condition &&
                                        errors.product_condition.length > 0 &&
                                        errors.product_condition[index] ? (
                                          <div className='text-danger'>
                                            <FormattedMessage
                                              id={
                                                errors.product_condition[index]
                                                  .barcode_no
                                              }
                                            />
                                          </div>
                                        ) : null}
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "middle",
                                          textAlign: "start"
                                        }}
                                      >
                                        {product.product}
                                      </td>
                                      <td>
                                        <Select
                                          onChange={(data) =>
                                            onChangeOfLocation(
                                              setFieldValue,
                                              data,
                                              index
                                            )
                                          }
                                          isSearchable={true}
                                          options={locationList}
                                          placeholder='Select'
                                          id='state'
                                          className='react-select-container receive-product-select'
                                          classNamePrefix='react-select'
                                          value={renderLocationValue(product)}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "middle",
                                          textAlign: "center"
                                        }}
                                      >
                                        1
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "middle",
                                          textAlign: "center"
                                        }}
                                      >
                                        1
                                      </td>

                                      <td>
                                        <Button
                                          variant='link p-0 m-0'
                                          className='delete-table-row'
                                          onClick={() => {
                                            if (
                                              values.product_condition
                                                .length === 1
                                            ) {
                                              setAddPrductTable(false);
                                            }
                                            product_condition.remove(index);
                                          }}
                                        >
                                          {product.isLoading ? (
                                            <ButtonLoader className='mr-1 ml-1' />
                                          ) : (
                                            <XCircle
                                              size={16}
                                              style={{
                                                color: "#990029",
                                                marginRight: "0"
                                              }}
                                            />
                                          )}
                                        </Button>

                                        <Button
                                          variant={`link p-0 ml-2 d-none`}
                                          // ref={handleAddProductConditionRef}
                                          ref={(el) =>
                                            (handleAddProductConditionRef.current[
                                              index
                                            ] = el)
                                          }
                                          onClick={() => {
                                            if (
                                              values.product_condition
                                                .length ===
                                              remainingReceivedQuantity
                                            ) {
                                              // setErrorMessage(
                                              //   intl.formatMessage({
                                              //     id: "TITLE.ORDERED.QUANTITY.SCANNED",
                                              //   })
                                              // );
                                              // handleErrorModalShow();
                                              return;
                                            }
                                            const obj = {
                                              barcode_no: "",
                                              product: "",
                                              location: "",
                                              isLoading: false
                                            };
                                            product_condition.insert(
                                              values.product_condition.length,
                                              obj
                                            );
                                          }}
                                        ></Button>
                                      </td>
                                    </tr>
                                  )
                                )
                              }
                            />
                          )}
                          {!isAddPrductTable && (
                            <tr>
                              <td colSpan={6}>
                                <div className='d-grid gap-2'>
                                  <Button
                                    variant='gray btn-icon btn-block'
                                    onClick={() => {
                                      if (
                                        values.product_condition.length === 0
                                      ) {
                                        const obj = {
                                          barcode_no: "",
                                          product: "",
                                          location: "",
                                          isLoading: false
                                        };
                                        values.product_condition.push(obj);
                                      }
                                      setAddPrductTable(true);
                                    }}
                                  >
                                    <Plus
                                      size={16}
                                      style={{ color: "#333333" }}
                                      className='mr-1 ml-1'
                                    />
                                    <FormattedMessage id='TITLE.ADD.PRODUCT' />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {isAddPrductTable && (
                        <Button
                          ref={handleAddNewReceiveItemRef}
                          variant='link btn-icon text-primary px-0 fs-14'
                          onClick={(e) => {
                            // handleAddProductConditionRef.current.click();
                            if (
                              handleAddProductConditionRef.current &&
                              handleAddProductConditionRef.current.length > 0
                            ) {
                              handleAddProductConditionRef.current[0].click();
                            }
                          }}
                        >
                          <Plus size={14} className='text-primary' />{" "}
                          <FormattedMessage id='TITLE.ADD.NEW.DEILVER.ITEM' />
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <div className='mt-3'>
                    <Form.Group>
                      <Form.Label htmlFor='notes'>
                        <FormattedMessage id='TITLE.NOTES.FOR.INTERNAL.USE' />
                      </Form.Label>
                      <Form.Control
                        id='notes'
                        name='notes'
                        as='textarea'
                        className='form-control'
                        resize='none'
                        onChange={(e) => setFieldValue("notes", e.target.value)}
                        value={values?.notes}
                        maxLength={255}
                      />
                      {touched.notes && errors.notes ? (
                        <div className='text-danger'>{errors.notes}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className='mt-3'>
                    <Form.Group
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div style={{ marginBottom: "10px" }}>
                        <Form.Label htmlFor='signature'>
                          <FormattedMessage id='TITLE.NOT.LOOSE.BREAK.EQUIPMENT' />
                          <span className='text-danger'>*</span>
                        </Form.Label>
                      </div>
                      <div
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <SignatureCanvas
                          ref={signatureCanvasRef}
                          className='form-control'
                          penColor='blue'
                          canvasProps={{
                            width: 500,
                            height: 100,
                            className: "sigCanvas"
                          }}
                          backgroundColor='white'
                          name='signature'
                          id='signature'
                          // onEnd={handleSignatureChange}
                        />
                        <div>
                          <button
                            type='button'
                            class='m-2 btn btn-outline-secondary'
                            onClick={handleClearCanvas}
                          >
                            Clear Signature
                          </button>
                        </div>
                      </div>

                      {touched.signature && errors.signature ? (
                        <div className='text-danger'>{errors.signature}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                  {/* </Form> */}
                </div>
              </div>
              <div className='column-footer'>
                <Button
                  variant='success mr-3 btn-inventory'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    if (isSingleBarcodeDetail.length <= 0) {
                      setErrorMessage(
                        intl.formatMessage({
                          id: "TITLE.BARCODE.IS.ALREADY.DEFINED"
                        })
                      );
                      handleErrorModalShow();
                    } else {
                      handleSubmit(values);
                    }
                  }}
                  disabled={isLoading}
                >
                  <FormattedMessage id='TITLE.SAVE' />
                  {isLoading && <ButtonLoader className='mr-1 ml-1' />}
                </Button>
                <Button
                  variant='tertiary text-dark btn-inventory'
                  onClick={() => {
                    onClickofcancel();
                  }}
                >
                  <FormattedMessage id='TITLE.CANCEL' />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
      {isViewDCProductDeliverModalOpen && (
        <ViewDCProductForDeliverModal
          isViewDCProductDeliverModalOpen={isViewDCProductDeliverModalOpen}
          handleCloseOfViewDCProductDeliverModalHide={
            handleCloseOfViewDCProductDeliverModalHide
          }
          deliverChallanProductList={singleDCPurchaseItem}
        />
      )}
    </>
  );
}

export default DeliverOutwardForm;
