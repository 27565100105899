import React, { Fragment, useState } from "react";
import { Card, Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Calendar } from "react-feather";
import { Line } from "react-chartjs-2";
import { addRemoveRTLLTRForDateRangePicker } from "../../helper/helper";
import { ContentLoader } from "../../helper/ContentLoader";
import { customRange } from "../../constants/utils";
import { useIntl, FormattedMessage } from "react-intl";
import { useLang } from "../../i18n";
import moment from "moment";

function PODCSummaryWidgets({ POSummary, isLoading, handlePODCSummaryFilter }) {
  const intl = useIntl();
  const selectedLang = useLang();
  const [data] = POSummary || [];
  let chartLables = [];
  let chartPO = [];
  let chartDC = [];

  const { po_details, dc_details, labels } = data || {};
  const chartPOData = po_details;
  const chartDCData = dc_details;
  if (labels) {
    if (selectedLang === "ar") {
      chartLables = labels.reverse();
      chartPO = chartPOData.reverse();
      chartDC = chartDCData.reverse();
    } else {
      chartLables = labels;
      chartPO = chartPOData;
      chartDC = chartDCData;
    }
  }

  // Predefined Range State
  const [selectedDateOption, setselectedDateOption] = useState("This Month");

  const handleApplyDatePicker = (event, picker) => {
    const { startDate, endDate, chosenLabel } = picker || {};
    const { _d: fromdate } = startDate || {};
    const { _d: todate } = endDate || {};
    handlePODCSummaryFilter(
      chosenLabel,
      fromdate.toISOString(),
      todate.toISOString()
    );
    addRemoveRTLLTRForDateRangePicker();
    // setFromDate(fromdate.toISOString());
    // setToDate(todate.toISOString());
    setselectedDateOption(picker.chosenLabel);
  };

  // PO Summary Graph
  const summaryChart = {
    responsive: true,
    labels: chartLables,
    datasets: [
      {
        label: intl.formatMessage({ id: "TITLE.PO.AMOUNT" }),
        lagend: "none",
        data: chartPO,
        backgroundColor: "transparent",
        borderColor: "rgba(0, 153, 112, 1)"
        // borderColor: "#009970",
        // backgroundColor: "#009970",
        // borderWidth: 0,
      },
      {
        label: intl.formatMessage({ id: "TITLE.CR.AMOUNT" }),
        lagend: "none",
        data: chartDC,
        backgroundColor: "transparent",
        borderColor: "rgba(153, 0, 41, 1)"
        // borderColor: "#009970",
        // backgroundColor: "#009970",
        // borderWidth: 0,
      }
    ]
  };

  return (
    <>
      <Card>
        <Card.Header className='d-flex flex-column flex-md-row justify-content-between'>
          <Card.Title className='mb-0 modal-title'>
            <FormattedMessage id='TITLE.PURCHASE.ORDERS.CHECKOUT.REQUEST.SUMMARY' />
          </Card.Title>
          <DateRangePicker
            key={"PODC"}
            onApply={handleApplyDatePicker}
            initialSettings={{
              startDate: moment().startOf("month"),
              endDate: moment().endOf("month"),
              ranges: customRange,
              opens: selectedLang === "ar" ? "right" : "left",
              applyButtonClasses: "btn-secondary"
            }}
          >
            <Button
              variant='tertiary'
              className='btn-icon mt-3 mt-md-0 btn-sm fs-14 f-w-600'
            >
              <Calendar size={16} />
              {selectedDateOption}
            </Button>
          </DateRangePicker>
        </Card.Header>
        <Card.Body>
          <>
            <div className='po-dc-chart'>
              {isLoading && (
                <ContentLoader isLoading={isLoading} pleaseWait={true} />
              )}
              <Line
                data={summaryChart}
                width={700}
                height={350}
                options={{
                  scales: {
                    xAxes: [
                      {
                        // position: "right", // right-align axis.
                      }
                    ],
                    yAxes: [
                      {
                        position: selectedLang === "ar" ? "right" : "left" // right-align axis.
                      }
                    ]
                  }
                }}
              />
            </div>
          </>
        </Card.Body>
      </Card>
    </>
  );
}

export default PODCSummaryWidgets;
