import moment from "moment";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useIntl } from "react-intl";

export function LocationProductView() {
  const intl = useIntl();
  const summaryChart = {
    responsive: true,
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    datasets: [
      {
        label: intl.formatMessage({
          id: "TITLE.PO.AMOUNT"
        }),
        lagend: "none",
        data: [
          3000, 3540, 3800, 4200, 3600, 4000, 4000, 4400, 4800, 4200, 3950, 3200
        ],
        backgroundColor: "transparent",
        borderColor: "rgba(0, 153, 112, 1)"
        // borderColor: "#009970",
        // backgroundColor: "#009970",
        // borderWidth: 0,
      },
      {
        label: intl.formatMessage({
          id: "TITLE.CR.AMOUNT"
        }),
        lagend: "none",
        data: [
          3600, 3800, 3654, 3200, 3848, 3489, 3678, 3954, 4269, 3123, 3698, 3200
        ],
        backgroundColor: "transparent",
        borderColor: "rgba(153, 0, 41, 1)"
        // borderColor: "#009970",
        // backgroundColor: "#009970",
        // borderWidth: 0,
      }
    ]
  };

  // Predefined Range State
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedDateOption, setselectedDateOption] = useState("This Month");

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
    setselectedDateOption(picker.chosenLabel);
  };
  return (
    <>
      <div className='view-body'>
        <div className='mb-3'>
          <h3 className='page-title'>Location 1</h3>
          <span className='text-dark'>10 Product(s)</span>
        </div>
        {/* <div className="mb-5">
          <span className="text-muted">Description</span>
          <p className="text-black">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div> */}
        <Table responsive className='viewpo-table table'>
          <thead>
            <tr>
              <th scope='col'>Product Name</th>
              <th scope='col'>SKU</th>
              <th scope='col'>Price</th>
              <th scope='col'>Stock on Hand</th>
              <th scope='col'>Reorder Level</th>
              <th scope='col'>Expiry Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='text-info'>HUAWEI - P50 Pro Mobile</td>
              <td className='digits'>HU011AQA</td>
              <td>AED 3,999.00</td>
              <td className='text-right'>20.00</td>
              <td className='text-right'>100</td>
              <td className='text-right'>05/12/2022</td>
            </tr>
            <tr>
              <td className='text-info'>Samsung Galaxy Note20</td>
              <td className='digits'>SG087FEQ</td>
              <td>AED 2,899.00</td>
              <td className='text-right'>10.00</td>
              <td className='text-right'>50</td>
              <td className='text-right'>05/12/2022</td>
            </tr>
            <tr>
              <td className='text-info'>iPhone 12 Pro 265GB</td>
              <td className='digits'>IP025FEQ</td>
              <td>AED 3,750.00</td>
              <td className='text-right'>40.00</td>
              <td className='text-right'>30</td>
              <td className='text-right'>05/12/2022</td>
            </tr>
            <tr>
              <td className='text-info'>fitbit Smartwatch </td>
              <td className='digits'>FB512BKBK</td>
              <td>AED 962.90</td>
              <td className='text-right'>05.00</td>
              <td className='text-right'>80</td>
              <td className='text-right'>05/12/2022</td>
            </tr>
            <tr>
              <td className='text-info'>BOSE Earbuds </td>
              <td className='digits'>FB512BKBK</td>
              <td>AED 1,266.10</td>
              <td className='text-right'>15.00</td>
              <td className='text-right'>50</td>
              <td className='text-right'>05/12/2022</td>
            </tr>
            <tr>
              <td className='text-info'>HUAWEI - P50 Pro Mobile</td>
              <td className='digits'>HU011AQA</td>
              <td>AED 3,999.00</td>
              <td className='text-right'>20.00</td>
              <td className='text-right'>100</td>
              <td className='text-right'>05/12/2022</td>
            </tr>
            <tr>
              <td className='text-info'>Samsung Galaxy Note20</td>
              <td className='digits'>SG087FEQ</td>
              <td>AED 2,899.00</td>
              <td className='text-right'>10.00</td>
              <td className='text-right'>50</td>
              <td className='text-right'>05/12/2022</td>
            </tr>
            <tr>
              <td className='text-info'>iPhone 12 Pro 265GB</td>
              <td className='digits'>IP025FEQ</td>
              <td>AED 3,750.00</td>
              <td className='text-right'>40.00</td>
              <td className='text-right'>30</td>
              <td className='text-right'>05/12/2022</td>
            </tr>
            <tr>
              <td className='text-info'>fitbit Smartwatch </td>
              <td className='digits'>FB512BKBK</td>
              <td>AED 962.90</td>
              <td className='text-right'>05.00</td>
              <td className='text-right'>80</td>
              <td className='text-right'>05/12/2022</td>
            </tr>
            <tr>
              <td className='text-info'>BOSE Earbuds </td>
              <td className='digits'>FB512BKBK</td>
              <td>AED 1,266.10</td>
              <td className='text-right'>15.00</td>
              <td className='text-right'>50</td>
              <td className='text-right'>05/12/2022</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default LocationProductView;
