import React from "react";
import { capitalizeFirstLetter } from "../../constants/utils";

const renderLocationList = (location) => {
  const { store_location: name, parent_location, locationId } = location;
  const { locationId: li } = locationId || {};
  var locName = name;
  var comma = ", ";
  if (parent_location) {
    locName = name.concat(comma, locationId?.store_location);
    if (li) {
      locName = locName.concat(comma, li?.store_location);
    }
  }
  return capitalizeFirstLetter(locName);
};

const AssetLocationColumnFormatter = (cellContent, row, rowIndex) => {
  const { location_Id = "-" } = row;
  return (
    <>
      <div>{renderLocationList(location_Id)}</div>
    </>
  );
};

export default AssetLocationColumnFormatter;
