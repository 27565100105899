import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  getErrorMessageBasedOnLanguage,
  unauthorization
} from "../../constants/utils";
import {
  auditReportExcelMastersApi,
  addScanAuditItemApi,
  auditReportListMastersApi,
  auditReportViewMastersApi
} from "../../axios/services/services";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import { ScanItemAuditSchema } from "../../helper/FormikSchema";
import { ErrorModal } from "../common/modals/ErrorModal";
import { Check } from "react-feather";
import { useHistory } from "react-router-dom";

export function ViewAuditPage() {
  let ScanAuditItemInitialValues = {
    barcode: "",
    comments: ""
  };
  const history = useHistory()

  const { id } = useParams();
  const scanInput = useRef();
  const [assignUserOrTemplates, setAssignUserOrTemplates] = useState(1);
  const [assetsUnlistedItems, setAssetsUnlistedItems] = useState(1);
  const [showScanModel, setShowScanModel] = useState(false);
  const [isLoadingAuditReportListView, setLoadingAuditReportListView] =
    useState(false);
  const [isLoadingAuditReportItemView, setLoadingAuditReportItemView] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [auditReportList, setAuditReportList] = useState([]);
  const [viewAuditReportList, setViewAuditReportList] = useState([]);
  const [viewAuditReportInfo, setViewAuditReportInfo] = useState({});
  const [viewUnlistedAuditReportList, setViewUnlistedAuditReportList] =
    useState([]);
  const [submitBarcodeLoading, setSubmitBarcodeLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [currentReportID, setCurrentReportID] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);


  const handleCloseScanModel = () => setShowScanModel(false);
  const handleShowScanModel = () => {
    setShowScanModel(true);
    setTimeout(() => {
      if (scanInput.current) {
        scanInput.current.focus();
      }
    }, 50);
  };

  async function getAuditReportList() {
    try {
      setLoadingAuditReportListView(true);
      const AuditReportListResponse = await auditReportListMastersApi({ id });
      const { Error, data } = AuditReportListResponse || {};
      setLoadingAuditReportListView(false);
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        return;
      }

      setAuditReportList(data);
      if (data.length > 0) {
        if (data[0]?.id) {
          getViewAuditReportList(data[0]?.id);
          setCurrentReportID(data[0]?.id);
        }
      }
    } catch (err) {
      console.error(err);
      setLoadingAuditReportListView(false);
    }
  }

  async function getViewAuditReportList(reportId) {
    try {
      setLoadingAuditReportItemView(true);
      const AuditReportViewResponse = await auditReportViewMastersApi({
        id: reportId
      });
      console.log(
        "🚀 ~ getViewAuditReportList ~ AuditReportViewResponse:",
        AuditReportViewResponse
      );
      const { Error, data, audit_report } = AuditReportViewResponse || {};
      setLoadingAuditReportItemView(false);
      if (Error) {
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        return;
      }
      setViewAuditReportInfo(audit_report ?? {});
      console.log("🚀 ~ getViewAuditReportList ~ data:", data);
      const filteredData = data.filter((item) => item?.list_type === 1);
      setViewAuditReportList(filteredData);
      const unListedFilteredData = data.filter((item) => item?.list_type === 0);
      setViewUnlistedAuditReportList(unListedFilteredData);
    } catch (err) {
      console.error(err);
      setLoadingAuditReportItemView(false);
    }
  }

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const formikScanAuditItem = useFormik({
    initialValues: ScanAuditItemInitialValues,
    validationSchema: ScanItemAuditSchema,
    onSubmit: async (values, { setStatus }) => {
      try {
        setSubmitBarcodeLoading(true);
        let addScanAuditItemResponse = await addScanAuditItemApi(
          currentReportID,
          values
        );
        let { message, status } = addScanAuditItemResponse || {};
        if (message && status !== 200) {
          let Error = { message_en: message };
          setSubmitBarcodeLoading(false);
          unauthorization(Error);
          setErrorMessage(getErrorMessageBasedOnLanguage(Error));
          handleErrorModalShow();
          return;
        }
        window.scrollTo(0, 0);
        setSuccessMessage(message);
        getViewAuditReportList(currentReportID);
        setTimeout(() => {
          setSuccessMessage(null);
        }, 1500);
        setSubmitBarcodeLoading(false);
      } catch (err) {
        console.log("error", err);
        setSubmitBarcodeLoading(false);
      }
    }
  });

  useEffect(() => {
    getAuditReportList();
  }, []);

  async function excelSheetDownload() {
    try {
      const filename = "Audit";
      const response = await auditReportExcelMastersApi({
        id: currentReportID,
        filename
      });
    } catch (error) {
      if (error && error.response) {
        return error.response.data;
      } else {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    getAuditReportList();
  }, []);

  return (
    <div>
      <div className='row mx-2 h-100'>
        <div className='col-md-3'>
          <div className='row mr-1'>
            <div className='col-md-12 card h-100 full-audit-card'>
              <ul className='nav nav-tabs d-flex justify-content-between mt-2'>
                <li className='nav-item'>
                  <a
                    className={`nav-link ${assignUserOrTemplates == 1 ? "active" : ""}`}
                    onClick={() => setAssignUserOrTemplates(1)}
                    href='#'
                  >
                    Assign Users
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={`nav-link ${assignUserOrTemplates == 2 ? "active" : ""}`}
                    onClick={() => setAssignUserOrTemplates(2)}
                    href='#'
                  >
                    Assign Templates
                  </a>
                </li>
              </ul>
              <div className='mt-3'>
                <table className='table text-center'>
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Audit Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingAuditReportListView ? (
                      <tr>
                        <td>
                          <b>Loading...</b>
                        </td>
                      </tr>
                    ) : auditReportList.length == 0 ? (
                      <tr>
                        <td>
                          <b>No Record Found</b>
                        </td>
                      </tr>
                    ) : (
                      auditReportList.map((auditReportDateItem, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                getViewAuditReportList(auditReportDateItem?.id);
                                setCurrentReportID(auditReportDateItem?.id);
                              }}
                            >
                              <b>
                                {auditReportDateItem?.generate_date
                                  ? moment(
                                      auditReportDateItem?.generate_date
                                    ).format("DD/MM/YYYY")
                                  : "-"}
                              </b>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-9'>
          <div className='row mb-5'>
            <div className='col-md-12 card pt-3 full-audit-card'>
              <div className='row container-fluid'>
                <div className='col-md-6'>
                  <b>
                    <span style={{ fontSize: "20px", fontWeight: "100px" }}>
                      AUDIT SAMPLE{" "}
                    </span>
                    <span className='text-success ml-3'>CLOSED</span>
                  </b>
                </div>
                <div className='col-md-6 text-right pr-0'>
                  <Button
                    variant='btn link-btn p-2 text-success'
                    style={{ fontSize: "22px" }}
                    onClick={handleShowScanModel}
                  >
                    Scan Barcode
                  </Button>
                  <Button
                    variant='btn link-btn p-2'
                    style={{ fontSize: "22px" }}
                  >
                    <i className='fa-solid fa-ellipsis-vertical'></i>
                  </Button>
                  <Button
                    variant='btn link-btn p-2'
                    style={{ fontSize: "22px" }}
                    onClick={()=>{
                      history.goBack()
                    }}
                  >
                    <i className='fa-solid fa-xmark'></i>
                  </Button>
                </div>
              </div>
              <div className='row mt-3 container-fluid'>
                <div className='col-md-4 ' style={{ fontSize: "14px" }}>
                  <div className='row mb-2'>
                    <div className='col-md-6'>
                      SCANNED items
                      <br></br>
                      {viewAuditReportInfo?.scan_item ?? "-"}/
                      {viewAuditReportInfo?.total_item ?? "-"}
                    </div>
                    <div className='col-md-6'>
                      SCAN AFTER DUE DATE
                      <br></br>
                      Yes
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div className='col-md-6'>
                      UNLISTED ITEMS
                      <br></br>
                      {viewAuditReportInfo?.un_listed_item ?? "-"}
                    </div>
                    <div className='col-md-6'>
                      AUDIT METHOD
                      <br></br>
                      Barcode Scan
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div className='col-md-6'>
                      NEWLY CREATED
                      <br></br>
                      {viewAuditReportInfo?.un_listed_item ?? "-"}
                    </div>
                  </div>
                </div>

                <div
                  className='col-md-6 mb-3'
                  style={{ border: "1px solid grey", borderRadius: "10px" }}
                >
                  <p>Assets</p>
                  <hr></hr>
                  <div className='row'>
                    <div className='col-md-4'>
                      FILTERS
                      <br></br>
                      Category(1)
                    </div>
                    <div className='col-md-4'>UPDATE FIELDS</div>
                    <div className='col-md-4'>
                      USER UPDATE FIELDS
                      <br></br>
                      Brand
                    </div>
                  </div>
                </div>
                <div
                  className='col-md mb-3 ml-2 w-100'
                  style={{ border: "1px solid grey", borderRadius: "10px" }}
                >
                  <p>ASSIGNED TO</p>
                  <div className='row'>
                    <div className='col-md-12'>
                      Basic User
                      <br></br>
                      Company Admin
                      <br></br>
                      Tech Manager
                    </div>
                  </div>
                </div>
              </div>
              <div className='container-fluid'>
                <ul className='nav nav-tabs d-flex justify-content-start my-2'>
                  <li className='nav-item'>
                    <a
                      className={`nav-link ${assetsUnlistedItems == 1 ? "active" : ""}`}
                      onClick={() => setAssetsUnlistedItems(1)}
                      href='#'
                    >
                      Assets
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className={`nav-link ${assetsUnlistedItems == 2 ? "active" : ""}`}
                      onClick={() => setAssetsUnlistedItems(2)}
                      href='#'
                    >
                      Unlisted Items
                    </a>
                  </li>
                  <div className='text-right ml-auto'>
                    <Button
                      variant='btn link-btn p-2'
                      style={{ fontSize: "18px" }}
                      onClick={excelSheetDownload}
                    >
                      <i className='fa fa-download' aria-hidden='true'></i>
                    </Button>
                  </div>
                </ul>

                <div className='mt-2'>
                  {assetsUnlistedItems === 1 ? (
                    <table className='table'>
                      <thead className='thead-light'>
                        <tr>
                          <th>UNIQUE IDENTIFIER</th>
                          <th>NOTES</th>
                          <th>DATE SCANNED</th>
                          <th>SCANNED BY</th>
                          <th>SCANNED STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingAuditReportItemView ? (
                          <tr>
                            <td>
                              <b>Loading...</b>
                            </td>
                          </tr>
                        ) : viewAuditReportList.length == 0 ? (
                          <tr>
                            <td>
                              <b>No Record Found</b>
                            </td>
                          </tr>
                        ) : (
                          viewAuditReportList.map(
                            (auditReportViewItem, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {auditReportViewItem?.assertInfo
                                      ?.barcode_no ?? "-"}
                                  </td>
                                  <td>
                                    {auditReportViewItem?.comments ?? "-"}
                                  </td>
                                  <td>
                                    {auditReportViewItem?.scanned_date
                                      ? moment(
                                          auditReportViewItem?.scanned_date
                                        ).format("DD/MM/YYYY")
                                      : "-"}
                                  </td>
                                  <td>
                                    {auditReportViewItem?.userScanInfo
                                      ?.full_name ?? "-"}
                                  </td>
                                  <td>
                                    <span
                                      className={`badge badge-pill ${auditReportViewItem?.scan_status ? "badge-success" : "badge-secondary"} status-color text-capitalize`}
                                    >
                                      {auditReportViewItem?.scan_status
                                        ? "Scanned"
                                        : "Unscanned"}
                                    </span>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <table className='table'>
                      <thead className='thead-light'>
                        <tr>
                          <th>UNIQUE IDENTIFIER</th>
                          <th>NOTES</th>
                          <th>DATE SCANNED</th>
                          <th>SCANNED BY</th>
                          <th>SCANNED STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingAuditReportItemView ? (
                          <tr>
                            <td>
                              <b>Loading...</b>
                            </td>
                          </tr>
                        ) : viewUnlistedAuditReportList.length == 0 ? (
                          <tr>
                            <td>
                              <b>No Record Found</b>
                            </td>
                          </tr>
                        ) : (
                          viewUnlistedAuditReportList.map(
                            (auditReportViewItem, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {auditReportViewItem?.assertInfo
                                      ?.barcode_no ?? "-"}
                                  </td>
                                  <td>
                                    {auditReportViewItem?.comments ?? "-"}
                                  </td>
                                  <td>
                                    {auditReportViewItem?.scanned_date
                                      ? moment(
                                          auditReportViewItem?.scanned_date
                                        ).format("DD/MM/YYYY")
                                      : "-"}
                                  </td>
                                  <td>
                                    {auditReportViewItem?.userScanInfo
                                      ?.full_name ?? "-"}
                                  </td>
                                  <td>
                                    <span
                                      className={`badge badge-pill ${auditReportViewItem?.scan_status ? "badge-success" : "badge-secondary"} status-color text-capitalize`}
                                    >
                                      {auditReportViewItem?.scan_status
                                        ? "Scanned"
                                        : "Unscanned"}
                                    </span>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showScanModel} onHide={handleCloseScanModel}>
        <Modal.Header closeButton>
          <Modal.Title>Scan Barcode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {successMessage && (
            <div className='notification icon inline success fs-16'>
              <Check size={8} className='text-white'></Check> {successMessage}
            </div>
          )}
          <Form>
            <Form.Group className='mb-3' controlId='formBasicBarcode'>
              <Form.Label>Barcode</Form.Label>
              <span className='text-danger'>*</span>
              <Form.Control
                type='text'
                placeholder='Enter barcode'
                ref={scanInput}
                onChange={(e) => {
                  formikScanAuditItem.setFieldValue(
                    "barcode",
                    e.target.value.trimStart()
                  );
                }}
                value={formikScanAuditItem.values.barcode}
              />
              {formikScanAuditItem.touched.barcode &&
              formikScanAuditItem.errors.barcode ? (
                <div className='text-danger mt-2'>
                  {formikScanAuditItem.errors.barcode}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicComment'>
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter comment'
                onChange={(e) => {
                  formikScanAuditItem.setFieldValue(
                    "comments",
                    e.target.value.trimStart()
                  );
                }}
                value={formikScanAuditItem.values.comments}
              />
            </Form.Group>
            <div className='text-right'>
              <Button
                variant='primary'
                type='button'
                className='mr-2'
                onClick={() => formikScanAuditItem.handleSubmit()}
              >
                {submitBarcodeLoading ? "Please Wait..." : "Submit"}
              </Button>
              <Button
                variant='secondary'
                type='button'
                onClick={handleCloseScanModel}
              >
                Close
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={() => setIsErrorModalOpen(false)}
        />
      )}
    </div>
  );
}
