import React, { useState, useMemo } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button } from "react-bootstrap";
import { useProductUIContext } from "../ProductUIContext";
import { isEqual } from "lodash";
import { Formik } from "formik";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorModal } from "../../common/modals/ErrorModal";

const initialFilterValues = {
  name: "",
  status: "",
  priceone: "",
  pricetwo: "",
  stock_keeping_unit: "",
  reorder_level: ""
};
export function ProductFilter({
  isLoadingFilter,
  handleCLickOfFilterButtonFilter,
  handleCLickOfResetFilterButtonFilter
}) {
  const intl = useIntl();
  const [filterStatus, setFilterStatus] = useState(" ");
  const [startDate, setStartDate] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const inwardUIContext = useProductUIContext();
  const categoryUIProps = useMemo(() => {
    return {
      queryParams: inwardUIContext.queryParams,
      setQueryParams: inwardUIContext.setQueryParams
    };
  }, [inwardUIContext]);

  const prepareFilter = (queryParams, values) => {
    const {
      name,
      status,
      priceone,
      pricetwo,
      stock_keeping_unit,
      reorder_level,
      category_name
    } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (priceone && pricetwo) {
      const amount = {
        priceOne: priceone ? priceone : undefined,
        priceTwo: pricetwo ? pricetwo : undefined
      };
      filter.amount = amount ? amount : undefined;
    }
    filter.name = name !== "" ? name : undefined;
    filter.status = status !== "" ? status : undefined;
    filter.priceone = priceone !== "" ? priceone : undefined;
    filter.pricetwo = pricetwo !== "" ? pricetwo : undefined;
    filter.stock_keeping_unit =
      stock_keeping_unit !== "" ? stock_keeping_unit : undefined;
    filter.reorder_level = reorder_level !== "" ? reorder_level : undefined;
    filter.category_name = category_name !== "" ? category_name : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(categoryUIProps.queryParams, values);
    if (!isEqual(newQueryParams, categoryUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      categoryUIProps.setQueryParams(newQueryParams);
    }
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          const { priceone, pricetwo } = values || {};
          if (priceone && !pricetwo) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.ENTER.TO.AMOUNT"
              })
            );
            handleErrorModalShow();
            return;
          }
          if (!priceone && pricetwo) {
            setErrorMessage(
              intl.formatMessage({
                id: "TITLE.ENTER.FROM.AMOUNT"
              })
            );
            handleErrorModalShow();
            return;
          }
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          resetForm,
          handleReset,
          setFieldValue
        }) => (
          <Form
            className='form form-label-right filter-card'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialFilterValues);
              setStartDate(null);
              setFilterStatus("");
              handleReset();
            }}
          >
            <Form.Group className='form-group mb-md-0 filter-form-group'>
              <div className='filter-item'>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.PRODUCT.NAME' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.PRODUCT.NAME"
                    })}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                    maxLength='70'
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.CATEGORY' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='category_name'
                    placeholder='Category'
                    onChange={(e) => {
                      setFieldValue("category_name", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.SKU' />
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='stock_keeping_unit'
                    placeholder={intl.formatMessage({
                      id: "TITLE.SKU"
                    })}
                    onChange={(e) => {
                      setFieldValue("stock_keeping_unit", e.target.value);
                    }}
                    autoComplete='off'
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.AMOUNT' />
                    </b>
                  </small>
                  <div className=''>
                    <Form.Control
                      type='number'
                      className='form-control mb-2'
                      style={{ minWidth: "100px" }}
                      name='priceone'
                      placeholder={intl.formatMessage({
                        id: "TITLE.FROM"
                      })}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onPaste={(evt) => {
                        const values = evt.clipboardData.getData("text");
                        const nonAlpha = values.replace(/[^\d.-]/g, "");
                        evt.preventDefault();
                        setFieldValue("priceone", nonAlpha);
                      }}
                      onChange={(e) => {
                        setFieldValue("priceone", e.target.value);
                      }}
                      value={values.priceone}
                      autoComplete='off'
                    />
                    <Form.Control
                      type='number'
                      className='form-control'
                      style={{ minWidth: "100px" }}
                      name='pricetwo'
                      placeholder={intl.formatMessage({
                        id: "TITLE.TO"
                      })}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onPaste={(evt) => {
                        const values = evt.clipboardData.getData("text");
                        const nonAlpha = values.replace(/[^\d.-]/g, "");
                        evt.preventDefault();
                        setFieldValue("pricetwo", nonAlpha);
                      }}
                      onChange={(e) => {
                        setFieldValue("pricetwo", e.target.value);
                      }}
                      value={values.pricetwo}
                      autoComplete='off'
                    />
                  </div>
                </div>

                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.STATUS' />
                    </b>
                  </small>
                  <Form.Control
                    name='status'
                    as='select'
                    className='select-control'
                    onChange={(event) => {
                      setFieldValue("status", event.target.value);
                      setFilterStatus(event.target.value);
                    }}
                    value={filterStatus}
                  >
                    <option value=''>
                      {intl.formatMessage({
                        id: "TITLE.ALL"
                      })}
                    </option>
                    <option value='1'>
                      {intl.formatMessage({
                        id: "TITLE.ACTIVE"
                      })}
                    </option>
                    <option value='0'>
                      {intl.formatMessage({
                        id: "TITLE.INACTIVE"
                      })}
                    </option>
                  </Form.Control>
                </div>
              </div>
              <div className='filter-action d-flex'>
                <Button
                  variant='secondary d-flex align-items-center btn-inventory mr-2'
                  type='submit'
                >
                  <Search
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader />}
                </Button>
                <Button
                  variant='tertiary d-flex align-items-center btn-inventory'
                  type='reset'
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
