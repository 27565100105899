import React, { useRef, useState, useEffect, useContext } from "react";
import { ProductLoadingDialog } from "./product-loading-dialog/ProductLoadingDialog";
import { ProductCard } from "./ProductCard";
import { ProductUIProvider } from "./ProductUIContext";
import SVG from "react-inlinesvg";
import { initialFilter } from "./ProductUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { toAbsoluteUrl } from "../../helper/helper";
import { DeleteModal } from "../common/modals/DeleteModal";
import { AddNewProductModal } from "../../global-modal/AddNewProductModal ";
import { EditProductModal } from "./edit-product/EditProductModal";
import {
  productListMastersApi,
  deleteProductApi
} from "../../axios/services/services";
import { SuccessModal } from "../common/modals/SuccessModal";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization
} from "../../constants/utils";
import { useIntl } from "react-intl";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";

export function ProductPage({ history }) {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const [data, setData] = useState({});
  const [counterList, setCounterList] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [isOpenAddNewProductModal, setOpenAddNewProductModal] = useState(false);
  const [isOpenEditProductModal, setOpenEditProductModal] = useState(false);

  const exportCSVButton = useRef();
  const [productList, setProductList] = useState([]);
  const [productListTotalCount, setProductListTotalCount] = useState();
  const [isUpdateProductDetails, setUpdateProductDetails] = useState(0);
  const [isFilter, setFilter] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isLoadingDeleteProduct, setLoadingDeleteProduct] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  async function getProductList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const productListResponse = await productListMastersApi({
      queryParams
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count } = productListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setProductList(data ? data : []);
    setProductListTotalCount(count ? count : 0);
  }

  const deleteProduct = async () => {
    const { id } = data;
    enableLoadingDeleteProduct();
    const deleteProductResponse = await deleteProductApi({ id });
    const { Error } = deleteProductResponse || {};
    if (Error) {
      disableLoadingDeleteProduct();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.PRODUCT.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    window.scrollTo(0, 0);
    setProductList([]);
    setUpdateProductDetails(Math.random());
    disableLoadingDeleteProduct();
  };

  useEffect(() => {
    getProductList();
  }, [queryParams, isUpdateProductDetails]);

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const enableLoadingDeleteProduct = () => {
    setLoadingDeleteProduct(true);
  };

  const disableLoadingDeleteProduct = () => {
    setLoadingDeleteProduct(false);
  };

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const addNewProductModalShow = () => {
    setOpenAddNewProductModal(true);
  };

  const addNewProductModalHide = () => {
    setOpenAddNewProductModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleFilterValues = (values) => {
    setFilterValues(values);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingExportButton = () => {
    setLoadingExportButton(true);
  };

  const disableLoadingExportButton = () => {
    setLoadingExportButton(false);
  };

  const handleSetCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const editProductModalShow = () => {
    setOpenEditProductModal(true);
  };

  const editProductModalHide = () => {
    setOpenEditProductModal(false);
  };

  const productUIEvents = {
    openViewDialog: (row) => {
      const path = `products/view-product-details/${row.id}`;
      onClickChangeRoute(path);
    },
    openEditDialog: (row) => {
      editProductModalShow();
      setData(row);
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    },
    redirectToViewPage: (row) => {
      const path = `/master/products/view-product-details/${row.id}`;
      onClickChangeRoute(path);
    },
    redirectToCategoryViewPage: (row) => {
      const path = `/master/categories/view-category-details/${row?.category?.id}`;
      onClickChangeRoute(path);
    }
  };

  const onClickOfDeleteButton = () => {
    deleteProduct();
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    addNewProductModalShow();
  };

  const handleCLickOfFilterButtonFilter = (values) => {
    setFilter(true);
  };

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const handleCloseOfEditNewProductModal = (editNewVendorDetails) => {
    setProductList([]);
    setUpdateProductDetails(Math.random());
    editProductModalHide();
  };

  const handleCloseOfAddNewProductModal = () => {
    setProductList([]);
    setUpdateProductDetails(Math.random());
    addNewProductModalHide();
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <div>
      <>
        {/* <Breadcrumb title="Product List" parent="Products" /> */}
        <ProductUIProvider
          productUIEvents={productUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <ProductLoadingDialog />
          <ProductCard
            isLoading={isLoading}
            counterList={counterList}
            productList={productList}
            handleFilterValues={handleFilterValues}
            filterValues={filterValues}
            loadingExportButton={loadingExportButton}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            productListTotalCount={productListTotalCount}
            currentPage={currentPage}
            setCurrentPage={handleSetCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            isLoadingFilter={isLoadingFilter}
            isFilterOpen={isFilterOpen}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
          />
        </ProductUIProvider>

        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className='danger-icon-wrapper text-center'>
              <span className='svg-icon menu-icon svg-icon-white svg-icon-4x mr-0'>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />

        {isOpenAddNewProductModal && (
          <AddNewProductModal
            open={isOpenAddNewProductModal}
            onCloseModal={addNewProductModalHide}
            handleCloseOfAddNewProductModal={handleCloseOfAddNewProductModal}
          />
        )}

        {isOpenEditProductModal && (
          <EditProductModal
            open={isOpenEditProductModal}
            data={data}
            onCloseModal={editProductModalHide}
            id={data.id}
            handleCloseOfEditNewProductModal={handleCloseOfEditNewProductModal}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteProduct}
            title={intl.formatMessage({
              id: "TITLE.DELETE.PRODUCT"
            })}
            description={intl.formatMessage({
              id: "TITLE.PRODUCT.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
            })}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={capitalizeFirstLetter(data?.name)}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}
        {openSuccessModal && (
          <SuccessModal
            message={successMessage}
            open={openSuccessModal}
            onCloseModal={onCloseSuccessModal}
          />
        )}
      </>
    </div>
  );
}
