/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";

const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    openViewDialog,
    openEditDialog,
    openDeleteDialog,
    isVisibleViewButton,
    isVisibleEditButton = true,
    isVisibleDeteletButton = true,
  }
) => {
  return (
    <>
      <ButtonToolbar className='view-download-btn justify-content-end'>
        {isVisibleViewButton && (
          <Button
            variant='btn link-btn p-2'
            onClick={() => {
              openViewDialog(row);
            }}
          >
            <i className='fa fa-eye text-success' />
          </Button>
        )}
        {isVisibleEditButton && (
          <Button
            variant='btn link-btn p-2'
            onClick={() => {
              openEditDialog(row);
            }}
          >
            <i className='fa fa-edit text-warning' />
          </Button>
        )}

        {isVisibleDeteletButton && (
          <Button
            variant='btn link-btn p-2'
            onClick={() => {
              openDeleteDialog(row);
            }}
          >
            <i className='fa fa-trash text-danger' />
          </Button>
        )}
      </ButtonToolbar>
      <> </>
    </>
  );
};

export default ActionsColumnFormatter;
