import { Button, ButtonGroup, Card } from "react-bootstrap";
import { Filter, Upload } from "react-feather";
import { FormattedMessage } from "react-intl";
import { inventoryListExportApi } from "../../axios/services/services";
import { AssetFilter } from "./asset-filter/AssetFilter";
import { AssetTable } from "./asset-table/AssetTable";

export function AssetCard({
  isLoading,
  assetList,
  isLoadingFilter,
  isFilterOpen,
  exportCSVButton,
  transactionListExport,
  assetListTotalCount,
  currentPage,
  setCurrentPage,
  filterValues,
  handleClickOfRemoveFilterButton,
  handleCLickOfFilterButtonFilter
}) {
  async function handleExport() {
    await inventoryListExportApi({
      // TODO - Integrate asset export api
      filename: "Assets"
    });
  }

  return (
    <>
      <Card className='card-custom card-stretch make-payment-wrapper'>
        <Card.Header className='align-items-center d-flex justify-content-between'>
          <h3 className='card-title page-title text-black mb-0'>
            <FormattedMessage id='TITLE.ASSETS' />
          </h3>
          <div className='d-flex'>
            <ButtonGroup className='mr-3'>
              <Button
                variant='tertiary d-flex align-items-center btn-inventory'
                onClick={() => {
                  handleClickOfRemoveFilterButton();
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className='d-none d-md-flex'>
                  {isFilterOpen ? (
                    <FormattedMessage id='TITLE.REMOVE' />
                  ) : (
                    <FormattedMessage id='TITLE.FILTER' />
                  )}
                </span>
              </Button>

              <Button
                variant='tertiary d-flex align-items-center btn-inventory'
                onClick={() => {
                  handleExport();
                }}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className='d-none d-md-flex'>
                  <FormattedMessage id='TITLE.EXPORT' />
                </span>
              </Button>
            </ButtonGroup>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && (
            <AssetFilter
              isLoadingFilter={isLoadingFilter}
              handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            />
          )}
          <AssetTable
            isLoading={isLoading}
            assetList={assetList}
            filterValues={filterValues}
            assetListTotalCount={assetListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
