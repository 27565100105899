/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import CreatableSelect from "react-select/creatable";
import { Check, File, Plus, X, XCircle } from "react-feather";
import { AddNewVendorModal } from "../../../global-modal/AddNewVendorModal";
import ButtonAtl from "@atlaskit/button";
import { Card, Col, Form, Row, Table, Button, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import { AddNewProductModal } from "../../../global-modal/AddNewProductModal ";
import { ErrorModal } from "../../common/modals/ErrorModal";
import {
  addInwardApi,
  ProductListApi,
  uploadDocumentApi,
  vendorListAllApi
} from "../../../axios/services/services";
import {
  amountFormatter,
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  getProductMediaUrl,
  setActiveMenu,
  totalAmountFromProductList,
  totalQuantityFromProductList,
  unauthorization
} from "../../../constants/utils";
import { FieldArray, Formik } from "formik";
import { AddInwardSchema } from "../../../helper/FormikSchema";
import {
  dd_MM_yyyy,
  FILE_EXTENTION,
  MB_20,
  YYYY_MM_DD
} from "../../../constants/const";
import { ContentLoader } from "../../../helper/ContentLoader";
import { ThumbnailWrapper } from "../../../helper/ThumbnailWrapper";
import {
  ChevronDownMaster,
  DropdownMaster,
  DropdownIndicatorMaster
} from "../../../helper/helper";
import { SectionLoader } from "../../../helper/SectionLoader";
import { ImageWrapper } from "../../../helper/ImageWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { MENUITEMS } from "../../../constants/menu";
import { UIContext } from "../../../helper/UIContext";
import moment from "moment";

function AddNewInward({ history }) {
  const uiContext = useContext(UIContext);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFileUploadLoading, setFileUploadLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(new Date());
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isUpdateVendorList, setUpdateVendorList] = useState(0);
  const [isUpdateProductList, setUpdateProductList] = useState(0);
  const [isNewPOAdded, setNewPOAdded] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState("");
  // const [newlyAddedVendor, setNewlyAddedVendor] = useState(null);
  const [searchedVendorName, setSearchedVendorName] = useState("");
  const [searchedProductName, setSearchedProductName] = useState("");
  const [uploadDocument, setUploadDocument] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isOpenAddNewVendorModal, setOpenAddNewVendorModal] = useState(false);
  const [isOpenAddNewProductModal, setOpenAddNewProductModal] = useState(false);
  const [isAddPrductTable, setAddPrductTable] = useState(true);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [initialValues, setInitialValues] = useState({
    contactId: "",
    date: new Date(),
    notes: "",
    expected_delivery: new Date(),
    courier_company_name: "",
    multiple_products: [
      {
        productName: "",
        price: 0,
        quantity: 1,
        description: "",
        isOpenProductDropdown: false,
        selectedProduct: "",
        menuIsOpen: true,
        productId: "",
        product_media: "",
        totalAmount: 0
      }
    ]
  });
  const intl = useIntl();

  const handleAddMultipleProductRef = useRef([]);
  const handleProductDropdown = useRef([]);
  let boundArrayHelpers;

  async function getVendorList() {
    enableLoading();
    const vendorListResponse = await vendorListAllApi({});
    disableLoading();
    const { Error, data } = vendorListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const vendors = data.map((vendor) => {
      const { id, contact_name } = vendor || {};
      vendor.value = id;
      vendor.label = capitalizeFirstLetter(contact_name);
      // const { get_contact } = newlyAddedVendor || {};
      // const { id: newAddedVendorId } = get_contact || {};
      // if (newAddedVendorId === id) {
      //   setSelectedVendor(vendor);
      //   initialValues.contactId = newAddedVendorId;
      //   setInitialValues(initialValues);
      // }
      return vendor;
    });
    setVendorList(vendors ? vendors : []);
  }

  async function getProductList() {
    var queryParams = { pageNumber: 1, pageSize: 100000 };
    enableLoading();
    const ProductListResponse = await ProductListApi({
      queryParams
    });
    disableLoading();
    const { Error, data } = ProductListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const products = data.map((product) => {
      const { id, name } = product || {};
      product.value = id;
      product.label = capitalizeFirstLetter(name);
      return product;
    });
    setProductList(products ? products : []);
  }

  useEffect(() => {
    getVendorList();
  }, [isUpdateVendorList]);

  useEffect(() => {
    getProductList();
  }, [isUpdateProductList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // Below code is to close product selection dropdown
      if (handleProductDropdown.current) {
        handleProductDropdown.current.map((ref) => {
          if (ref && !ref.contains(e.target)) {
            const values = boundArrayHelpers.form.values;
            if (
              values.multiple_products &&
              values.multiple_products.length > 0
            ) {
              const mp = values.multiple_products;
              const multipleProducts = mp.map((product) => {
                product.isOpenProductDropdown = false;
                return product;
              });
              values.multiple_products = multipleProducts;
              setInitialValues(values);
            }
          }
          return ref;
        });
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const enableLoadingFileUpload = () => {
    setFileUploadLoading(true);
  };

  const disableLoadingFileUpload = () => {
    setFileUploadLoading(false);
  };

  const addNewVendorModalShow = () => {
    setOpenAddNewVendorModal(true);
  };

  const addNewVendorModalHide = () => {
    setOpenAddNewVendorModal(false);
  };

  const addNewProductModalShow = () => {
    setOpenAddNewProductModal(true);
  };

  const addNewProductModalHide = () => {
    setOpenAddNewProductModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleCloseOfAddNewVendorModal = (data) => {
    // setNewlyAddedVendor(data);
    setVendorList([]);
    setUpdateVendorList(Math.random());
    addNewVendorModalHide();
  };

  const handleCloseOfAddNewProductModal = () => {
    setProductList([]);
    setUpdateProductList(Math.random());
    addNewProductModalHide();
  };

  const handleInputChangeOfVendor = (value) => {};

  const handleProductInputChange = (event) => {};

  const formatCreateLabel = (inputValue) => {
    setSearchedVendorName(capitalizeFirstLetter(inputValue));
    return (
      "+" +
      intl.formatMessage({
        id: "TITLE.ADD.NEW.VENDOR"
      })
    );
  };

  const productFormatCreateLabel = (inputValue) => {
    setSearchedProductName(capitalizeFirstLetter(inputValue));
    return (
      "+" +
      intl.formatMessage({
        id: "TITLE.ADD.NEW.PRODUCT"
      })
    );
  };

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const onSelectOfVendor = (setFieldValue, value) => {
    toggleOpen();
    if (value) {
      setSelectedVendor(value);
      setFieldValue("contactId", value.value);
    } else {
      setSelectedVendor(null);
      setFieldValue("contactId", "");
    }
  };

  const handleOnDeleteOfAttachment = (file, index) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    const newUploadedDocument = [...uploadDocument];
    newUploadedDocument.splice(newUploadedDocument[index], 1);
    setUploadDocument(newUploadedDocument);
  };

  const onSelectProductChange = (setFieldValue, data, product, index) => {
    const {
      value,
      name,
      price,
      available_quantity,
      product_media,
      description
    } = data || {};
    if (data.isSelected) {
      setErrorMessage(
        intl.formatMessage({
          id: "TITLE.SELECTED.PRODUCT.IS.ALREADY.ADDED"
        })
      );
      handleErrorModalShow();
      return;
    }
    productList.map((prods) => {
      if (product.productId) {
        if (product.productId === prods.id) {
          prods.isSelected = false;
        }
      }
      return prods;
    });
    setProductList(productList);
    data.isSelected = true;
    const { name: productMediaName } = product_media || {};
    setFieldValue(`multiple_products.${index}.productId`, value);
    setFieldValue(
      `multiple_products.${index}.productName`,
      capitalizeFirstLetter(name)
    );
    setFieldValue(`multiple_products.${index}.price`, price);
    setFieldValue(`multiple_products.${index}.quantity`, 1);
    setFieldValue(
      `multiple_products.${index}.available_quantity`,
      available_quantity
    );
    setFieldValue(`multiple_products.${index}.product_media`, productMediaName);
    setFieldValue(`multiple_products.${index}.description`, description);
    setFieldValue(
      `multiple_products.${index}.isOpenProductDropdown`,
      !product.isOpenProductDropdown
    );
    setFieldValue(`multiple_products.${index}.totalAmount`, price * 1);
  };

  const onChangeOfQuantity = (setFieldValue, data, product, index) => {
    const value = data.target.value;
    setFieldValue(`multiple_products.${index}.quantity`, value);
  };

  const onChangeOfDescription = (setFieldValue, data, product, index) => {
    const value = data.target.value;
    setFieldValue(`multiple_products.${index}.description`, value);
  };

  const renderProductImage = (rows) => {
    const { product_media } = rows || {};
    const fileName = product_media;
    return getProductMediaUrl(fileName);
  };

  const attachDocument = async (acceptedFiles) => {
    const uploadedFilesArray = [];
    const maxLengthForDocUpload = 5 - files.length;
    enableLoadingFileUpload();
    for (let index = 0; index < acceptedFiles.length; index++) {
      if (maxLengthForDocUpload === index) {
        break;
      }
      if (MB_20 <= acceptedFiles[index].size) {
        setErrorMessage(
          intl.formatMessage({ id: "TITLE.ATTACHED.FILE.IS.TOO.LARGE" })
        );
        handleErrorModalShow();
        continue;
      }
      let files = {
        file: acceptedFiles[index],
        containername: "product"
      };
      const uploadDocumentResponse = await uploadDocumentApi(files);
      const [uploadDoc] = uploadDocumentResponse || {};
      uploadedFilesArray.push(uploadDoc);
    }
    disableLoadingFileUpload();
    const filesTemp = [...files, ...uploadedFilesArray];
    setFiles(filesTemp);
    setUploadDocument([...uploadDocument, ...uploadedFilesArray]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    attachDocument(acceptedFiles);
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: FILE_EXTENTION,
    disabled: files.length === 5,
    maxFiles: 5,
    maxFilesize: MB_20,
    autoProcessQueue: true,
    multiple: true,
    onDrop
  });

  const addInward = async (values) => {
    enableSaveLoading();

    const {
      contactId,
      date,
      expected_delivery,
      notes,
      courier_company_name,
      multiple_products
    } = values || {};
    if (!isAddPrductTable) {
      disableSaveLoading();
      setErrorMessage(
        intl.formatMessage({
          id: "TITLE.YOU.NEED.TO.ADD.AT.LEAST.ONE.PRODUCT.TO.ADD.NEW.PUCHASE.ORDER"
        })
      );
      handleErrorModalShow();
      return;
    }

    const docs = [];
    uploadDocument.map((doc) => {
      const { filename, mimetype } = doc || {};
      docs.push({ filename, file_type: mimetype });
      return doc;
    });

    const productItems = [];
    multiple_products.map((product) => {
      const { productId, quantity, price, description } = product || {};
      productItems.push({
        productId,
        quantity: parseInt(quantity),
        price,
        description
      });
      return product;
    });

    const totalAmount = totalAmountFromProductList(multiple_products);
    const totalQuantity = totalQuantityFromProductList(multiple_products);

    let obj = {
      date: date ? moment(date).format(YYYY_MM_DD) : null,
      contactId,
      expected_delivery: expected_delivery
        ? moment(expected_delivery).format(YYYY_MM_DD)
        : null,
      notes,
      courier_company_name,
      purchaseorder_document: docs,
      purchaseorder_item: productItems,
      totalamount: totalAmount,
      totalquantity: totalQuantity,
      type: "po"
    };

    const addInwardResponse = await addInwardApi(obj);
    const { Error } = addInwardResponse || {};
    if (Error) {
      disableSaveLoading();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setNewPOAdded(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      disableSaveLoading();
      const path = "/inwards/inward-listing";
      onClickChangeRoute(path);
    }, 2500);
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  return (
    <>
      <Card>
        {isLoading && <SectionLoader />}

        <Formik
          initialValues={initialValues}
          validationSchema={AddInwardSchema}
          onSubmit={(values) => {
            addInward(values);
          }}
          onReset={(values) => {}}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            resetForm,
            handleReset,
            setFieldValue,
            errors,
            touched
          }) => (
            <Form
              className='form form-label-right filter-card'
              autoComplete='off'
              onSubmit={handleSubmit}
              onReset={() => {
                resetForm(initialValues);
                handleReset();
              }}
            >
              <Card.Header className='d-flex align-items-center justify-content-between'>
                <h3 className='card-title page-title text-black mb-0'>
                  <FormattedMessage id='TITLE.ADD.NEW.PURCHASE.ORDER' />
                </h3>
                <Button
                  variant='link p-0 btn-icon'
                  onClick={() => {
                    const path = "/inwards/inward-listing";
                    onClickChangeRoute(path);
                  }}
                >
                  <X size={20} className='text-dark mr-0 pr-0' />
                </Button>
              </Card.Header>
              <Card.Body>
                {isNewPOAdded && (
                  <div className='notification icon inline success fs-16'>
                    <Check size={8} className='text-white'></Check>
                    <FormattedMessage id='TITLE.PURCHASE.ORDER.HAS.BEEN.CREATED.SUCCESSFULLY' />
                  </div>
                )}
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='selectVendor'>
                        <FormattedMessage id='TITLE.SELECT.VENDOR' />
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <DropdownMaster
                        isOpen={isOpen}
                        onClose={toggleOpen}
                        target={
                          <ButtonAtl
                            iconAfter={<ChevronDownMaster />}
                            onClick={toggleOpen}
                            isSelected={isOpen}
                            className='form-control w-100 bg-white custom-select-btn mb-0'
                          >
                            {selectedVendor
                              ? `${selectedVendor.label}`
                              : intl.formatMessage({
                                  id: "TITLE.SELECT.VENDOR"
                                })}
                          </ButtonAtl>
                        }
                      >
                        <CreatableSelect
                          onChange={(value) => {
                            onSelectOfVendor(setFieldValue, value);
                          }}
                          onInputChange={handleInputChangeOfVendor}
                          options={vendorList}
                          createOptionPosition='top'
                          formatCreateLabel={formatCreateLabel}
                          isSearchable
                          onCreateOption={() => {
                            addNewVendorModalShow();
                            setOpen(false);
                          }}
                          autoFocus
                          backspaceRemovesValue={true}
                          components={{
                            DropdownIndicatorMaster,
                            IndicatorSeparator: null
                          }}
                          controlShouldRenderValue={true}
                          hideSelectedOptions={false}
                          isClearable={true}
                          menuIsOpen
                          placeholder={intl.formatMessage({
                            id: "TITLE.SELECT.VENDOR"
                          })}
                          // styles={selectStyles}
                          tabSelectsValue={true}
                          className='react-custom-select-container text-capitalize'
                          classNamePrefix='react-select'
                          name='contactId'
                        />
                      </DropdownMaster>
                      {touched.contactId && errors.contactId ? (
                        <div className='text-danger'>{errors.contactId}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>
                        <FormattedMessage id='TITLE.DATE' />
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <DatePicker
                        selected={date}
                        dateFormat={dd_MM_yyyy}
                        onChange={(date) => {
                          if (date) {
                            setDate(date);
                            setFieldValue("date", date);
                          } else {
                            setDate("");
                            setFieldValue("date", "");
                          }
                        }}
                        startDate={new Date()}
                        isClearable={false}
                        minDate={new Date()}
                        maxDate={new Date()}
                        placeholderText={intl.formatMessage({
                          id: "TITLE.DD/MM/YYYY"
                        })}
                        className='form-control w-100'
                        closeOnScroll={(e) => e.target === document}
                        name='date'
                      />
                      {touched.date && errors.date ? (
                        <div className='text-danger'>{errors.date}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <div className='d-flex align-items-center justify-content-between'>
                        <Form.Label htmlFor='attach-qutation'>
                          <FormattedMessage id='TITLE.ATTACH.FILE' />
                        </Form.Label>
                        <span className='small-text'>
                          <FormattedMessage id='TITLE.MAXIMUM.SIZE' />: 20MB
                        </span>
                      </div>
                      <section>
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <File
                            size={32}
                            className='text-dark'
                            style={{ strokeWidth: "1.5px" }}
                          ></File>
                          <p>
                            {" "}
                            <FormattedMessage id='TITLE.DRAG.DROP.FILES.HERE.OR.CLICK.THE.ICON' />
                          </p>
                        </div>

                        {isFileUploadLoading && <ContentLoader />}
                        <ThumbnailWrapper
                          files={files}
                          handleOnDeleteOfAttachment={
                            handleOnDeleteOfAttachment
                          }
                          container='product'
                        />
                      </section>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label htmlFor='notes'>
                        <FormattedMessage id='TITLE.NOTES' />
                        <span className='mr-1 ml-1 text-muted'>
                          (
                          <FormattedMessage id='TITLE.MAX.512.CHARACTERS' />)
                        </span>
                      </Form.Label>
                      <Form.Control
                        id='notes'
                        name='notes'
                        as='textarea'
                        placeholder={intl.formatMessage({
                          id: "TITLE.ENTER.YOUR.NOTES"
                        })}
                        className='form-control'
                        resize='none'
                        onChange={(e) => {
                          setFieldValue("notes", e.target.value);
                        }}
                        maxLength={512}
                      />
                      {touched.notes && errors.notes ? (
                        <div className='text-danger'>{errors.notes}</div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>
                        <FormattedMessage id='TITLE.EXPECTED.DELIVERY.DATE' />
                      </Form.Label>
                      <DatePicker
                        selected={expectedDeliveryDate}
                        dateFormat={dd_MM_yyyy}
                        onChange={(date) => {
                          if (date) {
                            setExpectedDeliveryDate(date);
                            setFieldValue("expected_delivery", date);
                          } else {
                            setExpectedDeliveryDate("");
                            setFieldValue("expected_delivery", "");
                          }
                        }}
                        minDate={date}
                        placeholderText={intl.formatMessage({
                          id: "TITLE.DD/MM/YYYY"
                        })}
                        className='form-control w-100'
                        closeOnScroll={(e) => e.target === document}
                        name='expected_delivery'
                      />
                      {touched.expected_delivery && errors.expected_delivery ? (
                        <div className='text-danger'>
                          {errors.expected_delivery}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className='responsive-m-table w-100'>
                      <Table bordered className='inwardProduct mt-3'>
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage id='TITLE.PRODUCT' />
                            </th>
                            <th style={{ width: "12%" }}>
                              <FormattedMessage id='TITLE.PRICE' />
                            </th>
                            <th style={{ width: "12%" }}>
                              <FormattedMessage id='TITLE.QUANTITY' />
                            </th>
                            <th style={{ width: "12%" }}>
                              <FormattedMessage id='TITLE.AMOUNT' />
                            </th>
                            <th style={{ width: "20%" }}>
                              <FormattedMessage id='TITLE.DESCRIPTION' />
                            </th>
                            <th style={{ width: "13%" }}>
                              <FormattedMessage id='TITLE.STOCK.ON.HAND' />
                            </th>
                            <th style={{ width: "2.5%" }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isAddPrductTable && (
                            <FieldArray
                              name='multiple_products'
                              render={(multiple_products) => {
                                bindArrayHelpers(multiple_products);
                                return values?.multiple_products?.map(
                                  (product, index) => (
                                    <tr key={index}>
                                      <td className='pl-2 pr-2 p-0'>
                                        <div className='table-product-view pt-2'>
                                          <div className='product-thumb'>
                                            <div className='border-0'>
                                              <ImageWrapper
                                                className={
                                                  "img-radius align-top blur-up lazyloaded"
                                                }
                                                src={renderProductImage(
                                                  product
                                                )}
                                              />
                                            </div>
                                          </div>
                                          <div
                                            className='product-name pl-3'
                                            style={{ flex: "1" }}
                                            ref={(ref) =>
                                              (handleProductDropdown.current[
                                                index
                                              ] = ref)
                                            }
                                          >
                                            <DropdownMaster
                                              key={index}
                                              isOpen={
                                                product.isOpenProductDropdown
                                              }
                                              target={
                                                <ButtonAtl
                                                  iconAfter={
                                                    <ChevronDownMaster />
                                                  }
                                                  onClick={() => {
                                                    values?.multiple_products.map(
                                                      (p) => {
                                                        if (
                                                          p.id === product.id
                                                        ) {
                                                          p.isOpenProductDropdown = false;
                                                        }
                                                        return p;
                                                      }
                                                    );
                                                    setFieldValue(
                                                      `multiple_products.${index}.isOpenProductDropdown`,
                                                      !product.isOpenProductDropdown
                                                    );
                                                  }}
                                                  isSelected={
                                                    product.isOpenProductDropdown
                                                  }
                                                  className='form-control w-100 bg-white custom-select-btn mb-0'
                                                >
                                                  {product.productName
                                                    ? product.productName
                                                    : intl.formatMessage({
                                                        id: "TITLE.TYPE.OR.CLICK.TO.SELECT.AN.ITEM"
                                                      })}
                                                </ButtonAtl>
                                              }
                                            >
                                              <CreatableSelect
                                                placeholder={intl.formatMessage(
                                                  {
                                                    id: "TITLE.SEARCH.PRODUCT"
                                                  }
                                                )}
                                                onChange={(data) => {
                                                  onSelectProductChange(
                                                    setFieldValue,
                                                    data,
                                                    product,
                                                    index
                                                  );
                                                }}
                                                onInputChange={
                                                  handleProductInputChange
                                                }
                                                options={productList}
                                                createOptionPosition='top'
                                                formatCreateLabel={
                                                  productFormatCreateLabel
                                                }
                                                isSearchable
                                                onCreateOption={() => {
                                                  addNewProductModalShow();
                                                }}
                                                autoFocus
                                                backspaceRemovesValue={true}
                                                components={{
                                                  DropdownIndicatorMaster,
                                                  IndicatorSeparator: null
                                                }}
                                                controlShouldRenderValue={true}
                                                hideSelectedOptions={false}
                                                isClearable={true}
                                                menuIsOpen={
                                                  product.isOpenProductDropdown
                                                }
                                                tabSelectsValue={true}
                                                value={product.productName}
                                                className='react-custom-select-container'
                                                classNamePrefix='react-select'
                                              />
                                            </DropdownMaster>
                                            {errors.multiple_products &&
                                            errors.multiple_products.length >
                                              0 &&
                                            errors.multiple_products[index] ? (
                                              <div className='text-danger'>
                                                {
                                                  errors.multiple_products[
                                                    index
                                                  ].productId
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <Form.Group>
                                          <Form.Control
                                            className='form-control-sm p-1 border-0'
                                            type='number'
                                            placeholder={intl.formatMessage({
                                              id: "TITLE.ENTER.PRICE"
                                            })}
                                            onKeyDown={(evt) =>
                                              ["e", "E", "+", "-"].includes(
                                                evt.key
                                              ) && evt.preventDefault()
                                            }
                                            onPaste={(evt) => {
                                              const values =
                                                evt.clipboardData.getData(
                                                  "text"
                                                );
                                              const nonAlpha = values.replace(
                                                /[^\d.-]/g,
                                                ""
                                              );
                                              evt.preventDefault();
                                              setFieldValue("price", nonAlpha);
                                            }}
                                            name='price'
                                            value={product?.price}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFieldValue(
                                                `multiple_products.${index}.price`,
                                                value
                                              );
                                            }}
                                          />
                                          {errors.multiple_products &&
                                          errors.multiple_products.length > 0 &&
                                          errors.multiple_products[index] ? (
                                            <div className='text-danger'>
                                              {
                                                errors.multiple_products[index]
                                                  .price
                                              }
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                      </td>
                                      <td>
                                        <Form.Group>
                                          <Form.Control
                                            className='form-control-sm p-1 border-0'
                                            type='number'
                                            name='quantity'
                                            placeholder={intl.formatMessage({
                                              id: "TITLE.ENTER.QUANTITY"
                                            })}
                                            value={product.quantity}
                                            onKeyDown={(evt) =>
                                              ["e", "E", "+", "-"].includes(
                                                evt.key
                                              ) && evt.preventDefault()
                                            }
                                            onPaste={(evt) => {
                                              const values =
                                                evt.clipboardData.getData(
                                                  "text"
                                                );
                                              const nonAlpha = values.replace(
                                                /[^\d.-]/g,
                                                ""
                                              );
                                              evt.preventDefault();
                                              setFieldValue(
                                                "quantity",
                                                nonAlpha
                                              );
                                            }}
                                            onChange={(data) => {
                                              onChangeOfQuantity(
                                                setFieldValue,
                                                data,
                                                product,
                                                index
                                              );
                                            }}
                                          />
                                          {errors.multiple_products &&
                                          errors.multiple_products.length > 0 &&
                                          errors.multiple_products[index] ? (
                                            <div className='text-danger'>
                                              {
                                                errors.multiple_products[index]
                                                  .quantity
                                              }
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                      </td>
                                      <td>
                                        <Form.Group>
                                          <Form.Control
                                            className='form-control-sm p-1 border-0'
                                            type='number'
                                            placeholder={intl.formatMessage({
                                              id: "TITLE.ENTER.AMOUNT"
                                            })}
                                            name='totalAmount'
                                            value={
                                              product.price * product.quantity
                                            }
                                            disabled
                                            autoComplete='off'
                                          />
                                          {touched.multiple_products &&
                                          errors.multiple_products &&
                                          errors.multiple_products.length > 0 &&
                                          errors.multiple_products[index] ? (
                                            <div className='text-danger'>
                                              {
                                                errors.multiple_products[index]
                                                  .totalAmount
                                              }
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                      </td>
                                      <td>
                                        <Form.Control
                                          id='description'
                                          name='description'
                                          as='textarea'
                                          disabled
                                          placeholder={intl.formatMessage({
                                            id: "TITLE.ENTER.DESCRIPTION"
                                          })}
                                          className='form-control form-control-sm p-1 border-0'
                                          resize='none'
                                          onChange={(data) => {
                                            onChangeOfDescription(
                                              setFieldValue,
                                              data,
                                              product,
                                              index
                                            );
                                          }}
                                          value={product.description}
                                        />
                                        {/* {touched.multiple_products &&
                                        errors.multiple_products &&
                                        errors.multiple_products.length > 0 &&
                                        errors.multiple_products[index] ? (
                                          <div className="text-danger">
                                            {
                                              errors.multiple_products[index]
                                                .description
                                            }
                                          </div>
                                        ) : null} */}
                                      </td>
                                      <td>
                                        <Form.Group>
                                          <Form.Control
                                            className='form-control-sm p-1 border-0'
                                            type='text'
                                            placeholder='0'
                                            value={product.available_quantity}
                                            disabled
                                          />
                                          {touched.multiple_products &&
                                          errors.multiple_products &&
                                          errors.multiple_products.length > 0 &&
                                          errors.multiple_products[index] ? (
                                            <div className='text-danger'>
                                              {
                                                errors.multiple_products[index]
                                                  .available_quantity
                                              }
                                            </div>
                                          ) : null}
                                        </Form.Group>
                                      </td>
                                      <td>
                                        <Button
                                          variant='link p-0 m-0'
                                          className='delete-table-row'
                                          onClick={() => {
                                            if (
                                              values.multiple_products
                                                .length === 1
                                            ) {
                                              setAddPrductTable(false);
                                            }
                                            if (
                                              values.multiple_products &&
                                              values.multiple_products.length >
                                                0
                                            ) {
                                              const productId =
                                                values.multiple_products[index]
                                                  .productId;
                                              productList.map((product) => {
                                                if (product.id === productId) {
                                                  product.isSelected = false;
                                                }
                                                return product;
                                              });
                                            }
                                            multiple_products.remove(index);
                                          }}
                                        >
                                          <XCircle
                                            size={16}
                                            style={{
                                              color: "#990029",
                                              marginRight: "0"
                                            }}
                                          />
                                        </Button>
                                        <Button
                                          variant={`link p-0 ml-2 d-none`}
                                          ref={(el) =>
                                            (handleAddMultipleProductRef.current[
                                              index
                                            ] = el)
                                          }
                                          onClick={() => {
                                            const obj = {
                                              productName: "",
                                              price: 0,
                                              quantity: 1,
                                              description: "",
                                              isOpenProductDropdown: false,
                                              selectedProduct: "",
                                              menuIsOpen: true,
                                              productId: "",
                                              product_media: ""
                                            };
                                            multiple_products.insert(
                                              values.multiple_products.length,
                                              obj
                                            );
                                          }}
                                        ></Button>
                                      </td>
                                    </tr>
                                  )
                                );
                              }}
                            />
                          )}

                          {!isAddPrductTable && (
                            <tr>
                              <td colSpan={7}>
                                <div className='d-grid gap-2'>
                                  <Button
                                    variant='gray btn-icon btn-block'
                                    onClick={() => {
                                      if (
                                        values.multiple_products.length === 0
                                      ) {
                                        const obj = {
                                          productName: "",
                                          price: 0,
                                          quantity: 1,
                                          description: "",
                                          isOpenProductDropdown: false,
                                          selectedProduct: "",
                                          menuIsOpen: true,
                                          productId: "",
                                          product_media: ""
                                        };
                                        values.multiple_products.push(obj);
                                      }
                                      setAddPrductTable(true);
                                    }}
                                  >
                                    <Plus
                                      size={16}
                                      style={{ color: "#333333" }}
                                    />{" "}
                                    <FormattedMessage id='TITLE.ADD.PRODUCT' />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>

                {isAddPrductTable && (
                  <Row>
                    <Col md={6}>
                      <div className='pt3'>
                        <Button
                          variant='tertiary'
                          className='btn-icon btn-icon text-dark inventory-btn'
                          onClick={(e) => {
                            if (
                              handleAddMultipleProductRef.current &&
                              handleAddMultipleProductRef.current.length > 0
                            ) {
                              handleAddMultipleProductRef.current[0].click();
                            }
                          }}
                        >
                          <Plus size={14} className='text-dark'></Plus>{" "}
                          <FormattedMessage id='TITLE.ADD.ANOTHER.ITEM' />
                        </Button>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='p-3 bg-light'>
                        <Row className='total-section'>
                          <Col md={6}>
                            <h6 className='modal-title f-w-900'>
                              <FormattedMessage id='TITLE.TOTAL' />
                            </h6>
                            <span className='text-muted small-text text-dark'>
                              <FormattedMessage id='TITLE.TOTAL.QUANTITY' /> :{" "}
                              {totalQuantityFromProductList(
                                values.multiple_products
                              )}
                            </span>
                          </Col>
                          <Col md={6}>
                            <h6 className='modal-title f-w-900 text-right'>
                              {amountFormatter(
                                totalAmountFromProductList(
                                  values.multiple_products
                                )
                              )}
                            </h6>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )}
              </Card.Body>
              <Card.Footer className='d-flex justify-content-end'>
                <Button
                  variant='success mr-3 btn-inventory'
                  onClick={() => handleSubmit()}
                  disabled={isSaveLoading}
                >
                  <FormattedMessage id='TITLE.SAVE' />
                  {isSaveLoading && (
                    <Spinner animation='border' size='sm' className='ml-1' />
                  )}
                </Button>
                <Button
                  variant='tertiary text-dark btn-inventory'
                  onClick={() => {
                    const path = "/inwards/inward-listing";
                    onClickChangeRoute(path);
                    window.scrollTo(0, 0);
                  }}
                >
                  <FormattedMessage id='TITLE.CANCEL' />
                </Button>
              </Card.Footer>
            </Form>
          )}
        </Formik>
      </Card>
      {isOpenAddNewProductModal && (
        <AddNewProductModal
          searchedProductName={searchedProductName}
          open={isOpenAddNewProductModal}
          onCloseModal={addNewProductModalHide}
          handleCloseOfAddNewProductModal={handleCloseOfAddNewProductModal}
        />
      )}

      {isOpenAddNewVendorModal && (
        <AddNewVendorModal
          searchedVendorName={searchedVendorName}
          open={isOpenAddNewVendorModal}
          onCloseModal={addNewVendorModalHide}
          handleCloseOfAddNewVendorModal={handleCloseOfAddNewVendorModal}
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}

export default AddNewInward;
