// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../LocationUIHelpers";
import { useLocationUIContext } from "../LocationUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import StatusTypeFormatter from "../../../columnformatter/StatusTypeFormatter";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import ParentLocationColumnFormatter from "../../../columnformatter/ParentLocationColumnFormatter";
import { FormattedMessage } from "react-intl";

export function LocationTable({
  isLoading,
  locationList,
  locationListTotalCount,
  currentPage,
  setCurrentPage
}) {
  // Location UI Context
  const locationUIContext = useLocationUIContext();
  const locationUIProps = useMemo(() => {
    return {
      ids: locationUIContext.ids,
      setIds: locationUIContext.setIds,
      queryParams: locationUIContext.queryParams,
      setQueryParams: locationUIContext.setQueryParams,
      openViewDialog: locationUIContext.openViewDialog,
      openEditDialog: locationUIContext.openEditDialog,
      openDeleteDialog: locationUIContext.openDeleteDialog
    };
  }, [locationUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "store_location",
      text: <FormattedMessage id='TITLE.STORE.LOCATION' />,
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "170px"
      }
    },
    {
      dataField: "parent_location",
      text: <FormattedMessage id='TITLE.PARENT.LOCATION' />,
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "170px"
      },
      formatter: ParentLocationColumnFormatter
    },
    {
      dataField: "status",
      text: <FormattedMessage id='TITLE.STATUS' />,
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px"
      },
      formatter: StatusTypeFormatter
    },
    {
      dataField: "action",
      text: <FormattedMessage id='TITLE.ACTIONS' />,
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: locationUIProps.openViewDialog,
        openEditDialog: locationUIProps.openEditDialog,
        openDeleteDialog: locationUIProps.openDeleteDialog,
        isVisibleViewButton: false
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px"
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: locationListTotalCount ? locationListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: locationUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-head-custom table-head-bg table-borderless table-vertical-center location-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={locationList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  locationUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={locationList}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
