import React, { useRef, useState, useEffect } from "react";
import { ProductUnitLoadingDialog } from "./productUnit-loading-dialog/ProductUnitLoadingDialog";
import { ProductUnitCard } from "./ProductUnitCard";
import { ProductUnitUIProvider } from "./ProductUnitUIContext";
import SVG from "react-inlinesvg";
import { initialFilter } from "./ProductUnitUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { toAbsoluteUrl } from "../../helper/helper";
import { DeleteModal } from "../common/modals/DeleteModal";
import { AddNewUnitModal } from "../../global-modal/AddNewUnitModal";
import { EditUnitModal } from "../../global-modal/EditUnitModal";
import {
  productUnitListMastersApi,
  deleteProductUnitApi
} from "../../axios/services/services";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  unauthorization
} from "../../constants/utils";
import { SuccessModal } from "../common/modals/SuccessModal";
import { FormattedMessage, useIntl } from "react-intl";

export function ProductUnitPage({ history }) {
  const intl = useIntl();
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);

  const exportCSVButton = useRef();
  const [productUnitList, setProductUnitList] = useState([]);
  const [productUnitListTotalCount, setProductUnitListTotalCount] = useState();
  const [isFilter, setFilter] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isUpdateProductUnitDetails, setUpdateProductUnitDetails] = useState(0);
  const [isOpenAddNewProductUnitModal, setOpenAddNewProductUnitModal] =
    useState(false);

  const [isOpenEditProductUnitModal, setOpenEditProductUnitModal] =
    useState(false);
  const [isLoadingDeleteProductUnit, setLoadingDeleteProductUnit] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  useEffect(() => {
    getProductUnitList();
  }, [queryParams, isUpdateProductUnitDetails]);

  async function getProductUnitList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const ProductUnitListResponse = await productUnitListMastersApi({
      queryParams
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count } = ProductUnitListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setProductUnitList(data ? data : []);
    setProductUnitListTotalCount(count ? count : 0);
  }

  const deleteProductUnit = async () => {
    const { id } = data;
    enableLoadingDeleteProductUnit();
    const deleteProductUnitResponse = await deleteProductUnitApi({ id });
    const { Error } = deleteProductUnitResponse || {};
    if (Error) {
      disableLoadingDeleteProductUnit();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.PRODUCT.UNIT.DELETED.SUCCESSFULLY" })
    );
    onOpenSuccessModal();
    window.scrollTo(0, 0);
    setProductUnitList([]);
    setUpdateProductUnitDetails(Math.random());
    disableLoadingDeleteProductUnit();
  };

  const onOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const enableLoadingDeleteProductUnit = () => {
    setLoadingDeleteProductUnit(true);
  };

  const disableLoadingDeleteProductUnit = () => {
    setLoadingDeleteProductUnit(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCloseOfAddNewProductUnitModal = () => {
    setProductUnitList([]);
    setUpdateProductUnitDetails(Math.random());
    addNewProductUnitModalHide();
  };

  const handleCloseOfEditNewProductUnitModal = () => {
    setProductUnitList([]);
    setUpdateProductUnitDetails(Math.random());
    editProductUnitModalHide();
  };

  const editProductUnitModalShow = () => {
    setOpenEditProductUnitModal(true);
  };

  const editProductUnitModalHide = () => {
    setOpenEditProductUnitModal(false);
  };

  const addNewProductUnitModalShow = () => {
    setOpenAddNewProductUnitModal(true);
  };

  const addNewProductUnitModalHide = () => {
    setOpenAddNewProductUnitModal(false);
  };

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleAddModalShow = () => {
    setAddModalOpen(true);
  };

  const handleAddModalHide = () => {
    setAddModalOpen(false);
  };

  const handleEditModalShow = () => {
    setEditModalOpen(true);
  };

  const handleEditModalHide = () => {
    setEditModalOpen(false);
  };

  const handleFilterValues = (values) => {
    setFilterValues(values);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingExportButton = () => {
    setLoadingExportButton(true);
  };

  const disableLoadingExportButton = () => {
    setLoadingExportButton(false);
  };

  const handleSetCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const productUnitUIEvents = {
    openViewDialog: (row) => {},
    openEditDialog: (row) => {
      setData(row);
      editProductUnitModalShow();
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    }
  };

  const onClickOfDeleteButton = () => {
    deleteProductUnit();
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    addNewProductUnitModalShow();
  };

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const handleCLickOfFilterButtonFilter = (values) => {
    setFilter(true);
  };

  return (
    <div>
      <>
        <ProductUnitUIProvider
          productUnitUIEvents={productUnitUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <ProductUnitLoadingDialog />

          {isOpenAddNewProductUnitModal && (
            <AddNewUnitModal
              open={isOpenAddNewProductUnitModal}
              onCloseModal={addNewProductUnitModalHide}
              handleCloseOfAddNewProductUnitModal={
                handleCloseOfAddNewProductUnitModal
              }
            />
          )}

          {isOpenEditProductUnitModal && (
            <EditUnitModal
              open={isOpenEditProductUnitModal}
              data={data}
              onCloseModal={editProductUnitModalHide}
              id={data.id}
              handleCloseOfEditNewProductUnitModal={
                handleCloseOfEditNewProductUnitModal
              }
            />
          )}

          <ProductUnitCard
            isLoading={isLoading}
            productUnitList={productUnitList}
            // handleFilterValues={handleFilterValues}
            // filterValues={filterValues}
            loadingExportButton={loadingExportButton}
            transactionListExport={productUnitList}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            productUnitListTotalCount={productUnitListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            isLoadingFilter={isLoadingFilter}
            isFilterOpen={isFilterOpen}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
          />
        </ProductUnitUIProvider>
        {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteProductUnit}
            title={intl.formatMessage({ id: "TITLE.DELETE.PRODUCT.UNIT" })}
            description={
              <>
                <FormattedMessage id='TITLE.PRODUCT.UNIT.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER' />
                <FormattedMessage id='TITLE.ARE.YOU.SURE.ABOUT.DELETING.IT' />
              </>
            }
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={capitalizeFirstLetter(data?.name)}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className='danger-icon-wrapper text-center'>
              <span className='svg-icon menu-icon svg-icon-white svg-icon-4x mr-0'>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />
      </>
      {openSuccessModal && (
        <SuccessModal
          message={successMessage}
          open={openSuccessModal}
          onCloseModal={onCloseSuccessModal}
        />
      )}
    </div>
  );
}
