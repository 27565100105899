import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  OverlayTrigger,
  Spinner,
  Tooltip
} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Calendar, Printer, Upload } from "react-feather";
import { LocationWiseTable } from "./locaionwise-table/LocationWiseTable";
import { LocationWiseFilter } from "./locationwise-filter/LocationwiseFilter";

const LocationWiseCard = ({
  isLoading,
  transactionList,
  filterValues,
  transactionListTotalCount,
  currentPage,
  setCurrentPage
}) => {
  const range = useMemo(() => {
    return {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month")
      ],
      "Last Year": [
        moment().subtract(1, "year").startOf("year"),
        moment().subtract(1, "year").endOf("year")
      ]
    };
  }, []);

  const printTooltip = useMemo(() => {
    return (props) => (
      <Tooltip id='button-tooltip' {...props}>
        Print Report
      </Tooltip>
    );
  }, []);

  const [isPageLoaderShow, setPageLoaderShow] = useState(true);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedDateOption, setselectedDateOption] = useState("This Month");

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
    setselectedDateOption(picker.chosenLabel);
  };

  useEffect(() => {
    setTimeout(() => {
      setPageLoaderShow(false);
    }, 500);
  }, []);

  const [selectedExportOption, setSelectedExportOption] = useState(null);

  return (
    <Card className='card-custom card-stretch make-payment-wrapper'>
      {isPageLoaderShow && (
        <div className='section-loader'>
          <Spinner animation='grow' size='sm' className='mr-1' />
          <Spinner animation='grow' size='sm' className='mr-1' />
          <Spinner animation='grow' size='sm' className='mr-1' />
          <Spinner animation='grow' size='sm' className='mr-1' />
          <Spinner animation='grow' size='sm' className='mr-1' />
        </div>
      )}

      <Card.Header className='align-items-center d-flex justify-content-between small-header'>
        <div>
          <DateRangePicker
            onEvent={handleEvent}
            initialSettings={{
              ranges: range,
              opens: "right",
              applyButtonClasses: "btn-secondary"
            }}
          >
            <Button
              variant='tertiary'
              className='btn-icon btn-sm fs-14 f-w-600'
            >
              <Calendar size={16} />
              {selectedDateOption}
            </Button>
          </DateRangePicker>
        </div>

        <div className='d-flex'>
          <OverlayTrigger
            placement='top'
            delay={{ show: 250, hide: 400 }}
            overlay={printTooltip}
          >
            <Button variant='outline-tertiary btn-sm fs-14 f-w-600 btn-icon mr-2'>
              <Printer size={16} className='p-0 mr-2' />
              <span className='d-none d-md-flex'>Print</span>
            </Button>
          </OverlayTrigger>

          <Dropdown>
            <Dropdown.Toggle variant='dropdown-sm' id='dropdown-basic'>
              <span className='d-none d-md-flex'>Export As</span>
              <Upload size={16} className='p-0 mr-2 d-block d-md-none' />
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight>
              <Dropdown.Item onClick={() => setSelectedExportOption("PDF")}>
                PDF
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedExportOption("XLSX")}>
                XLSX (Microsoft Excel)
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedExportOption("CSV")}>
                CSV (Comma Separated Value)
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>

      <Card.Body>
        <div className='text-center pt-2 pb-5'>
          <h3 className='page-title'>Active Purchase Orders Report</h3>
          <h6 className='fs-14 text-black'>
            {`From ${moment(fromDate).format("DD/MM/YYYY")} To ${moment(toDate).format("DD/MM/YYYY")}`}
          </h6>
        </div>

        <div className='d-flex justify-content-start'>
          <button type='button' className='btn btn-tertiary btn-icon ml-2'>
            Purchase Orders
          </button>
          <button type='button' className='btn btn-secondary btn-icon ml-2'>
            Delivery Challan
          </button>
        </div>

        <LocationWiseFilter />

        <LocationWiseTable
          isLoading={isLoading}
          transactionList={transactionList}
          filterValues={filterValues}
          transactionListTotalCount={transactionListTotalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Card.Body>
    </Card>
  );
};

export default LocationWiseCard;
