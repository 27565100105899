import React from "react";
import { Modal } from "react-bootstrap";
import { Check } from "react-feather";

export function SuccessModal({ onCloseModal, open, message }) {
  return (
    <>
      <Modal
        show={open}
        onHide={onCloseModal}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header
          closeButton
          className='p-0 pr-2 border-bottom-0 order'
        ></Modal.Header>
        <Modal.Body className='p-4'>
          <div className='d-flex justify-content-center mb-3'>
            <div className='avatar-icon bg-success w-44'>
              <Check size={24}></Check>
            </div>
          </div>
          <p className='text-center fs-16 text-dark confirmation-content mb-0'>
            {message}
            {/* Your password has been changed successfully */}
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
