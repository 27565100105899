import React, { useRef, useState } from "react";
import { BlockLocationCard } from "./BlockLocationCard";
import { BlockLocationUIProvider } from "./BlockLocationUIContext";
import SVG from "react-inlinesvg";
import { initialFilter } from "./BlockLocationUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { toAbsoluteUrl } from "../../helper/helper";
import { DeleteModal } from "../common/modals/DeleteModal";
import { AddNewBlockLocationModal } from "../../global-modal/AddNewBlockLocationModal";
import { EditBlockLocationModal } from "../../global-modal/EditBlockLocationModal";
import { BlockLocationLoadingDialog } from "./block-location-loading-dialog/BlockLocationLoadingDialog";

export function BlockLocationPage({ history }) {
  const [data, setData] = useState({});
  const [transactionList, setTransactionList] = useState([]);
  const [counterList, setCounterList] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddNewModalOpen, setAddNewModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionListTotalCount, setTransactionListTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [transactionListExport, setTransactionListExport] = useState(0);
  const exportCSVButton = useRef();

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleAddNewBlockLocationModalShow = () => {
    setAddNewModalOpen(true);
  };

  const handleAddNewBlockLocationModalHide = () => {
    setAddNewModalOpen(false);
  };

  const handleEditBlockLocationModalShow = () => {
    setEditModalOpen(true);
  };

  const handleEditBlockLocationModalHide = () => {
    setEditModalOpen(false);
  };

  const handleFilterValues = (values) => {
    setFilterValues(values);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingExportButton = () => {
    setLoadingExportButton(true);
  };

  const disableLoadingExportButton = () => {
    setLoadingExportButton(false);
  };

  const handleSetCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const blockLocationUIEvents = {
    openViewDialog: (row) => {
      // history.push("/view-blockLocation-details");
    },
    openEditDialog: (row) => {
      setData(row);
      handleEditBlockLocationModalShow();
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    }
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    handleAddNewBlockLocationModalShow();
  };

  return (
    <div>
      <>
        <BlockLocationUIProvider
          blockLocationUIEvents={blockLocationUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <BlockLocationLoadingDialog />
          {isAddNewModalOpen && (
            <AddNewBlockLocationModal
              open={isAddNewModalOpen}
              onCloseModal={handleAddNewBlockLocationModalHide}
            />
          )}

          {isEditModalOpen && (
            <EditBlockLocationModal
              open={isEditModalOpen}
              data={data}
              onCloseModal={handleEditBlockLocationModalHide}
            />
          )}

          <BlockLocationCard
            isLoading={isLoading}
            counterList={counterList}
            transactionList={transactionList}
            handleFilterValues={handleFilterValues}
            filterValues={filterValues}
            loadingExportButton={loadingExportButton}
            transactionListExport={transactionListExport}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            transactionListTotalCount={transactionListTotalCount}
            currentPage={currentPage}
            setCurrentPage={handleSetCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
          />
        </BlockLocationUIProvider>

        {isDeleteModalOpen && (
          <DeleteModal
            title={"Delete BlockLocation?"}
            description={
              "BlockLocation will be deleted forever and cannot be retrieved later. Are you sure about deleting it?"
            }
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
          />
        )}

        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className='danger-icon-wrapper text-center'>
              <span className='svg-icon menu-icon svg-icon-white svg-icon-4x mr-0'>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />
      </>
    </div>
  );
}
