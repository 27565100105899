import React, { useMemo } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { Upload, Filter, Plus } from "react-feather";
import { OutwardFilter } from "./outward-filter/OutwardFilter";
import { OutwardTable } from "./outward-table/OutwardTable";
import { useOutwardUIContext } from "./OutwardUIContext";
import { exportOutwardListingApi } from "../../axios/services/services";
import { FormattedMessage } from "react-intl";

export function OutwardCard({
  isLoading,
  DCList,
  DCListTotalCount,
  onClickOfCreateNew,
  isLoadingFilter,
  isFilterOpen,
  handleCLickOfFilterButtonFilter,
  handleClickOfRemoveFilterButton,
  DCStatusList
}) {
  const outwardUIContext = useOutwardUIContext();
  const outwardUIProps = useMemo(() => {
    return {
      ids: outwardUIContext.ids,
      queryParams: outwardUIContext.queryParams,
      setQueryParams: outwardUIContext.setQueryParams,
      openViewDialog: outwardUIContext.openViewDialog,
      openEditDialog: outwardUIContext.openEditDialog,
      openDeleteDialog: outwardUIContext.openDeleteDialog,
      redirectToViewPage: outwardUIContext.redirectToViewPage,
      openAgreeStatusDialog: outwardUIContext.openAgreeStatusDialog,
      openDisagreeStatusDialog: outwardUIContext.openDisagreeStatusDialog
    };
  }, [outwardUIContext]);

  const exportOutwardListing = async () => {
    await exportOutwardListingApi({ filename: "Outwards" });
  };

  return (
    <>
      <Card className='card-custom card-stretch make-payment-wrapper'>
        <Card.Header className='align-items-center d-flex justify-content-between'>
          <h3 className='card-title page-title text-black mb-0'>
            <FormattedMessage id='TITLE.ALL.CHECKOUT.REQUESTS' />
          </h3>
          <div className='d-flex'>
            <ButtonGroup className='mr-3'>
              <Button
                variant='tertiary d-flex align-items-center btn-inventory'
                onClick={() => {
                  handleClickOfRemoveFilterButton();
                }}
              >
                <Filter size={16} style={{ color: "#CAA25C" }} />{" "}
                <span className='d-none d-md-flex'>
                  {isFilterOpen ? (
                    <FormattedMessage id='TITLE.REMOVE' />
                  ) : (
                    <FormattedMessage id='TITLE.FILTER' />
                  )}
                </span>
              </Button>
              <div
                className='btn btn-tertiary d-flex align-items-center btn-inventory'
                onClick={() => exportOutwardListing()}
              >
                <Upload size={16} style={{ color: "#CAA25C" }} />
                <span className='d-none d-md-flex'>
                  <FormattedMessage id='TITLE.EXPORT' />
                </span>
              </div>
            </ButtonGroup>
            <Button
              variant='primary d-flex align-items-center btn-inventory'
              onClick={() => {
                onClickOfCreateNew();
              }}
            >
              <Plus size={16} style={{ color: "#CAA25C" }} />
              <span className='d-none d-md-flex'>
                <FormattedMessage id='TITLE.CREATE.NEW' />
              </span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isFilterOpen && (
            <OutwardFilter
              isLoadingFilter={isLoadingFilter}
              DCStatusList={DCStatusList}
              handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
            />
          )}
          <OutwardTable
            isLoading={isLoading}
            DCList={DCList}
            DCListTotalCount={DCListTotalCount}
          />
        </Card.Body>
      </Card>
    </>
  );
}
