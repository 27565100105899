import React from "react";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import { statusName } from "../components/view-vendor-details/ViewVendorUIHelpers";
import { ButtonLoader } from "../helper/ButtonLoader";

export function EditStatusVendorCustomerModal({
  isLoadingEditVendorCustomerStatus,
  isEditVendorCustomerStatusModal,
  data,
  selectedVendorCustomerStatus,
  setSelectedVendorCustomerStatus,
  handleClickOfEditStatusVendorCustomerUpdateButton,
  onClickOfEditStatusVendorCustomerCancelButton
}) {
  const intl = useIntl();
  const { contact_name } = data || {};

  return (
    <>
      <Modal
        show={isEditVendorCustomerStatusModal}
        onHide={onClickOfEditStatusVendorCustomerCancelButton}
        dialogClassName='modal-90w status-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>
            <FormattedMessage id='TITLE.UPDATE.STATUS' />{" "}
            {` - ${contact_name ? contact_name : ""}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <form autoComplete='off'>
            <div className='row'>
              <div className='col-md-6 mb-6'>
                <h5 className='fs-14 font-weight-normal text-left'>
                  <FormattedMessage id='TITLE.SELECT.STATUS' />
                </h5>

                <Select
                  value={selectedVendorCustomerStatus}
                  onChange={setSelectedVendorCustomerStatus}
                  isSearchable={true}
                  options={statusName}
                  placeholder={intl.formatMessage({
                    id: "TITLE.SELECT.STATUS"
                  })}
                  id='Currency'
                  className='react-select-container'
                  classNamePrefix='react-select'
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className='mt-3'>
          <ButtonToolbar className='d-flex justify-content-end button-wrap mb-8'>
            <Button
              type='button'
              variant='btn btn-primary mr-3'
              onClick={() => {
                handleClickOfEditStatusVendorCustomerUpdateButton();
              }}
              disabled={isLoadingEditVendorCustomerStatus}
            >
              <FormattedMessage id='TITLE.UPDATE' />
              {isLoadingEditVendorCustomerStatus && (
                <ButtonLoader className='mr-1 ml-1' />
              )}
            </Button>
            <Button
              type='reset'
              variant='btn btn-tertiary'
              onClick={onClickOfEditStatusVendorCustomerCancelButton}
            >
              <FormattedMessage id='TITLE.CANCEL' />
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </>
  );
}
