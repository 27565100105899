import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useIntl } from "react-intl";

import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";

import { USER_DETAILS } from "../../../constants/const";

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../helper/helper";

import AssetNameWithImageColumnFormatter from "../../../columnformatter/assetsColumnFormatters/AssetNameWithImageColumnFormatter";
import { Pagination } from "../../../pagination/Pagination";
import { useAssetUIContext } from "../AssetUIContext";
import * as uiHelpers from "../AssetUIHelpers";

import AssetProductCategoryNameColumnFormatter from "../../../columnformatter/assetsColumnFormatters/AssetProductCategoryNameColumnFormatter";
import AssetLocationColumnFormatter from "../../../columnformatter/assetsColumnFormatters/AssetLocationColumnFormatter";
import AssetCheckStatusColumnFormatter from "../../../columnformatter/assetsColumnFormatters/AssetCheckStatusColumnFormatter";
import AssetAssignedToColumnFormatter from "../../../columnformatter/assetsColumnFormatters/AssetAssignedToColumnFormatter";
import AssetUnderMaintanceToColumnFormatter from "../../../columnformatter/assetsColumnFormatters/AssetUnderMaintanceToColumnFormatter";

export function AssetTable({
  isLoading,
  assetList,
  assetListTotalCount,
  currentPage,
  setCurrentPage
}) {
  const intl = useIntl();
  // Product UI Context
  const assetUIContext = useAssetUIContext();
  const productUIProps = useMemo(() => {
    return {
      ids: assetUIContext.ids,
      setIds: assetUIContext.setIds,
      queryParams: assetUIContext.queryParams,
      setQueryParams: assetUIContext.setQueryParams,
      openViewDialog: assetUIContext.openViewDialog,
      openEditDialog: assetUIContext.openEditDialog,
      openDeleteDialog: assetUIContext.openDeleteDialog,
      redirectToViewPage: assetUIContext.redirectToViewPage,
      redirectToCategoryViewPage: assetUIContext.redirectToCategoryViewPage
    };
  }, [assetUIContext]);

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  // Table columns
  const columns = [
    {
      dataField: "barcode_no",
      text: intl.formatMessage({ id: "TITLE.BARCODE.NUMBER" }),
      sort: true,
      style: { minWidth: "170px", maxWidth: "350px" },
      sortCaret: sortCaret
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TITLE.PRODUCT.NAME" }),
      sort: false,
      style: { minWidth: "170px", maxWidth: "350px" },
      sortCaret: sortCaret,
      formatter: AssetNameWithImageColumnFormatter,
      formatExtraData: { redirectToViewPage: productUIProps.redirectToViewPage }
    },
    {
      dataField: "category_name",
      text: intl.formatMessage({ id: "TITLE.CATEGORY" }),
      sort: false,
      style: { minWidth: "170px" },
      sortCaret: sortCaret,
      formatter: AssetProductCategoryNameColumnFormatter,
      formatExtraData: {
        redirectToCategoryViewPage: productUIProps.redirectToCategoryViewPage
      }
    },
    {
      dataField: "location",
      text: intl.formatMessage({ id: "TITLE.LOCATION" }),
      sort: false,
      style: { minWidth: "170px", maxWidth: "350px" },
      formatter: AssetLocationColumnFormatter,
      sortCaret: sortCaret
    },
    {
      dataField: "checkinout",
      text: intl.formatMessage({ id: "TITLE.CHECKINOUT" }),
      sort: false,
      style: { minWidth: "170px", maxWidth: "350px" },
      sortCaret: sortCaret,
      formatter: AssetCheckStatusColumnFormatter
    },
    {
      dataField: "assignedto",
      text: intl.formatMessage({ id: "TITLE.ASSIGNED.TO" }),
      sort: false,
      style: { minWidth: "170px", maxWidth: "350px" },
      sortCaret: sortCaret,
      formatter: AssetAssignedToColumnFormatter
    },
    {
      dataField: "under_maintance",
      text: intl.formatMessage({ id: "TITLE.ASSET.MAINTANCE.STATUS" }),
      sort: false,
      style: { minWidth: "170px", maxWidth: "350px" },
      sortCaret: sortCaret,
      formatter: AssetUnderMaintanceToColumnFormatter
    },

    {
      dataField: "action",
      text: intl.formatMessage({ id: "TITLE.ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: productUIProps.openViewDialog,
        openDeleteDialog: productUIProps.openDeleteDialog,
        isVisibleViewButton: true,
        isVisibleEditButton: false,
        isVisibleDeteletButton:
          userRole === "Inward Manager" || userRole === "Outward Manager"
            ? false
            : true,

      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: { minWidth: "120px" }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: assetListTotalCount ? assetListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productUIProps.queryParams.pageSize,
    page: currentPage,
    onPageChange: (page) => {
      if (page === 0) return;
      setCurrentPage(page);
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-head-custom table-head-bg table-borderless table-vertical-center product-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={assetList}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  productUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={assetList}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
