import React, { useState, useMemo } from "react";
import { Search, RefreshCw } from "react-feather";
import { Form, Button ,FormGroup} from "react-bootstrap";
import { useAuditUIContext } from "../AuditUIContext";
import { isEqual } from "lodash";
import { Formik } from "formik";
import moment from "moment";
import DatePicker from "react-datepicker";
import { dd_MM_yyyy, YYYY_MM_DD } from "../../../constants/const";
import { ButtonLoader } from "../../../helper/ButtonLoader";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorModal } from "../../common/modals/ErrorModal";
import Select from "react-select"
import { AuditStatusList } from "../../../constants/utils";

const initialFilterValues = {
  audit_name: '',
  audit_status: '',
  audit_assignee:'',
  start_date: null,
  end_date:null

};
export function AuditFilter({
  isLoadingFilter,
  handleCLickOfFilterButtonFilter,
  handleCLickOfResetFilterButtonFilter
}) {
  const intl = useIntl();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const inwardUIContext = useAuditUIContext();
  const categoryUIProps = useMemo(() => {
    return {
      queryParams: inwardUIContext.queryParams,
      setQueryParams: inwardUIContext.setQueryParams
    };
  }, [inwardUIContext]);

  const prepareFilter = (queryParams, values) => {
    const {
      audit_name,
      audit_status,
      audit_assignee,
      start_date,
      end_date
    } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    
    filter.audit_name = audit_name !== "" ? audit_name : undefined;
    filter.audit_status = audit_status !== "" ? audit_status : undefined;
    filter.audit_assignee = audit_assignee !== "" ? audit_assignee : undefined;
    filter.start_date = start_date ? moment(start_date).format(YYYY_MM_DD) : undefined;
    filter.end_date = end_date ? moment(end_date).format(YYYY_MM_DD) : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(categoryUIProps.queryParams, values);
    if (!isEqual(newQueryParams, categoryUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      categoryUIProps.setQueryParams(newQueryParams);
    }
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
        onReset={(values) => {
          handleCLickOfFilterButtonFilter();
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          resetForm,
          handleReset,
          setFieldValue
        }) => (
          <Form
            className='form form-label-right filter-card'
            onSubmit={handleSubmit}
            onReset={() => {
              resetForm(initialFilterValues);
              handleReset();
            }}
          >
            <Form.Group className='form-group mb-md-0 filter-form-group'>
              <div className='filter-item'>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.AUDITFIELDS.FILTER.AUDIT.NAME' />  
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.AUDITFIELDS.FILTER.AUDIT.NAME"
                    })}
                    onChange={(e) => {
                      setFieldValue("audit_name", e.target.value);
                    }}
                    maxLength='70'
                    autoComplete='off'
                    value={values.audit_name}
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.AUDITFIELDS.FILTER.AUDIT.STATUS' />
                    </b>
                  </small>
                  <Select
                    options={AuditStatusList}
                    isClearable
                    onChange={(e)=>{
                      setFieldValue('audit_status',e ? e.value : "")
                    }}
                    value={AuditStatusList.find((x)=>x.value == values.audit_status) ?? null}
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.AUDITFIELDS.FILTER.AUDIT.ASSIGNEE' />  
                    </b>
                  </small>
                  <Form.Control
                    type='text'
                    className='form-control'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.AUDITFIELDS.FILTER.AUDIT.ASSIGNEE"
                    })}
                    onChange={(e) => {
                      setFieldValue("audit_assignee", e.target.value);
                    }}
                    maxLength='70'
                    autoComplete='off'
                    value={values.audit_assignee}
                  />
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.START.DATE' />
                    </b>
                  </small>
                  <FormGroup>
                    <DatePicker
                      dateFormat={dd_MM_yyyy}
                      className='mb-1'
                      selected={values.start_date}
                      onChange={(date) => {
                        
                        setFieldValue("start_date", date);
                      }}
                      placeholderText={intl.formatMessage({
                        id: "TITLE.START.DATE"
                      })}
                      selectsStart
                      startDate={values.start_date}
                      
                    />
                    </FormGroup>
                </div>
                <div className=''>
                  <small className='form-text text-muted mb-1'>
                    <b>
                      <FormattedMessage id='TITLE.DUE.DATE' />
                    </b>
                  </small>
                  <FormGroup>
                    <DatePicker
                      dateFormat={dd_MM_yyyy}
                      className='mb-1'
                      selected={values.end_date}
                      onChange={(date) => {
                        
                        setFieldValue("end_date", date);
                      }}
                      placeholderText={intl.formatMessage({
                        id: "TITLE.DUE.DATE"
                      })}
                      selectsStart
                      startDate={values.end_date}
                      
                    />
                    </FormGroup>
                </div>
              </div>
              <div className='filter-action d-flex'>
                <Button
                  variant='secondary d-flex align-items-center btn-inventory mr-2'
                  type='submit'
                >
                  <Search
                    size={20}
                    style={{ color: "#fff", marginRight: "0" }}
                  />
                  {isLoadingFilter && <ButtonLoader />}
                </Button>
                <Button
                  variant='tertiary d-flex align-items-center btn-inventory'
                  type='reset'
                >
                  <RefreshCw
                    size={20}
                    style={{ color: "#CAA25C", marginRight: "0" }}
                  />
                </Button>
              </div>
            </Form.Group>
          </Form>
        )}
      </Formik>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
