import React, { useContext, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Moment from "react-moment";
import { AUTH_TOKEN, DD_MM_YYYY } from "../../constants/const";
import {
  amountFormatter,
  getProductMediaUrl,
  setActiveMenu,
  getStatusPOBgClass,
  getMediaUrl
} from "../../constants/utils";
import { FormattedMessage } from "react-intl";
import { downloadAttachmentApi } from "../../axios/services/services";
import { UIContext } from "../../helper/UIContext";
import { MENUITEMS } from "../../constants/menu";
import { Download } from "react-feather";
import { ImageWrapper } from "../../helper/ImageWrapper";
export function PDFDCView({
  id,
  DCDetails,
  singleDCPurchaseItem,
  DCScanNotes
}) {
  const {
    purchaseorder_number,
    purchaseorderstatus,
    contact,
    date,
    returnByDate,
    expected_delivery,
    totalamount,
    notes
  } = DCDetails || {};

  const { purchase_media, signaturemedia } = DCDetails || {};
  // const [firstFile] = purchase_media || [];
  // const { filename } = firstFile || {};
  const { contact_name = "", id: contactId, shippingaddress } = contact || {};

  const { city, country, building_no } = shippingaddress || {};
  const { name: cityName } = city || {};
  const { name: countryName } = country || {};
  const { name: statusName } = purchaseorderstatus || {};

  const [singleDC, setSingleDc] = useState({});
  const history = useHistory();
  const uiContext = useContext(UIContext);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const downloadAttachedFile = async (filename) => {
    await downloadAttachmentApi({
      id,
      containername: "product",
      filename: filename,
      token: AUTH_TOKEN
    });
  };

  const renderAddress = () => {
    let dcAddress = "";
    if (!singleDC) {
      return;
    }
    const { address } = singleDC || {};
    const { location_name, street_1, street_2, city, state, country } =
      address || {};
    const { name: cityName } = city || {};
    const { name: stateName } = city || {};
    const { name: countryName } = city || {};

    if (location_name) {
      dcAddress = dcAddress?.concat(`${location_name},`);
    }
    if (street_1) {
      dcAddress = dcAddress?.concat(` ${street_1},`);
    }
    if (street_2) {
      dcAddress = dcAddress?.concat(` ${street_2},`);
    }
    if (cityName) {
      dcAddress = dcAddress?.concat(`${cityName},`);
    }
    if (stateName) {
      dcAddress = dcAddress?.concat(` ${stateName},`);
    }
    if (countryName) {
      dcAddress = dcAddress?.concat(` ${countryName},`);
    }
    return dcAddress;
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  const renderProductImage = (productItem) => {
    const { product } = productItem || {};
    const { product_media } = product || {};
    const { name } = product_media || {};
    return getProductMediaUrl(name);
  };

  const renderSignatureImage = () => {
    let signatureMediaFile = signaturemedia && signaturemedia[0]?.filename;
    return getMediaUrl(signatureMediaFile, "signature");
  };

  return (
    <>
      <div className='view-body'>
        <div className='pdf-view'>
          <div className={`status-ribbon ${getStatusPOBgClass(statusName)}`}>
            {statusName}
          </div>
          <div style={{ padding: "0 0.4in 0 0.55in", height: "0.7in" }}></div>
          <Table style={{ width: "100%", tableLayout: "Fixed", border: "0" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    width: "50%",
                    verticalAlign: "top",
                    border: "0",
                    padding: "0"
                  }}
                >
                  <span
                    style={{
                      color: "#000",
                      marginBottom: "5px",
                      display: "block",
                      fontSize: "10pt"
                    }}
                  ></span>
                  <span
                    style={{
                      color: "#333",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      fontSize: "10pt"
                    }}
                  >
                    {renderAddress()}
                  </span>
                </td>
                <td
                  style={{
                    width: "50%",
                    verticalAlign: "top",
                    border: "0",
                    padding: "0",
                    textAlign: "right"
                  }}
                >
                  <h1
                    style={{
                      fontSize: "24pt",
                      fontWeight: "900",
                      color: "#000",
                      marginBottom: "5px"
                    }}
                  >
                    <FormattedMessage id='TITLE.CHECKOUT.REQUEST' />
                  </h1>
                  <span
                    style={{
                      color: "#333",
                      fontWeight: "600",
                      fontSize: "10pt"
                    }}
                  >
                    {purchaseorder_number}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table
            style={{
              width: "100%",
              marginTop: "30px",
              tableLayout: "Fixed",
              border: "0"
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    width: "50%",
                    verticalAlign: "bottom",
                    border: "0",
                    padding: "0",
                    wordWrap: "break-word"
                  }}
                >
                  <span
                    style={{
                      fontSize: "10pt",
                      color: "#000",
                      marginBottom: "5px",
                      lineHeight: "20px",
                      display: "block"
                    }}
                  >
                    <FormattedMessage id='TITLE.CHECKOUT.TO' />:
                  </span>
                  <span
                    style={{
                      fontSize: "11pt",
                      color: "#2C4592",
                      marginBottom: "8px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      display: "inline-block",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      const path = `/customers/view-customer-details/${contactId}`;
                      onClickChangeRoute(path);
                    }}
                  >
                    {contact_name}
                    <div
                      style={{
                        color: "#000",
                        lineHeight: "20px",
                        display: "block"
                      }}
                    >
                      {building_no}
                      {`${building_no ? ", " : ""} `}
                      {cityName}
                    </div>
                    <div
                      style={{
                        color: "#000",
                        lineHeight: "20px",
                        display: "block"
                      }}
                    >
                      {countryName}
                    </div>
                  </span>
                </td>
                <td
                  style={{
                    verticalAlign: "bottom",
                    border: "0",
                    padding: "0",
                    textAlign: "right"
                  }}
                >
                  <Table
                    style={{
                      width: "100%",
                      wordWrap: "break-word",
                      cellSpacing: "0",
                      cellPadding: "0",
                      tableLayout: "Fixed",
                      border: "0"
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            textAlign: "right",
                            entityDetails: "padding",
                            padding: "5px 10px 5px 0",
                            width: "60%",
                            border: "0"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              color: "#333",
                              marginBottom: "5px",
                              lineHeight: "20px",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            <FormattedMessage id='TITLE.CHALLAN.DATE' />:
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            entityDetails: "padding",
                            padding: "5px 10px 5px 0",
                            width: "40%",
                            border: "0"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              color: "#333",
                              marginBottom: "5px",
                              lineHeight: "20px",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            <Moment date={date} format={DD_MM_YYYY} />
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            textAlign: "right",
                            entityDetails: "padding",
                            padding: "5px 10px 5px 0",
                            width: "60%",
                            border: "0"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              color: "#333",
                              marginBottom: "5px",
                              lineHeight: "20px",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            <FormattedMessage id='TITLE.RETURNBY.DATE' />:
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            entityDetails: "padding",
                            padding: "5px 10px 5px 0",
                            width: "40%",
                            border: "0"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              color: "#333",
                              marginBottom: "5px",
                              lineHeight: "20px",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            <Moment date={returnByDate} format={DD_MM_YYYY} />
                          </span>
                        </td>
                      </tr>

                      {/* <tr>
                        <td
                          style={{
                            textAlign: "right",
                            entityDetails: "padding",
                            padding: "5px 10px 5px 0",
                            width: "60%",
                            border: "0"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              color: "#333",
                              marginBottom: "5px",
                              lineHeight: "20px",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            <FormattedMessage id='TITLE.EXPECTED.DELIVERY.DATE' />
                            :
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            entityDetails: "padding",
                            padding: "5px 10px 5px 0",
                            width: "40%",
                            border: "0"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "10pt",
                              color: "#333",
                              marginBottom: "5px",
                              lineHeight: "20px",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            {expected_delivery ? (
                              <Moment
                                date={expected_delivery}
                                format={DD_MM_YYYY}
                              />
                            ) : (
                              "-"
                            )}
                          </span>
                        </td>
                      </tr> */}
                    </tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table
            style={{
              width: "100%",
              tableLayout: "fixed",
              marginTop: "20px",
              border: "0",
              cellSpacing: "0",
              cellPadding: "0"
            }}
          >
            <thead>
              <tr style={{ height: "40px" }}>
                <td
                  style={{
                    padding: "5px 0px 5px 5px",
                    verticalAlign: "middle",
                    width: "5%",
                    textAlign: "center",
                    fontSize: "10pt",
                    color: "#fff",
                    background: "#333"
                  }}
                >
                  #
                </td>

                <td
                  style={{
                    padding: "5px 10px 5px 20px",
                    verticalAlign: "middle",
                    textAlign: "left",
                    wordWrap: "break-word",
                    fontSize: "10pt",
                    color: "#fff",
                    background: "#333"
                  }}
                >
                  <FormattedMessage id='TITLE.PRODUCT' />
                </td>

                <td
                  style={{
                    padding: "5px 10px 5px 20px",
                    verticalAlign: "middle",
                    textAlign: "right",
                    wordWrap: "break-word",
                    fontSize: "10pt",
                    color: "#fff",
                    background: "#333"
                  }}
                >
                  <FormattedMessage id='TITLE.STATUS' />
                </td>

                <td
                  style={{
                    padding: "5px 10px 5px 5px",
                    verticalAlign: "middle",
                    width: "12%",
                    textAlign: "right",
                    wordWrap: "break-word",
                    fontSize: "10pt",
                    color: "#fff",
                    background: "#333"
                  }}
                >
                  <FormattedMessage id='TITLE.QTY' />
                </td>

                {/* <td
                  style={{
                    padding: "5px 10px 5px 5px",
                    verticalAlign: "middle",
                    width: "15%",
                    textAlign: "right",
                    wordWrap: "break-word",
                    fontSize: "10pt",
                    color: "#fff",
                    background: "#333"
                  }}
                >
                  <FormattedMessage id='TITLE.RATE' />
                </td>

                <td
                  style={{
                    padding: "5px 10px 5px 5px",
                    verticalAlign: "middle",
                    width: "18%",
                    textAlign: "right",
                    wordWrap: "break-word",
                    fontSize: "10pt",
                    color: "#fff",
                    background: "#333"
                  }}
                >
                  <FormattedMessage id='TITLE.AMOUNT' />
                </td> */}
              </tr>
            </thead>
            {singleDCPurchaseItem && singleDCPurchaseItem.lengh !== 0 ? (
              singleDCPurchaseItem.map((product, index) => {
                return (
                  <tbody key={index}>
                    <tr style={{ height: "40px" }}>
                      <td
                        style={{
                          padding: "5px 0px 5px 5px",
                          verticalAlign: "middle",
                          width: "5%",
                          textAlign: "center",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                      >
                        {index + 1}
                      </td>

                      <td>
                        <div className='table-product-view'>
                          <div className='product-thumb'>
                            <div>
                              <ImageWrapper
                                className={
                                  "img-radius align-top blur-up lazyloaded"
                                }
                                src={renderProductImage(product)}
                              />
                            </div>
                          </div>
                          <div className='product-name pl-3'>
                            <Link
                              to={`/inventoryproducts/view-inventoryproduct-details/${product?.product?.id}`}
                              className='text-primary fs-14 mb-2 text-capitalize'
                              onClick={() => {
                                const path = `/inventoryproducts/view-inventoryproduct-details/${product?.product?.id}`;
                                onClickChangeRoute(path);
                              }}
                            >
                              {product?.product?.name}
                            </Link>
                            <div className='small-description small-text text-dark'>
                              {product.description}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td
                        style={{
                          padding: "5px 5px 5px 5px",
                          verticalAlign: "middle",
                          width: "10%",
                          textAlign: "right",
                          wordWrap: "break-word",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                      >
                        {product?.product_status}
                      </td>

                      <td
                        style={{
                          padding: "5px 10px 5px 5px",
                          verticalAlign: "middle",
                          width: "10%",
                          textAlign: "right",
                          wordWrap: "break-word",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                      >
                        {product?.quantity}
                        {product?.product?.productunit?.name} <br />
                        <div className='small-description small-text text-dark'>
                          <FormattedMessage id='TITLE.DELIVERED.QUANTITY' />:
                          {product.received_quantity}
                        </div>
                      </td>

                      {/* <td
                        style={{
                          padding: "5px 10px 5px 5px",
                          verticalAlign: "middle",
                          width: "15%",
                          textAlign: "right",
                          wordWrap: "break-word",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                      >
                        {amountFormatter(product?.price)}
                      </td> */}

                      {/* <td
                        style={{
                          padding: "5px 10px 5px 5px",
                          verticalAlign: "middle",
                          width: "18%",
                          textAlign: "right",
                          wordWrap: "break-word",
                          fontSize: "10pt",
                          color: "#333",
                          border: "0"
                        }}
                      >
                        {amountFormatter(product?.totalamount)}
                      </td> */}
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <tbody>
                <tr>
                  <td colspan='5' className='no-record text-center'>
                    <FormattedMessage id='TITLE.NO.RECORDS.FOUNDS' />
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
          <Table
            style={{
              width: "100%",
              tableLayout: "fixed",
              marginTop: "20px",
              border: "0",
              borderTop: "1px solid #ddd",
              cellSpacing: "0",
              cellPadding: "0"
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    padding: "0",
                    verticalAlign: "top",
                    width: "50%",
                    textAlign: "right",
                    wordWrap: "break-word",
                    fontSize: "10pt",
                    color: "#333",
                    border: "0"
                  }}
                ></td>
                {/* <td
                  style={{
                    padding: "0",
                    verticalAlign: "top",
                    width: "50%",
                    textAlign: "right",
                    wordWrap: "break-word",
                    fontSize: "10pt",
                    color: "#333",
                    border: "0"
                  }}
                >
                  <Table
                    style={{
                      width: "100%",
                      tableLayout: "fixed",
                      marginTop: "0px",
                      border: "0",
                      cellSpacing: "0",
                      cellPadding: "0",
                      border: "0"
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: "10px 10px 10px 0px",
                            verticalAlign: "middle",
                            width: "50%",
                            textAlign: "right",
                            wordWrap: "break-word",
                            fontSize: "10pt",
                            fontWeight: "bold",
                            color: "#333",
                            border: "0"
                          }}
                        >
                          <FormattedMessage id='TITLE.TOTAL' />
                        </td>
                        <td
                          style={{
                            padding: "10px 10px 10px 0px",
                            verticalAlign: "middle",
                            width: "50%",
                            textAlign: "right",
                            wordWrap: "break-word",
                            fontSize: "10pt",
                            fontWeight: "bold",
                            color: "#333",
                            border: "0"
                          }}
                        >
                          {amountFormatter(totalamount)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td> */}
              </tr>
            </tbody>
          </Table>
          <div style={{ pageBreakInside: "avoid" }}>
            <label
              style={{
                display: "table-cell",
                fontSize: "10pt",
                color: "#333"
              }}
            >
              <FormattedMessage id='TITLE.AUTHORIZED.SIGNATURE' />
            </label>
            <ImageWrapper
              className={"img-radius align-top blur-up lazyloaded"}
              src={renderSignatureImage()}
              style={{ height: "150px", width: "250px" }}
            />
            {/* <div style={{ display: "table-cell" }}>
                <div
                  style={{
                    width: "200px",
                    borderBottom: "1px solid #333",
                    display: "inline-block"
                  }}
                ></div>
              </div> */}
          </div>
          <div style={{ padding: "0 0.4in 0 0.55in", height: "0.7in" }}></div>
        </div>
        <Row className='pt-5'>
          <Col md={12}>
            <h6 className='text-black mb-2 f-w-600'>
              <FormattedMessage id='TITLE.NOTES' />
            </h6>
            <p>{notes}</p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h6 className='text-black mb-2 f-w-600'>
              <FormattedMessage id='TITLE.ITEM.SCAN.NOTES' />
            </h6>
            {DCScanNotes &&
              DCScanNotes.map((scanNote, snIndex) => {
                return scanNote?.notes ? (
                  <div
                    key={snIndex}
                    className='d-flex text-muted justify-content-between p-2 border mb-1'
                  >
                    {scanNote?.notes}
                    <span className='text-dark d-flex align-items-center'>
                      <Moment date={scanNote?.created} format={DD_MM_YYYY} />
                    </span>
                  </div>
                ) : (
                  <></>
                );
              })}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h6 className='text-black mb-2 f-w-600'>
              <FormattedMessage id='TITLE.ATTACHED.FILE' />
            </h6>
            {purchase_media &&
              purchase_media.map((purchaseMedia, index) => {
                return (
                  <div
                    key={index}
                    className='cursor-pointer d-flex justify-content-between p-2 border mb-1'
                    onClick={() =>
                      downloadAttachedFile(purchaseMedia?.filename)
                    }
                  >
                    {purchaseMedia?.filename}
                    <Download />
                  </div>
                );
              })}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PDFDCView;
