// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../InwardUIHelpers";
import { useInwardUIContext } from "../InwardUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../helper/helper";
import { Pagination } from "../../../pagination/Pagination";
import ActionsColumnFormatter from "../../../columnformatter/ActionsColumnFormatter";
import POStatusTypeFormatter from "../../../columnformatter/POStatusTypeFormatter";
import VendorNameColumnFormatter from "../../../columnformatter/VendorNameColumnFormatter";
import PONumberHyperLinkColumnFormatter from "../../../columnformatter/PONumberHyperLinkColumnFormatter";
import ExpectedDateColumnFormatter from "../../../columnformatter/ExpectedDateColumnFormatter";
import TotalAmountColumnFormatter from "../../../columnformatter/TotalAmountColumnFormatter";
import DateTimeColumnFormatter from "../../../columnformatter/DateTimeColumnFormatter";
import { useIntl } from "react-intl";
import { USER_DETAILS } from "../../../constants/const";

export function InwardTable({
  isLoading,
  POList,
  POListTotalCount,
  currentPage
}) {
  const intl = useIntl();
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  // Inward UI Context
  const inwardUIContext = useInwardUIContext();
  const inwardUIProps = useMemo(() => {
    return {
      ids: inwardUIContext.ids,
      setIds: inwardUIContext.setIds,
      queryParams: inwardUIContext.queryParams,
      setQueryParams: inwardUIContext.setQueryParams,
      openViewDialog: inwardUIContext.openViewDialog,
      openEditDialog: inwardUIContext.openEditDialog,
      openEditStatusDialog: inwardUIContext.openEditStatusDialog,
      openAgreeStatusDialog: inwardUIContext.openAgreeStatusDialog,
      openDisagreeStatusDialog: inwardUIContext.openDisagreeStatusDialog,
      openDeleteDialog: inwardUIContext.openDeleteDialog,
      redirectToViewPage: inwardUIContext.redirectToViewPage,
      redirectToVendorViewPage: inwardUIContext.redirectToVendorViewPage
    };
  }, [inwardUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "purchaseorder_number",
      text: intl.formatMessage({
        id: "TITLE.PO.NUMBER"
      }),
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "150px"
      },
      formatter: PONumberHyperLinkColumnFormatter,
      formatExtraData: {
        redirectToViewPage: inwardUIProps.redirectToViewPage
      }
    },
    {
      dataField: "date",
      text: intl.formatMessage({
        id: "TITLE.CREATED.DATE"
      }),
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "130px"
      },
      formatter: DateTimeColumnFormatter
    },
    {
      dataField: "contact_name",
      text: intl.formatMessage({
        id: "TITLE.VENDOR.NAME"
      }),
      sort: true,
      style: {
        minWidth: "170px"
      },
      sortCaret: sortCaret,
      formatter: VendorNameColumnFormatter,
      formatExtraData: {
        redirectToVendorViewPage: inwardUIProps.redirectToVendorViewPage
      }
    },
    {
      dataField: "purchaseorder_status",
      text: intl.formatMessage({
        id: "TITLE.STATUS"
      }),
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px"
      },
      formatter: POStatusTypeFormatter,
      formatExtraData: {
        openEditStatusDialog: inwardUIProps.openEditStatusDialog,
        openAgreeStatusDialog: inwardUIProps.openAgreeStatusDialog,
        openDisagreeStatusDialog: inwardUIProps.openDisagreeStatusDialog,
        userRole: userRole
      }
    },
    {
      dataField: "totalamount",
      text: intl.formatMessage({
        id: "TITLE.AMOUNT"
      }),
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "150px"
      },
      formatter: TotalAmountColumnFormatter
    },
    {
      dataField: "expected_delivery",
      text: intl.formatMessage({
        id: "TITLE.EXPECTED.DELIVERY.DATE"
      }),
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "130px"
      },
      formatter: ExpectedDateColumnFormatter
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "TITLE.ACTIONS"
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: inwardUIProps.openViewDialog,
        openEditDialog: inwardUIProps.openEditDialog,
        openDeleteDialog: inwardUIProps.openDeleteDialog,
        isVisibleViewButton: true,
        isVisibleDeteletButton: userRole === "Inward Manager" ? false : true
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "120px"
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: POListTotalCount ? POListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: inwardUIProps.queryParams.pageSize,
    page: inwardUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    }
  };
  console.log("🚀 ~ paginationOptions.totalSize:", paginationOptions.totalSize)
  console.log("🚀 ~ paginationOptions.sizePerPageList:", paginationOptions.sizePerPageList)
  console.log("🚀 ~ paginationOptions.sizePerPage:", paginationOptions.sizePerPage)
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          console.log("paginationTableProps",paginationTableProps)
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <div className='position-relative'>
                <BootstrapTable
                  wrapperClasses='table-responsive'
                  classes='table table-head-custom table-head-bg table-borderless table-vertical-center inward-table table-inventory'
                  bootstrap4
                  bordered={false}
                  remote
                  keyField='id'
                  data={POList ? POList : []}
                  columns={columns}
                  defaultSorted={uiHelpers.defaultSorted}
                  onTableChange={getHandlerTableChange(
                    inwardUIProps.setQueryParams
                  )}
                  {...paginationTableProps}
                ></BootstrapTable>
                <PleaseWaitMessage isLoading={isLoading} />
                <NoRecordsFoundMessage
                  entities={POList}
                  isLoading={isLoading}
                />
              </div>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
