import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import { X, Check } from "react-feather";
import {
  editCustomFieldApi,
  getSingleCustomFieldDetailsApi,
  categoryListApi,
} from "../../../axios/services/services";
import { useFormik } from "formik";
import { AddEditCustomFieldSchema } from "../../../helper/FormikSchema";
import { ErrorModal } from "../../common/modals/ErrorModal";
import {
  customFieldTypeList,
  unauthorization
} from "../../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { getErrorMessageBasedOnLanguage } from "../../../constants/utils";
import Select from "react-select"
import CreatableSelect from "react-select/creatable";

let EditCustomFieldInitialValues = {
  field_name: '',
  field_name_in_arabic: '',
  field_type: '',
  show_in_filter: '',
  field_value: [],
  categoryId: [],
};

export function EditCustomFieldModal({
  onCloseModal,
  open,
  data,
  history,
  id,
  handleCloseOfEditNewProductModal
}) {

  const [customFieldEdited, setCustomFieldEdited] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [customFieldsDetails, setCustomFieldsDetails] = useState({});
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isLoadingSinglcustomField, setLoadingSingleCustomField] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [isUpdateCategoryList, setUpdateCategoryList] = useState(0);
  const [isUpdateSingleProductDetails, setUpdateSingleProductDetails] =
    useState(0);
  
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);

  const intl = useIntl();

  async function getCategoryList() {
    const status = 1;
    const categoryListResponse = await categoryListApi({ status });
    const { data, Error } = categoryListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const categories = data.map((category, index) => {
      return {
        label: category.name,
        value: category.id
      }
    });
    setCategoryList(categories);
  }

  useEffect(() => {
    if (isUpdateSingleProductDetails) getCategoryList();
  }, [isUpdateCategoryList, isUpdateSingleProductDetails]);

  const jsonDecode = (jsonData) => {
    try{
      let fieldValue = JSON.parse(jsonData) ?? []
      return fieldValue.map(x => ({ label: x, value: x }))
    }
    catch(error){
      return []
    }
  }

  async function getSingleProductDetails() {
    enableLoadingSingleProduct();
    const singleProductDetails = await getSingleCustomFieldDetailsApi({ id });
    console.log("🚀 ~ getSingleProductDetails ~ singleProductDetails:", singleProductDetails)
    disableLoadingSingleProduct(false);
    const { Error, data } = singleProductDetails || {};
    console.log("🚀 ~ getSingleProductDetails ~ data:", data)
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }

    setCustomFieldsDetails(data ? data : {});
    setUpdateSingleProductDetails(Math.random());

    formikEditCustomField.setFieldValue('field_name',data?.field_name ?? "")
    formikEditCustomField.setFieldValue('field_name_in_arabic',data?.field_name_in_arabic ?? "")
    formikEditCustomField.setFieldValue('field_type',data?.field_type ?? "")
    formikEditCustomField.setFieldValue('show_in_filter',data?.show_in_filter ?? "")
    formikEditCustomField.setFieldValue('field_value',jsonDecode(data?.field_value) ?? [])
    setValue(jsonDecode(data?.field_value) ?? [])
    formikEditCustomField.setFieldValue('categoryId',data.categories.map((x)=>{
      return {
        label: x.name,
        value: x.id
      }
    }))
  }

  useEffect(() => {
    getSingleProductDetails();
  }, []);

  const formikEditCustomField = useFormik({
    initialValues: EditCustomFieldInitialValues,
    validationSchema: AddEditCustomFieldSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableSaveLoading();

      const editProductResponse = await editCustomFieldApi(id,values);
      console.log("🚀 ~ onSubmit: ~ editProductResponse:", editProductResponse)

      const { data, Error } = editProductResponse || {};
      if (Error) {
        disableSaveLoading();
        unauthorization(Error);
        setErrorMessage(getErrorMessageBasedOnLanguage(Error));
        handleErrorModalShow();
        return;
      }
      setCustomFieldEdited(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableSaveLoading();
        handleCloseOfEditNewProductModal(data);
      }, 2500);
    }
  });

  const enableSaveLoading = () => {
    setSaveLoading(true);
  };

  const disableSaveLoading = () => {
    setSaveLoading(false);
  };

  const enableLoadingSingleProduct = () => {
    setLoadingSingleCustomField(true);
  };

  const disableLoadingSingleProduct = () => {
    setLoadingSingleCustomField(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const components = {
    DropdownIndicator: null,
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        formikEditCustomField.setFieldValue('field_value',[...value,createOption(inputValue)]) 
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  return (
    <>
      <Modal
        size='lg'
        show={open}
        onHide={onCloseModal}
        aria-labelledby='example-modal-sizes-title-lg'
        dialogClassName='addvendor-modal modal'
        scrollable={true}
      >
        <Modal.Header className='d-flex justify-content-between align-items-center'>
          <h3 className='modal-title' id='exampleModalLabel2'>
            <FormattedMessage id='TITLE.CUSTOMFIELDS.EDIT.CUSTOM.FIELD' />
            {isLoadingSinglcustomField && (
              <Spinner animation='border' size='sm' className='ml-1' />
            )}
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          {customFieldEdited && (
            <div className='notification icon inline success fs-16'>
              <Check size={8} className='text-white'></Check>
              <FormattedMessage id='TITLE.PRODUCT.HAS.BEEN.SUCCESSFULLY.EDITED' />
            </div>
          )}
          <Form autoComplete='off'>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='productName'>
                    <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.FIELD.NAME' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='name'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.CUSTOMFIELDS.ADDFORM.FIELD.NAME"
                    })}
                    maxLength='70'
                    value={formikEditCustomField.values.field_name}
                    onChange={(e)=>{formikEditCustomField.setFieldValue('field_name',e.target.value.trimStart())}}
                  />
                  {formikEditCustomField.touched.field_name &&
                  formikEditCustomField.errors.field_name ? (
                    <div className='text-danger p-2'>
                      {formikEditCustomField.errors.field_name}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='productName'>
                    <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.FIELD.NAME.IN.ARABIC' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='name'
                    name='name'
                    placeholder={intl.formatMessage({
                      id: "TITLE.CUSTOMFIELDS.ADDFORM.FIELD.NAME.IN.ARABIC"
                    })}
                    maxLength='70'
                    value={formikEditCustomField.values.field_name_in_arabic}
                    onChange={(e)=>{formikEditCustomField.setFieldValue('field_name_in_arabic',e.target.value.trimStart())}}
                  />
                  {formikEditCustomField.touched.field_name_in_arabic &&
                  formikEditCustomField.errors.field_name_in_arabic ? (
                    <div className='text-danger p-2'>
                      {formikEditCustomField.errors.field_name_in_arabic}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='category'>
                    <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.FIELD.TYPE' />
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Select
                    options={customFieldTypeList}
                    onChange={(e)=>{
                      if(e.value !== "select"){
                        setValue([])
                        formikEditCustomField.setFieldValue('field_value',[])
                      }
                      formikEditCustomField.setFieldValue('field_type',e.value)
                    }}
                    value={customFieldTypeList.find((x)=>x.value === formikEditCustomField?.values?.field_type) ?? []}
                  /> 
                  {/* < */}
                  {formikEditCustomField.touched.field_type &&
                  formikEditCustomField.errors.field_type ? (
                    <div className='text-danger p-2'>
                      {formikEditCustomField.errors.field_type}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              {formikEditCustomField?.values?.field_type === "select" ? 
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='category'>
                    <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.FIELD.VALUE'/>
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(newValue) => {
                      setValue(newValue)
                      formikEditCustomField.setFieldValue('field_value',newValue)
                    }}
                    onInputChange={(newValue) => {
                      setInputValue(newValue)
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter field value"
                    value={value}
                  />
                  {/* < */}
                  {formikEditCustomField.touched.field_value &&
                  formikEditCustomField.errors.field_value ? (
                    <div className='text-danger p-2'>
                      {formikEditCustomField.errors.field_value}
                    </div>
                  ) : null}
                </Form.Group>
              </Col> : "" }
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor='category'>
                    <FormattedMessage id='TITLE.CUSTOMFIELDS.ADDFORM.SHOW.FIELD.ON.CATEGORY'/>
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Select
                    value={formikEditCustomField.values.categoryId}
                    isMulti
                    name="Category"
                    options={categoryList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e)=>{
                      formikEditCustomField.setFieldValue('categoryId', e)
                    }}
                  />
                  {formikEditCustomField.touched.categoryId &&
                  formikEditCustomField.errors.categoryId ? (
                    <div className='text-danger p-2'>
                      {formikEditCustomField.errors.categoryId}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Check // prettier-ignore
                    type={'checkbox'}
                    id={`show_in_filter`}
                    label={`Show In Filter`}
                    onChange={(e)=>{
                      formikEditCustomField.setFieldValue('show_in_filter', e.target.checked)
                    }}
                    checked={formikEditCustomField.values.show_in_filter}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success mr-3 btn-inventory'
            onClick={() => {
              formikEditCustomField.handleSubmit();
            }}
            disabled={isSaveLoading}
          >
            <FormattedMessage id='TITLE.SAVE' />
            {isSaveLoading && (
              <Spinner animation='border' size='sm' className='ml-1' />
            )}
          </Button>
          <Button
            type='button'
            variant='tertiary text-dark'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <FormattedMessage id='TITLE.CANCEL' />
          </Button>
        </Modal.Footer>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}
    </>
  );
}
