import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { X } from "react-feather";
import DatePicker from "react-datepicker";
import { ButtonLoader } from "../helper/ButtonLoader";
import { dd_MM_yyyy } from "../constants/const";
import { FormattedMessage, useIntl } from "react-intl";

function ExpectedDeliveryDateModal({
  isLoadingExpectedDeliveryDate,
  onCloseModal,
  expectedDeliveryDate,
  noteForExpectedDeliveryDate,
  date,
  isOpen,
  handleSaveOfExpectedDeliveryDate
}) {
  const intl = useIntl();
  const [startDate, setStartDate] = useState(
    expectedDeliveryDate ? new Date(expectedDeliveryDate) : ""
  );
  const [note, setNote] = useState(
    noteForExpectedDeliveryDate ? noteForExpectedDeliveryDate : ""
  );

  return (
    <>
      <Modal
        size='sm'
        show={isOpen}
        onHide={onCloseModal}
        aria-labelledby='example-modal-sizes-title-lg'
        dialogClassName='modal inventory-modal'
        scrollable={true}
      >
        <Modal.Header className='d-flex justify-content-between align-items-center'>
          <h3 className='modal-title' id='exampleModalLabel2'>
            <FormattedMessage id='TITLE.EXPECTED.DELIVERY.DATE' />
          </h3>
          <Button
            variant='link p-0 btn-icon'
            onClick={() => onCloseModal("VaryingMdo")}
          >
            <X size={20} className='text-dark mr-0 pr-0' />
          </Button>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <Form autoComplete='off'>
            <Form.Group className='full-width-calendar'>
              <Form.Label>
                <FormattedMessage id='TITLE.EXPECTED.DELIVERY.DATE' />
              </Form.Label>
              <DatePicker
                selected={startDate}
                minDate={new Date(date)}
                dateFormat={dd_MM_yyyy}
                onChange={(date) => setStartDate(date)}
                placeholderText='DD/MM/YYYY'
                className='form-control w-100'
                // closeOnScroll={(e) => e.target === document}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor='notes'>
                <FormattedMessage id='TITLE.NOTES' />
                {/* <span className="text-danger">*</span> */}
              </Form.Label>
              <Form.Control
                id='notes'
                name='notes'
                as='textarea'
                placeholder={intl.formatMessage({
                  id: "TITLE.ENTER.YOUR.NOTES"
                })}
                className='form-control'
                // resize="none"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                maxLength={512}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            variant='success btn-inventory'
            onClick={() => handleSaveOfExpectedDeliveryDate(startDate, note)}
            disabled={isLoadingExpectedDeliveryDate}
          >
            <FormattedMessage id='TITLE.SAVE' />
            {isLoadingExpectedDeliveryDate && (
              <ButtonLoader className='mr-1 ml-1' />
            )}
          </Button>
          <Button
            type='button'
            variant='tertiary text-dark btn-inventory'
            onClick={() => onCloseModal()}
          >
            <FormattedMessage id='TITLE.CANCEL' />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExpectedDeliveryDateModal;
