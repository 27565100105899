import React, { useRef, useState, useEffect, useContext } from "react";
import { LocationLoadingDialog } from "./location-loading-dialog/LocationLoadingDialog";
import { LocationCard } from "./LocationCard";
import { LocationUIProvider } from "./LocationUIContext";
import { initialFilter } from "./LocationUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { DeleteModal } from "../common/modals/DeleteModal";
import { AddNewLocationModal } from "../../global-modal/AddNewLocationModal";
import { EditLocationModal } from "../../global-modal/EditLocationModal";
import {
  locationListMastersApi,
  deleteLocationApi
} from "../../axios/services/services";
import {
  capitalizeFirstLetter,
  getErrorMessageBasedOnLanguage,
  setActiveMenu,
  unauthorization
} from "../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { SuccessModal } from "../common/modals/SuccessModal";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";

export function LocationPage({ history }) {
  const intl = useIntl();
  const uiContext = useContext(UIContext);
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [isOpenAddNewLocationModal, setOpenAddNewLocationModal] =
    useState(false);
  const [isOpenEditLocationModal, setOpenEditLocationModal] = useState(false);

  const exportCSVButton = useRef();
  const [locationList, setLocationList] = useState([]);
  const [locationListTotalCount, setLocationListTotalCount] = useState();
  const [isUpdateLocationDetails, setUpdateLocationDetails] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isLoadingDeleteLocation, setLoadingDeleteLocation] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [parentLocation, setParentLocation] = useState(null);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  async function getLocationList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const LocationListResponse = await locationListMastersApi({
      queryParams
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count } = LocationListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }

    const locations = data.map((location) => {
      const { id, store_location, parent_location, locationId } =
        location || {};
      if (parent_location) {
        const {
          store_location: depth_one_store_location,
          parent_location: depth_one_parent_location,
          locationId: depth_one_locationId
        } = locationId || {};

        if (depth_one_parent_location) {
          const { store_location: depth_two_store_location } =
            depth_one_locationId || {};
          location.value = id;
          location.label =
            depth_two_store_location +
            ", " +
            depth_one_store_location +
            ", " +
            store_location;
        } else {
          location.value = id;
          location.label = depth_one_store_location + ", " + store_location;
        }
      } else {
        location.value = id;
        location.label = store_location;
      }

      return location;
    });
    setParentLocation(locations);
    setLocationList(data ? data : []);
    setLocationListTotalCount(count ? count : 0);
  }

  useEffect(() => {
    getLocationList();
  }, [queryParams, isUpdateLocationDetails]);

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const enableLoadingDeleteLocation = () => {
    setLoadingDeleteLocation(true);
  };

  const disableLoadingDeleteLocation = () => {
    setLoadingDeleteLocation(false);
  };

  const addNewLocationModalShow = () => {
    setOpenAddNewLocationModal(true);
  };

  const addNewLocationModalHide = () => {
    setOpenAddNewLocationModal(false);
  };

  const editLocationModalShow = () => {
    setOpenEditLocationModal(true);
  };

  const editLocationModalHide = () => {
    setOpenEditLocationModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    addNewLocationModalShow();
  };

  const onClickOfDeleteButton = () => {
    deleteLocation();
  };

  const handleCloseOfAddNewLocationModal = (addNewVendorDetails) => {
    setUpdateLocationDetails(Math.random());
    addNewLocationModalHide();
  };

  const handleCloseOfEditNewLocationModal = (editNewVendorDetails) => {
    setUpdateLocationDetails(Math.random());
    editLocationModalHide();
  };

  const handleCLickOfFilterButtonFilter = (values) => {
    setFilter(true);
  };

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const showSuccessModal = () => {
    setSuccessModalOpen(true);
  };

  const hideSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const locationUIEvents = {
    openViewDialog: (row) => {
      const path = `/master/locations/view-Location-details/${row.id}`;
      onClickChangeRoute(path);
    },
    openEditDialog: (row) => {
      setData(row);
      editLocationModalShow();
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteModalShow();
    }
  };

  const deleteLocation = async () => {
    const { id } = data;
    enableLoadingDeleteLocation();
    const deleteLocationResponse = await deleteLocationApi({ id });
    const { Error } = deleteLocationResponse || {};
    if (Error) {
      disableLoadingDeleteLocation();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.LOCATION.DELETED.SUCCESSFULLY" })
    );
    showSuccessModal();
    window.scrollTo(0, 0);
    setUpdateLocationDetails(Math.random());
    disableLoadingDeleteLocation();
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <div>
      <>
        <LocationUIProvider
          locationUIEvents={locationUIEvents}
          queryParams={queryParams}
          setQueryParamsBase={setQueryParamsBase}
          ids={ids}
          setIds={setIds}
        >
          <LocationLoadingDialog />
          {isOpenAddNewLocationModal && (
            <AddNewLocationModal
              locationList={locationList}
              open={isOpenAddNewLocationModal}
              onCloseModal={addNewLocationModalHide}
              handleCloseOfAddNewLocationModal={
                handleCloseOfAddNewLocationModal
              }
              parentLocationListing={parentLocation}
            />
          )}

          {isOpenEditLocationModal && (
            <EditLocationModal
              open={isOpenEditLocationModal}
              data={data}
              onCloseModal={editLocationModalHide}
              id={data.id}
              handleCloseOfEditNewLocationModal={
                handleCloseOfEditNewLocationModal
              }
              getParentLocationListing={parentLocation}
            />
          )}
          <LocationCard
            isLoading={isLoading}
            locationList={locationList}
            transactionListExport={locationList}
            handleExportButton={handleExportButton}
            exportCSVButton={exportCSVButton}
            locationListTotalCount={locationListTotalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onClickOfCreateNew={handleOnClickOfCreateNew}
            handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
            isLoadingFilter={isLoadingFilter}
            isFilterOpen={isFilterOpen}
            handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
          />
        </LocationUIProvider>

        {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteLocation}
            title={intl.formatMessage({ id: "TITLE.DELETE.LOCATION" })}
            description={
              <>
                <FormattedMessage id='TITLE.LOCATION.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER' />
                <FormattedMessage id='TITLE.ARE.YOU.SURE.ABOUT.DELETING.IT' />
              </>
            }
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            id={capitalizeFirstLetter(data.store_location)}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}

        {isErrorModalOpen && (
          <ErrorModal
            message={errorMessage}
            isErrorModalOpen={isErrorModalOpen}
            hideErrorModal={handleErrorModalHide}
          />
        )}
        {isSuccessModalOpen && (
          <SuccessModal
            open={isSuccessModalOpen}
            onCloseModal={hideSuccessModal}
            message={successMessage}
          />
        )}
      </>
    </div>
  );
}
