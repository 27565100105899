// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../InwardValuationUIHelpers";
import { useInwardValuationUIContext } from "../InwardValuationUIContext";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../../helper/helper";
import { Pagination } from "../../../../pagination/Pagination";
import AmountColumnFormatter from "../../../../columnformatter/AmountColumnFormatter";
import { useIntl } from "react-intl";

export function InwardValuationTable({
  isLoading,
  reportInwardValuation,
  reportInwardValuationTotalCount,
  reportInwardValuationTotalAmount
}) {
  const intl = useIntl();
  // InwardValuation UI Context
  const inwardvaluationUIContext = useInwardValuationUIContext();
  const inwardvaluationUIProps = useMemo(() => {
    return {
      ids: inwardvaluationUIContext.ids,
      setIds: inwardvaluationUIContext.setIds,
      queryParams: inwardvaluationUIContext.queryParams,
      setQueryParams: inwardvaluationUIContext.setQueryParams,
      openViewDialog: inwardvaluationUIContext.openViewDialog,
      openEditDialog: inwardvaluationUIContext.openEditDialog,
      openDeleteDialog: inwardvaluationUIContext.openDeleteDialog
    };
  }, [inwardvaluationUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "TITLE.PRODUCT.NAME"
      }),
      ...(reportInwardValuationTotalCount &&
        reportInwardValuationTotalCount > 0 && {
          footer: "Total"
        }),
      style: {
        minWidth: "100px"
      }
    },
    {
      dataField: "stock_keeping_unit",
      text: intl.formatMessage({
        id: "TITLE.SKU"
      }),
      ...(reportInwardValuationTotalCount &&
        reportInwardValuationTotalCount > 0 && {
          footer: ""
        }),
      style: {
        minWidth: "130px"
      }
    },
    {
      dataField: "count",
      text: intl.formatMessage({
        id: "TITLE.QTY.ORDERED"
      }),
      ...(reportInwardValuationTotalCount &&
        reportInwardValuationTotalCount > 0 && {
          footer: ""
        }),
      style: {
        minWidth: "130px"
      }
    },
    {
      dataField: "available_quantity",
      text: intl.formatMessage({
        id: "TITLE.STOCK.ON.HAND"
      }),
      ...(reportInwardValuationTotalCount &&
        reportInwardValuationTotalCount > 0 && {
          footer: ""
        }),
      style: {
        minWidth: "150px"
      }
    },
    {
      dataField: "amount",
      text: intl.formatMessage({
        id: "TITLE.TOTAL.AMOUNT"
      }),
      ...(reportInwardValuationTotalCount &&
        reportInwardValuationTotalCount > 0 && {
          footer: reportInwardValuationTotalAmount
        }),
      style: {
        minWidth: "130px"
      },
      formatter: AmountColumnFormatter
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: reportInwardValuationTotalCount
      ? reportInwardValuationTotalCount
      : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: inwardvaluationUIProps.queryParams.pageSize,
    page: inwardvaluationUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-bordered table-vertical-center inward-table table-inventory'
                bootstrap4
                bordered={false}
                remote
                keyField='id'
                data={reportInwardValuation}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  inwardvaluationUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              <NoRecordsFoundMessage
                entities={reportInwardValuation}
                isLoading={isLoading}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
