import React, { useContext, useEffect, useRef, useState } from "react";
import { InwardCard } from "./InwardCard";
import { InwardUIProvider } from "./InwardUIContext";
import { initialFilter } from "./InwardUIHelpers";
import { ErrorModal } from "../common/modals/ErrorModal";
import { DeleteModal } from "../common/modals/DeleteModal";
import { EditStatusPOModal } from "../../global-modal/EditStatusPOModal";
import {
  agreeStatusPOApi,
  deletePOApi,
  editPOStatusApi,
  POListApi,
  POStatusListApi
} from "../../axios/services/services";
import { SuccessModal } from "../common/modals/SuccessModal";
import { setActiveMenu, unauthorization } from "../../constants/utils";
import { useIntl } from "react-intl";
import { getErrorMessageBasedOnLanguage } from "../../constants/utils";
import { USER_DETAILS } from "../../constants/const";
import { MENUITEMS } from "../../constants/menu";
import { UIContext } from "../../helper/UIContext";
import { ConfirmationModal } from "../common/modals/ConfirmationModal";
import { Check, X } from "react-feather";

export function InwardPage({ history }) {
  const uiContext = useContext(UIContext);
  const [data, setData] = useState({});
  const [POList, setPOList] = useState(null);
  const [POStatusList, setPOStatusList] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditStatusModal, setEditStatusModal] = useState(false);
  const [
    isConfirmationAgreeStatusModalOpen,
    setConfirmationAgreeStatusModalOpen
  ] = useState(false);
  const [
    isConfirmationDisagreeStatusModalOpen,
    setConfirmationDisagreeStatusModalOpen
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [isLoadingDeleteInward, setLoadingDeleteInward] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [isLoadingEditPOStatus, setLoadingEditPOStatus] = useState(false);
  const [isLoadingConfirmationAgreeStatus, setLoadingConfirmationAgreeStatus] =
    useState(false);
  const [
    isLoadingConfirmationDisagreeStatus,
    setLoadingConfirmationDisagreeStatus
  ] = useState(false);
  const [isEditPOStatusSuccess, setEditPOStatusSuccess] = useState(false);
  const [selectedPOStatus, setSelectedPOStatus] = useState(null);
  const [isUpdatePODetails, setUpdatePODetails] = useState(0);
  const [POListTotalCount, setPOListTotalCount] = useState();
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const exportCSVButton = useRef();
  const intl = useIntl();

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  async function getPOList() {
    enableLoading();
    if (isFilter) {
      enableLoadingFilter();
    }
    const POListResponse = await POListApi({
      queryParams
    });
    disableLoading();
    if (isFilter) {
      disableLoadingFilter();
    }
    setFilter(false);
    const { Error, data, count } = POListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setPOList(data ? data : []);
    setPOListTotalCount(count ? count : 0);
  }

  async function getPOStatusList() {
    const POStatusListResponse = await POStatusListApi({});
    const { Error, get_po_status } = POStatusListResponse || {};
    if (Error) {
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    const poStatusList = get_po_status.map((status) => {
      const { id, name } = status || {};
      status.label = name;
      status.value = id;
      return status;
    });
    setPOStatusList(poStatusList ? poStatusList : []);
  }

  useEffect(() => {
    getPOList();
  }, [queryParams, isUpdatePODetails]);

  useEffect(() => {
    getPOStatusList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleDeleteModalShow = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalHide = () => {
    setIsDeleteModalOpen(false);
  };

  const handleEditStatusModalShow = () => {
    setEditStatusModal(true);
  };

  const handleEditStatusModalHide = () => {
    setEditStatusModal(false);
  };

  const handleConfirmationAgreeStatusModalShow = () => {
    setConfirmationAgreeStatusModalOpen(true);
  };

  const handleConfirmationAgreeStatusModalHide = () => {
    setConfirmationAgreeStatusModalOpen(false);
  };

  const handleConfirmationDisagreeStatusModalShow = () => {
    setConfirmationDisagreeStatusModalOpen(true);
  };

  const handleConfirmationDisagreeStatusModalHide = () => {
    setConfirmationDisagreeStatusModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingFilter = () => {
    setLoadingFilter(true);
  };

  const disableLoadingFilter = () => {
    setLoadingFilter(false);
  };

  const enableLoadingEditPOStatus = () => {
    setLoadingEditPOStatus(true);
  };

  const disableLoadingEditPOStatus = () => {
    setLoadingEditPOStatus(false);
  };

  const enableLoadingConfirmationAgree = () => {
    setLoadingConfirmationAgreeStatus(true);
  };

  const disableLoadingConfirmationAgree = () => {
    setLoadingConfirmationAgreeStatus(false);
  };

  const enableLoadingConfirmationDisagree = () => {
    setLoadingConfirmationDisagreeStatus(true);
  };

  const disableLoadingConfirmationDisagree = () => {
    setLoadingConfirmationDisagreeStatus(false);
  };

  const enableLoadingDeleteInward = () => {
    setLoadingDeleteInward(true);
  };

  const disableLoadingDeleteInward = () => {
    setLoadingDeleteInward(false);
  };

  const showSuccessModal = () => {
    setSuccessModalOpen(true);
  };

  const hideSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const handleCLickOfFilterButtonFilter = (values) => {
    setFilter(true);
  };

  const handleClickOfRemoveFilterButton = () => {
    setFilterOpen(!isFilterOpen);
    setFilter(false);
    setQueryParamsBase(initialFilter);
  };

  const handleClickOfEditStatusPOUpdateButton = () => {
    editPurchaseOrderStatus();
  };

  const onClickOfConfirmationAgreeStatusButton = () => {
    AgreePurchaseOrderStatus();
  };

  const onClickOfConfirmationDisagreeStatusButton = () => {
    DisagreePurchaseOrderStatus();
  };

  const handleExportButton = async () => {};

  const handleOnClickOfCreateNew = () => {
    const path = "/inwards/add-inward";
    onClickChangeRoute(path);
  };

  const onClickOfDeleteButton = () => {
    deletePurchaseOrder();
  };

  const deletePurchaseOrder = async () => {
    const { id } = data || {};
    enableLoadingDeleteInward();
    const deletePOResponse = await deletePOApi({ id });
    const { Error } = deletePOResponse || {};
    if (Error) {
      disableLoadingDeleteInward();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    setIsDeleteModalOpen(false);
    setSuccessMessage(
      intl.formatMessage({ id: "TITLE.PURCHASE.ORDER.DELETED.SUCCESSFULLY" })
    );
    showSuccessModal();
    window.scrollTo(0, 0);
    setUpdatePODetails(Math.random());
    disableLoadingDeleteInward();
  };

  const editPurchaseOrderStatus = async () => {
    const { id } = data || {};
    enableLoadingEditPOStatus();
    const editPOStatusResponse = await editPOStatusApi({
      id,
      purchaseorderstatusId: selectedPOStatus.value
    });
    const { Error } = editPOStatusResponse || {};
    if (Error) {
      disableLoadingEditPOStatus();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    window.scrollTo(0, 0);
    setEditPOStatusSuccess(true);
    setTimeout(() => {
      disableLoadingEditPOStatus();
      setEditStatusModal(false);
      setEditPOStatusSuccess(false);
      setUpdatePODetails(Math.random());
    }, 2500);
  };

  const AgreePurchaseOrderStatus = async () => {
    enableLoadingConfirmationAgree();
    const { id } = data || {};
    const [POStatus] = POStatusList.filter((status) => {
      return status.name === "Approved";
    });
    const agreeStatusPOResponse = await agreeStatusPOApi({
      id,
      purchaseorderstatusId: POStatus.id
    });
    const { Error } = agreeStatusPOResponse || {};
    if (Error) {
      disableLoadingConfirmationAgree();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    window.scrollTo(0, 0);
    handleConfirmationAgreeStatusModalHide();
    setUpdatePODetails(Math.random());
    disableLoadingConfirmationAgree();
  };

  const DisagreePurchaseOrderStatus = async () => {
    enableLoadingConfirmationDisagree();
    const { id } = data || {};
    const [POStatus] = POStatusList.filter((status) => {
      return status.name === "Rejected";
    });
    const disagreeStatusPOResponse = await agreeStatusPOApi({
      id,
      purchaseorderstatusId: POStatus.id
    });
    const { Error } = disagreeStatusPOResponse || {};
    if (Error) {
      disableLoadingConfirmationDisagree();
      unauthorization(Error);
      setErrorMessage(getErrorMessageBasedOnLanguage(Error));
      handleErrorModalShow();
      return;
    }
    window.scrollTo(0, 0);
    handleConfirmationDisagreeStatusModalHide();
    setUpdatePODetails(Math.random());
    disableLoadingConfirmationDisagree();
  };

  const inwardUIEvents = {
    openViewDialog: (row) => {
      const path = `/inwards/view-po-details/${row.id}`;
      onClickChangeRoute(path);
    },
    openEditDialog: (row) => {
      setData(row);
      // const { purchaseorderstatus } = row || {};
      // const { name } = purchaseorderstatus || {};
      // if (name === "Received") {
      //   setErrorMessage(
      //     intl.formatMessage({
      //       id: "TITLE.YOU.CANT.EDIT.PURCHASE.ORDER.ONCE.RECEIVED",
      //     })
      //   );
      //   handleErrorModalShow();
      //   return;
      // }
      const path = `/inwards/edit-inward/${row.id}`;
      onClickChangeRoute(path);
    },
    openEditStatusDialog: (row) => {
      setData(row);
      if (userRole === "Asset Manager") {
        setData(row);
        const { purchaseorderstatusId, purchaseorderstatus } = row || {};
        const { name } = purchaseorderstatus || {};
        if (name === "Waiting For Approval") {
          const [POStatus] = POStatusList.filter((status) => {
            return status.id === purchaseorderstatusId;
          });
          setSelectedPOStatus(POStatus);
          handleEditStatusModalShow();
        }
      }
    },
    openAgreeStatusDialog: (row) => {
      setData(row);
      handleConfirmationAgreeStatusModalShow();
    },
    openDisagreeStatusDialog: (row) => {
      setData(row);
      handleConfirmationDisagreeStatusModalShow();
    },
    openDeleteDialog: (row) => {
      const { purchaseorderstatus } = row || {};
      const { name: purchaseOrderStatus } = purchaseorderstatus || {};
      if (
        purchaseOrderStatus === "Waiting For Approval" ||
        (userRole === "Asset Manager" && purchaseOrderStatus === "Approved")
      ) {
        setData(row);
        handleDeleteModalShow();
      } else {
        setErrorMessage(
          intl.formatMessage({
            id: "TITLE.YOU.CAN.NOT.DELETE.PO"
          })
        );
        handleErrorModalShow();
      }
    },
    redirectToViewPage: (row) => {
      setData(row);
      const path = `/inwards/view-po-details/${row.id}`;
      onClickChangeRoute(path);
    },
    redirectToVendorViewPage: (row) => {
      setData(row);
      const path = `/vendors/view-vendor-details/${row.contactId}`;
      onClickChangeRoute(path);
    }
  };

  const onClickChangeRoute = (routePath) => {
    setMainMenu(setActiveMenu(mainmenu, routePath));
    uiContext.setCurrentPath(routePath);
    history.push(routePath);
  };

  return (
    <>
      <InwardUIProvider
        inwardUIEvents={inwardUIEvents}
        queryParams={queryParams}
        setQueryParamsBase={setQueryParamsBase}
        ids={ids}
        setIds={setIds}
      >
        {isEditStatusModal && (
          <EditStatusPOModal
            isEditStatusModal={isEditStatusModal}
            isLoadingEditPOStatus={isLoadingEditPOStatus}
            isEditPOStatusSuccess={isEditPOStatusSuccess}
            data={data}
            POStatusList={POStatusList}
            selectedPOStatus={selectedPOStatus}
            setSelectedPOStatus={setSelectedPOStatus}
            handleClickOfEditStatusPOUpdateButton={
              handleClickOfEditStatusPOUpdateButton
            }
            onClickOfEditStatusPOCancelButton={handleEditStatusModalHide}
          />
        )}

        {isConfirmationAgreeStatusModalOpen && (
          <ConfirmationModal
            isLoading={isLoadingConfirmationAgreeStatus}
            title={intl.formatMessage({ id: "TITLE.APPROVE.PO" })}
            isSuccess={"text-success"}
            variant={"success"}
            icon={
              <Check
                size={60}
                className='text-success mb-2'
                style={{ strokeWidth: "1.5px" }}
              />
            }
            description={intl.formatMessage({
              id: "TITLE.ARE.YOU.SURE.YOU.WANT.TO.APPROVE.PURCHASE.ORDER"
            })}
            cancelText={intl.formatMessage({ id: "TITLE.CANCEL" })}
            logoutText={intl.formatMessage({ id: "TITLE.YES" })}
            isOpen={isConfirmationAgreeStatusModalOpen}
            onCloseModal={handleConfirmationAgreeStatusModalHide}
            id={data.purchaseorder_number}
            onClickOfConfirmationButton={onClickOfConfirmationAgreeStatusButton}
          />
        )}

        {isConfirmationDisagreeStatusModalOpen && (
          <ConfirmationModal
            isLoading={isLoadingConfirmationDisagreeStatus}
            title={intl.formatMessage({ id: "TITLE.REJECT.PO" })}
            icon={
              <X
                size={60}
                className='text-danger mb-2'
                style={{ strokeWidth: "1.5px" }}
              />
            }
            variant={"danger"}
            isSuccess={"text-danger"}
            description={intl.formatMessage({
              id: "TITLE.ARE.YOU.SURE.YOU.WANT.TO.REJECT.PURCHASE.ORDER"
            })}
            cancelText={intl.formatMessage({ id: "TITLE.CANCEL" })}
            logoutText={intl.formatMessage({ id: "TITLE.YES" })}
            isOpen={isConfirmationDisagreeStatusModalOpen}
            onCloseModal={handleConfirmationDisagreeStatusModalHide}
            id={data.purchaseorder_number}
            onClickOfConfirmationButton={
              onClickOfConfirmationDisagreeStatusButton
            }
          />
        )}

        {isDeleteModalOpen && (
          <DeleteModal
            isLoading={isLoadingDeleteInward}
            id={data.purchaseorder_number}
            title={intl.formatMessage({
              id: "TITLE.DELETE.INWARD"
            })}
            description={intl.formatMessage({
              id: "TITLE.INWARD.WILL.BE.DELETED.FOREVER.AND.CANNOT.BE.RETRIEVED.LATER"
            })}
            isOpen={isDeleteModalOpen}
            onCloseModal={handleDeleteModalHide}
            onClickOfDeleteButton={onClickOfDeleteButton}
          />
        )}

        <InwardCard
          isLoading={isLoading}
          isLoadingFilter={isLoadingFilter}
          isFilterOpen={isFilterOpen}
          POList={POList}
          POStatusList={POStatusList}
          POListTotalCount={POListTotalCount}
          onClickOfCreateNew={handleOnClickOfCreateNew}
          handleClickOfRemoveFilterButton={handleClickOfRemoveFilterButton}
          handleCLickOfFilterButtonFilter={handleCLickOfFilterButtonFilter}
        />
      </InwardUIProvider>

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
        />
      )}

      {isSuccessModalOpen && (
        <SuccessModal
          open={isSuccessModalOpen}
          onCloseModal={hideSuccessModal}
          message={successMessage}
        />
      )}
    </>
  );
}
