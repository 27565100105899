import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
  amountFormatter,
  capitalizeFirstLetter,
  getMediaUrl,
  getProductMediaUrl
} from "../../../constants/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { useLang } from "../../../i18n";
import { USER_DETAILS } from "../../../constants/const";
import { ImageWrapper } from "../../../helper/ImageWrapper";
import { getCustomFieldListOfAssetApi } from "../../../axios/services/services";
import { EditCustomFieldModal } from "./EditCustomFieldModal";
import { RepairModal } from "./RepairModal";
import moment from "moment";

export function DefaultAssetView({ assetDetails, id, POSummary, getSingleAssetDetails=()=>{}}) {
  
  const [customFieldData,setCustomFieldData] = useState([])
  const [showEditModel,setShowEditModel] = useState(false)
  const [showRepairModel, setShowRepairModel] = useState(false);
  const selectedLang = useLang();
  const {
    barcode_no,
    product,
    under_maintance,
  } = assetDetails || {};
  const {
    category, productunit, product_media
  } = product;
  const { name: productUnitName } = productunit || {};
  const { name: categoryName } = category || {};
  const [productMedia] = product_media || [];
  const { name: productMediaName } = productMedia || {};
  const [data] = POSummary || [];
  let chartLables = [];

  const { po_details, dc_details, labels } = data || {};
  const chartPOData = po_details;
  const chartDCData = dc_details;
  if (labels) {
    if (selectedLang === "ar") {
      chartLables = labels.reverse();
    } else {
      chartLables = labels;
    }
  }

  const intl = useIntl();

  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const { role } = userDetails || {};
  const { name: userRole } = role || {};

  // PO Summary Graph

  const poObj = {
    label: intl.formatMessage({ id: "TITLE.PO.AMOUNT" }),
    lagend: "none",
    data: chartPOData,
    backgroundColor: "transparent",
    borderColor: "rgba(0, 153, 112, 1)"
  };

  const dcObj = {
    label: intl.formatMessage({ id: "TITLE.CR.AMOUNT" }),
    lagend: "none",
    data: chartDCData,
    backgroundColor: "transparent",
    borderColor: "rgba(153, 0, 41, 1)"
  };

  const summaryChart = {
    responsive: true,
    labels: chartLables,
    ...(userRole === "Asset Manager" && { datasets: [poObj, dcObj] }),
    ...(userRole === "Inward Manager" && { datasets: [poObj] }),
    ...(userRole === "Outward Manager" && { datasets: [dcObj] })
  };

  const getCustomFieldData = async () => {
    let data = await getCustomFieldListOfAssetApi({id})
    setCustomFieldData(data.data ?? [])
    console.table(data.data)
  }

  useEffect(()=>{
    getCustomFieldData()
  },[id])

  const showDate = (value) => {
    try{
      return value ? moment(value).format("LL") : "-"
    }
    catch(err){
      return "-"
    }
  }

  const showDateTime = (value) => {
    try{
      return value ? moment(value).format("hh:mm A MMMM DD, YYYY") : "-"
    }
    catch(err){
      return "-"
    }
  }

  // Function to handle file download
  const handleDownload = (filename) => {
    let assetUrl = getMediaUrl(filename, "product");
    window.open(assetUrl);
  };

  return (
    <>
      <div className='view-body'>
        <Row className='mt-4 mb-5'>
          <Col md={6} style={{ paddingRight: "30px", paddingBottom: "80px" }}>
            <div
              style={{
                border: "1px solid #eee",
                borderRadius: "8px",
                overflow: "hidden"
              }}
            >
              <ImageWrapper
                className={"img-radius align-top blur-up lazyloaded img-fluid"}
                src={getProductMediaUrl(productMediaName)}
              />
            </div>
          </Col>
          { showEditModel ? <EditCustomFieldModal id={id} open={showEditModel} onCloseModal={()=>setShowEditModel(false)} customFieldData={customFieldData} getCustomFieldData={getCustomFieldData}/> : ""}
          {showRepairModel ? ( // Conditionally render the RepairModal
            <RepairModal
              id={id}
              open={showRepairModel}
              onCloseModal={() => {
                setShowRepairModel(false)
                getSingleAssetDetails()
              }}
            />
          ) : (
            ""
          )}
          <Col md={6} lg={6}>
            <Row>
              <Col md={10} xs={3}>
                <div className="text-right">
                  <button type="button" className="btn btn-outline-secondary" onClick={()=>{
                    setShowRepairModel(true);
                  }}><FormattedMessage id='TITLE.ASSET.NEED.TO.REPAIR' /></button>
                </div>
              </Col>
              <Col md={2} xs={9}>
                <div className="text-right">
                  <button type="button" className="btn btn-outline-secondary" onClick={()=>{
                    setShowEditModel(true);
                  }}><FormattedMessage id='TITLE.EDIT'/></button>
                </div>
              </Col>
              <Col md={6} xs={7}>
                <div>
                  <h3 className='modal-title'>
                    <FormattedMessage id='TITLE.PRIMARY.DETAILS' />
                  </h3>
                </div>
              </Col>
            </Row>    
            <div className='address'>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className='text-dark'>
                    <FormattedMessage id='TITLE.BARCODE.NO' /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className='text-blck'>
                  {barcode_no}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className='text-dark'>
                    <FormattedMessage id='TITLE.CATEGORY' /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className='text-blck'>
                  {capitalizeFirstLetter(categoryName)}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className='text-dark'>
                    <FormattedMessage id='TITLE.NAME' /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className='text-blck'>
                  {capitalizeFirstLetter(product?.name)}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className='text-dark'>
                    <FormattedMessage id='TITLE.STATUS' /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className='text-blck'>
                  {product?.status === 0
                    ? intl.formatMessage({ id: "TITLE.INACTIVE" })
                    : intl.formatMessage({ id: "TITLE.ACTIVE" })}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className='text-dark'>
                    <FormattedMessage id='TITLE.PRICE' /> :
                  </h6>
                </Col>
                <Col md={8} xs={4} className='text-blck'>
                  {amountFormatter(product?.price)}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className='text-dark'>
                    <FormattedMessage id='TITLE.ASSET.MAINTANCE.STATUS' /> :
                  </h6>
                </Col>
                <Col md={8} xs={4} className={under_maintance ? "text-danger" : "text-success"}>
                {under_maintance === 1 ? 'Under Maintenance' : 'Ready To Use'}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className='text-dark'>
                    <FormattedMessage id='TITLE.SKU' /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className='text-blck'>
                  {product?.stock_keeping_unit ? product?.stock_keeping_unit : "-"}
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={6}>
                  <h6 className='text-dark'>
                    <FormattedMessage id='TITLE.PRODUCT.UNIT' /> :
                  </h6>
                </Col>
                <Col md={8} xs={6} className='text-blck'>
                  {productUnitName}
                </Col>
              </Row>
              {customFieldData.map((customFieldItem,index)=>{
                return <Row key={index}>
                  <Col md={4} xs={6}>
                    <h6 className='text-dark'>
                      {(selectedLang === "en" ? customFieldItem?.customFieldInfo?.field_name : customFieldItem?.customFieldInfo?.field_name_in_arabic)}
                    </h6>
                  </Col>
                  <Col md={8} xs={6} className='text-blck'>
                    {
                     customFieldItem?.field_value ? (customFieldItem.customFieldInfo.field_type === "date" ? showDate(customFieldItem?.field_value) : 
                     customFieldItem.customFieldInfo.field_type === "datetime" ? showDateTime(customFieldItem?.field_value) : 
                     customFieldItem.customFieldInfo.field_type === "file" ? 
                     <a href={"#"} onClick={()=>handleDownload(customFieldItem?.field_value)}>Download File</a> : customFieldItem?.field_value) : "-"
                    }
                  </Col>
                </Row>
              })}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DefaultAssetView;
